/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import {api} from '../../../../api'



const Header = () => {

  const [count,setCount] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("gmdashboardcount.php");
        setCount(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="header bg-gradient-info pb-0 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            
            <Row>
                        <Col lg="6" xl="3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">Completed Project</CardTitle>
                                        </div>
                                        
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #759bff, #843cf6)' }}>
                                                <i className="fas fa-users fa-beat-fade"></i>
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{count.completed_projects}</span>

                                    
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="6" xl="3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">Ongoing Projects</CardTitle>


                                        </div>
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #fc5286, #fbaaa2)' }}>
                                                <i className="fas fa-chart-pie fa-beat-fade" style={{ '--fa-animation-duration': '2s;', '--fa-fade-opacity': '0.6;' }}></i>
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{count.todo_projects}</span>

                                    


                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="6" xl="3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">Clients</CardTitle>


                                        </div>
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #ffc480, #ff763b)' }}>
                                                <i className="fa-solid fa-list-check fa-beat-fade" />
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{count.client_count}</span>

                                   
                                </CardBody>
                            </Card>
                        </Col>

                        {/* Completed Tasks Card */}
                        <Col lg="6" xl="3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">Total Request</CardTitle>


                                        </div>
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #0e4cfd, #6a8eff)' }}>
                                                <i className="fas fa-chart-line  fa-beat-fade" />
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{count.total_requests}</span>

                                    
                                </CardBody>
                            </Card>
                        </Col>

                        

              </Row>
              <Row className="mt-4">
                        <Col lg="6" xl="3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">Actioned Request</CardTitle>
                                        </div>
                                        
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #759bff, #843cf6)' }}>
                                                <i className="fas fa-users fa-beat-fade"></i>
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{count.approved_requests}</span>

                                    
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="6" xl="3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">Unactioned Request</CardTitle>


                                        </div>
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #fc5286, #fbaaa2)' }}>
                                                <i className="fas fa-chart-pie fa-beat-fade" style={{ '--fa-animation-duration': '2s;', '--fa-fade-opacity': '0.6;' }}></i>
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{count.unapproved_requests}</span>

                                    


                                </CardBody>
                            </Card>
                        </Col>

                        

              </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const Budgetcard = () => {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Employees
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          350,897
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Department
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">2,356</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Projects
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">924</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-warning mr-2">
                        <i className="fas fa-arrow-down" /> 1.10%
                      </span>{" "}
                      <span className="text-nowrap">Since yesterday</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Status
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">49,65%</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-percent" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fas fa-arrow-up" /> 12%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;

