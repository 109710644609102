import React, { useState, useEffect } from "react";
import {
  Card,
  Badge,
  Button,
  Table,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Label,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
  CardHeader,
} from "reactstrap";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CircleChart from "dashboard/hr-management/variables/circlebar";
import { api } from "../../../../api";

const Materials4 = () => {
  const [matdata, setmatdata] = useState();

  const [dropdownOpen, setDropdownOpen] = useState(false);


  const [searchQuery, setSearchQuery] = useState("");


  //Pagination code part 1 goes here

  const [allData, setAllData] = useState([]);
const [displayData, setDisplayData] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [entriesToShow, setEntriesToShow] = useState(5);

  const toggleDropdown = (index) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [countDetails, setcountDetails] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`materailsdetailsstore.php?all='all'`);
        setAllData(response.data);

        setCurrentPage(1);

        const response2 = await api.get(`materailsdetailsstore.php`);
        setcountDetails(response2.data);
        setLoading(false);
        console.log("allData",allData);
        console.log("countDetails",countDetails);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [searchQuery]);



 
  const [showRoleModal, setShowRoleModal] = useState(false);

  const toggleRole = () => {
    setShowRoleModal(!showRoleModal);
  };

  const [selectedId, setSelectedId] = useState(null);

  const [report, setreport] = useState({status:'',description:'',cost:''});

  console.log('repo',report);


  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await api.post("materialreport.php", report);
  //     console.log("Data sent successfully:", response.data);
  //     // window.location.reload();
  //   } catch (error) {
  //     console.error("Error submitting data:", error);
  //   }
  // };

  const handleItemClick = (id) => {
    // setSelectedId(id);
    setreport((prevState) => ({
      ...prevState,
      id: id,
    }));
    toggleModal();
  };

  const [loading, setLoading] = useState(true);

  const handleStatusAction = async (e) => {
    // e.preventDefault();
    try {
      const response = await api.post("materialreport.php", report);
      console.log("Data sent successfully:", response.data);
      // window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    window.location.reload();
  };

  const handleDownload = () => {
    // const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    // const fileExtension = '.xlsx';
    // const fileName = 'material_list';
    // // Convert dataToShow to the format needed for XLSX.utils.json_to_sheet
    // const formattedData = allData && allData?.map((item,index) => ({
    //   'Sl.No': ++index,
    //   'Item Name': item.mat_name,
    //   'Quantity': item.mat_quantity,
    //   'Cost ': item.mat_money,
    //   'Requested On': item.Date_Time,
    //   'Priority': item.Priority
    // }));
    // // Create a worksheet
    // const ws = XLSX.utils.json_to_sheet(formattedData, {
    //   header: ['Sl.No', 'Item Name', 'Quantity', 'Cost','Requested On','Priority'],
    // });
    // // Create a workbook
    // const wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Material List');
    // // Apply bold and background color to headers
    // const headerCellStyle = { font: { bold: true }, fill: { bgColor: { indexed: 64 } } };
    // Object.keys(ws).filter((key) => key.startsWith('A1')).forEach((key) => {
    //   ws[key].s = headerCellStyle;
    // });
    // // Convert the workbook to a binary Excel file
    // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    // // Create a Blob from the Excel buffer
    // const excelData = new Blob([excelBuffer], { type: fileType });
    // // Create a URL for the Blob
    // const excelUrl = URL.createObjectURL(excelData);
    // // Create a link and trigger the download
    // const link = document.createElement('a');
    // link.href = excelUrl;
    // link.download = fileName + fileExtension;
    // document.body.appendChild(link);
    // link.click();
    // // Cleanup
    // setTimeout(function () {
    //   document.body.removeChild(link);
    //   window.URL.revokeObjectURL(excelUrl);
    // }, 100);
  };

  const statusOptions = [
    { value: "Approved", label: "Accept" },
    { value: "Rejected", label: "Reject" },
  ];

  const getStatusBadge = (status) => {
    switch (status) {
      case "pending":
        return <Badge color="warning">{status}</Badge>;
      case "Approved":
        return <Badge color="success">{status}</Badge>;
      case "Rejected":
        return <Badge color="danger">{status}</Badge>;
      default:
        return <Badge color="info">{status}</Badge>;
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedproject, setselectedproject] = useState();
  const [modal, setModal] = useState(false); // State to control modal visibility
  const [modalEmployee, setModalEmployee] = useState(null);

  const toggleModalView = () => {
    setModalOpen(!modalOpen);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const [projectDropdownOpen1, setProjectDropdownOpen1] = useState(false);

  useEffect(() => {
    const startIndex = (currentPage - 1) * entriesToShow;
    const endIndex = startIndex + entriesToShow;
    setDisplayData(allData.slice(startIndex, endIndex));
  }, [allData, currentPage, entriesToShow]);

  const totalPages = Math.ceil(allData.length / entriesToShow);

const handleEntriesChange = (value) => {
  const newEntriesToShow = value === 'All' ? allData.length : Number(value);
  setEntriesToShow(newEntriesToShow);
  setCurrentPage(1); // Reset to first page when changing entries
};

const prePage = () => {
  if (currentPage > 1) {
    setCurrentPage(prevPage => prevPage - 1);
  }
};

const nextPage = () => {
  if (currentPage < totalPages) {
    setCurrentPage(prevPage => prevPage + 1);
  }
};

const getSerialNumber = (index) => {
  return (currentPage - 1) * entriesToShow + index + 1;
};


  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="d-flex justify-content-center align-items-center">
            <Col lg="6" xl="3" className="mb-3">
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{ borderRadius: "30px" }}
              >
                <CardBody>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CardTitle className="h4 mb-0">Total Requests</CardTitle>
                    </div>
                    <div style={{ height: "30px", width: "30px" }}>
                      <div
                        className="icon icon-shape bg-danger text-white rounded-circle shadow"
                        style={{
                          background:
                            "linear-gradient(230deg, #759bff, #843cf6)",
                        }}
                      >
                        <i className="fa-regular fa-comment-dots fa-fade"></i>
                      </div>
                    </div>
                  </div>
                  <span className="h2 font-weight-bold  ml-3">
                    {countDetails && countDetails.length > 0
                      ? countDetails[0]?.total_request
                      : 0}
                  </span>
                </CardBody>
              </Card>
            </Col>

            {/* Completed Projects Card */}
            <Col lg="6" xl="3" className="mb-3">
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{ borderRadius: "30px" }}
              >
                <CardBody>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CardTitle className="h4 mb-0">Amount Spent</CardTitle>
                    </div>
                    <div style={{ height: "30px", width: "30px" }}>
                      <div
                        className="icon icon-shape bg-danger text-white rounded-circle shadow"
                        style={{
                          background:
                            "linear-gradient(230deg, #569715, #569715)",
                        }}
                      >
                        <i
                          className="fa-solid fa-wallet fa-spin"
                          style={{
                            "--fa-animation-duration": "2s;",
                            "--fa-fade-opacity": "0.6;",
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <span className="h2 font-weight-bold  ml-3">
                    {countDetails && countDetails.length > 0
                      ? countDetails[0]?.Amount_spent
                      : null}
                  </span>
                </CardBody>
              </Card>
            </Col>

            {/* Inprogress Projects Card */}
            <Col lg="6" xl="3" className="mb-3">
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{ borderRadius: "30px" }}
              >
                <CardBody>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CardTitle className="h4 mb-0">
                        Approved Request
                      </CardTitle>
                    </div>
                    <div style={{ height: "30px", width: "30px" }}>
                      <div
                        className="icon icon-shape bg-danger text-white rounded-circle shadow"
                        style={{
                          background:
                            "linear-gradient(230deg, #C50C36, #C50C36)",
                        }}
                      >
                        <i className="fa-solid fa-coins fa-bounce" />
                      </div>
                    </div>
                  </div>
                  <span className="h2 font-weight-bold  ml-3">
                    {countDetails && countDetails.length > 0
                      ? countDetails[0]?.Approved_request
                      : null}
                  </span>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" xl="3" className="mb-3">
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{ borderRadius: "30px" }}
              >
                <CardBody>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CardTitle className="h4 mb-0">
                        Pending Requested
                      </CardTitle>
                    </div>
                    <div style={{ height: "30px", width: "30px" }}>
                      <div
                        className="icon icon-shape bg-danger text-white rounded-circle shadow"
                        style={{
                          background:
                            "linear-gradient(230deg, #7AA2E3, #7AA2E3)",
                        }}
                      >
                        <i className="fa-solid fa-scale-unbalanced-flip fa-flip" />
                      </div>
                    </div>
                  </div>
                  <span className="h2 font-weight-bold  ml-3">
                    {countDetails && countDetails.length > 0
                      ? countDetails[0]?.Pending_request
                      : null}
                  </span>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="header bg-gradient-info py-5  ">
            <Container fluid>
              <Row className="mt-5">
                <Col className="mb-5 mb-xl-0" xl="12">
                  <Card className="shadow">
                    <CardBody>
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <div className="col">
                            <h3 className="mb-0">Materials List</h3>
                          </div>
                          <div className="col text-right">
                            <Dropdown
                            isOpen={projectDropdownOpen1}
                            toggle={()=>{setProjectDropdownOpen1(!projectDropdownOpen1)}}
                            >
                              <DropdownToggle caret>
                                Show Entries:{" "}
                                {entriesToShow === allData.length
                                  ? "All"
                                  : entriesToShow}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => handleEntriesChange(5)}
                                >
                                  5
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => handleEntriesChange(10)}
                                >
                                  10
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => handleEntriesChange("All")}
                                >
                                  All
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                            <button
                              className="btn btn-success mr-2"
                              onClick={handleDownload}
                            >
                              Download
                            </button>
                          </div>
                        </Row>
                      </CardHeader>

                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr style={{ textAlign: "center" }}>
                            <th
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "700",
                              }}
                              scope="col"
                            >
                              {" "}
                              Sl.No{" "}
                            </th>
                            <th
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "700",
                              }}
                              scope="col"
                            >
                              {" "}
                              Item Name{" "}
                            </th>
                            <th
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "700",
                              }}
                              scope="col"
                            >
                              {" "}
                              Quantity{" "}
                            </th>
                            <th
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "700",
                              }}
                              scope="col"
                            >
                              {" "}
                              Cost{" "}
                            </th>
                            <th
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "700",
                              }}
                              scope="col"
                            >
                              {" "}
                              Priority{" "}
                            </th>
                            <th
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "700",
                              }}
                              scope="col"
                            >
                              {" "}
                              status{" "}
                            </th>
                            <th
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "700",
                              }}
                              scope="col"
                            >
                              {" "}
                              Action{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                          {displayData  && displayData .length > 0 ? (
                            displayData .map((item, index) => (
                              <tr key={index}>
                                <th scope='row'>{getSerialNumber(index)}</th>
                                <td>{item.mat_name}</td>
                                <td>{item.mat_quantity}</td>
                                <td>{item.mat_money}</td>
                                <td>
                                  {item.Priority === "Low" ? (
                                    <Badge color="success">
                                      {item.Priority}
                                    </Badge>
                                  ) : item.Priority === "High" ? (
                                    <Badge color="danger">
                                      {item.Priority}
                                    </Badge>
                                  ) : (
                                    <Badge color="warning">
                                      {item.Priority}
                                    </Badge>
                                  )}
                                </td>
                                <td>{getStatusBadge(item.store_status)}</td>
                                <td>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => {
                                      setModalEmployee(item);
                                      toggleModalView();
                                    }}
                                  >
                                    <i
                                      className="fa-solid fa-eye"
                                      style={{ fontSize: "22px" }}
                                    ></i>
                                  </button>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => handleItemClick(item.m_id)}
                                  >
                                    Set Action
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="8" className="text-cenetr">
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>

                      <nav className="mt-3">
                        <ul className="pagination justify-content-center">
                          <li className="page-item">
                            <a
                              href="#"
                              className="page-link text-primary"
                              onClick={prePage}
                              disabled={currentPage === 1}
                            >
                              Prev
                            </a>
                          </li>
                          <li className="page-item">
                            <a
                              href="#"
                              className="page-link text-primary"
                              onClick={nextPage}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>




          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>
              <h2 style={{ fontWeight: "bolder" }}>Status Action</h2>
            </ModalHeader>
            <ModalBody>
              {/* Your form elements go here */}
              <Form onSubmit={handleStatusAction}>
                <FormGroup>
                  <Label for="exampleSelect">Select</Label>
                  <Input
                    className="form-control-alternative"
                    id="input-status"
                    type="select"
                    name="status"
                    onChange={(e)=>setreport({...report,status:e.target.value})}
                  >
                    <option value="">Select Status</option>
                    <option value="Approved">Accept</option>
                    <option value="Rejected">Reject</option>
                   
                  </Input>
                </FormGroup>
                {report.status === 'Approved' && (
                  <FormGroup>
                    <Label for="costInput">Enter Cost</Label>
                    <Input
                      type="text"
                      name="cost"
                      id="costInput"
                      onChange={(e)=>setreport({...report,cost:e.target.value})}
                      required
                    >
                    </Input>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="exampleText">Description</Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="exampleText"
                    onChange={(e)=>setreport({...report,description:e.target.value})}
                  />
                </FormGroup>
                <Row>
                  <Col lg="12" style={{ textAlign: "center" }}>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
                {/* Add more form fields as needed */}
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>

      {modalEmployee && modalEmployee && (
        <Modal isOpen={modalOpen} toggle={toggleModalView}>
          <ModalHeader toggle={toggleModalView}>Resource View</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <label
                    className="border p-2 rounded font-weight-bold"
                    style={{ width: "155px" }}
                  >
                    Material Name
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.mat_name}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Budget
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.mat_money}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Quantity
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.mat_quantity}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Priority
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.Priority}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Date
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.Date_Time}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    General Manager
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.mat_status}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Action
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.gm_report}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Store Manager
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.store_status}
                  </label>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default Materials4;
