import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  CardTitle,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { api } from "../../../../api";
import Select from "react-select";
import Cookies from "js-cookie";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const Project = () => {
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [showForm, setShowForm] = useState(false); // State to control form visibility
  const [showAddClientModal, setShowAddClientModal] = useState(false); // State to control "Add Client" modal visibility
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [deleteEmployee, setdeleteEmployee] = useState(null);

  const [clientForm, setClientForm] = useState([]);

  const [employeeIdToDelete, setEmployeeIdToDelete] = useState(null);

  const toggleDropdown = (id) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const [clientData, setClientData] = useState([
    {
      // Initialize your form data fields here
      client_name: "",
      client_email: "",
      client_number: "",
      company_name: "",
      p_name: "",
      p_budget: "",
      p_sqrfeet: "",
      p_location: "",
      p_timeline: "",
      contract_details: "",
    },
  ]);

  // Function to handle form submission
  const handleSubmit = async (event) => {
    // event.preventDefault(true);

    try {
      const response = await api.post("clientGM.php", clientData);
      console.log("Data sent successfully:", response.data);
      setClientData({
        client_name: "",
        client_email: "",
        client_number: "",
        company_name: "",
        p_name: "",
        p_budget: "",
        p_sqrfeet: "",
        p_location: "",
        p_timeline: "",
        contract_details: "",
      });

      // Optionally, you can update state or perform other actions after successful submission
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const toggleModal = () => setModalOpen((prevState) => !prevState);

  const toggleAddClientModal = () =>
    setShowAddClientModal((prevState) => !prevState);

  // const toggleDeleteConfirmationModal = () => setShowDeleteConfirmationModal(prevState => !prevState);

  const handleAddClient = () => {
    // Show the form when "Add Client" is clicked
    setShowForm(true);
    // Show the "Add Client" modal
    toggleAddClientModal();
  };

  <CKEditor
    editor={ClassicEditor}
    data={clientForm.description}
    onChange={(event, editor) => {
      const data = editor.getData();
      setClientForm((prevState) => ({
        ...clientData,
        contract_details: data,
      }));
    }}
  ></CKEditor>;

  // const handleInputChange = (event) => {
  //   event.preventDefault();
  //   const {name, value}  = event.target
  //   // console.log(event.target);
  //   setClientData({
  //     ...clientData,
  //     [event.target.name]: event.target.value
  //   });
  //   console.log(clientData);
  // };

  const [tasks, setTasks] = useState([]);
  const [multiTask,setMultiTask] = useState([]);
  const [loading, setLoading] = useState(true);

  const emp_id = Cookies.get("token");
  // let emp_id = 1;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(
          `viewProjectDetails.php?p_m_id=${emp_id}`
        );
        setTasks(response.data);
        setMultiTask(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Sample employee data (replace with your actual data)
  const employees = tasks;

  // console.log(tasks)

  // Function to filter employees based on search query
  // const filterEmployees = 
  // Update filtered employees when searchQuery changes
  // useEffect(
  //   () => {
  //     filterEmployees();
  //   },
  //   [searchQuery],
  //   tasks
  // );

  const filteredEmployee = multiTask.filter(employee => 
    employee.p_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleViewMoreModal = (employee) => {
    setSelectedEmployee(employee);
  };

  const renderViewMoreModal = () => {
    if (!selectedEmployee) return null;

    return (
      <Modal isOpen={true} toggle={() => toggleViewMoreModal(null)}>
        <ModalHeader toggle={() => toggleViewMoreModal(null)}>
          Employee View
        </ModalHeader>
        <ModalBody style={{ justifyContent: "center", textAlign: "center" }}>
          <img
            alt="..."
            className="rounded-circle"
            src={require("../../assets/img/theme/team-4-800x800.jpg")}
            style={{ height: 100, marginBottom: 10 }}
          />
          <p>
            {" "}
            <b>Project Name :</b> {selectedEmployee.p_name}
          </p>
          <p>
            {" "}
            <b>Project Status :</b> {selectedEmployee.p_status}
          </p>
          <p>
            <b>Project Budget :</b> {selectedEmployee.p_budget}
          </p>
          <p>
            <b>Site Managers :</b> {selectedEmployee.site_managers}
          </p>
          <p>
            <b>Project location :</b> {selectedEmployee.p_location}
          </p>
          <p>
            <b>Start Date:</b> {selectedEmployee.start_date}
          </p>
          <p>
            <b>End Date :</b> {selectedEmployee.end_date}
          </p>
          <p>
            <b>Project Expensive :</b> {selectedEmployee.p_expense}
          </p>
          <p>
            <b>Start Revenue:</b> {selectedEmployee.p_revenue}
          </p>
          <p>
            <b>Contract details :</b>{" "}
            {selectedEmployee.p_description.replace(/<[^>]*>?/gm, "")}{" "}
          </p>
          <p>
            <b>Files :</b>{" "}
            {/* <a href={`../../../images/${selectedEmployee.files}`} download> */}
              {/* {selectedEmployee.files}
            </a> */}
          </p>
          {/* Add other employee details as needed */}
        </ModalBody>
      </Modal>
    );
  };

  const [flippedStates, setFlippedStates] = useState({});

  const handleClick = (id) => {
    setFlippedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };



  const [deleteConfirmationEmployeeId, setDeleteConfirmationEmployeeId] =
    useState(null);

  const toggleDeleteConfirmationModal = (employeeId) => {
    setDeleteConfirmationEmployeeId(
      employeeId === deleteConfirmationEmployeeId ? null : employeeId
    );
  };

  const handleDropdownItemClick = (action, employeeId, employeeName) => {
    if (action === "Delete") {
      // Open delete confirmation modal
      toggleDeleteConfirmationModal(employeeId);
    } else {
      console.log(`Clicked ${action}`);
    }
  };

  const handleDeleteConfirmation = (confirmed, employeeId) => {
    if (confirmed) {
      console.log(`Deleting employee with ID: ${employeeId}`);
      const fetchData = async () => {
        try {
          const response = await api.get(`deleteProject.php?id=${employeeId}`);
          console.log(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
    toggleDeleteConfirmationModal(employeeId);
  };






  const [selectedSiteManagers, setSelectedSiteManagers] = useState([]);

  const siteOptions = [
    { value: "1", label: "harish" },
    { value: "2", label: "jyo" },
  ];

  const [members, setmembers] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("empGMdetails.php");

        // Extract only two variables and format them
        const formattedData = response.data.map((item) => ({
          value: item.emp_id, // Assuming employee id is stored in 'id'
          label: item.emp_name, // Assuming employee name is stored in 'name'
        })); // Take only the first two records

        setmembers(formattedData);
        // console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);



  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "ongoing", label: "Ongoing" },
    { value: "completed", label: "Completed" },
  ];

  // const handleProjectStatus = async (noti_Id, n_status) => {
  //   try {
  //     const response = await api.get("projectNotication.php", {n_id: noti_Id, status : n_status});
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error("Error updating notification status:", error);
  //   }
  // }

  const  handleProjectStatus = async (p_id, noti_Id, n_status) => {
    const fetchData = async () => {
      try {
        const response = await api.get(`projectNotification.php?n_id=${noti_Id}&&status=${n_status}&&p_id=${p_id}`);
        console.log(response.data);
        if(n_status === "Rejected"){
          setTasks((prevTasks) => prevTasks.filter((tasks) => tasks.p_id !== p_id));
          setMultiTask((prevTasks) => prevTasks.filter((multiTask) => multiTask.p_id !== p_id));
        }else{
          window.location.reload();
        }
        

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  };


  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="mb-5">
            <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
              <h1>Project Details</h1>
            </Col>
            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex text-right">
              <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                <FormGroup className="mb-0 ml-6">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search Project"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            {filteredEmployee && Array.isArray(filteredEmployee) ? (
              filteredEmployee.map((employee) => (
                <Col lg="6" xl="3" key={employee.id}>
                  <Card
                    onClick={() => handleClick(employee.p_id)}
                    className="card-stats mb-4 mb-xl-0 "
                    style={{
                      borderRadius: "30px",
                      position: "relative",
                      transformStyle: "preserve-3d",
                      transition: "transform 0.6s",
                      transform: flippedStates[employee.p_id] ? 'rotateY(180deg)' : 'rotateY(0deg)',
                      cursor: "pointer",
                      height: "300px",
                      zIndex: 1,
                      background: "rgb(255,255,255)",
                      background:
                        "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(230,249,251,1) 60%)",
                    }}
                  >
                    <CardBody
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className=" justify-content-center"
                        style={{ alignItems: "center", textAlign: "center" }}
                      >
                        <div>
                          <CardTitle className="h4 mb-0">
                            <h1>{employee.p_name}</h1>
                          </CardTitle>
                        </div>
                      </div>
                      <div className="pt-0"
                        style={{
                          width: "100px",
                          height: "100px",
                          text : "center"
                        }}
                      >
                        <CircularProgressbar
                          value={employee.project_progress}
                          text={`${employee.project_progress}%`}
                          styles={buildStyles({
                            textColor: "blue",

                            pathColor: "#007bff",
                            trailColor: "#eee",
                          })}
                        />
                        </div>
                        {employee && employee.status === "pending" && (
                          <>
                            <h4 className="text-center mb-0 p-0">New Project</h4>
                            <div className="d-flex mt-1">
                            <button className="btn btn-success p-1 w-20" onClick={() => handleProjectStatus(employee.p_id,employee.n_id , "Accepted")}>Accept</button>
                            <button className="btn btn-danger p-1 w-20" onClick={() => handleProjectStatus(employee.p_id,employee.n_id , "Rejected")}>Reject</button>
                            </div>
                          </>
                          
                        )}

                      
                    </CardBody>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        backfaceVisibility: "hidden",
                        transform: "rotateY(180deg)",
                        background:
                          "radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(230, 249, 251) 60%)",
                        borderRadius: "30px",
                        zIndex: "1",
                      }}
                    >
                      <CardBody className=" pt-md-4 ">
                        <div className="text-center">
                          <h3>{employee.p_name}</h3>
                          <h3>{employee.p_budget}</h3>
                          <h3>{employee.p_location}</h3>
                          <h3>{employee.end_date}</h3>
                          <div className="avatar-group d-flex justify-content-center">
                            {employee.profile.images.map((image, index) => (
                              <div
                                className="avatar-group "
                                style={{ height: "40px", width: "25px" }}
                                key={index}
                              >
                                <a
                                  className="avatar avatar-sm "
                                  href="#pablo"
                                  id={`tooltip-${employee.p_id}-${index}`}
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <img
                                    alt={`Image ${index}`}
                                    className="rounded-circle"
                                    style={{ height: "35px", width: "35px" }}
                                    src={require(`../../../images/${image}`)}
                                  />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-${employee.p_id}-${index}`}
                                >
                                  {employee.profile.full_names[index]}
                                </UncontrolledTooltip>
                              </div>
                            ))}
                          </div>
                          <hr className="my-4 " />
                          <div className="" style={{}}>
                          {employee && employee.status === "Accepted" && (
                            <Link
                              to={`/project-admin/project-management/editproject/${employee.p_id}`}
                              style={{ color: "Black" }}
                            >
                              <i
                                onClick={() => console.log("Edit clicked")}
                                class="fas fa-edit"
                                style={{ marginRight: "10px" }}
                              ></i>
                            </Link>
                          )}
                            <Link
                              to={`/project-admin/project-management/viewproject/${employee.p_id}`}
                              style={{ color: "Black" }}
                              onClick={() => toggleViewMoreModal(employee)}
                            >
                              <i
                                class="fa-solid fa-eye"
                                style={{ marginRight: "10px" }}
                              ></i>
                            </Link>
                          </div>
                        </div>
                      </CardBody>
                    </div>
                  </Card>
                </Col>
              ))
            ) : (
              // Render something else if tasks is not defined or is not an array
              <h3>No Project available</h3>
            )}
          </Row>
        </Container>






        
      </div>



      {renderViewMoreModal()}
    </>
  );
};

export default Project;
