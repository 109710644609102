import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// core components
import Header from "dashboard/project-management/components/Headers/Header";
import Select from "react-select";
import { api } from "../../../../api";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Editproject = () => {
  const { id } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [showAddClientModal, setShowAddClientModal] = useState(false); // New state for Add Project modal
  const [clientForm, setClientForm] = useState({});

  const toggleModal = () => setModalOpen((prevState) => !prevState);
  const toggleAddClientModal = () =>
    setShowAddClientModal((prevState) => !prevState); // Function to toggle Add Project modal

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleManagerSelectChange = (selectedOption) => {
    setSelectedManager(selectedOption);
  };

  const handleLocationSelectChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
  };

  const emp_id = 1;

  const [members, setmembers] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("empGMdetails.php");

        // Extract only two variables and format them
        const formattedData = response.data.map((item) => ({
          value: item.emp_id, // Assuming employee id is stored in 'emp_id'
          label: `${item.emp_name} [${item.role}] - (${item.project_count})`,
          isDisabled: item.project_count >= 1 ? true : false
        }));

        setmembers(formattedData);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [updateValue, setupdateValues] = useState({
    p_id: id,
    p_name: "",
    p_status: "",
    p_budget: "",
    site_manager: '',
    start_date: "",
    end_date: "",
    p_expense: "",
    p_revenue: "",
    p_location: "",
    p_description: "",
    files: '',
    emp_id : ''
  });

  useEffect(() => {
    api
      .get("viewProjectByID.php?p_id=" + id)
      .then((res) => {
        setupdateValues({
          ...updateValue,
          p_name: res.data[0].p_name,
          p_status: res.data[0].p_status,
          p_budget: res.data[0].p_budget,
          site_manager: res.data[0].site_manager,
          start_date: res.data[0].start_date,
          end_date: res.data[0].end_date,
          p_expense: res.data[0].p_expense,
          p_revenue: res.data[0].p_revenue,
          p_location: res.data[0].p_location,
          p_description: res.data[0].p_description,
          files: res.data[0].files,
          emp_id : res.data[0].project_manager_id
        });
        console.log(res.data);
        console.log(updateValue)
      })
      .catch((err) => console.log(err));
  }, [id]);

  const [project, setProject] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`viewProjectDetails.php?p_m_id=${emp_id}`);
                setProject(response.data);
                console.log(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);


  
  const handleFormSubmit = async (e) => {
    // e.preventDefault();

        const data = {
          p_id : updateValue.p_id,
          p_site_manager : updateValue.site_manager
        }

        console.log(data);

        try {
          const response = await api.get(`editProject.php?p_site_manager=${data.p_site_manager}&&p_id=${data.p_id}`);
          console.log("Data sent successfully:", response.data);
          window.location.reload();
          } catch (error) {
          console.error("Error submitting data:", error);
          }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setupdateValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(updateValue);
  };



  const [selectedSiteManagers, setSelectedSiteManagers] = useState();

  useEffect(() => {
    if (updateValue.site_manager != null) {
        const fetchData = async () => {
            try {
                const response = await api.get(
                    `empSiteManDetails.php?site_manager=${updateValue.site_manager}`
                );

                // Ensure response.data is an array and is not empty
                if (Array.isArray(response.data) && response.data.length > 0) {
                    const formattedData = response.data.map((item) => ({
                        value: item.emp_id, // Assuming employee id is stored in 'emp_id'
                        label: item.emp_name,
                    }));
                    setSelectedSiteManagers(formattedData);
                } else {
                    console.log("No data received or empty array");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }
}, [updateValue.site_manager]); // Add updateValue.site_manager as dependency


  const handleSiteManagerSelectChange = (selectedOptions) => {
    setSelectedSiteManagers(selectedOptions);
    setupdateValues((prevState) => ({
      ...prevState,
      site_manager: selectedOptions.map((option) => option.value),
    }));
    console.log(updateValue);
  };

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Col className="order-xl-2 mb-5 mb-xl-0 text-center  mb-5">
          <h1>Edit Project Details</h1>
        </Col>
        <Link to="/project-admin/project-management/project">
          {" "}
          <Button className="ml-5">Back</Button>
        </Link>
        <Container className="py-4 ">
          <div
            className="p-4 rounded"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid black",
            }}
          >
            <Form onSubmit={handleFormSubmit}>
              <div className="row">
                <Col md={6}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-name">
                      Project Name
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-name"
                      type="text"
                      name="p_name"
                      value={updateValue.p_name}
                      placeholder="Project Name"
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-name">
                      Project Budget
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-budget"
                      type="number"
                      name="p_budget"
                      defaultValue={updateValue.p_budget}
                      placeholder="Project Budget"
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-site-manager"
                    >
                      Select Engineers
                    </label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isMulti
                      defaultValue={selectedSiteManagers}
                      onChange={handleSiteManagerSelectChange}
                      options={members}
                      value={selectedSiteManagers}
                    />
                  </FormGroup>
                </Col>
                <div className="col-md-6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-age">
                      Project Starting Date
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-age"
                      type="date"
                      name="start_date"
                      defaultValue={updateValue.start_date}
                      onChange={handleInputChange}
                      placeholder="Starting Date"
                      readOnly
                    />
                  </FormGroup>
                </div>
                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-ending"
                    >
                      Project Ending Date
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-ending"
                      type="date"
                      name="end_date"
                      defaultValue={updateValue.end_date}
                      onChange={handleInputChange}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-status"
                    >
                      Project Expencess
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-budget"
                      type="number"
                      name="p_expense"
                      defaultValue={updateValue.p_expense}
                      onChange={handleInputChange}
                      placeholder="Project Budget"
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-status"
                    >
                      Project Revenue
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-budget"
                      type="number"
                      name="p_revenue"
                      defaultValue={updateValue.p_revenue}
                      onChange={handleInputChange}
                      placeholder="Project Budget"
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-location"
                    >
                      Location
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-location"
                      type="location"
                      name="p_location"
                      defaultValue={updateValue.p_location}
                      onChange={handleInputChange}
                      placeholder="Location"
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-description"
                    >
                      Project Description
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={updateValue.p_description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setupdateValues((prevState) => ({
                          ...prevState,
                          p_description: data,
                        }));
                      }}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </div>
              {/* <Link to="/admin/project"> */}
                <div className="row">
                  <div className="col-md-12 text-center">
                    {" "}
                    {/* Centered button */}
                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </div>
                </div>
              {/* </Link> */}
            </Form>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Editproject;
