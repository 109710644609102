
import React, { useState, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import Chart from "chart.js";
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Col,
  Badge,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Dropdown,  CardTitle,
} from "reactstrap";
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "dashboard/general-management/variables/charts.js";
import { api } from "../../../api";
import Cookies from "js-cookie";
import Calendar from 'react-calendar';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import the styles

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [chartData, setChartData] = useState({
    labels: ["January", "February", "March", "April", "May", "June", "July","Julay", "Augest", "September", "Octember", "November", "December",],
    datasets: [
      {
        label: "Example Dataset",
        data: [5, 20, 30, 81, 56, 55, 100, 30, 81, 56, 55, 10,0],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1
      }
    ]
  });

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);

   //All Projects

   const [selectedProject, setSelectedProject] = useState("All Projects");
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };


  const client_id = Cookies.get("token");
  const [project, setproject] = useState([]);
  const [countdetails, setcountdetails] = useState();

  const [progress, setProgress] = useState(0);
  const [budget, setBudget] = useState(0);

  const [message, setmessage] = useState();

  useEffect(() => {
    const fetchData = async () => {
        try {
          const response2 = await api.get(`clientproject.php?client_project_id=${client_id}`);
          setproject(response2.data);
          const response = await api.get(`clientcount.php?client_id=${client_id}`);
          setcountdetails(response.data);
          setProgress(response.data.progress);
          setBudget(Number(((response.data.material_cost / response.data.total_budget) * 100).toFixed(2)));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    fetchData();

    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      setmessage("Good Morning🌞");
    } else if (currentHour >= 12 && currentHour < 17) {
      setmessage("Good Afternoon⛅");
    } else if (currentHour >= 17 && currentHour < 21) {
      setmessage("Good Evening🌇");
    } else {
      setmessage("Good Night🌙");
    }

}, []);

  const handleProjectSelection = async (p_id, p_name) => {
    try {
      if(p_id == "all"){
        const response = await api.get(`clientcount.php?client_id=${client_id}`);
        setcountdetails(response.data);
        setProgress(response.data.progress);
        setBudget(Number(((response.data.material_cost / response.data.total_budget) * 100).toFixed(2)));
      }else{
        const response = await api.get(`clientcount.php?p_id=${p_id}&client_id=${client_id}`);
        setcountdetails(response.data);
        setProgress(response.data.progress);
        setBudget(Number(((response.data.material_cost / response.data.total_budget) * 100).toFixed(2)));
      }  
      
      setSelectedProject(p_name);
    
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // const bardata = {
  //   progress : countdetails.progress,
  //   budget : (countdetails.material_cost / countdetails.total_budget)/100
  // }



    const [date, setDate] = useState(new Date());
    const [selectedDateLabel, setSelectedDateLabel] = useState('');
    const [isBirthday, setIsBirthday] = useState(false);
    const [timeOfDay, setTimeOfDay] = useState('');

    // Function to format the selected date label
    const getFormattedDateLabel = (date) => {
        // Customize the date label format as needed
        return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
    };
    useEffect(() => {
        // Update the selected date label when the date changes
        setSelectedDateLabel(getFormattedDateLabel(date));
    }, [date]);

    const checkBirthday = (date) => {
        // Check if the selected date matches a predefined birthday (e.g., January 1)
        return date.getMonth() === 0 && date.getDate() === 1;
    };

    const getTimeOfDay = (date) => {
        const hour = date.getHours();

        if (hour >= 0 && hour < 12) {
            return 'Morning🌞';
        } else if (hour >= 12 && hour < 18) {
            return 'Afternoon⛅'; // Corrected to 'Afternoon'
        } else {
            return 'Night🌙';
        }
    };
  
  const data = {
    labels: ["Project Progress","Budget"], // Y-axis labels
    datasets: [
      {
        label: " Branch,",
        backgroundColor: "rgb(75, 192, 192)",
        data: [progress,budget], // Data for the single bar
      },
    ],
    
  };
  
  // Modify your options object
  const options = {
    indexAxis: 'y' ,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'left' ,
      },
      title: {
        display: true,
        text: 'Chart.js Horizontal Bar Chart',
      },
    },
  };
  
 
  

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }



console.log('selectedProject',selectedProject);


  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>   
          <div className="header-body">
            {/* Card stats */}
            <Row>
              {/* Your existing code... */}
              <Col lg="12" className="mb-3 mt-5">
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="mb-4 justify-content-end " >
                <DropdownToggle caret>
                  {selectedProject}
                </DropdownToggle>
                <DropdownMenu left>
                  {project && project.length > 0 ? (
                      <>
                          <DropdownItem onClick={() => handleProjectSelection("all","All Projects")}>
                              All Projects
                          </DropdownItem>
                          {project.map(project => (
                              <DropdownItem key={project.p_id} onClick={() => handleProjectSelection(project.p_id,project.p_name)}>
                                  {project.p_name}
                              </DropdownItem>
                          ))}
                      </>
                  ) : (
                      <DropdownItem>
                          No Projects
                      </DropdownItem>
                  )}


                </DropdownMenu>
              </Dropdown>
              </Col>

              {/* Total Projects Card */}
              <Col lg="6" xl="3" className="mb-3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            
                                            <CardTitle className="h4 mb-0">Project Progress</CardTitle>
                                        </div>
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #759bff, #843cf6)' }}>
                                                <i className="fas fa-chart-bar fa-beat-fade"></i>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="d-flex "> <span className="h2 font-weight-bold  ml-3" >{countdetails && countdetails?.progress}%</span><h6 className="text-uppercase text-muted ls-1 mb-1 mt-2 ml-2">
                          Progress
                        </h6>
                                        </div> 

                                    
                                </CardBody>
                            </Card>
                        </Col>

                        {/* Completed Projects Card */}
                        <Col lg="6" xl="3" className="mb-3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">Materials</CardTitle>


                                        </div>
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #fc5286, #fbaaa2)' }}>
                                          
                                                <i className="fas fa-pencil fa-hammer fa-spin" style={{ '--fa-animation-duration': '2s;', '--fa-fade-opacity': '0.6;' }}></i>
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{countdetails && countdetails?.material_count}</span>

                                    


                                </CardBody>
                            </Card>
                        </Col>



                        {/* Inprogress Projects Card */}
                        <Col lg="6" xl="3" className="mb-3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">Materials Cost</CardTitle>


                                        </div>
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #ffc480, #ff763b)' }}>
                                                <i className="fa-solid fa-helmet-safety fa-bounce" />
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{countdetails && countdetails?.material_cost !== null ? countdetails.material_cost : 0}</span>

                                   
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6" xl="3" className="">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">Project Budget</CardTitle>


                                        </div>
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, rgb(14 253 253), rgb(106, 142, 255))' }}>
                                                <i className="fa-solid fa-sack-dollar fa-bounce" />
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{countdetails && countdetails?.total_budget}</span>

                                   
                                </CardBody>
                            </Card>
                        </Col>


              {/* <Col lg="12" className="mb-3 mt-5">
                <Card className="shadow" style={{ borderRadius: '30px' }}>
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Project
                        </h6>
                        <h2 className="mb-0">Project Task</h2>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Line data={chartData} options={chartOptions} />
                    </div>
                  </CardBody>
                </Card>
              </Col> */}

              {/* Your existing code... */}
            </Row>
            <Row>
            <Col lg="7"  className="mb-3 mt-5">
                <Card className="shadow" style={{ borderRadius: '30px' }}>
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Project
                        </h6>
                        <h2 className="mb-0">Project Task</h2>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    <CardBody style={{height:'390px'}}>
  <Bar data={data}  options={options} />
</CardBody>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="5" >
                            <Card className="card-stats mb-4 mb-xl-0 mb-3 mt-5">
                                <CardBody>

                                    <Row>
                                        <Col className="col-auto mt-3 mr-3 ">
                                            {/* Use the Calendar component with inline CSS */}
                                            <Calendar
                                                className="custom-calendar"
                                                onChange={setDate}
                                                value={date}
                                            />

                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            {isBirthday && (
                                                <div>
                                                    <Row>
                                                        <Col className="col-auto">
                                                            {/* <img
                                                                src="path/to/birthday_image.jpg"
                                                                alt="Birthday"
                                                                style={{
                                                                    width: "100px",
                                                                    height: "100px",
                                                                    borderRadius: "50%",
                                                                }}
                                                            /> */}
                                                        </Col>
                                                        <Col>
                                                            {/* Add birthday wish on the other side */}
                                                            <p>🎉 Happy Birthday! 🎉</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                            {timeOfDay && <p className="text-center p-3" style={{
                                                fontWeight: 'bold', color: '#000', backgroundColor: "#dff6f9",
                                                borderRadius: "0.375rem",
                                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                            }}>Good {timeOfDay}</p>}
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            backgroundColor: "#dff6f9",
                                            borderRadius: "0.375rem",
                                            padding: "10px",
                                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        <Col xs={12} className="text-center">
                                            <h2>{message}</h2>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* Page content */}
      <div className="header bg-gradient-info py-1">
        {/* Your existing code... */}
      </div>
    </>
  );
};

export default Index;
