import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,

} from "reactstrap";
// core components
import Header from "dashboard/project-management/components/Headers/Header";
import Select from 'react-select';


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const EditRequest4 = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [showAddClientModal, setShowAddClientModal] = useState(false); // New state for Add Project modal
    const [clientForm, setClientForm] = useState({});
    const [selectedSiteManagers, setSelectedSiteManagers] = useState([]);


    const toggleModal = () => setModalOpen(prevState => !prevState);
    const toggleAddClientModal = () => setShowAddClientModal(prevState => !prevState); // Function to toggle Add Project modal

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClientForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log("Form submitted:", clientForm);
        // Reset form fields after submission (if needed)
        setClientForm({
            name: '',
            status: '',
            Timeline: '',
            Budget: '',
            Experiences: '',
            Reviews: '',
            Contact: '',
            Payment: '',

        });
        // Close the modal after submission (if needed)
        setShowAddClientModal(false);
    };

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [selectedManager, setSelectedManager] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');



    // Sample employee data (replace with your actual data)
    const employees = [
        { id: 1, name: 'Mine', age: 2022, location: 'Developers', position: 'Mine Description', image: require("../../assets/img/theme/team-4-800x800.jpg"), dropdownOpen: false },
        { id: 2, name: 'AecEarth', age: 2021, location: 'Career', position: 'AecEarth Description', image: require("../../assets/img/theme/team-4-800x800.jpg"), dropdownOpen: false },
        { id: 3, name: 'Swifter', age: 2000, location: 'Sales', position: 'Swifter Description', image: require("../../assets/img/theme/team-4-800x800.jpg"), dropdownOpen: false },
        { id: 4, name: 'BIM', age: 2023, location: 'Construction', position: 'BIM Description', image: require("../../assets/img/theme/team-4-800x800.jpg"), dropdownOpen: false },
        // Add more employees as needed
    ];

    // Function to toggle dropdown for a specific employee
    const toggleDropdown = (employeeId) => {
        setFilteredEmployees(prevEmployees => prevEmployees.map(employee => {
            if (employee.id === employeeId) {
                return { ...employee, dropdownOpen: !employee.dropdownOpen };
            }
            return employee;
        }));
    };

    // Function to filter employees based on search query
    const filterEmployees = () => {
        if (!searchQuery) {
            setFilteredEmployees(employees);
        } else {
            const filtered = employees.filter(employee =>
                employee.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredEmployees(filtered);
        }
    };



    // Update filtered employees when searchQuery changes
    useEffect(() => {
        filterEmployees();
    }, [searchQuery]);


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setClientForm(prevState => ({
            ...prevState,
            file: file
        }));
    };

    const handleManagerSelectChange = (selectedOption) => {
        setSelectedManager(selectedOption);
    };

    const handleLocationSelectChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
    };

    const handleSiteManagerSelectChange = (selectedOptions) => {
        setSelectedSiteManagers(selectedOptions);
        setClientForm(prevState => ({
            ...prevState,
            siteManagers: selectedOptions.map(option => option.value)
        }));
    };
    const siteOptions = [
        { value: 'harish', label: 'harish' },
        { value: 'jyo', label: 'jyo' },
    ];
    const statusOptions = [
        { value: 'pending', label: 'Pending' },
        { value: 'ongoing', label: 'Ongoing' },
        { value: 'completed', label: 'Completed' }
    ];
    const managerOptions = [
        { value: 'approved', label: 'Approved' },
        { value: 'rejected', label: 'Rejected' },
    ];

    

    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Col className="order-xl-2 mb-5 mb-xl-0 text-center  mb-5">
                    <h1>Edit Request Details</h1>
                </Col>
                <Button onClick={()=>window.history.back()} className='ml-5'>Back</Button>
                <Container className="py-4 ">
                <div className="p-4 rounded" style={{ backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid black' }}>

                    <Row className='px-3'>

                        <Col md={12}>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-status">Request Name</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-status"
                                    type="text"
                                    name="status"
                                    value={clientForm.status}
                                    onChange={handleInputChange}
                                    placeholder='Enter Request'
                                >
                                    
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-name">Request To</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-name"
                                    type="text"
                                    name="task"
                                    value={clientForm.text}
                                    onChange={handleInputChange}
                                    placeholder='Enter Request to'
                                />
                            </FormGroup>
                        </Col>

                        <Col md={12}>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-manager">Requested On</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-manager"
                                    type="date"
                                    name="manager"
                                    value={clientForm.manager}
                                    onChange={handleInputChange}
                                >
                                    
                                </Input>
                            </FormGroup>
                        </Col>


                       
                        <Col md={12}>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-description">Project Description</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={clientForm.description}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setClientForm(prevState => ({
                                            ...prevState,
                                            description: data
                                        }));
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-manager">Select Status</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-manager"
                                    type="select"
                                    name="manager"
                                    value={clientForm.manager}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Status</option>
                                    {managerOptions.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12' className='text-center mb-2'>
                            <Button color="primary" type="submit">Submit</Button>
                        </Col>
                    </Row>
                    </div>
                </Container>


            </div>


        </>
    );
};

export default EditRequest4;
