import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// import {CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { api } from "../../../../api";
import Cookies from "js-cookie";

const Deparment1 = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const toggleModal = () => setModalOpen((prevState) => !prevState);

  // Sample employee data (replace with your actual data)
  const employees = [
    {
      id: 1,
      name: "Jyosh",
      location: "Bucharest, Romania",
      position: "Front end Developer",
      image: require("../../assets/img/theme/team-4-800x800.jpg"),
    },
    {
      id: 2,
      name: "Harish",
      location: "Bucharest, Romania",
      position: "Front end Developer",
      image: require("../../assets/img/theme/team-4-800x800.jpg"),
    },
    {
      id: 3,
      name: "Shashi",
      location: "Bucharest, Romania",
      position: "Back end Developer",
      image: require("../../assets/img/theme/team-4-800x800.jpg"),
    },
    {
      id: 4,
      name: "Divya",
      location: "Bucharest, Romania",
      position: "Back end Developer",
      image: require("../../assets/img/theme/team-4-800x800.jpg"),
    },

    // Add more employees as needed
  ];
  const projects = ["Project 1", "Project 2", "Project 3", "Project 4"];

  // Function to filter employees based on search query
  const filterEmployees = () => {
    if (!searchQuery) {
      setFilteredEmployees(employees);
    } else {
      const filtered = employees.filter((employee) =>
        employee.position.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredEmployees(filtered);
    }
  };

  // Update filtered employees when searchQuery changes
  useEffect(() => {
    filterEmployees();
  }, [searchQuery]);

  const emp_id = Cookies.get("token");

  const [progressPercentage, setProgressPercentage] = useState(75);

  const [project, setproject] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `viewProjectDetails.php?g_m_id=${emp_id}`
        );
        setproject(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [department, setdepartment] = useState();

  const handleProjectSelect = async (project) => {

    setSelectedProject(project.p_name);

    try {
      const response = await api.get(
        `deptcount.php?id=${project.p_id}`
      );
      setdepartment(response.data);
      console.log(response.data[1]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    
  };

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="mb-3">
            <Col className="order-xl-2  mb-xl-0 text-left ">
              <h1>Deparment Details</h1>
            </Col>
            <Col className="order-xl-2  mb-xl-0 d-flex justify-content-end">
              <div className="mb-4 ml-4">
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle caret>
                  {selectedProject ? selectedProject : "Select Project"}
                  </DropdownToggle>
                  <DropdownMenu left>
                    {Array.isArray(project) ? (
                      project.map((projectItem, index) => (
                        <DropdownItem
                          key={index}
                          onClick={() => handleProjectSelect(projectItem)}
                        >
                          {projectItem.p_name}{" "}
                        </DropdownItem>
                      ))
                    ) : (
                      <p>No projects available</p>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            {department && department.map((dept,index) => (
              <Col lg="6" xl="3" key={index}>
                <Card
                  className="card-stats mb-4 mb-xl-0 "
                  style={{ borderRadius: "30px" }}
                >
                  <CardBody
                    style={{
                      display: "grid",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="text-center">
                      <h3>
                        {dept[0].dept_name}
                      </h3>
                      <div
                        className="progress-bar-container m-4"
                        style={{ width: "100px", height: "100px" }}
                      >
                        <CircularProgressbar
                          value={dept[0].task_completion_percentage}
                          text={`${dept[0].task_completion_percentage !== null ? dept[0].task_completion_percentage : 0}%`}
                          styles={buildStyles({
                            textColor: "blue",
                            pathColor: "#007bff",
                            trailColor: "#eee",
                            width: "60%",
                          })}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Deparment1;
