import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Badge,
} from "reactstrap";
import Select from "react-select";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { api } from "../../../../api";
// import Select from 'react-select';
import Cookies from "js-cookie";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const Project1 = () => {
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [showForm, setShowForm] = useState(false); // State to control form visibility
  const [showAddClientModal, setShowAddClientModal] = useState(false); // State to control "Add Client" modal visibility
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [deleteProjectName, setDeleteProjectName] = useState("");

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [deleteEmployee, setdeleteEmployee] = useState(null);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [clientForm, setClientForm] = useState([]);

  const [employeeIdToDelete, setEmployeeIdToDelete] = useState(null);

  const toggleDropdown = (id) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const [clientData, setClientData] = useState([
    {
      // Initialize your form data fields here
      client_name: "",
      client_email: "",
      client_number: "",
      company_name: "",
      p_name: "",
      p_budget: "",
      p_sqrfeet: "",
      p_location: "",
      p_timeline: "",
      contract_details: "",
      client_id: "",
    },
  ]);

  // Function to handle form submission
  const handleSubmit = async (event) => {
    // event.preventDefault(true);

    try {
      const response = await api.post("clientGM.php", clientData);
      console.log("Data sent successfully:", response.data);
      setClientData({
        client_name: "",
        client_email: "",
        client_number: "",
        company_name: "",
        p_name: "",
        p_budget: "",
        p_sqrfeet: "",
        p_location: "",
        p_timeline: "",
        contract_details: "",
      });

      // Optionally, you can update state or perform other actions after successful submission
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };


  const [flippedStates, setFlippedStates] = useState({});

  const handleClick = (id) => {
    setFlippedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const [progressPercentage, setProgressPercentage] = useState(75);

  const toggleModal = () => setModalOpen((prevState) => !prevState);

  const toggleAddClientModal = () =>
    setShowAddClientModal((prevState) => !prevState);

  // const toggleDeleteConfirmationModal = () => setShowDeleteConfirmationModal(prevState => !prevState);

  const handleAddClient = () => {
    // Show the form when "Add Client" is clicked
    setShowForm(true);
    // Show the "Add Client" modal
    toggleAddClientModal();
  };

  <CKEditor
    editor={ClassicEditor}
    data={clientForm.description}
    onChange={(event, editor) => {
      const data = editor.getData();
      setClientForm((prevState) => ({
        ...clientData,
        contract_details: data,
      }));
    }}
  ></CKEditor>;

  // const handleInputChange = (event) => {
  //   event.preventDefault();
  //   const {name, value}  = event.target
  //   // console.log(event.target);
  //   setClientData({
  //     ...clientData,
  //     [event.target.name]: event.target.value
  //   });
  //   console.log(clientData);
  // };

  const [tasks, setTasks] = useState([]);
  const [searchData,setSearchData] = useState([]);
  const [loading, setLoading] = useState(true);

  const emp_id = Cookies.get("token");
  // let emp_id = 1;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(
          `viewProjectDetails.php?g_m_id=${emp_id}`
        );
        setTasks(response.data);
        setSearchData(response.data)
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Sample employee data (replace with your actual data)
  const employees = tasks;

  // console.log(tasks)

  // Function to filter employees based on search query
  const filterEmployees = () => {
    if (!searchQuery) {
      setTasks(searchData);
    } else {
      const filtered = tasks.filter(
        (employee) =>
       
          employee.p_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setTasks(filtered);
    }
  };

  // Update filtered employees when searchQuery changes
  useEffect(
    () => {
      filterEmployees();
    },
    [searchQuery],
    tasks
  );

  const toggleViewMoreModal = (employee) => {
    setSelectedEmployee(employee);
  };

  const renderViewMoreModal = () => {
    if (!selectedEmployee) return null;

    return (
      <Modal isOpen={true} toggle={() => toggleViewMoreModal(null)}>
        <ModalHeader toggle={() => toggleViewMoreModal(null)}>
          Employee View
        </ModalHeader>
        <ModalBody style={{ justifyContent: "center", textAlign: "center" }}>
          <img
            alt="..."
            className="rounded-circle"
            src={require("../../assets/img/theme/team-4-800x800.jpg")}
            style={{ height: 100, marginBottom: 10 }}
          />
          <p>
            {" "}
            <b>Project Name :</b> {selectedEmployee.p_name}
          </p>
          <p>
            {" "}
            <b>Project Status :</b> {selectedEmployee.p_status}
          </p>
          <p>
            <b>Project Budget :</b> {selectedEmployee.p_budget}
          </p>
          <p>
            <b>Site Managers :</b> {selectedEmployee.site_manager_names}
          </p>
          <p>
            <b>Project location :</b> {selectedEmployee.p_location}
          </p>
          <p>
            <b>Start Date:</b> {selectedEmployee.start_date}
          </p>
          <p>
            <b>End Date :</b> {selectedEmployee.end_date}
          </p>
          <p>
            <b>Contract details :</b>{" "}
            {selectedEmployee.p_description.replace(/<[^>]*>?/gm, "")}{" "}
          </p>
          <p>
            <b>Departments :</b> {selectedEmployee.department_names}
          </p>
          <p>
            <b>Files :</b>{" "}
            <a href={`../../../images/${selectedEmployee.files}`} download>
              {selectedEmployee.files}
            </a>
          </p>
          {/* Add other employee details as needed */}
        </ModalBody>
      </Modal>
    );
  };

  const [deleteConfirmationEmployeeId, setDeleteConfirmationEmployeeId] =
    useState(null);

  const toggleDeleteConfirmationModal = (employeeId) => {
    setDeleteConfirmationEmployeeId(
      employeeId === deleteConfirmationEmployeeId ? null : employeeId
    );
  };

  const handleDropdownItemClick = (action, employeeId, employeeName) => {
    if (action === "Delete") {
      // Open delete confirmation modal
      toggleDeleteConfirmationModal(employeeId);
    } else {
      console.log(`Clicked ${action}`);
    }
  };

  const handleDeleteConfirmation = (confirmed, employeeId) => {
    if (confirmed) {
      console.log(`Deleting employee with ID: ${employeeId}`);
      const fetchData = async () => {
        try {
          const response = await api.get(`deleteProject.php?id=${employeeId}`);
          console.log(response.data);
          window.location.reload();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
    toggleDeleteConfirmationModal(employeeId);
  };

  const [addproject, setaddproject] = useState({
    p_name: "",
    p_budget: "",
    engineers: [],
    start_date: "",
    end_date: "",
    departments: [],
    p_location: "",
    p_description: "",
    files: [],
    BOQ: null,
    BOM: null,
    p_m_id: "",
    emp_id: emp_id, // this id need to come from while user logs in
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("p_name", addproject.p_name);
    formDataToSend.append("p_budget", addproject.p_budget);
    formDataToSend.append("engineers[]", addproject.engineers);
    formDataToSend.append("start_date", addproject.start_date);
    formDataToSend.append("end_date", addproject.end_date);
    formDataToSend.append("p_location", addproject.p_location);
    formDataToSend.append("p_description", addproject.p_description);
    formDataToSend.append("departments[]", addproject.departments);
    if (addproject.files && addproject.files.length > 0) {
      for (let i = 0; i < addproject.files.length; i++) {
        formDataToSend.append("image[]", addproject.files[i]);
      }
    } else {
      console.log("No files to upload");
    }
    formDataToSend.append("boq", addproject.BOQ);
    formDataToSend.append("bom", addproject.BOM);
    formDataToSend.append("p_m_id", addproject.p_m_id);
    formDataToSend.append("emp_id", addproject.emp_id);
    formDataToSend.append("client_id", addproject.client_id);

    try {
      const response = await api.post("addProject.php", formDataToSend);
      console.log("Data sent successfully:", response.data);
      // alert("New Project Added Successfully..")
      window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setaddproject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(addproject);
  };

  const [selectedSiteManagers, setSelectedSiteManagers] = useState([]);
  const [selecteddepartment, setselecteddepartment] = useState([]);

  const siteOptions = [
    { value: "1", label: "harish" },
    { value: "2", label: "jyo" },
  ];

  const [members, setmembers] = useState();
  const [projectmanager, setprojectmanager] = useState();
  const [department, setdepartment] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("empGMdetails.php");
        const response2 = await api.get('empGMdetails.php?p_m="p_m"');
        const response3 = await api.get("department.php");

        // Extract only two variables and format them
        const formattedData = response.data.map((item) => ({
          value: item.emp_id, // Assuming employee id is stored in 'id'
          label: item.emp_name + " (" + item.role + ")", // Assuming employee name is stored in 'name'
        })); // Take only the first two records

        // Extract only two variables and format them
        const formattedData2 = response2.data.map((item) => ({
          value: item.emp_id, // Assuming employee id is stored in 'id'
          label: item.emp_name, // Assuming employee name is stored in 'name'
        })); // Take only the first two records

        // Extract only two variables and format them
        const formattedData3 = response3.data.map((item) => ({
          value: item.d_id, // Assuming employee id is stored in 'id'
          label: item.dept_name, // Assuming employee name is stored in 'name'
        })); // Take only the first two records

        setmembers(formattedData);
        setprojectmanager(formattedData2);
        setdepartment(formattedData3);
        console.log(response.data);
        console.log(response3.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleSiteManagerSelectChange = (selectedOptions) => {
    setSelectedSiteManagers(selectedOptions);
    setaddproject((prevState) => ({
      ...prevState,
      engineers: selectedOptions.map((option) => option.value),
    }));
    console.log(addproject);
  };

  const handledepartmentSelectChange = (selectedOptions) => {
    setselecteddepartment(selectedOptions);
    setaddproject((prevState) => ({
      ...prevState,
      departments: selectedOptions.map((option) => option.value),
    }));
    console.log(addproject);
  };

  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "ongoing", label: "Ongoing" },
    { value: "completed", label: "Completed" },
  ];

  const [BOQ, setBOQ] = useState('No File Selected');
  const [BOM, setBOM] = useState('No File Selected');

  const handleBOQFileChange = (e) => {
    const file = e.target.files[0];
    setBOQ(file.name);
    setaddproject((prevState) => ({
      ...prevState,
      BOQ: file,
    }));
    console.log(addproject);
  };

  const handleBOMFileChange = (e) => {
    const file = e.target.files[0];
    setBOM(file.name);
    setaddproject((prevState) => ({
      ...prevState,
      BOM: file,
    }));
    console.log(addproject);
  };

  const fileInputRef = useRef(null);

  const handleAddFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    setaddproject((prevState) => ({
      ...prevState,
      files: [...prevState.files, ...files],
    }));
  };

  const [clientName, setclientName] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("clientGM.php");
        setclientName(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);



  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="mb-5">
            <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
              <h1>Project Details</h1>
            </Col>
            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex text-right">
              <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                <FormGroup className="mb-0 ml-6">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search Project"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
              <Col className="order-xl-2 mb-5 mb-xl-0 ">
                <Button onClick={handleAddClient}>Add Project</Button>
              </Col>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            {tasks && Array.isArray(tasks) ? (
              tasks.map((employee) => (
                <Col lg="6" xl="3" key={employee.id}>
                  <Card
                    onClick={() => handleClick(employee.p_id)}
                    className="card-stats mb-4 mb-xl-0 "
                    style={{
                      borderRadius: "30px",
                      position: "relative",
                      transformStyle: "preserve-3d",
                      transition: "transform 0.6s",
                      transform: flippedStates[employee.p_id]
                        ? "rotateY(180deg)"
                        : "rotateY(0deg)",
                      cursor: "pointer",
                      height: "300px",
                      zIndex: 0,
                      background: "rgb(255,255,255)",
                      background:
                        "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(230,249,251,1) 60%)",
                    }}
                  >
                    <CardBody
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className=" justify-content-center"
                        style={{ alignItems: "center", textAlign: "center" }}
                      >
                        <div>
                          <CardTitle className="h4 mb-0">
                            <h1>{employee.p_name}</h1>
                          </CardTitle>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                          // marginBottom: "40px",
                        }}
                      >
                        <CircularProgressbar
                          value={employee.project_progress}
                          text={`${employee.project_progress}%`}
                          styles={buildStyles({
                            textColor: "blue",

                            pathColor: "#007bff",
                            trailColor: "#eee",
                          })}
                        />
                        
                      </div>
                      {employee && employee.project_manager_id == 0 && (
                            <Badge color='warning' className="text-center">Pending</Badge>
                        )}
                    </CardBody>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        backfaceVisibility: "hidden",
                        transform: "rotateY(180deg)",
                        background:
                          "radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(230, 249, 251) 60%)",
                        borderRadius: "30px",
                        zIndex: "1",
                      }}
                    >
                      <CardBody className=" pt-md-4 ">
                        <div className="text-center">
                          <h3>{employee.p_name}</h3>
                          <h3>{employee.p_budget}</h3>
                          <h3>{employee.p_location}</h3>
                          <h3>{employee.end_date}</h3>
                          <div className="avatar-group d-flex justify-content-center">
                            {employee.profile.images.map((image, index) => (
                              <div
                                className="avatar-group "
                                style={{ height: "40px", width: "25px" }}
                                key={index}
                              >
                                <a
                                  className="avatar avatar-sm "
                                  href="#pablo"
                                  id={`tooltip-${employee.p_id}-${index}`}
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <img
                                    alt={`Image ${index}`}
                                    className="rounded-circle"
                                    style={{ height: "35px", width: "35px" }}
                                    src={require(`../../../images/${image}`)}
                                  />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-${employee.p_id}-${index}`}
                                >
                                  {employee.profile.full_names[index]}
                                </UncontrolledTooltip>
                              </div>
                            ))}
                          </div>
                          <hr className="my-4 " />
                          <div className="" style={{}}>
                            <Link
                              to={`/general-admin/general-management/editproject1/${employee.p_id}`}
                              style={{ color: "Black" }}
                            >
                              <i
                                onClick={() => console.log("Edit clicked")}
                                class="fas fa-edit"
                                style={{ marginRight: "10px" }}
                              ></i>
                            </Link>
                            <Link
                              to={`/general-admin/general-management/viewproject1/${employee.p_id}`}
                              style={{ color: "Black" }}
                              onClick={() => toggleViewMoreModal(employee)}
                            >
                              <i
                                class="fa-solid fa-eye"
                                style={{ marginRight: "10px" }}
                              ></i>
                            </Link>
                            <a
                              href="#"
                              onClick={() =>
                                handleDropdownItemClick(
                                  "Delete",
                                  employee.p_id,
                                  employee.p_name
                                )
                              }
                            >
                              <i class="fa fa-trash"></i>
                            </a>
                          </div>
                        </div>
                      </CardBody>
                    </div>
                  </Card>
                </Col>
              ))
            ) : (
              // Render something else if tasks is not defined or is not an array
              <h3>No Project available</h3>
            )}
          </Row>
        </Container>
      </div>

      {employees &&
        Array.isArray(employees) &&
        employees.length > 0 &&
        employees.map((employee) => (
          <Modal
            key={employee.p_id}
            isOpen={deleteConfirmationEmployeeId === employee.p_id}
            toggle={() => toggleDeleteConfirmationModal(employee.p_id)}
          >
            <ModalHeader
              toggle={() => toggleDeleteConfirmationModal(employee.p_id)}
            >
              Confirm Delete
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete {employee.client_name}?
              <div className="mt-3 text-right">
                <form>
                  <Button
                    color="danger"
                    onClick={() =>
                      handleDeleteConfirmation(true, employee.p_id)
                    }
                  >{`Yes, delete ${employee.p_name}`}</Button>{" "}
                </form>
                <Button
                  color="secondary"
                  onClick={() => handleDeleteConfirmation(false, employee.p_id)}
                >
                  No
                </Button>
              </div>
            </ModalBody>
          </Modal>
        ))}

      {/* Add Client Modal */}
      <Modal isOpen={showAddClientModal} toggle={toggleAddClientModal}>
        <ModalHeader toggle={toggleAddClientModal}>Add Project</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleFormSubmit} enctype="multipart/form-data">
            <Row>
              <Col md={12}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-status">
                    Client Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-status"
                    type="select"
                    name="client_id"
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Client Name</option>
                    {
                      Array.isArray(clientName) && clientName.length > 0 ? (
                        clientName.map((option) => (
                          <option key={option.client_id} value={option.client_id}>
                            {option.client_name}
                          </option>
                        ) )
                      ):(
                        <option value="" > No clients available </option> 
                      )
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-budget">
                    Project Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-budget"
                    type="text"
                    name="p_name"
                    onChange={handleInputChange}
                    placeholder="Project Name"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-budget">
                    Project Budget
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-budget"
                    type="number"
                    name="p_budget"
                    onChange={handleInputChange}
                    placeholder="Project Budget"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-status">
                    Select Project Manager
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-status"
                    type="select"
                    name="p_m_id"
                    onChange={handleInputChange}
                  >
                    <option value="">Select </option>
                    {projectmanager ? (
                      projectmanager.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    ) : (
                      <option value="">No Project Manager's</option>
                    )}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-site-manager"
                  >
                    Select Department's
                  </label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isMulti
                    value={selecteddepartment}
                    onChange={handledepartmentSelectChange}
                    options={department}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-starting"
                  >
                    Project Starting Date
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-starting"
                    type="date"
                    name="start_date"
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-ending">
                    Project Ending Date
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-ending"
                    type="date"
                    name="end_date"
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-location"
                  >
                    Location
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-location"
                    type="text"
                    name="p_location"
                    onChange={handleInputChange}
                    placeholder="Location"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-description"
                  >
                    Project Description
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={addproject.p_description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setaddproject((prevState) => ({
                        ...prevState,
                        p_description: data,
                      }));
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-file">
                    Upload BOQ File
                  </label>
                  <div className="custom-file-input-wrapper">
                    <label className="btn btn-success p-1 custom-file-input-label" htmlFor="input-BOQ-file">
                      Choose File
                    </label>
                    <Input
                      type="file"
                      id="input-BOQ-file"
                      name="BOQ"
                      style={{ display: 'none' }}
                      onChange={handleBOQFileChange}
                    />
                    <h5>{BOQ}</h5>
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-file">
                    Upload BOM File
                  </label>
                  <div className="custom-file-input-wrapper">
                    <label className="btn btn-success p-1 custom-file-input-label" htmlFor="input-BOM-file">
                      Choose File
                    </label>
                    <Input
                      type="file"
                      id="input-BOM-file"
                      name="BOM"
                      style={{ display: 'none' }}
                      onChange={handleBOMFileChange}
                    />
                      <h5>{BOM}</h5>
                  </div>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label>Files:</label>
                  <div>
                    <Button
                      type="button"
                      color="success"
                      style={{ padding: "3px" }}
                      onClick={handleAddFileClick}
                      className="p-1"
                    >
                      Upload Documents
                    </Button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      multiple
                    />
                    {addproject.files.length > 0 ? (
                      addproject.files.map((file, index) => (
                        <h5 className="mt-1 mt-0" key={index}>File {index + 1} : {file.name}</h5>
                      ))
                    ) : (
                      <h5>No Documents Selected</h5>
                    )}

                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "center" }}>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      {renderViewMoreModal()}
    </>
  );
};

export default Project1;
