import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import UserHeader from "dashboard/general-management/components/Headers/UserHeader.js";
import {api} from '../../../../api';
import Cookies from 'js-cookie';

const Profile5 = () => {
  // State to manage input value
  const [inputValue, setInputValue] = useState('');
  const [image, setImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Do something with the form data, e.g., submit it to a server
    console.log('Form submitted with value:', inputValue);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setProfileImage(URL.createObjectURL(file));
    // You can also send the file to the server here if needed
  };

  const cookieId = Cookies.get('token');


  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    // Initialize your form data fields here
    client_id : cookieId,
    client_name: '',
    client_email: '',
    client_number: '',
    company_name : '',
    profile_image : ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`clientview.php?id=${cookieId}`);
        setTasks(response.data);
        console.log(tasks)
        setFormData({
          client_id : response.data.client_id,
          client_name: response.data.client_name,
          client_email: response.data.client_email,
          client_number: response.data.client_number,
          company_name: response.data.company_name,
          profile_image : response.data.profile_image
        })
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };
    fetchData();
  }, []);


  const handleInputChange = (event) => {
    // console.log(event.target);
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
    console.log(formData);
  };



  return (
    <>
      <UserHeader />
      <div className="header bg-gradient-info py-1">
        {/* Page content */}
        <Container className="mt--7 mb--5" fluid>
          <Row>
          <Col className="order-xl-2 mb-10 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {tasks.profile_image &&
                      <img
                        style={{
                          width: '150px',
                          height: '150px',
                          border: '2px solid black',
                          borderRadius: '50%',
                          overflow: 'hidden',
                          boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                          margin: '0 auto',
                        }}
                        alt="..."
                        className="rounded-circle"
                        src={require(`../../../images/${tasks.profile_image}`)}
                      />
                    }
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              </CardHeader>
              <CardBody className="pt-0 pt-md-5 mt-5">
                <div className="text-center">
                  <h3>
                  {tasks.client_name}
                  </h3>
                  <h3>{tasks.client_email}</h3>
                  <h3>{tasks.client_number}</h3>
                  <h3>{tasks.company_name}</h3>
                </div>
              </CardBody>
            </Card>
          </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="shadow">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit Profile</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody style={{ backgroundColor: 'white' }}>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>

                    <Row className="justify-content-center align-items-center">
                      <Col lg="12" className="text-center mb-3">
                        {/* Displaying a placeholder image if profileImage is null */}
                        {!profileImage && (
                          <div
                            className="profile-image-container"
                            style={{
                              width: '120px',
                              height: '120px',
                              border: '2px solid black',
                              borderRadius: '50%',
                              overflow: 'hidden',
                              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                              margin: '0 auto',
                            }}
                          >
                            {tasks.profile_image &&
                            <img
                                src={require(`../../../images/${tasks.profile_image}`)}
                                alt="Placeholder"
                                name="image"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            }
                          </div>
                        )}
                        {/* Displaying the uploaded image */}
                        {profileImage && (
                          <div
                            className="profile-image-container"
                            style={{
                              width: '120px',
                              height: '120px',
                              border: '2px solid black',
                              borderRadius: '50%',
                              overflow: 'hidden',
                              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                              margin: '0 auto',
                            }}
                          >
                            <img
                              src={profileImage}
                              alt="Profile"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                        )}
                      </Col>

                      <Col lg="12" className="text-center">
                        <Button
                          color="info"
                          onClick={() => document.getElementById('input-profile-image').click()}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} /> Choose Profile Image
                        </Button>
                        <FormGroup style={{ display: 'none' }}>
                          <Input
                            type="file"
                            id="input-profile-image"
                            accept="image/*"
                            onChange={handleFileUpload}
                            
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="pl-lg-4 mt-2">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Username
                            </label>
                            <Input
                              className="form-control-alternative"
                              // defaultValue="lucky.jesse"
                              id="input-username"
                              placeholder="Username"
                              type="text"
                              name='client_name'
                              defaultValue={tasks.client_name}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="Email@example.com"
                              type="email"
                              name='client_email'
                              defaultValue={tasks.client_email}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Mobile number
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="Mobile Number"
                              type="tel"
                              name='client_number'
                              defaultValue={tasks.client_number}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Company Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              placeholder="company_name"
                              type="text"
                              name='company_name'
                              defaultValue={tasks.company_name}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col lg='12' style={{ textAlign: 'center' }}>
                        <Form onSubmit={handleSubmit}>
                          <Button color="primary" type="submit">Submit</Button>
                        </Form>
                      </Col>
                    </Row>
                      
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Profile5;
