import React, { useState, useEffect } from "react";
import { Link,  useNavigate, useLocation, useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// core components
import Header from "dashboard/hr-management/components/Headers/Header";
import Select from "react-select";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { api } from "../../../../api";

const Editproject4 = () => {

  const {id} = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [editemp , seteditemp] = useState({
    emp_id : id,
    emp_name : "",
    email : "",
    phoneno : "",
    dob : "",
    doj : "",
    salary : "",
    role : "",
    address : "",
    files : ''
  });

  useEffect(() => {
    api
      .get("employeesView.php?get_id=" +id)
      .then((res) => {
        seteditemp({
          ...editemp,
          emp_name: res.data[0].emp_name,
          email: res.data[0].email,
          phoneno: res.data[0].phoneno,
          dob: res.data[0].dob,
          doj: res.data[0].doj,
          salary: res.data[0].salary,
          role: res.data[0].role,
          address: res.data[0].address,
          files: res.data[0].profile_image
        });
        setProfileImage({
          oldimage : res.data[0].profile_image
        })
        console.log(res.data);
        console.log(editemp)
      })
      .catch((err) => console.log(err));
  }, [id]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    seteditemp((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    console.log(editemp)
  };

  const [profileImage, setProfileImage] = useState({
    oldimage : ""
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        seteditemp((prevState) => ({
            ...prevState,
            files: file,
            profile_image: file.name // assuming you want to update profile_image with the filename
        }));
    }
};


  const handleFormSubmit = async (e) => {
    // e.preventDefault();
        
        const formDataToSend = new FormData();
        formDataToSend.append('emp_id', editemp.emp_id);
        formDataToSend.append('emp_name', editemp.emp_name);
        formDataToSend.append('email', editemp.email);
        formDataToSend.append('phoneno', editemp.phoneno);
        formDataToSend.append('dob', editemp.dob);
        formDataToSend.append('doj', editemp.doj);
        formDataToSend.append('role', editemp.role);
        formDataToSend.append('salary', editemp.salary);
        formDataToSend.append('address', editemp.address);
        formDataToSend.append('files', editemp.files);

        try {
        const response = await api.post("editEmployee.php",formDataToSend);
        console.log("Data sent successfully:", response.data);
        window.history.back();

        navigate(location.pathname);
        

        } catch (error) {
        console.error("Error submitting data:", error);
        }
  };



  return (
    <>
      <div className="header bg-gradient-info py-6 py-lg-7">
        <Container className="py-4 ">
          <div
            className="p-4 rounded"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid black",
            }}
          >
            <Link to="/hr-admin/hr-management/employees3">
              {" "}
              <Button className="ml-2" color="dark">Back</Button>
            </Link>
            <Col className="order-xl-2 mb-5 mb-xl-0 text-center  mb-5">
              <h1 className="mb-5">Edit Employee Details</h1>
            </Col>
            <Form onSubmit={handleFormSubmit}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-name">
                      Employee Name
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-name"
                      type="text"
                      name="emp_name"
                      defaultValue={editemp.emp_name}
                      onChange={handleInputChange}
                      placeholder="Enter Employee Name"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-number"
                    >
                      Contact Number
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-number"
                      type="number"
                      name="phoneno"
                      defaultValue={editemp.phoneno}
                      placeholder="Enter Contact Number"
                      onChange={handleInputChange}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-email">
                      Email Id
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-mail"
                      type="text"
                      name="email"
                      defaultValue={editemp.email}
                      onChange={handleInputChange}
                      placeholder="Enter Email Address"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-birthday"
                    >
                      Date of Birth
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-birthday"
                      type="date"
                      name="dob"
                      defaultValue={editemp.dob}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-manager"
                    >
                      Select Role
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-manager"
                      type="select"
                      name="manager"
                      defaultValue={editemp.role}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Manager</option>
                      <option value="gm">General Manager</option>
                      <option value="pm">Project Manager</option>
                      <option value="sm">Site Manager</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-joining"
                    >
                      Date of Joining
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-joining"
                      type="date"
                      name="doj"
                      defaultValue={editemp.doj}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-salary"
                    >
                      Salary
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-salary"
                      type="number"
                      name="salary"
                      defaultValue={editemp.salary}
                      onChange={handleInputChange}
                      placeholder="Enter Salary"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-file">
                      Upload Image
                    </label>

                    <input
                            type="file"
                            className="form-control"
                            id="input-file"
                            name="profile_image"
                            onChange={handleFileChange}
                        />
                        <p>Old Image: {profileImage && profileImage.oldimage}</p>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-location"
                    >
                      Address
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-location"
                      type="text"
                      name="address"
                      defaultValue={editemp.address}
                      onChange={handleInputChange}
                      placeholder="Location"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12" style={{ textAlign: "center" }}>
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Editproject4;
