import React, { useState, useEffect } from 'react';
import {
    Button, Card, CardHeader, Table, Container, Row, Col, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Badge, Label,
    UncontrolledTooltip, UncontrolledDropdown, ModalFooter,
} from "reactstrap";
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Header from "dashboard/project-management/components/Headers/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Webcam from "react-webcam";
import { Logger } from 'sass';
import { api } from '../../../../api';
import Cookies from 'js-cookie';
import * as XLSX from 'xlsx';
import { showSuccessToast, showErrorToast ,showDeleteToast } from '../../../../toastUtils';

const Labour = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [modal, setModal] = useState(false); // State to control modal visibility
    const toggleModalAdd = () => setModalAdd(prevState => !prevState);
    const [clientForm, setClientForm] = useState({})
    const [image, setImage] = useState(null);
    const [profileImage, setProfileImage] = useState(null);

    const emp_id = Cookies.get('token');

  // Generate years from current year to 10 years ago
  const imageStyle = {
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    transition: 'transform 0.3s ease',
};

    const toggleDropdown = (dropdownKey) => {
        setDropdownState(prevState => ({
            ...prevState,
            [dropdownKey]: !prevState[dropdownKey]
        }));
    };
    const [dropdownState, setDropdownState] = useState({
        projectDropdownOpen: false,
        reportDropdownOpen_1: false,
        projectDropdownOpen_2: false,
        reportDropdownOpen_2: false
    });

    const imageHoverStyle = {
        ...imageStyle,
        transform: 'scale(1.5)',
    };
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const toggleDropdown2 = () => setDropdownOpen2((prevState) => !prevState);
    const [selectedProject, setSelectedProject] = useState("");

    const [modalAdd, setModalAdd] = useState(false);
    const [modalSalary, setModalSalary] = useState(false);


    const [project, setproject] = useState([]);
    const fetchProjectData = async () => {
    try {
        const response = await api.get(`viewProjectDetails.php?s_m_id=${emp_id}`);
        setproject(response.data);
        console.log(response.data);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
    };

  useEffect(() => {
    let originalData = []; // use let instead of const

    const fetchData = async () => {
        try {
            const response = await api.get(`addLabour.php?emp_id=${emp_id}`);
            console.log(response.data); // Log the response data

            // Ensure originalData is an array
            if (Array.isArray(response.data)) {
                originalData = response.data;
            } else {
                console.error("Error: response data is not an array");
                return;
            }

            // Filter data after fetching
            const filtered = originalData.filter(item =>
                item.l_name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredData(filtered);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
    fetchProjectData();
}, [searchQuery]);

    // const toggleDropdown = () => {
    //     setDropdownOpen(!dropdownOpen);
    // };

    const toggleProjectDropdown = () => {
        setProjectDropdownOpen(!projectDropdownOpen);
    };

    const toggleModal = () => {
        setModal(!modal);
    };

    const [labourData, setlabourData] = useState({
        emp_id : emp_id,
        name : '',
        number : '',
        p_id : '',
        image : '',
        payment : 0
    });

    const handleFormSubmit = async (e) => {
        // e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append("emp_id", emp_id);
        formDataToSend.append("p_id", labourData.p_id);
        formDataToSend.append("name", labourData.name);
        formDataToSend.append("number", labourData.number);
        formDataToSend.append("image", labourData.image);
        formDataToSend.append("salary", labourData.payment);

        try {
            const response = await api.post("addLabour.php",formDataToSend);
            console.log("Data sent successfully:", response.data);
            if(response.data.message === "Exists"){
                alert("User with this number already exists");
            }else{
                window.location.reload();
            }
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setlabourData(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(labourData);
    };





    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setProfileImage(URL.createObjectURL(file));
        setlabourData(prevState => ({
            ...prevState,
            image : file
        }));
        console.log(labourData);
    };

    const currentyear = new Date().getFullYear();
    const currentmonth = new Date().getMonth() + 1;

    const [filterSalaryReport, setfilterSalaryReport] = useState({
        p_id : "",
        engg_id : emp_id,
        year : currentyear,
        month : currentmonth

    });
   
    
    const handleProjectSelect = async (project) => {
        setSelectedProject(project.p_name);
        setfilterSalaryReport(prevState => ({
            ...prevState,
            p_id : project.p_id
        }))
        console.log(filterSalaryReport);
        try {
            const response = await api.get(`addLabour.php?p_id=${project.p_id}&emp_id=${emp_id}`);
            if (Array.isArray(response.data)) {
                setFilteredData(response.data);
            } else {
                console.error("Error: response data is not an array");
                setFilteredData([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setFilteredData([]);
        }
    };
    
    

    const deleteLabour = (lab_id) => {

        const fetchData = async () => {
            try {
              const response = await api.get(`addLabour.php?l_id=${lab_id}`);
              console.log(response.data);
              setFilteredData((prevTasks) => prevTasks.filter((tasks) => tasks.l_id !== lab_id));
            } catch (error) {
              console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }
    
    const handleLabourAttandances = (lab_id) => {
        const fetchData = async () => {
            const data = {
                l_id : lab_id
            }
            try {
              const response = await api.post(`labourAttendances.php`, data);
              console.log(response.data);
              setFilteredData(prevTasks => {
                return prevTasks.map(task => {
                    if (task.l_id === lab_id) {
                        task.attendances = "present";
                    }
                    return task;
                });
              });
            } catch (error) {
              console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }

    const projects = ['Project 1', 'Project 2', 'Project 3', 'Project 4'];

    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const startYear = 2000;
    const endYear = new Date().getFullYear() + 10;
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
        setfilterSalaryReport(prevState => ({
            ...prevState,
            year : event.target.value
        }))
        console.log(filterSalaryReport);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
        setfilterSalaryReport(prevState => ({
            ...prevState,
            month : event.target.value
        }))
        console.log(filterSalaryReport);
    };
    

    const exportToExcel = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const exportData = filteredData.map(item => ({
            'Labour ID': item.l_id,
            'Name': item.l_name,
            'Number': item.l_number,
            'Salary': item.salary,
            'Attendance': item.attendances
        }));

        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });
        const url = URL.createObjectURL(data);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'LabourData' + fileExtension);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    




    
    const exportSalaryData = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
    
        try {
            const response = await api.get(`labourAttendances.php?engg_id=${filterSalaryReport.engg_id}&&p_id=${filterSalaryReport.p_id}&&year=${filterSalaryReport.year}&&month=${filterSalaryReport.month}`);
            console.log(response.data);

            if(response.data.message === "noproject"){
                showDeleteToast("No data found.");
            }else{
                // Extract necessary data for export
                const exportData = response.data.details.map(item => ({
                    'Labour Name': item.l_name,
                    'Number Of Days Worked': item.a_count,
                    'Salary': item.salary,
                }));

                // Calculate total salary
                const totalSalary = response.data.total;
                // Add a row for total salary in exportData
                exportData.push({
                    'Labour Name': 'Total',
                    'Number Of Days Worked': '',
                    'Salary': totalSalary,
                });

                // Convert data to worksheet
                const ws = XLSX.utils.json_to_sheet(exportData);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                // Create Blob and URL for download
                const data = new Blob([excelBuffer], { type: fileType });
                const url = URL.createObjectURL(data);

                // Create download link and trigger download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'LabourSalaryData' + fileExtension);
                document.body.appendChild(link);
                link.click();

                // Clean up: remove the link element after download
                document.body.removeChild(link);

                showSuccessToast("File dowloaded successfully.");
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setModalSalary(false);
    }
    

    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Container fluid>
                    <Row className='mb-5'>
                        <Col>
                        <Dropdown
                          className="mb-2"
                          isOpen={dropdownOpen2}
                          toggle={toggleDropdown2}
                        >
                          <DropdownToggle caret>
                            {selectedProject
                              ? selectedProject
                              : "All Projects"}
                          </DropdownToggle>
                          <DropdownMenu left>
                            {Array.isArray(project) ? (
                              project.map((projectItem, index) => (
                                <DropdownItem
                                  key={index}
                                  onClick={() =>
                                    handleProjectSelect(projectItem)
                                  }
                                >
                                  {projectItem.p_name}{" "}
                                  {/* Render specific property of project object */}
                                </DropdownItem>
                              ))
                            ) : (
                              <p>No projects available</p>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                        </Col>
                        <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
                            <h1>Labour Details</h1>
                        </Col>
                       
                        <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
                            <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                                <FormGroup className="mb-0 ml-6">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Search Name"
                                            type="text"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                      
                                        <div className="col">
                                            <h3 className="mb-0">Attendance List</h3>
                                        </div>
                                        <Dropdown isOpen={dropdownState.reportDropdownOpen_1} toggle={() => toggleDropdown('reportDropdownOpen_1')}>
                                            <DropdownToggle caret  style={{backgroundColor : "#2dce89", border : "0px solid #2dce89"}}>
                                                Export Report
                                            </DropdownToggle>
                                            <DropdownMenu left>
                                                <DropdownItem onClick={exportToExcel} >Labour Data</DropdownItem>
                                                <DropdownItem onClick={() => setModalSalary(true)}>Lable Salary</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                        <div>
                                        <Button color="primary" onClick={toggleModalAdd}>Add</Button>
                                        </div>
                                    </Row>
                                </CardHeader>

                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Labour ID</th>
                                            <th scope="col">Profile</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Number</th>
                                            <th scope="col">Salary</th>
                                            <th scope="col">Attendance</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {Array.isArray(filteredData) && filteredData.length > 0 ? (
                                        filteredData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.l_id}</td>
                                                <td>
                                                    <img
                                                        alt="...Image Not Found"
                                                        className="rounded-circle"
                                                        src={require(`../../../images/${item.profile_image}`)}
                                                        style={{ width: '50px', height: '50px' }} // replace imageStyle with inline style for example
                                                    />
                                                </td>
                                                <td>{item.l_name}</td>
                                                <td>{item.l_number}</td>
                                                <td>{item.salary}</td>
                                                <td>
                                                    <Badge color={item.attendances === "present" ? 'success' : 'danger'}>
                                                        {item.attendances}
                                                    </Badge>
                                                </td>
                                                <td>
                                                    <a href="#pablo" className='mr-3'>
                                                        <i className='fa fa-trash' onClick={() => deleteLabour(item.l_id)}></i>
                                                    </a>
                                                    {item.attendances !== "present" && (
                                                        <button
                                                            onClick={() => handleLabourAttandances(item.l_id)}
                                                            className="badge btn btn-danger text-center pl-3"
                                                            type='submit'
                                                        >
                                                            <i className='fas fa-user-check'></i>
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No data available</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modalAdd} toggle={toggleModalAdd}>
                <ModalHeader toggle={toggleModalAdd}>Add Labour</ModalHeader>
                <Form onSubmit={handleFormSubmit}>
                    <Row className="justify-content-center align-items-center">
                        <Col lg="12" className="text-center mb-3">
                            {/* Displaying a placeholder image if profileImage is null */}
                            {!profileImage && (
                            <div
                                className="profile-image-container"
                                style={{
                                width: '120px',
                                height: '120px',
                                border: '2px solid black',
                                borderRadius: '50%',
                                overflow: 'hidden',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                                margin: '0 auto',
                                }}
                            >
                                <img
                                src={require("../../assets/img/brand/AECP.logo.png")}
                                alt="Placeholder"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                />
                            </div>
                            )}
                            {/* Displaying the uploaded image */}
                            {profileImage && (
                            <div
                                className="profile-image-container"
                                style={{
                                width: '120px',
                                height: '120px',
                                border: '2px solid black',
                                borderRadius: '50%',
                                overflow: 'hidden',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                                margin: '0 auto',
                                }}
                            >
                                <img
                                src={profileImage}
                                alt="Profile"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                />
                            </div>
                            )}
                        </Col>

                        <Col lg="12" className="text-center">
                            <Button
                            color="info"
                            onClick={() => document.getElementById('input-profile-image').click()}
                            // style={{
                            //   margin: '0 auto',
                            //   display: 'block',
                            //   width: '200px',
                            
                            // }}
                            >
                            <FontAwesomeIcon icon={faPencilAlt} /> Labour Image
                            </Button>
                            <FormGroup style={{ display: 'none' }}>
                            <Input
                                type="file"
                                id="input-profile-image"
                                accept="image/*"
                                onChange={handleFileUpload}
                                
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                    <Row className='px-3'>
                    <Col md={12}>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-status"
                                >
                                  Project Name
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-status"
                                  type="select"
                                  name="p_id"
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select Project</option>
                                  {project &&
                                    Array.isArray(project) &&
                                    project.map((option) => (
                                      <option
                                        key={option.p_id}
                                        value={option.p_id}
                                      >
                                        {option.p_name}
                                      </option>
                                    ))}
                                </Input>
                              </FormGroup>
                            </Col>

                        <Col md={12}>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-status">Labour Name</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-status"
                                    type="text"
                                    name="name"
                                    onChange={handleInputChange}
                                    placeholder='name'
                                    required
                                >
                                
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-name">Mobile Number</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-name"
                                    type="text"
                                    name="number"
                                    onChange={handleInputChange}
                                    placeholder='Number'
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-name">Payment [Per Day]</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-name"
                                    type="number"
                                    name="payment"
                                    onChange={handleInputChange}
                                    placeholder='Number'
                                    min="0"
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg='12' className='text-center mb-2'>
                            <Button color="primary" type="submit">Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>

               {/* Export Lable Salary Modal */}
               <Modal isOpen={modalSalary} toggle={() => setModalSalary(!modalSalary)}>
                <ModalHeader toggle={() => setModalSalary(!modalSalary)}>Export Lable Salary</ModalHeader>
                <Row className="px-3">
                    <Col className='col-4'>
                        <Dropdown isOpen={dropdownState.projectDropdownOpen_2} toggle={() => toggleDropdown('projectDropdownOpen_2')}>
                            <DropdownToggle caret>
                            {selectedProject
                              ? selectedProject
                              : "All Projects"}
                            </DropdownToggle>
                            <DropdownMenu left>
                                {Array.isArray(project) ? (
                                project.map((projectItem, index) => (
                                    <DropdownItem
                                    key={index}
                                    onClick={() =>
                                        handleProjectSelect(projectItem)
                                    }
                                    >
                                    {projectItem.p_name}
                                    </DropdownItem>
                                ))
                                ) : (
                                <p>No projects available</p>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                    <Col className='col-4'>
                        <select className="form-control" value={selectedYear} onChange={handleYearChange}>
                            <option value="">Select Year</option>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </Col>
                    <Col className='col-4'>
                        <select className="form-control" value={selectedMonth} onChange={handleMonthChange}>
                            <option value="">Select Month</option>
                            {months.map((month, index) => (
                                <option key={index} value={index + 1}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row>
                    <Col lg='12' className='text-center my-3'>
                        <Button color="primary" type="submit" onClick={() => exportSalaryData()}>Export</Button>
                    </Col>
                </Row>
            </Modal>

        </>
    );
};

export default Labour;
