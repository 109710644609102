import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { api } from '../../../../api';
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    FormGroup,
} from "reactstrap";

const Editstock4 = () => {
    const { id } = useParams();

    console.log('id',id);
    const [updateValue, setupdateValues] = useState({
        item_id: id,
        item_name: '',
        item_category: '',
        current_stock: '',
        unit: '',
        cost: ''
    });

    console.log('update2',updateValue);

    

    useEffect(() => {
        console.log("Item ID:", id); 
        api.get("ViewStockSTM.php/" + id) 
            .then((res) => {
                const { product } = res.data; 
                setupdateValues(prevState => ({
                    ...prevState,
                    item_name: product.item_name,
                    item_category: product.item_category,
                    current_stock: product.current_stock,
                    unit: product.unit,
                    cost: product.cost,
                }));
            })
            .catch((err) => console.log(err));
    }, [id]);
    

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const response = await api.post(`edititemsSTM.php`, updateValue);
            console.log("Data sent successfully:", response.data);
    
            // Check if the status is 'success' and then navigate back
            if (response.data.status === 'success') {
                window.history.back(); // Navigate back
            } else {
                // Optionally, you can handle other cases like showing an error message
                console.log("Update was not successful:", response.data.message);
            }
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setupdateValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    console.log('update',updateValue);

    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Col className="order-xl-2 mb-5 mb-xl-0 text-center  mb-5">
                    <h1>Edit Task Details</h1>
                </Col>
                <Link to="/store-admin/store-management/employees4"> <Button className='ml-5'>Back</Button></Link>
                <Container className="py-4 ">
                    <div className="p-4 rounded" style={{ backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid black' }}>
                        <Row className='px-3'>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Item Name</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-name"
                                        type="text"
                                        name="item_name"
                                        onChange={handleInputChange}
                                        value={updateValue.item_name}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-name">Item Category</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-name"
                                        type="text"
                                        name="item_category"
                                        onChange={handleInputChange}
                                        value={updateValue.item_category}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-manager">Current Stock</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-manager"
                                        type="number"
                                        name='current_stock'
                                        onChange={handleInputChange}
                                        value={updateValue.current_stock}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-starting">Unit(Kg)</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-starting"
                                        type="text"
                                        name="unit"
                                        onChange={handleInputChange}
                                        value={updateValue.unit}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-ending">Cost</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-ending"
                                        type="number"
                                        name="cost"
                                        onChange={handleInputChange}
                                        value={updateValue.cost}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='12' className='text-center mb-2'>
                                <Button color="primary" type="submit" onClick={handleSubmit}>Submit</Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Editstock4;
