/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "dashboard/project-management/views/Dashboard";
import Profile from "dashboard/project-management/views/examples/Profile.js";
import Project from "dashboard/project-management/views/examples/Project.js";
import Register from "dashboard/project-management/views/examples/Register.js";
import Login from "dashboard/project-management/views/examples/Login.js";
import Tables from "dashboard/project-management/views/examples/Tables.js";
import Icons from "dashboard/project-management/views/examples/Icons.js";
import Client from "dashboard/project-management/views/examples/Client.js";
import Budget from "dashboard/project-management/views/examples/Budget.js";
import Editproject from "dashboard/project-management/views/examples/Editproject.js";
import Edittask from "dashboard/project-management/views/examples/Edittask.js";
import Site from "dashboard/project-management/views/examples/Site.js";
import Materials from "dashboard/project-management/views/examples/Materials";
import Community from "dashboard/project-management/views/examples/Community.js";
import Hub from "dashboard/project-management/views/examples/Hub.js";
import Leave from "dashboard/project-management/views/examples/Leave";
import Leavetable from "dashboard/project-management/views/examples/Leavetable";
import Task from "dashboard/project-management/views/examples/Task";
import Report from "dashboard/project-management/views/examples/Report";
import Viewproject from "dashboard/project-management/views/examples/Viewproject";















import Index from "dashboard/general-management/views/Index";
import Profile1 from "dashboard/general-management/views/examples/Profile1.js";
import Project1 from "dashboard/general-management/views/examples/Project1.js";
import Employee1 from "dashboard/general-management/views/examples/Employee1.js";
import Client1 from "dashboard/general-management/views/examples/Client1.js";
import Budget1 from "dashboard/general-management/views/examples/Budget1.js";
import Editclient1 from "dashboard/general-management/views/examples/Editclient1";
import Editproject1 from "dashboard/general-management/views/examples/Editproject1";
import Viewproject1 from "dashboard/general-management/views/examples/Viewproject1";
import Deparment1 from "dashboard/general-management/views/examples/Deparment1";









import Dashboard2 from "dashboard/site-management/views/Dashboard2";
import Profile2 from "dashboard/site-management/views/examples/Profile2.js";
import Project2 from "dashboard/site-management/views/examples/Project2.js";
import Budget2 from "dashboard/site-management/views/examples/Budget2.js";
import Editproject2 from "dashboard/site-management/views/examples/Editproject2.js";
import Edittask2 from "dashboard/site-management/views/examples/Edittask2";
import Employee2 from "dashboard/site-management/views/examples/Employee2.js";
import Materials2 from "dashboard/site-management/views/examples/Materials2";
import Community2 from "dashboard/site-management/views/examples/Community2.js";
import Hub2 from "dashboard/site-management/views/examples/Hub2.js";
import Leave2 from "dashboard/site-management/views/examples/Leave2";


import Task2 from "dashboard/site-management/views/examples/Task2";
import Viewtask2 from "dashboard/site-management/views/examples/Viewtask2";













import Dashboard3 from "dashboard/hr-management/views/Dashboard3";
import Employees3 from "dashboard/hr-management/views/examples/Employees3";
import Leave3 from "dashboard/hr-management/views/examples/Leave3";
import Leavetable3 from "dashboard/hr-management/views/examples/Leavetable3";
import Role3 from "dashboard/hr-management/views/examples/Role3";
import Department3 from "dashboard/hr-management/views/examples/Department3";
import Profile3 from "dashboard/hr-management/views/examples/Profile3";
import Community3 from "dashboard/hr-management/views/examples/Community3";
import Hub3 from "dashboard/hr-management/views/examples/Hub3";
// import ViewTask from "dashboard/site-management/views/examples/viewTask";











// Store






import Dashboard4 from "dashboard/store-management/views/Dashboard4";
import Profile4 from "dashboard/store-management/views/examples/Profile4";
import Editproject4 from "dashboard/store-management/views/examples/Editproject4";
import Community4 from "dashboard/store-management/views/examples/Community4";
import Hub4 from "dashboard/store-management/views/examples/Hub4";
import Leavetable4 from "dashboard/store-management/views/examples/Leavetable3";
import Leavetable2 from "dashboard/site-management/views/examples/Leavetable2";

import Editstock4 from "dashboard/store-management/views/examples/Editstock4";
import Viewwaste4 from "dashboard/store-management/views/examples/Viewwaste4";
import Editwaste4 from "dashboard/store-management/views/examples/Editwaste4";
import Requestwaste4 from "dashboard/store-management/views/examples/Requestwaste4";
import ViewMaintainance4 from "dashboard/store-management/views/examples/ViewMaintainance4";
import EditMaintainance4 from "dashboard/store-management/views/examples/EditMaintainance4";
import EditRequest4 from "dashboard/store-management/views/examples/EditRequest4";

import Invoice4 from "dashboard/store-management/views/examples/Invoice4";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
// import SampleInvoice4 from "dashboard/store-management/views/examples/SampleInvoice4";






//shashi
import Requestproject from "dashboard/project-management/views/examples/Requestproject";
import Materials1 from "dashboard/general-management/views/examples/Materials1";
import Report2 from "dashboard/site-management/views/examples/Report2";






// client

import Index5 from "dashboard/client-management/views/Index5";
import Profile5 from "dashboard/client-management/views/examples/Profile5.js";
import Project5 from "dashboard/client-management/views/examples/Project5.js";
import Employee5 from "dashboard/client-management/views/examples/Employee5.js";
import Material5 from "dashboard/client-management/views/examples/Material5.js";
import Budget5 from "dashboard/client-management/views/examples/Budget5.js";
import Editproject5 from "dashboard/client-management/views/examples/Editproject5.js";
import Editclient5 from "dashboard/client-management/views/examples/Editclient5";
import Deparment5 from "dashboard/client-management/views/examples/Deparment5";
import Viewtask from "dashboard/project-management/views/examples/Viewtask";
import Clientproject1 from "dashboard/general-management/views/examples/Clientproject1";
import Resource1 from "dashboard/general-management/views/examples/Resource1";
import Resource2 from "dashboard/site-management/views/examples/Resource2";
import Storemanagement4 from "dashboard/store-management/views/examples/Storemanagement4";
import Materials4 from "dashboard/store-management/views/examples/Materials4";
import Equipment4 from "dashboard/store-management/views/examples/Equipment4";
import Wasteage4 from "dashboard/store-management/views/examples/Wasteage4";
import Requestresource3 from "dashboard/hr-management/views/examples/Requestresource3";
import Viewproject2 from "dashboard/site-management/views/examples/viewproject2";
import Labour from "dashboard/site-management/views/examples/Labour";



//President


import Dashboard6 from "dashboard/president-management/views/Dashboard6";
import Profile6 from "dashboard/president-management/views/examples/Profile6";

import Materials6 from "dashboard/president-management/views/examples/Materials6";

import Corousel6 from "dashboard/president-management/views/examples/Corousel6";
import Projects6 from "dashboard/president-management/views/examples/Projects6";
import { TbReportSearch } from "react-icons/tb";
// import Requestresource3 from "dashboard/hr-management/views/examples/Requestresource3";
import Viewproject6 from "dashboard/president-management/views/examples/ViewProject6";
import Editproject3 from "dashboard/hr-management/views/examples/Editproject3";





export var routes_project = [
  {
    path: "/project-management/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-secondary",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path:"/project-management/project",
    name: "Project",
    icon: "ni ni-planet text-primary",
    component: <Project />,
    layout: "/admin",
  },
  {
    path: "/project-management/task",
    name: "Task",
    icon: "ni ni-check-bold text-red",    
    component: <Task />,
    layout: "/admin",
  },
  
  {
    path: "/project-management/Profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path:"/project-management/materials",
    name: "Materials",
    icon: "ni ni-building text-yellow",
    component: <Materials />,
    layout: "/admin",
  },

  {
    path:"/project-management/site",
    name: "Engineer's",
    icon: "ni ni-circle-08 text-green",
    component: <Site />,
    layout: "/admin",
  },
  // {
  //   path:"/client",
  //   name:"Client",
  //   icon:"ni ni-badge text-red",
  //   component: <Client />,
  //   layout: "/admin",
  // },
  
  {
    path:"/project-management/budget",
    name:"Budget",
    icon:"ni ni-collection  text-orange",
    component: <Budget />,
    layout: "/admin",
  },
  {
    path:"/project-management/editproject/:id",
    name: "Edit_Project",
    icon: "ni ni-planet text-blue",
    component: <Editproject />,
    layout: "/admin",
  },
  {
    path:"/project-management/edittask/:id",
    name: "Edit_Task",
    icon: "ni ni-planet text-blue",
    component: <Edittask />,
    layout: "/admin",
  },
  // {
  //   path:"/project-management/community",
  //   name: "Community Hub",
  //   icon: "ni ni-world-2 text-purple",
  //   component: <Community />,
  //   layout: "/admin",
  // },
  {
    path: "/project-management/hub",
    name: "Hub",
    icon: "ni ni-planet text-blue",
    component: <Hub />,
    layout: "/admin",
  },
  {
    path: "/project-management/leave",
    name: "Leave Mangement",
    icon: "ni ni-paper-diploma text-pink",
    component: <Leave />,
    layout: "/admin",
  },
  {
    path: "/project-management/leavetable/:id",
    name: "Leave Table",
    icon: "ni ni-planet text-blue",
    component: <Leavetable />,
    layout: "/admin",
  },
  {
    path: "/project-management/Report",
    name: "Task Report",
    icon: "ni ni-planet text-blue",  
    component: <Report />,
    layout: "/admin",
  },

  {
    path: "/project-management/Requestproject",
    name: "Request Resource",
    icon: "fa-solid fa-people-group text-red",
    component: <Requestproject />,
    layout: "/admin",
  },
  {
    path: "/project-management/viewproject/:id",
    name: "View_project",
    icon: "ni ni-planet text-blue",
    component: <Viewproject />,
    layout: "/admin",
  },
  {
    path:"/project-management/Viewtask/:id",
    name: "View_Task",
    icon: "ni ni-planet text-blue",
    component: <Viewtask/>,
    layout: "/admin",
  },
 
];


export default routes_project;





// -----------------------------------------------------------------------------------------------------------------------------------------------
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// -----------------------------------------------------------------------------------------------------------------------------------------------







export var routes_general =[

  {
    path: "/general-management/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Index />,
    layout: "/admin",
  },
  {
    path:"/general-management/client1",
    name:"Client",
    icon:"ni ni-badge text-red",
    component: <Client1 />,
    layout: "/admin",
  },
  {
    path:"/general-management/Clientproject1",
    name: "Client Project",
    icon: "fa-solid fa-sheet-plastic text-yellow ",
    component: <Clientproject1 />,
    layout: "/admin",
  },
  {
    path:"/general-management/project1",
    name: "Project",
    icon: "fa-solid fa-clipboard text-blue",
    component: <Project1 />,
    layout: "/admin",
  },
  
  {
    path: "/general-management/Profile1",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile1 />,
    layout: "/admin",
  },
  // {
  //   path: "/general-management/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: <Login />,
  //   layout: "/auth",
  // },
  {
    path:"/general-management/employee1",
    name: "Employee",
    icon: "ni ni-circle-08 text-green",
    component: <Employee1 />,
    layout: "/admin",
  },
 
  {
    path:"/general-management/deparment1.js",
    name: "Deparment",
    icon: "ni ni-planet text-pink",
    component: <Deparment1 />,
    layout: "/admin",
  },
  
  {
    path:"/general-management/budget1",
    name:"Budget",
    icon:"ni ni-collection  text-orange",
    component: <Budget1 />,
    layout: "/admin",
  },
 
  {
    path:"/general-management/editclient1/:id",
    name: "Edit_Client",
    icon: "ni ni-planet text-blue",
    component: <Editclient1 />,
    layout: "/admin",
  },
  
  {
    path:"/general-management/editproject1/:id",
    name: "Edit_Project",
    icon: "ni ni-planet text-blue",
    component: <Editproject1 />,
    layout: "/admin",
  },
  {
    path:"/general-management/viewproject1/:id",
    name: "View_Project",
    icon: "ni ni-planet text-blue",
    component: <Viewproject1 />,
    layout: "/admin",
  },
  {
    path:"/general-management/materials1",
    name: "Materials",
    icon: "ni ni-building text-yellow",
    component: <Materials1 />,
    layout: "/admin",
  },
  
  {
    path:"/general-management/Resource1",
    name: "Resource",
    icon: "fa-solid fa-people-group text-red",
    component: <Resource1 />,
    layout: "/admin",
  },
 
  
  
 
 
];


// export default routes_general;

// -----------------------------------------------------------------------------------------------------------------------------------------------
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// -----------------------------------------------------------------------------------------------------------------------------------------------








export var routes_site = [
  {
    path: "/site-management/dashboard2",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-secondary",
    component: <Dashboard2 />,
    layout: "/admin",
  },
  {
    path:"/site-management/project2",
    name: "Project",
    icon: "ni ni-planet text-primary",
    component: <Project2/>,
    layout: "/admin",
  },
  {
    path: "/site-management/task2",
    name: "Task",
    icon: "ni ni-check-bold text-red",    
    component: <Task2 />,
    layout: "/admin",
  },
  
  {
    path: "/site-management/user-profile2",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile2 />,
    layout: "/admin",
  },
  {
    path:"/site-management/materials2",
    name: "Materials",
    icon: "ni ni-building text-yellow",
    component: <Materials2 />,
    layout: "/admin",
  },

  // {
  //   path:"/site-management/employee2",
  //   name: "Employees",
  //   icon: "ni ni-circle-08 text-green",
  //   component: <Employee2 />,
  //   layout: "/admin",
  // },
  // {
  //   path:"/client",
  //   name:"Client",
  //   icon:"ni ni-badge text-red",
  //   component: <Client />,
  //   layout: "/admin",
  // },
  
  // {
  //   path:"/site-management/budget2",
  //   name:"Budget",
  //   icon:"ni ni-collection  text-orange",
  //   component: <Budget2 />,
  //   layout: "/admin",
  // },
  // {
  //   path:"/site-management/editproject",
  //   name: "Edit_Project",
  //   icon: "ni ni-planet text-blue",
  //   component: <Editproject2 />,
  //   layout: "/admin",
  // },
  {
    path:"/site-management/edittask2/:id",
    name: "Edit_Task",
    icon: "ni ni-planet text-blue",
    component: <Edittask2 />,
    layout: "/admin",
  },
  {
    path:"/site-management/Viewtask2/:id",
    name: "View_Task",
    icon: "ni ni-planet text-blue",
    component: <Viewtask2/>,
    layout: "/admin",
  },
  {
    path: "/site-management/viewproject2/:id",
    name: "View_project",
    icon: "ni ni-planet text-blue",
    component: <Viewproject2 />,
    layout: "/admin",
  },
  // {
  //   path:"/site-management/community2",
  //   name: "Community Hub",
  //   icon: "ni ni-world-2 text-purple",
  //   component: <Community2 />,
  //   layout: "/admin",
  // },
  {
    path: "/site-management/hub2",
    name: "Hub",
    icon: "ni ni-planet text-blue",
    component: <Hub2 />,
    layout: "/admin",
  },
  {
    path: "/site-management/leave2",
    name: "Leave Mangement",
    icon: "ni ni-paper-diploma text-pink",
    component: <Leave2 />,
    layout: "/admin",
  },
  {
    path: "/site-management/leavetable2/:id",
    name: "Leave Table",
    icon: "ni ni-planet text-blue",
    component: <Leavetable2 />,
    layout: "/admin",
  },
  {
    path: "/site-management/Resource2",
    name: "Resource",
    icon: "ni ni-planet text-blue",
    component: <Resource2/>,
    layout: "/admin",
  },
  {
    path: "/site-management/Report2",
    name: "Task Report",
    icon: "ni ni-planet text-blue",
    component: <Report2 />,
    layout: "/admin",
  },
  {
    path: "/site-management/Labour",
    name: "Labour",
    icon: "ni ni-planet text-blue",
    component: <Labour />,
    layout: "/admin",
  },
 
 
 
];












// -----------------------------------------------------------------------------------------------------------------------------------------------
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// -----------------------------------------------------------------------------------------------------------------------------------------------
















export var routes_hr = [

  {
    path: "hr-admin/hr-management/dashboard3",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-secondary",
    component: <Dashboard3 />,
    layout: "/admin",
  },
  {
    path: "hr-admin/hr-management/user-profile3",
    name: "User_Profile",
    icon: "ni ni-tv-2 text-secondary",
    component: <Profile3/>,
    layout: "/admin",
  },
 
  {
    path:"hr-admin/hr-management/Requestresource3",
    name: "Resource",
    icon: "ni ni-circle-08 text-green",
    component: <Requestresource3/>,
    layout: "/admin",
  },
  
 
  

  
  {
    path:"hr-admin/hr-management/editproject3/:id",
    name: "Edit_Project",
    icon: "ni ni-planet text-blue",
    component: <Editproject3 />,
    layout: "/admin",
  },
  {
    path: "/hr-admin",
    icon:"fa fa-users text-red",
    name: <span style={{ width: '100%', display: 'inline-block',cursor:'pointer' }}>    
    HR Management<i className="ni ni-bold-down text-white ml-4" style={{ marginRight: '5px' }}></i> </span>,
    component: null,
    layout: "/admin",
    collapse: true,
    key: "hr-management-dropdown",
    routes: [
      
      {
        path: "/hr-management/role3",
        name: <span style={{ width: '130px', display: 'inline-block' }}>Role</span>,
        icon: "ni ni-planet text-primary",
        component: <Role3 />,
        layout: "/admin",
      },
      {
        path: "/hr-management/department3",
        name: <span style={{ width: '130px', display: 'inline-block' }}>Department</span>,
        icon: "ni ni-building text-yellow",
        component: <Department3 />,
        layout: "/admin",
      },
      {
        path: "/hr-management/employees3",
        name: <span style={{ width: '130px', display: 'inline-block' }}>Employees</span>,
        icon: "ni ni-circle-08 text-green",
        component: <Employees3 style={{ width: '100%' }} />,
        layout: "/admin",
      },
    ],
  },
 {
    path: "/hr-management/employees3",
    // name: "Employees",
    // icon: "ni ni-circle-08 text-green",
    component: <Employees3 />,
    layout: "/admin",
    hidden:true

  },
  {
    path: "/hr-management/department3",
    // name: "Department",
    // icon: "ni ni-building text-yellow",
    component: <Department3 />,
    layout: "/admin",
    hidden:true

  },
  {
    path: "/hr-management/role3",
    // name: "Role",
    // icon: "ni ni-planet text-primary",
    component: <Role3 />,
    layout: "/admin",
    hidden:true
  },

  // {
  //   path: "hr-admin/hr-management/community3",
  //   name: "Community Hub",
  //   icon: "ni ni-world-2 text-purple",
  //   component: <Community3/>,
  //   layout: "/admin",
  // }, 
  {
    path: "hr-admin/hr-management/hub3",
    name: "Hub",
    icon: "ni ni-planet text-blue",
    component: <Hub3 />,
    layout: "/admin",
  },
  {
    path: "hr-admin/hr-management/leave3",
    name: "Leave Mangement",
    icon: "ni ni-paper-diploma text-pink",
    component: <Leave3 />,
    layout: "/admin",
  },
  {
    path: "hr-admin/hr-management/leavetable3/:id",
    name: "Leave Table",
    icon: "ni ni-planet text-blue",
    component: <Leavetable3 />,
    layout: "/admin",
  }, 

 
 
];






export var routes_store = [

  {
    path: "/store-management/dashboard4",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-secondary",
    component: <Dashboard4 />,
    layout: "/admin",
  },
  {
    path: "/store-management/user-profile4",
    name: "User_Profile",
    icon: "ni ni-tv-2 text-secondary",
    component: <Profile4/>,
    layout: "/admin",
  },
 
  // {
  //   path:"/store-management/Project#",
  //   name: "project",
  //   icon: "ni ni-circle-08 text-green",
  //   component: <Project3 />,
  //   layout: "/admin",
  // },
  
  {
    path:"/store-management/Storemanagement4",
    name: "Store Management",
    icon: "fa-solid fa-store text-primary" ,
    component: <Storemanagement4 />,
    layout: "/admin",
  },
  {
    path:"/store-management/editstock4/:id",
    name: "Edit_Stock",
    icon: "ni ni-planet text-blue",
    component: <Editstock4 />,
    layout: "/admin",
    
  },
  {
    path:"/store-management/Materials4",
    name: "Materials Required",
    icon: "fa-sharp fa-solid fa-hammer text-yellow",
    component: <Materials4 />,
    layout: "/admin",
  },

  {
    path:"/store-management/Equipment4",
    name: "Equipment Maintenance",
    icon: "ni ni-settings text-orange ",
    component: <Equipment4 />,
    layout: "/admin",
  },
  {
    path:"/store-management/editproject4/:id",
    name: "Edit_Project",
    icon: "ni ni-planet text-blue",
    component: <Editproject4 />,
    layout: "/admin",
  },
  // {
  //   path: "/store-management/community4",
  //   name: "Community Hub ",
  //   icon: "ni ni-world-2 text-purple",
  //   component: <Community4/>,
  //   layout: "/admin",
  // }, 
  {
    path: "/store-management/hub4",
    name: "Hub",
    icon: "ni ni-planet text-blue",
    component: <Hub4/>,
    layout: "/admin",
  },
  {
    path: "/store-management/Wasteage4",
    name: "Wastage Maintainance",
    icon: "fa-solid fa-trash-alt text-green",
    component: <Wasteage4 />,
    layout: "/admin",
  },

  {
    path: "/store-management/Viewwaste4",
    name: "Wastage Maintainance",
    icon: "fa-solid fa-trash-alt text-green",
    component: < Viewwaste4/>,
    layout: "/admin",
  },

  {
    path: "/store-management/Editwaste4",
    name: "Wastage Maintainance",
    icon: "fa-solid fa-trash-alt text-green",
    component: <Editwaste4 />,
    layout: "/admin",
  },


  {
    path: "/store-management/leavetable4/:id",
    name: "Leave Table",
    icon: "ni ni-planet text-blue",
    component: <Leavetable4 />,
    layout: "/admin",
  }, 

  {
    path: "/store-management/Requestwaste4",
    name: "Request",
    icon: "ni ni-planet text-blue",
    component: <Requestwaste4 />,
    layout: "/admin",
  }, 

  {
    path: "/store-management/ViewMaintainance4/:id",
    name: "View Maintainance",
    icon: "ni ni-planet text-blue",
    component: <ViewMaintainance4 />,
    layout: "/admin",
  }, 

  {
    path: "/store-management/EditMaintainance4/:id",
    name: "Edit Maintainance",
    icon: "ni ni-planet text-blue",
    component: <EditMaintainance4 />,
    layout: "/admin",
  }, 

  {
    path: "/store-management/EditRequest4",
    name: "Edit Request",
    icon: "ni ni-planet text-blue",
    component: <EditRequest4 />,
    layout: "/admin",
  }, 

  {
    path: "/store-management/Invoice4",
    name: <span className="text-success">Invoice</span>, 
    icon: "fa-solid fa-file-invoice",
    component: <Invoice4 />,
    layout: "/admin",
  },


]

// client
export var routes_client =[

  {
    path: "/client-management/index5",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Index5 />,
    layout: "/admin",
  },

  
  {
    path: "/client-management/user-profile5",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile5 />,
    layout: "/admin",
  },

  {
    path:"/client-management/Material5",
    name:"Material",
    icon:"ni ni-building text-red",
    component: <Material5 />,
    layout: "/admin",
  },
  
  

];


export var routes_pres = [

  {
    path: "/president-management/dashboard6",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-secondary",
    component: <Dashboard6 />,
    layout: "/admin",
  },
  {
    path: "/president-management/user-profile6",
    name: "User_Profile",
    icon: "ni ni-tv-2 text-secondary",
    component: <Profile6/>,
    layout: "/admin",
  },
 

  {
    path:"/president-management/projects6",
    name: "Projects",
    icon: "fas fa-chart-line text-info",
    component: < Projects6 />,
    layout: "/admin",
  },

  {
    path:"/president-management/materials6",
    name: "Materials",
    icon: "fa-sharp fa-solid fa-hammer text-yellow",
    component: < Materials6 />,
    layout: "/admin",
  },


  {
    path: "/president-management/corousel6",
    name: "Community Hub ",
    icon: "ni ni-world-2 text-purple",
    component: <Corousel6/>,
    layout: "/admin",
  },
  

  {
    path: "/president-management/view-project6/:id",
    name: "View Project ",
    icon: "ni ni-world-2 text-purple",
    component: <Viewproject6/>,
    layout: "/admin",
  }, 




]