import React, { useState, useEffect } from 'react';
import {
  UncontrolledDropdown, CardBody, Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption, CardHeader, Card, DropdownToggle, DropdownMenu, DropdownItem, Table, Container, Row, Col, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Dropdown, Button,
  Modal, ModalHeader, ModalBody
} from "reactstrap";
import { api } from '../../../../api';
import Cookies from 'js-cookie';

const Materials2 = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [materialShow,setMaterialShow] = useState([]);


  const [searchQuery, setSearchQuery] = useState('');

  const toggleModalAdd = () => setModalAdd(prevState => !prevState);
  const [modalAdd, setModalAdd] = useState(false);
  const [clientForm, setClientForm] = useState({})
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteProjectName, setDeleteProjectName] = useState('');

  const [entriesToShow, setEntriesToShow] = useState(5);
  const [projectDropdownOpen1, setProjectDropdownOpen1] = useState(false);


  const shopName = [
    { "value": "Low", "label": "Low" },
    { "value": "High", "label": "High" },

  ];
  const projects = ['Project 1', 'Project 2', 'Project 3', 'Project 4'];

  const handleDeleteConfirmation = (confirmed, m_id) => {
    if (confirmed) {
      console.log(`Deleting materials with ID: ${m_id}`);
      const fetchData = async () => {
        try {
          const response = await api.get(`deleteMaterial.php?id=${m_id}`);
          console.log(response.data);
          window.location.reload();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
    // Close the delete confirmation modal
    setShowDeleteConfirmation(false);
  };

  const emp_id = Cookies.get('token');

  const [modalOpen, setModalOpen] = useState(false);
  const toggleModalView = () => {
    setModalOpen(!modalOpen);
  };
  const [modalEmployee, setModalEmployee] = useState(null);

  const [project, setproject] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`viewProjectDetails.php?s_m_id=${emp_id}`);
        setproject(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);



  const [projectmanager, setprojectmanager] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post(`employeesView.php`);
        setprojectmanager(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);


  const [reqmaterial, setreqmaterial] = useState({
    emp_id: emp_id,
    mat_name: "",
    mat_priority: "",
    mat_quantity: "",
    p_id: "",
    g_m_id: "",
    files: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setreqmaterial(prevState => ({
      ...prevState,
      [name]: value
    }));
    console.log(reqmaterial)
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setreqmaterial((prevState) => ({
      ...prevState,
      files: file,
    }));
  };

  const handleFormSubmit = async (e) => {
    // e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('emp_id', reqmaterial.emp_id);
    formDataToSend.append('p_id', reqmaterial.p_id);
    formDataToSend.append('mat_name', reqmaterial.mat_name);
    formDataToSend.append('mat_quantity', reqmaterial.mat_quantity);
    formDataToSend.append('mat_priority', reqmaterial.mat_priority);
    const files = reqmaterial.files; // Assuming reqmaterial.files is a FileList or an array of File objects

    for (let i = 0; i < files.length; i++) {
        formDataToSend.append('files[]', files[i]);
    }
    formDataToSend.append('g_m_id', reqmaterial.g_m_id);

    try {
      const response = await api.post("materialrequest.php", formDataToSend);
      console.log("Data sent successfully:", response.data);
      alert("Materail request submitted successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const [allmaterial, setallmaterial] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`materialrequest.php?emp_id=${emp_id}`);

        setDataToShow(response.data.slice(0, entriesToShow));
        setMaterialShow(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);



  const handleProjectSelection = async (p_id) => {
    try {
      const response = await api.get(`materialrequest.php?p_id=${p_id}&emp_id=${emp_id}`);
      setDataToShow(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);



  const [currentPage, setCurrentPage] = useState(1);
  const [showWasteFields, setShowWasteFields] = useState(false);
  const [showReplaceFields, setShowReplaceFields] = useState(false);

  const totalPages = Math.ceil(materialShow.length / entriesToShow);
  useEffect(() => {
    if (Array.isArray(filteredData)) {
      setDataToShow(filteredData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
    }
  }, [entriesToShow, currentPage, filteredData, totalPages]);



  const toggleProjectDropdown = () => {
    setProjectDropdownOpen(!projectDropdownOpen);
  };

  const toggleProjectDropdown1 = () => {
    setProjectDropdownOpen1(!projectDropdownOpen1);
  };

  const handleEntriesChange = (value) => {
    setEntriesToShow(value === 'All' ? materialShow.length : Number(value));
    setCurrentPage(1);
  };
  

  const prePage = () => {
  if (currentPage > 1) {
    setCurrentPage(prevPage => prevPage - 1);
  }
};

const nextPage = () => {
  if (currentPage < totalPages) {
    setCurrentPage(prevPage => prevPage + 1);
  }
};

const getSerialNumber = (index) => {
  return (currentPage - 1) * entriesToShow + index + 1;
};

  

  const changeCPage = (n) => {
    setCurrentPage(n);
  };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setDataToShow(materialShow.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
    } else {
      const filteredData = materialShow.filter(item =>
        item.mat_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.mat_quantity.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.mat_money.toString().includes(searchQuery) ||
        item.Date_Time.includes(searchQuery)
      );
      setDataToShow(filteredData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
    }
  }, [searchQuery, entriesToShow, currentPage, materialShow]);



  // const [profileImages, setProfileImages] = useState([]);
  // const handleFileUpload = (event) => {
  //       const files = event.target.files;
  //       const fileList = Object.values(files); 
  //       setProfileImages(prevImages => [...prevImages, ...fileList]);
  //       console.log(fileList);        
  // };

  const style = () => {
    const nextIcon = document.querySelector('.carousel-control-next-icon');
    if (nextIcon) {
      nextIcon.style.backgroundColor = 'black';
    }

    const prevIcon = document.querySelector('.carousel-control-prev-icon');
    if (prevIcon) {
      prevIcon.style.backgroundColor = 'black';
    }
  }; 
  style();
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [profileImages, setProfileImages] = useState([]);


  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === profileImages.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? profileImages.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const uploadedImagesArray = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = function (e) {
        const path = URL.createObjectURL(file);

        uploadedImagesArray.push({ name: file.name, data: path });
        if (uploadedImagesArray.length === files.length) {
          setProfileImages(uploadedImagesArray);
        }
      };
      console.log(uploadedImagesArray);
      reader.readAsDataURL(file);
    }

    setreqmaterial(prevImages => ({
      ...prevImages,
      files: [...prevImages.files, ...files]
    }));
  };

  const slides = profileImages.map((image, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={index}
    >
      <div className="profile-image-container" style={{
        width: '376px',
        height: '256px',
        border: '2px solid black',
        borderRadius: '9%',
        overflow: 'hidden',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        margin: '0 auto',
      }}>
        <img
          src={image.data}
          alt={`Uploaded Image ${index + 1}`}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
      <CarouselCaption captionText={image.name} captionHeader="" />
    </CarouselItem>
  ));


  const images = [
    "../../../images/65e867cb84c92_platter-2009590_960_720 copy.jpg",
    "../../../images/65e867cb84c92_platter-2009590_960_720.jpg"
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const [activeIndex1, setActiveIndex1] = useState(0);
  const imagesString = modalEmployee?.files;
  const imagesArray = imagesString ? imagesString.split(",") : [];

  const next1 = () => {
    if (animating || imagesArray.length === 0) return;
    const nextIndex =
      activeIndex1 === imagesArray.length - 1 ? 0 : activeIndex1 + 1;
    setActiveIndex1(nextIndex);
  };

  const previous1 = () => {
    if (animating || imagesArray.length === 0) return;
    const nextIndex =
      activeIndex1 === 0 ? imagesArray.length - 1 : activeIndex1 - 1;
    setActiveIndex1(nextIndex);
  };

  const goToIndex1 = (newIndex) => {
    if (animating || imagesArray.length === 0) return;
    setActiveIndex1(newIndex);
  };

  const slides1 = imagesArray
    .map((image, index) => {
      // Check if the image exists
      try {
        require(`../../../images/${image}`);
      } catch (error) {
        // Handle the case where the image doesn't exist
        console.error(`Image ${image} not found`);
        return null; // Return null to indicate that the slide should be skipped
      }

      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={index}
        >
          <div
            className="profile-image-container"
            style={{
              width: "376px",
              height: "256px",
              border: "2px solid black",
              borderRadius: "9%",
              overflow: "hidden",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
              margin: "0 auto",
            }}
          >
            <img
              id=""
              alt={`Uploaded Image ${index + 1 + image}`}
              src={require(`../../../images/${image}`)}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
          <CarouselCaption captionText="" captionHeader="" />
        </CarouselItem>
      );
    })
    .filter((slide) => slide !== null); // Filter out null values

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className='mb-5'>
            <Col>
              <Dropdown isOpen={projectDropdownOpen} toggle={toggleProjectDropdown}>
                <DropdownToggle caret>
                  Select Project
                </DropdownToggle>
                <DropdownMenu left>
                  {Array.isArray(project) && project.length > 0 ? (
                    project.map(project => (
                      <DropdownItem key={project.p_id} onClick={() => handleProjectSelection(project.p_id)}>
                        {project.p_name}
                      </DropdownItem>
                    ))
                  ) : (
                    <p>No Project Yet?</p>
                  )}
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
              <h1>Materials Details</h1>
            </Col>

            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
              <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                <FormGroup className="mb-0 ml-2">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon >
                    <Input
                      placeholder="Search Materials"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}

                    />

                  </InputGroup>

                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row className="">

            <Col className="mb-5 mb-xl-0" xl="12" style={{ marginTop: "-20px" }}>

              <Card className="shadow" >
                <CardBody>
                  <CardHeader className="border-0">

                    <Row className="align-items-left">
                      <div className="col">
                        <div className="col text-right">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="mr-3">
                              <Dropdown isOpen={projectDropdownOpen1} toggle={toggleProjectDropdown1}>
                                <DropdownToggle caret>
                                  Show Entries: {entriesToShow === filteredData.length ? "All" : entriesToShow}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem onClick={() => handleEntriesChange(5)}>5</DropdownItem>
                                  <DropdownItem onClick={() => handleEntriesChange(10)}>10</DropdownItem>
                                  <DropdownItem onClick={() => handleEntriesChange('All')}>All</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                            <div>
                              <Button color="primary" onClick={toggleModalAdd}>Request Materials</Button>
                            </div>
                          </div>
                        </div>



                      </div>

                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">SL.No</th>
                        <th scope="col">Material Name</th>
                        <th scope="col">Quality</th>
                        <th scope="col">Prices</th>
                        <th scope="col">Date/time</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(dataToShow) && dataToShow.length > 0 ? (
                        dataToShow.map((item, index) => (
                          <tr key={index}>
                            <th scope="row">{getSerialNumber(index)}</th>
                            <td>{item.mat_name}</td>
                            <td>{item.mat_quantity}</td>
                            <td>{item.mat_money}</td>
                            <td>{item.Date_Time}</td>
                            <td>{item.mat_status}</td>
                            <td>
                            <button onClick={() => { setModalEmployee(item); toggleModalView(); }} className='btn btn-deeppink'><i className="fa-solid fa-eye" ></i></button>
                              <button onClick={() => { setDeleteProjectName(item.m_id); setShowDeleteConfirmation(true) }} className='btn btn-deeppink'><i class="fa-solid fa-trash text-danger"></i></button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td style={{ textAlign: 'center' }} colSpan="7">No data available</td>
                        </tr>
                      )}

                    </tbody>
                  </Table>

                  <nav className='mt-3'>
                    <ul className='pagination justify-content-center'>
                      <li className='page-item'>
                        <a
                          href='#'
                          className='page-link text-primary'
                          onClick={prePage}
                          disabled={currentPage === 1}
                        >
                          Prev
                        </a>
                      </li>
                      <li className='page-item'>
                        <a
                          href='#'
                          className='page-link text-primary'
                          onClick={nextPage}
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={modalAdd} toggle={toggleModalAdd}>
        <ModalHeader toggle={toggleModalAdd}>Add Materials</ModalHeader>
        <Form onSubmit={handleFormSubmit} enctype="multipart/form-data">
          <Row className='px-3'>

            <Col md={12}>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-status">Project Name</label>
                <Input
                  className="form-control-alternative"
                  id="input-status"
                  type="select"
                  name="p_id"
                  onChange={handleInputChange}
                >
                  <option value="">Select Project Name</option>
                  {project && project.length > 0 ? (
                    project.map(option => (
                      <option key={option.p_id} value={option.p_id}>{option.p_name}</option>
                    ))
                  ) : (
                    <option value="">No projects available</option>
                  )}

                </Input>
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-status">General Manager</label>
                <Input
                  className="form-control-alternative"
                  id="input-status"
                  type="select"
                  name="g_m_id"
                  onChange={handleInputChange}
                >
                  <option value="">Select General Manager</option>
                  {Array.isArray(projectmanager) && projectmanager.length > 0 ? (
                    projectmanager.map(option => (
                      <option key={option.emp_id} value={option.emp_id}>{option.emp_name + "[" + option.role + "]"}</option>
                    ))
                  ) : (
                    <option value="">No project managers available</option>
                  )}


                </Input>
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-name">Material Name</label>
                <Input
                  className="form-control-alternative"
                  id="input-material"
                  type="text"
                  name="mat_name"
                  onChange={handleInputChange}
                  placeholder='Material Name'
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-name">Material Quantity</label>
                <Input
                  className="form-control-alternative"
                  id="input-material"
                  type="text"
                  name="mat_quantity"
                  onChange={handleInputChange}
                  placeholder='Material Quantity'
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-status">Materials Priority </label>
                <Input
                  className="form-control-alternative"
                  id="input-status"
                  type="select"
                  name="mat_priority"
                  onChange={handleInputChange}
                >
                  <option value="">Select Priority</option>
                  {shopName.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            {/* <Col md={12}>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-budget">Material Price</label>
                <Input
                  className="form-control-alternative"
                  id="input-budget"
                  type="number"
                  name="mat_price"
                  onChange={handleInputChange}
                  placeholder='Material Price'
                />
              </FormGroup>
            </Col> */}

            <Col md={12}>
            <label className="form-control-label" htmlFor="input-budget">Upload Images</label>

            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
            >

              <CarouselIndicators items={profileImages} activeIndex={activeIndex} onClickHandler={goToIndex} />

              {slides}

              <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} style={{ backgroundColor: 'black' }} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>

          </Col>
          <Col lg="12" className="text-left m-2">
            <Button
              color="info"
              onClick={() => document.getElementById('input-profile-image').click()}
            >
              Upload
            </Button>

            <FormGroup style={{ display: 'none', }}>
              <Input
                type="file"
                id="input-profile-image"
                accept="image/*"
                onChange={handleFileUpload}

                multiple
              />
            </FormGroup>
          </Col>
          </Row>
          <Row>
            <Col lg='12' className='text-center mb-2'>
              <Button color="primary" type="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>



      <Modal isOpen={showDeleteConfirmation} toggle={() => setShowDeleteConfirmation(false)}>
        <ModalHeader>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete ?
        </ModalBody>
        <div className="modal-footer">
          <Button color="danger" onClick={() => handleDeleteConfirmation("confirmed", deleteProjectName)}>Yes</Button>
          <Button color="secondary" onClick={() => setShowDeleteConfirmation(false)}>No</Button>
        </div>
      </Modal>


      {modalEmployee && modalEmployee && (
              <Modal isOpen={modalOpen} toggle={toggleModalView}>
                <ModalHeader toggle={toggleModalView}>Material View</ModalHeader>
                <ModalBody >
                <div className="row">
                                    <div className="col-md-12">
                                        <div className=''>
                                            <label className='border p-2 rounded font-weight-bold' style={{width:'155px'}}>Material Name</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.mat_name}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>Quantity</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.mat_quantity}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>Priority</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.Priority}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>Date</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.Date_Time}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>General Manager</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.mat_status}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>Action</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.gm_report}</label>
                                        </div>
                                        <div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>Uploaded Images</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <Carousel
                                              activeIndex={activeIndex1}
                                              next={next1}
                                              previous={previous1}
                                            >
                                              <CarouselIndicators
                                                items={imagesArray}
                                                activeIndex={activeIndex1}
                                                onClickHandler={goToIndex1}
                                              />

                                              {slides1}

                                              <CarouselControl
                                                direction="prev"
                                                directionText="Previous"
                                                onClickHandler={previous1}
                                              />
                                              <CarouselControl
                                                direction="next"
                                                directionText="Next"
                                                onClickHandler={next1}
                                              />
                                            </Carousel>
                                        </div>
                                    </div>
                </div>
                </ModalBody>
              </Modal>
            )}

    </>
  );
};

export default Materials2;
