import React, { useState, useEffect } from 'react';
import {
    Button, Card, CardHeader, Table, Container, Row, Col, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Badge, Label,
    UncontrolledTooltip, UncontrolledDropdown, ModalFooter,
} from "reactstrap";
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Header from "dashboard/project-management/components/Headers/Header";
import { api } from '../../../../api';
import Cookies from 'js-cookie';
import { isEqual } from 'lodash';

const Leave = () => {
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const [filteredData, setFilteredData] = useState([]);

    //Pagination code part 1
    const [leaveList, setLeaveList] = useState([]);
    const [dynamo, setDynamo] = useState([]);
    const [change, setChange] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [modal, setModal] = useState(false); // State to control modal visibility
    const [modalAdd, setModalAdd] = useState(false);
    const toggleModalAdd = () => setModalAdd(prevState => !prevState);
    const [clientForm, setClientForm] = useState({
        status: "",
        starting: "",
        ending: "",
        description: ""
    });

    const [dropdownOpen, setDropdownOpen] = useState({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteProjectName, setDeleteProjectName] = useState('');


    // console.log(clientForm);

    // const emp_id = 1;
    const emp_id = Cookies.get('token');







    useEffect(() => {
        const fetchLeaveData = async () => {
            try {
                const response = await api.get(`leavePM.php?p_m_id=${emp_id}`);
                if (response.status === 200) {
                    setFilteredData(response.data);
                    setLeaveList(response.data);
                    setDynamo(response.data);
                    setChange(response.data);
                } else {
                    console.error("Failed to fetch leave data");
                }
            } catch (error) {
                console.error("Error fetching leave data:", error);
            }
        };

        fetchLeaveData();
    }, []); // Removed filteredData from dependency array

    if (isEqual(filteredData, leaveList)) {
        console.log('Correct', leaveList);
    }


    // const filteredSearchData = 
    //     filteredData.filter(item =>
    //         item.leave_type.toLowerCase().includes(searchQuery.toLowerCase())
    //     );

    const filteredSearchData = Array.isArray(filteredData) ?
        filteredData.filter(item =>
            (item.leave_type || item.leave_type).toLowerCase().includes(searchQuery.toLowerCase())
        ) :
        [];



    // console.log(filteredData);
    // const toggleDropdown = () => {
    //     setDropdownOpen(!dropdownOpen);
    // };

    const toggleDropdown = (index) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }

    const toggleProjectDropdown = () => {
        setProjectDropdownOpen(!projectDropdownOpen);
    };

    const toggleModal = () => {
        setModal(!modal);
    };

    const statusOptions = [
        { "value": "Casual Leave", "label": "Casual Leave" },
        { "value": "Medical Leave", "label": "Medical Leave" },
        { "value": "Voting Leave", "label": "Voting Leave" },
        { "value": "Sabbatical Leave", "label": "Sabbatical Leave" },
        { "value": "Bereavement Leave", "label": "Bereavement Leave" },
        { "value": "Maternity Leave", "label": "Maternity Leave" },
        { "value": "Paternity Leave", "label": "Paternity Leave" },
        { "value": "Adoption Leave", "label": "Adoption Leave" },
        { "value": "Family Care Leave", "label": "Family Care Leave" },
        { "value": "Study Leave", "label": "Study Leave" },
        { "value": "Jury Duty Leave", "label": "Jury Duty Leave" },
        { "value": "Public Holiday Leave", "label": "Public Holiday Leave" },
        { "value": "Religious Holiday Leave", "label": "Religious Holiday Leave" }
    ];
    const managerOptions = [
        { value: 'jay', label: 'jay' },
        { value: 'anu', label: 'anu' },
    ];
    const siteOptions = [
        { value: 'harish', label: 'harish' },
        { value: 'jyo', label: 'jyo' },
    ];
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setClientForm(prevState => ({
            ...prevState,
            file: file
        }));
    };


    const projects = ['Project 1', 'Project 2', 'Project 3', 'Project 4'];
    const [project, setproject] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`viewProjectDetails.php?p_m_id=${emp_id}`);
                setproject(response.data);
                console.log(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);



    const [display2, setdisplay2] = useState("none");
    const handleProjectSelection = async (p_id) => {
        try {
            const response = await api.get(`leaveViewByPID.php?pid=${p_id}`);
            setFilteredData(response.data);
            setChange(response.data);
            setCurrentPage(1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleStatusAction = async (id, status) => {
        const data = {
            id: id,
            status: status
        }
        try {
            const response = await api.post('leaveViewByPID.php', data);
            console.log(response.data);
        } catch (error) {
            console.error('Error updating status:', error);
        }
        window.location.reload();
    };

    const [leaveDetails, setleaveDetails] = useState({
        emp_id: emp_id,
        leave_type: '',
        Start_date: '',
        End_date: '',
        description: ''

    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setleaveDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(leaveDetails);
    };

    const handleSubmit = async (event) => {
        // event.preventDefault();

        try {
            const response = await api.post("leavePM.php", leaveDetails);
            console.log("Data sent successfully:", response.data);
            toggleModalAdd(true)
            window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    const handleDeleteConfirmation = (confirmed, m_id) => {
        if (confirmed) {
            const fetchData = async () => {
                try {
                    const response = await api.get(`leaveEmpDetails.php?del_id=${deleteProjectName}`);
                    console.log(response.data);
                    window.location.reload();
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
        }
        // Close the delete confirmation modal
        setShowDeleteConfirmation(false);
    };


    //Pagination Code Goes here Part 2
    const [projectDropdownOpen1, setProjectDropdownOpen1] = useState(false);
    const [entriesToShow, setEntriesToShow] = useState(5);
    const totalPages = Math.ceil(dynamo.length / entriesToShow);

    const toggleProjectDropdown1 = () => {
        setProjectDropdownOpen1(!projectDropdownOpen1);
    };

    const handleEntriesChange = (value) => {
        setEntriesToShow(value === 'All' ? leaveList.length : Number(value));
    };

    const prePage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const getSerialNumber = (index) => {
        return (currentPage - 1) * entriesToShow + index + 1;
    };

    useEffect(() => {
        if (Array.isArray(change)) {
            setFilteredData(change.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
        } else {
            setFilteredData([]); // Handle case where change is not an array
        }
    }, [entriesToShow, currentPage, change]);
    





    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Container fluid>
                    <Row className='mb-5'>
                        <Col>
                            <Dropdown isOpen={projectDropdownOpen} toggle={toggleProjectDropdown}>
                                <DropdownToggle caret>
                                    Select Project
                                </DropdownToggle>
                                <DropdownMenu left>
                                    {display2 != "none" && (
                                        <DropdownItem onClick={() => handleProjectSelection("all")}>
                                            All Projects
                                        </DropdownItem>
                                    )}
                                    {Array.isArray(project) ? (
                                        project.map(project => (
                                            <DropdownItem key={project.p_id} onClick={() => handleProjectSelection(project.p_id)}>
                                                {project.p_name}
                                            </DropdownItem>
                                        ))
                                    ) : (
                                        // Render fallback UI or handle the error in any other appropriate way
                                        <p className="text-center">No projects found</p>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
                            <h1>Leave Management Table</h1>
                        </Col>

                        <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
                            <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                                <FormGroup className="mb-0 ml-6">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Search Materials"
                                            type="text"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Leave List</h3>

                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="mr-3">
                                                    <Dropdown isOpen={projectDropdownOpen1} toggle={toggleProjectDropdown1}>
                                                        <DropdownToggle caret>
                                                            Show Entries: {entriesToShow === dynamo.length ? "All" : entriesToShow}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => handleEntriesChange(5)}>5</DropdownItem>
                                                            <DropdownItem onClick={() => handleEntriesChange(10)}>10</DropdownItem>
                                                            <DropdownItem onClick={() => handleEntriesChange('All')}>All</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>

                                                <Button color="primary" onClick={toggleModalAdd}>Apply Leave</Button>

                                            </div>


                                        </div>
                                    </Row>
                                </CardHeader>

                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope='col'>Id</th>
                                            <th scope="col">Leave Type</th>
                                            <th scope="col">Start Date</th>
                                            <th scope="col">End Date</th>
                                            <th scope='col'>Description</th>
                                            <th scope='col'>Status</th>
                                            <th scope='col'>View</th>
                                            <th scope='col'>Action</th>

                                            {/* <th scope="col">Current Status</th>
                                            <th scope="col">Views</th>
                                            <th scope="col">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredSearchData && Array.isArray(filteredData) ? (
                                            filteredSearchData.length > 0 ? (
                                                filteredSearchData.map((item, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">{getSerialNumber(index)}</th>
                                                        <td>{item.leave_type}</td>
                                                        <td>{item.start_date}</td>
                                                        <td>{item.end_date}</td>
                                                        <td>{item.content_des}</td>
                                                        <td>
                                                            <Badge style={{ backgroundColor: '#F7D800', color: 'black' }} className="p-2">
                                                                {item.status}
                                                            </Badge>
                                                        </td>
                                                        <td>
                                                            <Link to={`/project-admin/project-management/leavetable/${item.id}`}>   <i className="fa-solid fa-eye" ></i></Link>
                                                        </td>
                                                        {item && item.emp_id != emp_id ? (
                                                            <td>
                                                                <Dropdown isOpen={dropdownOpen[index]} toggle={() => toggleDropdown(index)}>
                                                                    <DropdownToggle caret>
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem onClick={() => handleStatusAction(item.id, "Approved")}>Approved</DropdownItem>
                                                                        <DropdownItem onClick={() => handleStatusAction(item.id, "Rejected")}>Rejected</DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                <button onClick={() => { setDeleteProjectName(item.id); setShowDeleteConfirmation(true) }} className='btn btn-deeppink'><i class="fa-solid fa-trash text-danger"></i></button>
                                                            </td>
                                                        )}


                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="5" style={{ textAlign: 'center' }}>No details available, Project not yet started</td>
                                                </tr>
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan="8" style={{ textAlign: 'center' }}>No employees applied leave on this project</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </Table>
                                <nav className='mt-3'>
                                    <ul className='pagination justify-content-center'>
                                        <li className='page-item'>
                                            <a
                                                href='#'
                                                className='page-link text-primary'
                                                onClick={prePage}
                                                disabled={currentPage === 1}
                                            >
                                                Prev
                                            </a>
                                        </li>
                                        <li className='page-item'>
                                            <a
                                                href='#'
                                                className='page-link text-primary'
                                                onClick={nextPage}
                                                disabled={currentPage === totalPages}
                                            >
                                                Next
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modalAdd} toggle={toggleModalAdd}>
                <ModalHeader toggle={toggleModalAdd}>Add Leave</ModalHeader>
                <Row className='px-3'>

                    <Col md={12}>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-status">Leave Type</label>
                            <Input
                                className="form-control-alternative"
                                id="input-status"
                                type="select"
                                name="leave_type"
                                onChange={handleInputChange}
                            >
                                <option value="">Select Project</option>
                                {statusOptions.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-starting">Leave Start Date</label>
                            <Input
                                className="form-control-alternative"
                                id="input-starting"
                                type="date"
                                name="Start_date"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-ending">Leave End Date</label>
                            <Input
                                className="form-control-alternative"
                                id="input-ending"
                                type="date"
                                name="End_date"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-description">Describe about your condition</label>
                            <Input
                                className="form-control-alternative"
                                id="input-description"
                                type="textarea"
                                name="description"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg='12' className='text-center mb-2'>
                        <Button color="primary" type="submit" onClick={handleSubmit}>Submit</Button>
                    </Col>
                </Row>
            </Modal>

            <Modal isOpen={showDeleteConfirmation} toggle={() => setShowDeleteConfirmation(false)}>
                <ModalHeader>Confirm Deletion</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete ?
                </ModalBody>
                <div className="modal-footer">
                    <Button color="danger" onClick={() => handleDeleteConfirmation("confirmed", deleteProjectName)}>Yes</Button>
                    <Button color="secondary" onClick={() => setShowDeleteConfirmation(false)}>No</Button>
                </div>
            </Modal>

        </>
    );
};

export default Leave;