import React, { useState, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// core components
import Header from "dashboard/general-management/components/Headers/Header.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { api } from "../../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { loadImage } from "Utility/imageIssue";

const Client1 = () => {
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [showForm, setShowForm] = useState(false); // State to control form visibility
  const [showAddClientModal, setShowAddClientModal] = useState(false); // State to control "Add Client" modal visibility
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [deleteEmployee, setdeleteEmployee] = useState(null);

  const [clientForm, setClientForm] = useState([]);

  const [employeeIdToDelete, setEmployeeIdToDelete] = useState(null);

  const toggleDropdown = (id) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const [clientData, setClientData] = useState([
    {
      // Initialize your form data fields here
      client_name: "",
      client_email: "",
      client_number: "",
      company_name: "",
      profile_image: null,
      address: "",
    },
  ]);

  // Function to handle form submission
  const handleSubmit = async (event) => {
    // event.preventDefault(true);

    const formdata = new FormData();
    formdata.append("client_name", clientData.client_name);
    formdata.append("client_email", clientData.client_email);
    formdata.append("client_number", clientData.client_number);
    formdata.append("company_name", clientData.company_name);
    formdata.append("profile_image", clientData.profile_image);
    formdata.append("address", clientData.address);

    try {
      const response = await api.post("clientGM.php", formdata);
      console.log("Data sent successfully:", response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const toggleModal = () => setModalOpen((prevState) => !prevState);

  const toggleAddClientModal = () =>
    setShowAddClientModal((prevState) => !prevState);

  // const toggleDeleteConfirmationModal = () => setShowDeleteConfirmationModal(prevState => !prevState);

  const handleAddClient = () => {
    // Show the form when "Add Client" is clicked
    setShowForm(true);
    // Show the "Add Client" modal
    toggleAddClientModal();
  };

  <CKEditor
    editor={ClassicEditor}
    data={clientForm.description}
    onChange={(event, editor) => {
      const data = editor.getData();
      setClientForm((prevState) => ({
        ...clientData,
        contract_details: data,
      }));
    }}
  ></CKEditor>;

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    // console.log(event.target);
    setClientData({
      ...clientData,
      [event.target.name]: event.target.value,
    });
    console.log(clientData);
  };

  const [tasks, setTasks] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get("clientGM.php");
        setTasks(response.data);
        setSearchData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Sample employee data (replace with your actual data)
  const employees = tasks;

  console.log(tasks);

  // Function to filter employees based on search query
  const filterEmployees = () => {
    if (!searchQuery) {
      setFilteredEmployees(employees);
    } else {
      const filtered = employees.filter(
        (employee) =>
          employee.client_name &&
          employee.client_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredEmployees(filtered);
    }
  };

  // Update filtered employees when searchQuery changes
  useEffect(
    () => {
      filterEmployees();
    },
    [searchQuery],
    tasks
  );

  const toggleViewMoreModal = (employee) => {
    setSelectedEmployee(employee);
  };

  const renderViewMoreModal = () => {
    if (!selectedEmployee) return null;

    return (
      <Modal isOpen={true} toggle={() => toggleViewMoreModal(null)}>
        <ModalHeader toggle={() => toggleViewMoreModal(null)}>
          Employee View
        </ModalHeader>
        <ModalBody style={{ justifyContent: "center", textAlign: "center" }}>
          <img
            alt="..."
            className="rounded-circle"
            src={loadImage(selectedEmployee.profile_image)}
            style={{ height: 100, marginBottom: 10 }}
          />
          <p>
            {" "}
            <b>Name :</b> {selectedEmployee.client_name}
          </p>
          <p>
            <b>Email</b> : {selectedEmployee.client_email}
          </p>
          <p>
            {" "}
            <b>Mobile Number :</b> {selectedEmployee.client_number}
          </p>
          <p>
            <b>Company Name :</b> {selectedEmployee.company_name}
          </p>
          {/* <p><b>Contract details :</b> {selectedEmployee.contract_details.replace(/<[^>]*>?/gm, '')} </p>    */}
          {/* Add other employee details as needed */}
        </ModalBody>
      </Modal>
    );
  };

  const [deleteConfirmationEmployeeId, setDeleteConfirmationEmployeeId] =
    useState(null);

  const toggleDeleteConfirmationModal = (employeeId) => {
    setDeleteConfirmationEmployeeId(
      employeeId === deleteConfirmationEmployeeId ? null : employeeId
    );
  };

  const handleDropdownItemClick = (action, employeeId, employeeName) => {
    if (action === "Delete") {
      // Open delete confirmation modal
      toggleDeleteConfirmationModal(employeeId);
    } else {
      console.log(`Clicked ${action}`);
    }
  };

  const handleDeleteConfirmation = (confirmed, employeeId) => {
    if (confirmed) {
      console.log(`Deleting employee with ID: ${employeeId}`);
      const fetchData = async () => {
        try {
          const response = await api.get(`deleteclient.php?id=${employeeId}`);
          console.log(response.data);
          window.location.reload();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
    // Close the delete confirmation modal
    toggleDeleteConfirmationModal(employeeId);
  };

  const [profileImage, setProfileImage] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      console.log(file);
      setProfileImage(URL.createObjectURL(file));
      // You can also send the file to the server here if needed
      setClientData({ ...clientData, profile_image: event.target.files[0] });
    }
  };

  const displayClient = Array.isArray(tasks) ? tasks.filter((client) =>
    client.client_name.toLowerCase().includes(searchQuery.toLowerCase())
  ):[];



  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="mb-5">
            <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
              <h1>Client Details</h1>
            </Col>
            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex text-right">
              <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                <FormGroup className="mb-0 ml-6">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search Client Name"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
              <Col className="order-xl-2 mb-5 mb-xl-0 ">
                <Button onClick={handleAddClient}>Add Client</Button>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            {employees &&
              employees.length > 0 &&
              employees.map((employee) => (
                <Modal
                  key={employee.client_id}
                  isOpen={deleteConfirmationEmployeeId === employee.client_id}
                  toggle={() => toggleDeleteConfirmationModal(employee.id)}
                >
                  <ModalHeader
                    toggle={() =>
                      toggleDeleteConfirmationModal(employee.client_id)
                    }
                  >
                    Confirm Delete
                  </ModalHeader>
                  <ModalBody>
                    Are you sure you want to delete {employee.client_name}?
                    <div className="mt-3 text-right">
                      <form>
                        <Button
                          color="danger"
                          onClick={() =>
                            handleDeleteConfirmation(true, employee.client_id)
                          }
                        >{`Yes, delete ${employee.client_name}`}</Button>{" "}
                      </form>
                      <Button
                        color="secondary"
                        onClick={() =>
                          handleDeleteConfirmation(false, employee.client_id)
                        }
                      >
                        No
                      </Button>
                    </div>
                  </ModalBody>
                </Modal>
              ))}

            {displayClient.map((employee) => (
              <Col md="3" key={employee.client_id}>
                <Card className="card-profile shadow mb-5">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={loadImage(employee.profile_image)}
                            style={{ height: 100, width: 100 }}
                          />
                        </a>
                      </div>
                      {/* Three-dot icon */}

                      <Dropdown
                        isOpen={dropdownOpen[employee.client_id]}
                        toggle={() => toggleDropdown(employee.client_id)}
                        style={{ top: "5px", left: "110px" }}
                      >
                        <DropdownToggle
                          tag="span"
                          data-toggle="dropdown"
                          aria-expanded={dropdownOpen}
                          caret
                          style={{
                            cursor: "pointer",
                            justifyContent: "center",
                          }}
                        >
                          <i className="fas fa-ellipsis-v"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <Link
                            to={`/general-admin/general-management/editclient1/${employee.client_id}`}
                            style={{ color: "Black" }}
                          >
                            <DropdownItem
                              onClick={() => console.log("Edit clicked")}
                            >
                              Edit
                            </DropdownItem>
                          </Link>
                          <DropdownItem
                            onClick={() =>
                              handleDropdownItemClick(
                                "Delete",
                                employee.client_id,
                                employee.client_name
                              )
                            }
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>

                  <CardBody className="pt-0 pt-md-5 mt-5">
                    <div className="text-center">
                      <h3>
                        {employee.client_name}
                        <span className="font-weight-light">, {}</span>
                      </h3>
                      <div className="h4 font-weight-300">
                        {employee.client_email}
                      </div>
                      <div className="h5 font-weight-300">
                        {employee.client_number}
                      </div>

                      <hr className="my-4" />
                      <a
                        href="#pablo"
                        onClick={() => toggleViewMoreModal(employee)}
                      >
                        View more
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      {/* Add Client Modal */}
      <Modal isOpen={showAddClientModal} toggle={toggleAddClientModal}>
        <ModalHeader toggle={toggleAddClientModal}>Add Client</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row className="justify-content-center align-items-center">
              <Col lg="12" className="text-center mb-3">
                {!profileImage && (
                  <div
                    className="profile-image-container"
                    style={{
                      width: "120px",
                      height: "120px",
                      border: "2px solid black",
                      borderRadius: "50%",
                      overflow: "hidden",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                      margin: "0 auto",
                    }}
                  >
                    {tasks.profile_image && (
                      <img
                        src={loadImage(tasks.profile_image)}
                        alt="Placeholder"
                        name="image"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                )}
                {profileImage && (
                  <div
                    className="profile-image-container"
                    style={{
                      width: "120px",
                      height: "120px",
                      border: "2px solid black",
                      borderRadius: "50%",
                      overflow: "hidden",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                      margin: "0 auto",
                    }}
                  >
                    <img
                      src={profileImage}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              </Col>

              <Col lg="12" className="text-center">
                <Button
                  color="info"
                  onClick={() =>
                    document.getElementById("input-profile-image").click()
                  }
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>

                <FormGroup style={{ display: "none" }}>
                  <Input
                    type="file"
                    id="input-profile-image"
                    accept="image/*"
                    onChange={handleFileUpload}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    Client Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-name"
                    type="text"
                    name="client_name"
                    onChange={handleInputChange}
                    placeholder="Client Name"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    Client Email
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-name"
                    type="email"
                    name="client_email"
                    onChange={handleInputChange}
                    required
                    placeholder="Client Email"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    Client Number
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-name"
                    type="number"
                    name="client_number"
                    onChange={handleInputChange}
                    required
                    placeholder="Client Number"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    Company Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-name"
                    type="text"
                    name="company_name"
                    onChange={handleInputChange}
                    required
                    placeholder="Company Name"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={12}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-location"
                  >
                    Location
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-name"
                    type="location"
                    name="address"
                    required
                    onChange={handleInputChange}
                    placeholder="Location"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      {renderViewMoreModal()}
    </>
  );
};

export default Client1;
