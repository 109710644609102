import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Progress,
  CardImg,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CircleChart from "dashboard/hr-management/variables/circlebar";
import {api} from '../../../api';


const Dashboard3 = () => {
  const [date, setDate] = useState(new Date());
  const [selectedDateLabel, setSelectedDateLabel] = useState("");
  const [isBirthday, setIsBirthday] = useState(false);
  const [timeOfDay, setTimeOfDay] = useState("");

  // Function to format the selected date label
  const getFormattedDateLabel = (date) => {
    // Customize the date label format as needed
    return `${date.toLocaleString("default", {
      month: "long",
    })} ${date.getDate()}, ${date.getFullYear()}`;
  };
  useEffect(() => {
    // Update the selected date label when the date changes
    setSelectedDateLabel(getFormattedDateLabel(date));
  }, [date]);

  const checkBirthday = (date) => {
    // Check if the selected date matches a predefined birthday (e.g., January 1)
    return date.getMonth() === 0 && date.getDate() === 1;
  };

  const getTimeOfDay = (date) => {
    const hour = date.getHours();

    if (hour >= 0 && hour < 12) {
      return "Morning🌞";
    } else if (hour >= 12 && hour < 18) {
      return "Afternoon⛅"; // Corrected to 'Afternoon'
    } else {
      return "Night🌙";
    }
  };

  useEffect(() => {
    const label = getFormattedDateLabel(date);
    setSelectedDateLabel(label);
    setIsBirthday(checkBirthday(date));
    setTimeOfDay(getTimeOfDay(date));
  }, [date]);

  const [progressPercentage, setProgressPercentage] = useState(75);


  const [employees, setEmployees] = useState([]);
    const fetchEmployeeName = async () => {
        try {
            const response = await api.get("employeeCalendar.php");
            setEmployees(response.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchEmployeeName();
    },[])


    useEffect(() => {
        const label = getFormattedDateLabel(date);
        setSelectedDateLabel(label);

        // Check if it's the user's birthday
        // const isAnyBirthday = employees.some(employee => isEmployeeBirthday(date, employee))
        // setIsBirthday(checkBirthday(date));

        // Determine the time of day
        setTimeOfDay(getTimeOfDay(date));
    }, [date, employees]);


    const isEmployeeBirthday = (date, employee) => {
        const employeeDOB = new Date(employee.dob);
        return (
            date.getDate() === employeeDOB.getDate() &&
            date.getMonth() === employeeDOB.getMonth()
        );
    };

    const isEmployeeWorkAnniversary = (date, employee) => {
        const anniversaryDate = new Date(employee.doj);
        return (
            date.getDate() === anniversaryDate.getDate() &&
             date.getMonth() === anniversaryDate.getMonth()
        );     
    };

  const [count,setCount] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("hrdashboard.php");
        setCount(response.data);
        console.log("counrt",response.data);

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  

  // const empCount = count?.No records?.empCount;

console.log('count',count);
  

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>

          <Row>
            {/* Total Projects Card */}
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h2"
                        style={{ color: "#545dd4", fontWeight: "bolder" }}
                      >
                        Total Employees
                      </CardTitle>
                      <Col className="col-auto mt-3">
                        <div
                          className="icon icon-shape text-white rounded-circle shadow mr-3"
                          style={{ backgroundColor: "#545DD4" }}
                        >
                          <i className="fas fa-users" />
                        </div>
                        <span className="h1 font-weight-bold mb-0 ml-2">{count[0]?.empcount ?? "0"}</span>
                      </Col>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            {/* Inprogress Projects Card */}
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h2"
                        style={{ color: "#569715", fontWeight: "bolder" }}
                      >
                        Ontime Today
                      </CardTitle>
                      <Col className="col-auto mt-3 mr-3">
                        <div
                          className="icon icon-shape text-white rounded-circle shadow"
                          style={{ backgroundColor: "#569715" }}
                        >
                          <i className="fas fa-calendar-check" />
                        </div>
                        <span className="h1 font-weight-bold mb-0 ml-3">{count[1]?.ontimecount ?? "0"}</span>
                      </Col>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            {/* Completed Tasks Card */}
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h2"
                        style={{ color: "#c50c36", fontWeight: "bolder" }}
                      >
                        Late Today
                      </CardTitle>
                      <Col className="col-auto mt-3 mr-3">
                        <div
                          className="icon icon-shape text-white rounded-circle shadow"
                          style={{ backgroundColor: "#C50C36" }}
                        >
                          <i className="fas fa-calendar-xmark" />
                        </div>
                        <span className="h1 font-weight-bold mb-0 ml-3">{count[2]?.latecount ?? "0"}</span>
                      </Col>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h2"
                        style={{ color: "#632686", fontWeight: "bolder" }}
                      >
                        Leave Requests
                      </CardTitle>
                      <Col className="col-auto mt-3 mr-3">
                        <div
                          className="icon icon-shape text-white rounded-circle shadow"
                          style={{ backgroundColor: "#632686" }}
                        >
                          <i className="fas fa-envelopes-bulk" />
                        </div>
                        <span className="h1 font-weight-bold mb-0 ml-3">{count[3]?.leavecount ?? "0"}</span>
                      </Col>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="8">
              <Card className="card-stats mb-4 mb-xl-0 m-0">
                <CardBody>
                  <Row>
                    <h1 className="px-2" style={{fontWeight:'bolder'}}>Employee's Attendance</h1>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-stats mb-4 mb-xl-0 p-2 mt-3">
                <CardBody>
                 <CircleChart/>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
                            <Card className="card-stats mb-4 mb-xl-0">
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle tag="h3" className="text-uppercase text-muted mb-0">
                                                Calendar
                                            </CardTitle>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col className="col-auto mt-3 mr-3">
                                            {/* Use the Calendar component with inline CSS */}
                                            <Calendar
                                                style={{
                                                    width: '100%', // Set the width to 100% of the container
                                                    height: '300px', // Set a fixed height
                                                    backgroundColor: '#fff', // Set background color
                                                    borderRadius: '8px', // Add border-radius for rounded corners
                                                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
                                                }}
                                                onChange={setDate}
                                                value={date}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <p>Selected Date: {selectedDateLabel}</p>
                                            {isBirthday && (
                                                <div>
                                                    <Row>
                                                        <Col className="col-auto">
                                                            {/* Add the image on one side */}
                                                            <img
                                                                src="path/to/birthday_image.jpg"
                                                                alt="Birthday"
                                                                style={{
                                                                    width: '100px', // Set the width of the image
                                                                    height: '100px', // Set the height of the image
                                                                    borderRadius: '50%', // Make it a circular image
                                                                }}
                                                            />
                                                        </Col>

                                                    </Row>
                                                </div>
                                            )}
                                            {timeOfDay && <p className="text-center p-3" style={{fontWeight:'bold', color:'#000',backgroundColor:"#dff6f9",
                      borderRadius: "0.375rem",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",}}>Good {timeOfDay}</p>}
                                        </Col>
                                    </Row>

                                    {/* Birthday and Anniversary */}

                                    {employees.some((employee) => isEmployeeBirthday(date, employee) || isEmployeeWorkAnniversary(date, employee)) && (
                                        <>
                                            {employees.map((employee, index) => {
                                                if (isEmployeeBirthday(date, employee) && isEmployeeWorkAnniversary(date, employee)) {
                                                    return (
                                                        <Row key={index} style={{
                                                            backgroundColor:"#dff6f9",
                                                          borderRadius: "0.375rem",
                                                          padding: "10px",
                                                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                                        }}>
                                                            <Col xs={3} className='text-center'>
                                                                <img
                                                                    // src={require(`./../../images/${employee.profile_image}`)}
                                                                  
                                                                    alt="Birthday & Work Anniversary"
                                                                    style={{ width: '80px', height: '80px', borderRadius: '50%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <p style={{ fontSize: 'bold', color: '#333', marginBottom: '10px', textAlign: 'center' }}>🎉Happy Birthday & Work Anniversary!🎉</p>
                                                                <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px', color: '#666', marginBottom: '5px' }}>{employee.emp_name}</p>
                                                                <p style={{ marginTop: '10px', fontSize: '16px', color: '#555', textAlign: 'center' }}>Double celebration! Wishing you a day filled with joy, laughter, and all the things you love.</p>
                                                            </Col>
                                                        </Row>
                                                    );
                                                } else if (isEmployeeBirthday(date, employee)) {
                                                    return (
                                                        <Row key={index} style={{ border: '2px solid #ddd', borderRadius: '10px', padding: '20px', backgroundColor: '#fff', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', marginBottom: '10px' }}>
                                                            <Col xs={3} className='text-center'>
                                                                <img
                                                                    src={require(`./../../images/${employee.profile_image}`)}
                                                                    alt="Birthday"
                                                                    style={{ width: '80px', height: '80px', borderRadius: '50%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <p style={{ fontSize: 'bold', color: '#333', marginBottom: '10px', textAlign: 'center' }}>🎉Happy Birthday!🎉</p>
                                                                <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px', color: '#666', marginBottom: '5px' }}>{employee.emp_name}</p>
                                                                <p style={{ marginTop: '10px', fontSize: '16px', color: '#555', textAlign: 'center' }}>Wishing you a day filled with joy, laughter, and all the things you love!</p>
                                                            </Col>
                                                        </Row>
                                                    );
                                                } else if (isEmployeeWorkAnniversary(date, employee)) {
                                                    return (
                                                        <Row key={index} style={{ border: '2px solid #ddd', borderRadius: '10px', padding: '20px', backgroundColor: '#fff', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', marginBottom: '10px' }}>
                                                            <Col xs={3} className='text-center'>
                                                                <img
                                                                   // src={require(`./../../images/${employee.profile_image}`)}
                                                                   src={require("../../images/celebrate-emoji.gif")}

                                                                    alt="Work Anniversary"
                                                                    style={{ width: '80px', height: '80px', borderRadius: '50%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <p style={{ fontSize: 'bold', color: '#333', marginBottom: '10px', textAlign: 'center' }}>🎉Congratulations on your work anniversary!🎉</p>
                                                                <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px', color: '#666', marginBottom: '5px' }}>{employee.emp_name}</p>
                                                                <p style={{ marginTop: '10px', fontSize: '16px', color: '#555', textAlign: 'center' }}>It's been a pleasure having you on the team. Your hard work and dedication are appreciated.</p>
                                                            </Col>
                                                        </Row>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </>
                                    )}




                                    {/* ... Your existing code ... */}

                                </CardBody>
                            </Card>
                        </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard3;
