import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, Table, Container, Row, Col, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Badge, Label } from "reactstrap";
import { Link, useParams } from 'react-router-dom';
import {api} from '../../../../api'

const Leavetable4 = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [modal, setModal] = useState(false); // State to control modal visibility

    useEffect(() => {
        // Replace this with your actual data fetching logic
        const originalData = [
            { Materials: "bricks" },
        ];

        const filtered = originalData.filter(item =>
            item.Materials.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchQuery]);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleProjectDropdown = () => {
        setProjectDropdownOpen(!projectDropdownOpen);
    };

    const toggleModal = () => {
        setModal(!modal);
    };

    // getting id for client to edit
    const {id} = useParams();

    const [leaveDetails , setleaveDetails] = useState();
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await api.get(`leaveEmpDetails.php?id=${id}`);
            setleaveDetails(response.data);
            console.log(response.data)
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }, []);


      const [descAction , setdescAction ] = useState({
        id : id,
        status : '',
        description : ''
      });

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setdescAction(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(descAction);
    };


    const statusOptions = [
        { value: "Approved", label: "Accept" },
        { value: "Reject", label: "Reject" }
      ];



    const handleStatusAction = async (e) => {
        // e.preventDefault();
        try {
            const response = await api.post('leaveViewByPID.php', descAction);
            console.log(response.data);
        } catch (error) {
            console.error('Error updating status:', error);
        }
        window.location.reload();
    };



    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Container fluid>
                    <Row className='mb-5'>
                        <Link to="/hr-admin/hr-management/leave3"> <i className="fa-solid fa-right-from-bracket ml-4 mt-2" style={{ transform: 'rotate(180deg)', fontSize: '30px', color:'#fff' }}></i></Link>

                        <Col className="order-xl-2 mb-5 mb-xl-0 text-center ">
                            <h1 className='text-white'>Employee Leave Details</h1>
                        </Col>


                    </Row>


                    <Row className="mt-5">
                        
                        <Col className="mb-5 mb-xl-0" xl="12">
                            
                            <Card className="shadow">
                                {leaveDetails && leaveDetails.length > 0 ? (
                                leaveDetails.map((employee, index) => (
                                <CardHeader className="border-0">
                                   
                                        <div class="card-body p-0">

                                            <table class="table table-bordered table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th className="text-nowrap text-left fw-bold" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Employee Id</th>
                                                        <td colspan="5" class="text-left">{employee.emp_id}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-nowrap text-left" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Employee Name</th>
                                                        <td colspan="5" class="text-left">{employee.emp_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-nowrap text-left" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Employee Role</th>
                                                        <td colspan="5" class="text-left">{employee.role}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-nowrap text-left" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Employee Email</th>
                                                        <td colspan="5" class="text-left">{employee.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-nowrap text-left" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Employee Contact</th>
                                                        <td colspan="5" class="text-left">{employee.phoneno}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-nowrap text-left" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Leave type</th>
                                                        <td colspan="5" class="text-left">{employee.leave_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-nowrap text-left" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Leave From</th>
                                                        <td colspan="5" class="text-left">{employee.start_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-nowrap text-left" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Leave UpTo</th>
                                                        <td colspan="5" class="text-left">{employee.end_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-nowrap text-left" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Leave Applie On</th>
                                                        <td colspan="5" class="text-left">{employee.applied_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-nowrap text-left" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Status</th>
                                                        <td colspan="5" class="text-left">{employee.status}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-nowrap text-left" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Leave Description</th>
                                                        <td colspan="5" class="text-left"style={{textAlign:'justify'}}>{employee.content_des}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-nowrap text-left" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Admin Remarks</th>
                                                        <td colspan="5" class="text-left" style={{ textAlign: 'justify' }}>{employee.Admin_remark}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-nowrap text-left" scope="row" style={{ width: '575px', fontWeight: 'bold' }}>Admin Action On</th>
                                                        <td colspan="5" class="text-left">{employee.Admin_action}</td>
                                                    </tr>

                                                </tbody>
                                                
                                            </table>
                                            {employee && employee.role === "Project Manager" && (
                                                <div className="text-center mt-4 p-3" onClick={toggleModal}>
                                                    <Button color='dark'>Set Action</Button>
                                                </div>
                                            )}



                                            
                                        </div>
                                  
                                </CardHeader>
                              ))
                              ) : (
                                  <div>No leave details available</div>
                              )}

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}><h2 style={{fontWeight:'bolder'}}>Status Action</h2></ModalHeader>
                <ModalBody>
                    {/* Your form elements go here */}
                    <Form onSubmit={handleStatusAction} >
                        <FormGroup>
                            <Label for="exampleSelect">Select</Label>
                            <Input
                                className="form-control-alternative"
                                id="input-status"
                                type="select"
                                name="status"
                                onChange={handleInputChange}
                            >
                                <option value="">Select Status</option>
                                {statusOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleText">Description</Label>
                            <Input type="textarea" name="description" id="exampleText" onChange={handleInputChange} />
                        </FormGroup>
                        <Row>
                            <Col lg='12' style={{ textAlign: 'center' }}>
                                <Button color="primary" type="submit">Submit</Button>
                            </Col>
                            </Row>
                        {/* Add more form fields as needed */}
                    </Form>
                </ModalBody>
            </Modal>

        </>
    );
};

export default Leavetable4;

