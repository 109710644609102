import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  Button,
  Table,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
  CardHeader,
} from "reactstrap";

import * as XLSX from 'xlsx';

import "react-calendar/dist/Calendar.css";
import { api } from '../../../../api'
import { Link } from 'react-router-dom';


const Storemanagement4 = () => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('viewitemsSTM.php');
        const products = response.data.products;
        setOriginalData(products); // Store original data
        setFilteredData(products); // Initially set filtered data to original data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const [itemToDelete, setItemToDelete] = useState(null);

  const handleDeleteIconClick = (item_id) => {
    setItemToDelete(item_id);
    setShowDeleteConfirmation(true);
  };


  const handleDeleteConfirmation = async () => {
    try {
      const response = await api.delete('deleteitemsSTM.php', {
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ item_id: itemToDelete })
      });
      console.log(response.data);
      window.location.reload(); // Reload the page after deletion
    } catch (error) {
      console.error("Error deleting item:", error);
    }
    setItemToDelete(null);
    setShowDeleteConfirmation(false);
  };

  const [showRoleModal, setShowRoleModal] = useState(false);
  const toggleRole = () => {
    setShowRoleModal(!showRoleModal);
  };

  const [role, setrole] = useState();

  const [viewrole, setviewrole] = useState();
  const [loading, setLoading] = useState(true);

  const [entriesToShow, setEntriesToShow] = useState(5);
  const [dataToShow, setDataToShow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filteredData.length / entriesToShow);

  useEffect(() => {
    setDataToShow(filteredData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
  }, [entriesToShow, currentPage, filteredData]);

  const toggleProjectDropdown = () => {
    setProjectDropdownOpen(!projectDropdownOpen);
  };

  const handleEntriesChange = (value) => {
    setEntriesToShow(value === 'All' ? filteredData.length : Number(value));
    setCurrentPage(1);
  };

  const prePage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changeCPage = (n) => {
    setCurrentPage(n);
  };


  const handleDownload = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'stock_list';

    const formattedData = dataToShow.map((item) => ({
      'Item Id': item.item_id,
      'Item Name': item.item_name,
      'Item Category': item.item_category,
      
      'Current Stock(Quantity)': item.current_stock,
      'Unit(Kg)': item.unit ,
      'Cost': item.cost,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData, {
      header: ['Item Id', 'Item Name', 'Item Category', 'Current Stock(Quantity)', 'Unit(Kg)', 'Cost'],
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Stock List');

    const headerCellStyle = { font: { bold: true }, fill: { bgColor: { indexed: 64 } } };
    Object.keys(ws).filter((key) => key.startsWith('A1')).forEach((key) => {
      ws[key].s = headerCellStyle;
    });

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const excelData = new Blob([excelBuffer], { type: fileType });

    const excelUrl = URL.createObjectURL(excelData);

    const link = document.createElement('a');
    link.href = excelUrl;
    link.download = fileName + fileExtension;
    document.body.appendChild(link);
    link.click();

    setTimeout(function () {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(excelUrl);
    }, 100);
  };


  const [addstock, setAddStock] = useState({
    item_name: '',
    item_category: '',
    current_stock: 0,
    unit: '',
    cost: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddStock(prevState => ({
      ...prevState,
      [name]: value
    }));
    console.log(addstock);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = {
      item_name: addstock.item_name,
      item_category: addstock.item_category,
      current_stock: addstock.current_stock,
      unit: addstock.unit,
      cost: addstock.cost
    };

    try {
      const response = await api.post("additemsSTM.php", formDataToSend);
      console.log("Data sent successfully:", response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredData(originalData);
    } else {
      const filteredResults = originalData.filter(item =>
        item.item_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filteredResults);
    }
  }, [searchQuery, originalData]);

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <div className="header bg-gradient-info py-5">
            <Container fluid>
              <Row className="mb-5">
                <Col xs="12" className="d-flex justify-content-between align-items-center flex-wrap">
                  <Form className="navbar-search navbar-search-dark form-inline w-100 mb-3 mb-md-0">
                    <FormGroup className="mb-0 w-100">
                      <InputGroup className="input-group-alternative w-70">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                          <Input
                            placeholder="Search Stock"
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-100" // Adjust width of input field
                          />
                        </InputGroupAddon>
                      </InputGroup>
                      {/* Add margin to the button */}
                      <div className="ml-3">
                        <Button onClick={toggleRole}>
                          Add Stock
                        </Button>
                      </div>
                    </FormGroup>
                  </Form>


                </Col>
              </Row>
            </Container>
          </div>
          <Row className="">
            <Col xl="12" style={{ marginTop: "-20px" }}>
              <Card className="shadow">
                <CardBody>
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Stock List</h3>
                      </div>
                      <div className="col text-right">
                        <Dropdown isOpen={projectDropdownOpen} toggle={toggleProjectDropdown}>
                          <DropdownToggle caret>
                            Show Entries: {entriesToShow === filteredData.length ? 'All' : entriesToShow}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => handleEntriesChange(5)}>5</DropdownItem>
                            <DropdownItem onClick={() => handleEntriesChange(10)}>10</DropdownItem>
                            <DropdownItem onClick={() => handleEntriesChange(50)}>50</DropdownItem>
                            <DropdownItem onClick={() => handleEntriesChange('All')}>All</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                        <button className="btn btn-success mr-2" onClick={handleDownload}>Download</button>
                      </div>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Item Id</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">Item Category</th>
                        <th scope="col">Current Stock(Quantity)</th>
                        <th scope="col">Unit(Kg)</th>
                        <th scope="col">Cost</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>
                      {dataToShow.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">{item.item_id}</th>
                          <td>{item.item_name}</td>
                          <td>{item.item_category}</td>
                          <td>{item.current_stock}</td>
                          <td>{item.unit}</td>
                          <td>{item.cost}</td>
                          <td style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Link to={`/store-admin/store-management/editstock4/${item.item_id}`} style={{ marginRight: '10px' }} >
                              <i
                                className="fa-solid fa-pen"
                                style={{
                                  fontSize: "23px",
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              ></i>
                            </Link>
                            <i
                              className="fa fa-trash"
                              style={{
                                fontSize: "23px",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDeleteIconClick(item.item_id)}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <nav className='mt-3'>
                    <ul className='pagination justify-content-center'>
                      <li className='page-item'>
                        <a
                          href='#'
                          className='page-link text-primary'
                          onClick={prePage}
                          disabled={currentPage === 1}
                        >
                          Prev
                        </a>
                      </li>
                      <li className='page-item'>
                        <a
                          href='#'
                          className='page-link text-primary'
                          onClick={nextPage}
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={showDeleteConfirmation} toggle={() => setShowDeleteConfirmation(false)}>
            <ModalHeader>Confirm Deletion</ModalHeader>
            <ModalBody>Are you sure you want to delete this role ?</ModalBody>
            <div className="modal-footer">
              <Button color="danger" onClick={handleDeleteConfirmation}>
                Yes
              </Button>
              <Button color="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                No
              </Button>
            </div>
          </Modal>
          <Modal isOpen={showRoleModal} toggle={toggleRole}>
            <ModalHeader toggle={toggleRole}>
              <h2 style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
                New Stock
              </h2>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={handleFormSubmit}>
                <Row>
                  <Col md={6}>
                    <label className="form-control-label" htmlFor="input-role">
                      Enter Item Name
                    </label>
                    <Input
                      name='item_name'
                      className="form-control-alternative"
                      id="input-role"
                      type="text"
                      onChange={handleInputChange}
                      placeholder="Enter Item Name"
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <label className="form-control-label" htmlFor="input-role">
                      Enter Item Category
                    </label>
                    <Input
                      name='item_category'
                      className="form-control-alternative"
                      id="input-role"
                      type="text"
                      onChange={handleInputChange}
                      placeholder="Enter Category Name"
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <label className="form-control-label" htmlFor="input-role">
                      Enter Current Stock
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-role"
                      type="number"
                      name="current_stock"
                      onChange={handleInputChange}
                      placeholder="Enter Current Stock"
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <label className="form-control-label" htmlFor="input-role">
                      Enter Unit(Kg or cm etc)
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-role"
                      type="text"
                      name="unit"
                      onChange={handleInputChange}
                      placeholder="Enter Unit in Kg"
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <label className="form-control-label" htmlFor="input-role">
                      Enter Cost
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-role"
                      type="number"
                      name="cost"
                      onChange={handleInputChange}
                      placeholder="Enter Cost"
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "center" }}>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default Storemanagement4;
