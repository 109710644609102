import React, { useState, useEffect } from 'react';
import {
    Button, Card, CardHeader, Table, Container, Row, Col, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Badge,
} from "reactstrap";
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { api } from '../../../../api';
import Cookies from 'js-cookie';

const Leave2 = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);
    const toggleModalAdd = () => setModalAdd(prevState => !prevState);
    const [clientForm, setClientForm] = useState({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteProjectName, setDeleteProjectName] = useState('');
    const [dataToShow, setDataToShow] = useState([]);
    const [projectDropdownOpen1, setProjectDropdownOpen1] = useState(false);
    const [entriesToShow, setEntriesToShow] = useState(5);
    const [leaveData, setLeaveData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const emp_id = Cookies.get('token');

    const toggleProjectDropdown1 = () => {
        setProjectDropdownOpen1(!projectDropdownOpen1);
    };

    const handleEntriesChange = (value) => {
        setEntriesToShow(value === 'All' ? leaveData.length : Number(value));
        setCurrentPage(1);
    };

    const statusOptions = [
        { "value": "Casual Leave", "label": "Casual Leave" },
        { "value": "Medical Leave", "label": "Medical Leave" },
        { "value": "Voting Leave", "label": "Voting Leave" },
        { "value": "Sabbatical Leave", "label": "Sabbatical Leave" },
        { "value": "Bereavement Leave", "label": "Bereavement Leave" },
        { "value": "Maternity Leave", "label": "Maternity Leave" },
        { "value": "Paternity Leave", "label": "Paternity Leave" },
        { "value": "Adoption Leave", "label": "Adoption Leave" },
        { "value": "Family Care Leave", "label": "Family Care Leave" },
        { "value": "Study Leave", "label": "Study Leave" },
        { "value": "Jury Duty Leave", "label": "Jury Duty Leave" },
        { "value": "Public Holiday Leave", "label": "Public Holiday Leave" },
        { "value": "Religious Holiday Leave", "label": "Religious Holiday Leave" }
    ];

    useEffect(() => {
        const fetchLeaveData = async () => {
            try {
                const response = await api.get(`leaveEmpDetails.php?emp_id=${emp_id}`);
                if (response.status === 200) {
                    setLeaveData(response.data);
                    setFilteredData(response.data);
                    setDataToShow(response.data.slice(0, entriesToShow));
                } else {
                    console.error("Failed to fetch leave data");
                }
            } catch (error) {
                console.error("Error fetching leave data:", error);
            }
        };
        fetchLeaveData();
    }, [emp_id, entriesToShow]);

    const handleDeleteConfirmation = async () => {
        try {
            const response = await api.get(`leaveEmpDetails.php?del_id=${deleteProjectName}`);
            console.log(response.data);
            window.location.reload();
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setShowDeleteConfirmation(false);
    };

    const [leave, setleave] = useState({
        emp_id: emp_id
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setleave(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFormSubmit = async (e) => {
        // e.preventDefault();
        try {
            const response = await api.post("leavePM.php", leave);
            console.log("Data sent successfully:", response.data);
            window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    const totalPages = Math.ceil(filteredData.length / entriesToShow);

    const prePage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const getSerialNumber = (index) => {
        return (currentPage - 1) * entriesToShow + index + 1;
    };

    useEffect(() => {
        if (Array.isArray(filteredData)) {
            setDataToShow(filteredData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
        }
    }, [entriesToShow, currentPage, filteredData]);

    useEffect(() => {
        if (searchQuery.trim() === "") {
            setFilteredData(leaveData);
        } else {
            const filtered = leaveData.filter(item =>
                item.leave_type.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredData(filtered);
        }
        setCurrentPage(1);
    }, [searchQuery, leaveData]);

    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Container fluid>
                    <Row className='mb-5'>
                        <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
                            <h1>Leave Management Table</h1>
                        </Col>

                        <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
                            <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                                <FormGroup className="mb-0 ml-6">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Search Materials"
                                            type="text"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Leave List</h3>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="mr-3">
                                                    <Dropdown isOpen={projectDropdownOpen1} toggle={toggleProjectDropdown1}>
                                                        <DropdownToggle caret>
                                                            Show Entries: {entriesToShow === leaveData.length ? "All" : entriesToShow}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => handleEntriesChange(5)}>5</DropdownItem>
                                                            <DropdownItem onClick={() => handleEntriesChange(10)}>10</DropdownItem>
                                                            <DropdownItem onClick={() => handleEntriesChange('All')}>All</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                                <div className='text-right'>
                                                    <Button color="primary" onClick={toggleModalAdd}>Apply Leave</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </Row>
                                </CardHeader>

                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">SL.No</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Applied On</th>
                                            <th scope="col">Start Date</th>
                                            <th scope="col">End Date</th>
                                            <th scope="col">Current Status</th>
                                            <th scope="col">Views</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataToShow && Array.isArray(dataToShow) ? (
                                            dataToShow.map((item, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{getSerialNumber(index)}</th>
                                                    <td>{item.leave_type}</td>
                                                    <td>{item.applied_date}</td>
                                                    <td>{item.start_date}</td>
                                                    <td>{item.end_date}</td>
                                                    <td>
                                                        <Badge style={{ backgroundColor: '#F7D800', color: 'black' }} className="p-2">
                                                            {item.status}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <Link to={`/site-admin/site-management/leavetable2/${item.id}`}>
                                                            <i className="fa-solid fa-eye"></i>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <button onClick={() => { setDeleteProjectName(item.id); setShowDeleteConfirmation(true) }} className='btn btn-deeppink'>
                                                            <i className="fa-solid fa-trash text-danger"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                                <nav className='mt-3'>
                                    <ul className='pagination justify-content-center'>
                                        <li className='page-item'>
                                            <a
                                                href='#'
                                                className='page-link text-primary'
                                                onClick={prePage}
                                                disabled={currentPage === 1}
                                            >
                                                Prev
                                            </a>
                                        </li>
                                        <li className='page-item'>
                                            <a
                                                href='#'
                                                className='page-link text-primary'
                                                onClick={nextPage}
                                                disabled={currentPage === totalPages}
                                            >
                                                Next
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modalAdd} toggle={toggleModalAdd}>
                <ModalHeader toggle={toggleModalAdd}>Add Leave</ModalHeader>
                <Form onSubmit={handleFormSubmit}>
                    <Row className='px-3'>

                        <Col md={12}>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-status">Leave Type</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-status"
                                    type="select"
                                    name="leave_type"
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Leave Type</option>
                                    {statusOptions.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-starting">Starting Date</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-starting"
                                    type="date"
                                    name="Start_date"
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-ending">Ending Date</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-ending"
                                    type="date"
                                    name="End_date"
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-description">Leave Description</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    onChange={(event, editor) => {
                                        const data = editor.getData().replace(/<[^>]*>/g, ''); // Remove HTML tags
                                        setleave(prevState => ({
                                            ...prevState,
                                            description: data
                                        }));
                                    }}
                                />
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12' className='text-center mb-2'>
                            <Button color="primary" type="submit">Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal isOpen={showDeleteConfirmation} toggle={() => setShowDeleteConfirmation(false)}>
                <ModalHeader>Confirm Deletion</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete?
                </ModalBody>
                <div className="modal-footer">
                    <Button color="danger" onClick={handleDeleteConfirmation}>Yes</Button>
                    <Button color="secondary" onClick={() => setShowDeleteConfirmation(false)}>No</Button>
                </div>
            </Modal>
        </>
    );
};

export default Leave2;
