import React from "react";
import Logo from '../../../images/usauni1.jpg';
import axios from 'axios';
import { api } from '../../../../api';

const SampleInvoice4 = ({ invoice }) => {

  const handleDownload = () => {
    // Send invoice data to PHP script and initiate download
    api.post('invoicePdf2.php', invoice, { responseType: 'blob' })
      .then(response => {
        // Create a blob object from the PDF data
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Create a link element to trigger the download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'invoice.pdf');
        // Append the link to the body and click it programmatically
        document.body.appendChild(link);
        link.click();
        // Cleanup
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error generating PDF:', error);
      });
  };

  return (
    <div className="header py-7 py-lg-8" style={{ fontFamily: 'Roboto', backgroundColor: 'white' }}>
      <div className="container print-invoice" style={{ backgroundColor: 'white', height: '100%' }}>
        <div className="row">
          <div className="col-12">
            <div className="clearfix">
              <div id="logo" className="float-start" style={{ width: "100px", height: "100px", borderRadius: '50px' }}>
                <img
                  src={Logo}
                  style={{ width: "100%", height: "100%", borderRadius: '50%' }}
                  alt="Company Logo"
                />
              </div>
              <h1 className="text-center mb-5">INVOICE NUMBER: {invoice.invoice_number}</h1>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">
                <h2>Enterprise/Individual</h2>
                <div><b>Name:</b> {invoice.client_name}</div>
                <div><b>Project Name:</b> {invoice.project_name}</div>
                <div><b>Address:</b> {invoice.client_address}</div>
                <div><b>Phone:</b> {invoice.client_phone}</div>
                <div><b>Email:</b> {invoice.client_email}</div>
                <div><b>Date:</b> {new Date().toLocaleString()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="service">SERVICE</th>
                    <th>PRICE</th>
                    <th>QTY</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.services.map((item, index) => (
                    <tr key={index}>
                      <td>{item.service_name}</td>
                      <td>{item.unit_cost}</td>
                      <td>{item.quantity}</td>
                      <td>{item.total1}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="3">SUBTOTAL</td>
                    <td>{invoice.sub_total}</td>
                  </tr>
                  <tr>
                    <td colSpan="3">TAX in %</td>
                    <td>{invoice.tax}</td>
                  </tr>
                  <tr style={{ fontWeight: "bold" }}>
                    <td colSpan="3" className="grand total">
                      GRAND TOTAL
                    </td>
                    <td className="grand total" style={{ fontSize: "17px" }}>
                      {invoice.total_final}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="text-center">
              <button className="btn btn-primary" id="exportbtn" onClick={handleDownload}>
                Download Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleInvoice4;
