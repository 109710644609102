
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { api } from '../../../../api';
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody, Label,
} from "reactstrap";


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';




const ViewMaintainance4 = () => {

    const {id} = useParams();

    const [modalOpen, setModalOpen] = useState(false);
    const [showAddClientModal, setShowAddClientModal] = useState(false); // New state for Add Project modal
    const [clientForm, setClientForm] = useState({});
    const [selectedSiteManagers, setSelectedSiteManagers] = useState([]);


    const toggleModal = () => setModalOpen(prevState => !prevState);
    const toggleAddClientModal = () => setShowAddClientModal(prevState => !prevState); // Function to toggle Add Project modal




    const [updateValue, setUpdateValue] = useState({
        issues_addressed:'',
        downtime_duration:'',
        cost:'',
        status:'',
        image:null
    });


    const handleStatusChange = (e) => {
        setUpdateValue({ ...updateValue, status: e.target.value });
    };

    const statusOptions = [
        { value: 'preventive', label: 'Preventive Maintenance' },
        { value: 'emergency', label: 'Emergency Maintenance' },
        { value: 'parts-replacement', label: 'Parts Replacement' },
        { value: 'predictive', label: 'Predictive Maintenance' }
    ];

    const [hasIssue, setHasIssue] = useState(false);


    const [statusValue, setStatusValue] = useState('');

    const statusValues = [
        { value: 'in-progress', label: 'In-Progress' },
        { value: 'completed', label: 'Completed' },
        
    ];





    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('equipment_id', id);
        formData.append('hasIssue', hasIssue ? 'true' : 'false');
        formData.append('issues_addressed', updateValue.issues_addressed);
        formData.append('downtime_duration', updateValue.downtime_duration);
        formData.append('status', updateValue.status);
        formData.append('cost', updateValue.cost);
        if (updateValue.image) {
            formData.append('image', updateValue.image);
        }

        try {
            const response = await api.post('equipmaintanenceviewSTM.php', formData);
            console.log("Data sent successfully:", response.data);
            window.history.back()
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };



    const [searchQuery, setSearchQuery] = useState('');
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [selectedManager, setSelectedManager] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');




    const toggleDropdown = (employeeId) => {
        setFilteredEmployees(prevEmployees => prevEmployees.map(employee => {
            if (employee.id === employeeId) {
                return { ...employee, dropdownOpen: !employee.dropdownOpen };
            }
            return employee;
        }));
    };

    // Function to filter employees based on search query
    const filterEmployees = () => {
        if (!searchQuery) {
            setFilteredEmployees(employees);
        } else {
            const filtered = employees.filter(employee =>
                employee.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredEmployees(filtered);
        }
    };







    const handleManagerSelectChange = (selectedOption) => {
        setSelectedManager(selectedOption);
    };

    const handleLocationSelectChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
    };

    const handleSiteManagerSelectChange = (selectedOptions) => {
        setSelectedSiteManagers(selectedOptions);
        setClientForm(prevState => ({
            ...prevState,
            siteManagers: selectedOptions.map(option => option.value)
        }));
    };

    // const statusOptions = [
    //     { value: 'to-do', label: 'to-do' },
    //     { value: 'in-progress', label: 'in-progress' },
    //     { value: 'completed', label: 'completed' }
    // ];


    const [employees, setemployees] = useState();
    const fetchEmployeeData = async () => {
        try {
            const response = await api.get("employeesView.php");
            setemployees(response.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchEmployeeData();
    }, []);


    // const handleStatusChange = (event) => {
    //     setupdateValues({ ...updateValue, status: event.target.value });
    // };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdateValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        console.log(updateValue);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUpdateValue(prevState => ({
            ...prevState,
            image: file
        }));
    };

    console.log('id',id);

    const [project, setproject] = useState([]);
    const fetchProjectData = async () => {
        try {
            const response = await api.get(`equipmaintanenceviewSTM.php?equipment_id=${id}`);
            setproject(response.data.equipment_details); // assuming the data is stored in equipment_list
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Update filtered employees when searchQuery changes
    useEffect(() => {
        filterEmployees();
        fetchProjectData();
    }, [searchQuery,id]);


console.log('updatelevel',project);


    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Col className="order-xl-2 mb-5 mb-xl-0 text-center  mb-5">
                    <h1>View Equipment Details</h1>
                </Col>
                <Button className='ml-5' onClick={(()=>window.history.back())}>Back</Button>
                <Container className="py-4 ">
                    <div className="p-4 rounded" style={{ backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid black' }}>

                        <Row className='px-3'>

                            {project &&  (
                            <React.Fragment >
                            


                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Equipment Name</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-status"
                                        type="text"
                                        name="equipment_name"
                                        value={project.equipment_name}
                                        onChange={handleInputChange}
                                    >
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Equipment Type</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-status"
                                        type="text"
                                        name="equipment_type"
                                        onChange={handleStatusChange}
                                        value={project.equipment_type}
                                    >
                                       
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Maintenance Type</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-status"
                                        type="text"
                                        name="maintenance_type"
                                        onChange={handleStatusChange}
                                        value={project.maintenance_type}
                                    >
                                        {/* <option value="">Select Maintenance Type</option>
                                        {statusOptions.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))} */}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-name">Maintainance Date</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-name"
                                        type="date"
                                        name="maintenance_date"
                                        value={project.maintenance_date}
                                        placeholder='Category Name'
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>

                            {/* <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-name">Next Maintainance Date</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-name"
                                        type="date"
                                        name="next_maintenance_date"
                                        defaultValue={project.next_maintenance_date}
                                        // placeholder='Category Name'
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col> */}

                            {/* Radio Button Group */}
                            <Col md={12}>
                                <FormGroup>
                                    <legend className="form-control-label">Do you have an issue?</legend>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="hasIssue" onChange={() => setHasIssue(true)} checked={hasIssue} />
                                            Yes
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="hasIssue" onChange={() => setHasIssue(false)} checked={!hasIssue} />
                                            No
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                            </Col>

                            {/* Additional Fields */}
                            {hasIssue && (
                                <>
                                    <Col md={12}>
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="option1">Address Issue</label>
                                            <Input
                                                className="form-control-alternative"
                                                id="option1"
                                                type="text"
                                                name="issues_addressed"
                                                onChange={handleInputChange}
                                                placeholder='Enter Your Issue'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="option2">Downtime Duration</label>
                                            <Input
                                                className="form-control-alternative"
                                                id="option2"
                                                type="text"
                                                name="downtime_duration"
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="option3">Cost to resolve issue</label>
                                            <Input
                                                className="form-control-alternative"
                                                id="option3"
                                                type="number"
                                                name="cost"
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </>
                            )}



                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Select Status</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-status"
                                        type="select"
                                        name="status"
                                        onChange={handleStatusChange}
                                        value={updateValue.status}
                                    >
                                        <option value="">Select Status</option>
                                        {statusValues.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>


                            <Col md={12}>
                            <FormGroup>
                                <Label className="form-control-label">Upload File</Label>
                                <Input
                                    type="file"
                                    accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
                                    onChange={handleFileChange}
                                />
                            </FormGroup>
                        </Col>
                            </React.Fragment>

                            ) }










                        </Row>
                        <Row>
                            <Col lg='12' className='text-center mb-2'>
                                <Button color="primary" type="submit" onClick={handleSubmit} >Submit</Button>
                            </Col>
                        </Row>
                    </div>
                </Container>


            </div>


        </>
    );
};

export default ViewMaintainance4;