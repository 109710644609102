import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { api } from "../../../../api";
import Cookies from "js-cookie";

const Employee1 = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [entriesToShow, setEntriesToShow] = useState(5);
  const [dataToShow, setDataToShow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [searchData,setSearchData] = useState([]);
  const [project, setProject] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const toggleModal = () => setModalOpen((prevState) => !prevState);

  const emp_id = Cookies.get("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `viewProjectDetails.php?g_m_id=${emp_id}`
        );
        setProject(response.data);
        const response2 = await api.get(
          `employeesView.php?project_general_id=${emp_id}`
        );
        setAllData(response2.data);
        setSearchData(response2.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [emp_id]);

  useEffect(() => {
    const filtered =
      Array.isArray(allData) &&
      allData.filter(
        (employee) =>
          employee.emp_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          employee.address.toLowerCase().includes(searchQuery.toLowerCase())
      );
    setFilteredEmployees(filtered);
  }, [searchQuery, allData]);

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
    toggleModal();
  };

  useEffect(() => {
    setDataToShow(
      Array.isArray(filteredEmployees) &&
        filteredEmployees.slice(
          (currentPage - 1) * entriesToShow,
          currentPage * entriesToShow
        )
    );
  }, [entriesToShow, currentPage, filteredEmployees]);

  const toggleProjectDropdown = () => {
    setProjectDropdownOpen(!projectDropdownOpen);
  };

  const handleEntriesChange = (value) => {
    setEntriesToShow(Number(value));
    setCurrentPage(1);
  };

  const prePage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredEmployees.length / entriesToShow)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changeCPage = (n) => {
    setCurrentPage(n);
  };

  const [selectedProjectName,setSelectedProjectName] = useState("Select Project");
  const [selectedProject, setSelectedProject] = useState("");
  const handleProjectSelection = async (p_id, p_name) => {
    setSelectedProject(p_name);
    setSelectedProjectName(p_name);
    try {
      const response = await api.get(`sitemanagerview.php?p_id=${p_id}`);
      console.log("API Response:", response.data);
      setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="mb-5">
            <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
              <h1>Employee Details</h1>
            </Col>
            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex">
              <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search Employee Role"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret>{selectedProjectName}</DropdownToggle>
                <DropdownMenu>
                  {Array.isArray(project) ? (
                    project.map((project) => (
                      <DropdownItem
                        key={project.p_name}
                        onClick={() =>
                          handleProjectSelection(project.p_id, project.p_name)
                        }
                      >
                        {project.p_name}
                      </DropdownItem>
                    ))
                  ) : (
                    <DropdownItem>No Projects Found</DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col
              className="mb-5 mb-xl-0"
              xl="12"
              style={{ marginTop: "-20px" }}
            >
              <Card className="shadow">
                <CardBody>
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Employee List</h3>
                      </div>
                      <div className="col text-right">
                        <Dropdown
                          isOpen={projectDropdownOpen}
                          toggle={toggleProjectDropdown}
                        >
                          <DropdownToggle caret>
                            Show Entries: {entriesToShow}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => handleEntriesChange(5)}
                            >
                              5
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleEntriesChange(10)}
                            >
                              10
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleEntriesChange(50)}
                            >
                              50
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Emp ID</th>
                        <th>Profile</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Location</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataToShow && dataToShow.length > 0 ? (
                        dataToShow.map((item, index) => (
                          <tr key={index}>
                            <td>{item.emp_id}</td>
                            <td>
                              <img
                                alt="...Image Not Found"
                                className="rounded-circle"
                                src={require(`../../../images/${item.profile_image}`)}
                                style={{ height: 30, width: 30 }}
                              />
                            </td>
                            <td>{item.emp_name}</td>
                            <td>{item.role}</td>
                            <td>{item.address}</td>
                            <td style={{ fontSize: "20px" }}>
                              <a
                                href="#pablo"
                                onClick={() => handleEmployeeClick(item)}
                              >
                                <i className="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No Employee Data Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <nav className="mt-3">
                    <ul className="pagination justify-content-center">
                      <li className="page-item">
                        <a
                          href="#"
                          className="page-link text-primary"
                          onClick={prePage}
                        >
                          Prev
                        </a>
                      </li>
                      {[currentPage - 1, currentPage, currentPage + 1].map(
                        (pageNumber) => {
                          if (
                            pageNumber > 0 &&
                            pageNumber <=
                              Math.ceil(
                                filteredEmployees.length / entriesToShow
                              )
                          ) {
                            return (
                              <li key={pageNumber} className="page-item">
                                <a
                                  href="#"
                                  className={`page-link ${
                                    pageNumber === currentPage ? "active" : ""
                                  }`}
                                  onClick={() => changeCPage(pageNumber)}
                                >
                                  {pageNumber}
                                </a>
                              </li>
                            );
                          }
                          return null;
                        }
                      )}
                      <li className="page-item">
                        <a
                          href="#"
                          className="page-link text-primary"
                          onClick={nextPage}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {selectedEmployee && (
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Employee Details</ModalHeader>
          <ModalBody>
            <div style={{ justifyContent: "center", textAlign: "center" }}>
              <img
                alt="Employee"
                className="rounded-circle"
                src={require(`../../../images/${selectedEmployee.profile_image}`)}
                style={{ height: 100, marginBottom: 10 }}
              />
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <label
                    className="border p-2 rounded font-weight-bold"
                    style={{ width: "155px" }}
                  >
                    Employee ID
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {selectedEmployee.emp_id}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Name
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {selectedEmployee.emp_name}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Email
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {selectedEmployee.email}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Role
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {selectedEmployee.role}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Mobile Number{" "}
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {selectedEmployee.phoneno}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Date Of Join
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {selectedEmployee.doj}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Salary
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {selectedEmployee.salary}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Address
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {selectedEmployee.address}
                  </label>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default Employee1;
