import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Progress,
  CardImg,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CircleChart4 from "dashboard/store-management/variables/CircleChart4";

import { api } from '../../../api'

const Dashboard4 = () => {

  const [upCards, setUpCards] = useState([]);
  const handleEquipmentCards = async () => {
    try {
      const response = await api.get(`countdashboardSTM.php`);
      if (response.data.status === "success") {
        setUpCards(response.data.data);
      } else {
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      console.error("Error Fetching Data", error);
    }
  };

  useEffect(() => {
    handleEquipmentCards();
  }, []);

  console.log('Dashboardcards', upCards);

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="d-flex justify-content-center align-items-center">
            <Col lg="6" xl="3" className="mb-3">
              <Card className="card-stats mb-4 mb-xl-0" style={{ borderRadius: '30px' }}>
                <CardBody>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CardTitle className="h4 mb-0">Total Item</CardTitle>
                    </div>
                    <div style={{ height: '30px', width: '30px' }}>
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #759bff, #843cf6)' }}>
                        <i className="fa-solid fa-compass-drafting fa-beat-fade"></i>
                      </div>
                    </div>
                  </div>
                  {upCards.map((card, index) => (
                    <span key={index} className="h2 font-weight-bold">{card.total_items}</span>
                  ))}
                </CardBody>
              </Card>
            </Col>

            {/* Completed Projects Card */}
            <Col lg="6" xl="3" className="mb-3">
              <Card className="card-stats mb-4 mb-xl-0" style={{ borderRadius: '30px' }}>
                <CardBody>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CardTitle className="h4 mb-0">Required Items</CardTitle>
                    </div>
                    <div style={{ height: '30px', width: '30px' }}>
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #fc5286, #fbaaa2)' }}>
                        <i className="fa-solid fa-wrench fa-spin" style={{ '--fa-animation-duration': '2s;', '--fa-fade-opacity': '0.6;' }}></i>
                      </div>
                    </div>
                  </div>
                  {upCards.map((card, index) => (
                    <span key={index} className="h2 font-weight-bold">{card.required_items}</span>
                  ))}
                </CardBody>
              </Card>
            </Col>

            {/* Inprogress Projects Card */}
            <Col lg="6" xl="3" className="mb-3">
              <Card className="card-stats mb-4 mb-xl-0" style={{ borderRadius: '30px' }}>
                <CardBody>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CardTitle className="h4 mb-0">Today's Request</CardTitle>
                    </div>
                    <div style={{ height: '30px', width: '30px' }}>
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #ffc480, #ff763b)' }}>
                        <i className="fa-solid fa-code-pull-request fa-bounce" />
                      </div>
                    </div>
                  </div>
                  {upCards.map((card, index) => (
                    <span key={index} className="h2 font-weight-bold">{card.todays_requests}</span>
                  ))}
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" xl="3" className="mb-3">
              <Card className="card-stats mb-4 mb-xl-0" style={{ borderRadius: '30px' }}>
                <CardBody>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CardTitle className="h4 mb-0">Emergency Requirement</CardTitle>
                    </div>
                    <div style={{ height: '30px', width: '30px' }}>
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #632686, #4B0082)' }}>
                        <i className="fa-solid fa-heartbeat fa-flip" />
                      </div>
                    </div>
                  </div>
                  {upCards.map((card, index) => (
                    <span key={index} className="h2 font-weight-bold">{card.emergency_requirements}</span>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="mb-4 pl-0 pr-0">
              <Card className="card-stats shadow-sm p-2">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h2 className="font-weight-bold mb-0">Stocks</h2>
                    <div className="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px' }}>
                      <i className="fa-solid fa-chart-pie" style={{ fontSize: '1.5rem' }}></i>
                    </div>
                  </div>
                  <Col >
                    <CircleChart4 />
                  </Col>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard4;
