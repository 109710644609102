import React, { useState, useEffect } from "react";
import { Pie } from 'react-chartjs-2';
import {api} from '../../../api'

const CircleChart = () => {
  // Dummy data for present and absent employees
  const presentPercentage = 50;
  const absentPercentage = 10;
  const lateCommer = 10;
  const ontimeCommer = 12;


  const [count,setCount] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("hello");
    const fetchData = async () => {
      console.log("hello");
      try {
        console.log("hello");
        const response = await api.get("hrdashboard.php");
        setCount(response.data);
        console.log(count[4].present + "hello");
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);



  const data = {
    labels: ['Present', 'Absent', 'Late', 'OnTime'],
    datasets: [
      {
        data: [count[4]?.present ?? 0,  (count[0]?.empcount ?? 0) - (count[4]?.present ?? 0), count[2]?.latecount ?? 0, count[1]?.ontimecount ?? 0],
        backgroundColor: ['#1d6f1d', '#B90000', '#FFAA1D', '#0C4CA3'],
      },
    ],
  };

  return (
    <div>
      <Pie data={data} />
    </div>
  );
};

export default CircleChart;
