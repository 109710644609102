import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { api } from '../../../../api';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Header from "dashboard/hr-management/components/Headers/Header";

const Department3 = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
const [departdata, setdepartdata] = useState([]);

useEffect(() => {
  // Fetch data and set departdata
  const fetchData = async () => {
    try {
      const response = await api.get(`department.php`);
      setdepartdata(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  fetchData(); // Call the fetchData function

}, []); // Empty dependency array to run only once when component mounts

useEffect(() => {
  // Filter the data when searchQuery or departdata changes
  const originalData = departdata || [];
  const filtered = originalData.filter((item) =>
    item.dept_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  setFilteredData(filtered);
}, [searchQuery, departdata]); // Dependency array includes searchQuery and departdata




const [showAddDepartmentModal, setshowAddDepartmentModal] = useState(false);

  const toggleAddDepartmentModal = () => {
    setshowAddDepartmentModal(!showAddDepartmentModal);
  };

  const [department, setdepartment] = useState();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setdepartment((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    console.log(department)
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setdepartment(prevState => ({
        ...prevState,
        dept_logo: file
    }));
    console.log(department)
};

    // Function to handle form submission
    const handleSubmit = async (event) => {
      // event.preventDefault();

      const formDataToSend = new FormData();
      formDataToSend.append('dept_name', department.dept_name);
      formDataToSend.append('dept_logo', department.dept_logo);

      try {
          const response = await api.post("department.php", formDataToSend);
          console.log("Data sent successfully:", response.data);
          window.location.reload();
      } catch (error) {
          console.error("Error submitting data:", error);
      }
  };


  const [itemIdToDelete, setItemIdToDelete] = useState(null);

  const handleDeleteIconClick = (d_id) => {
    setItemIdToDelete(d_id);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirmation = (e) => {
    e.preventDefault();
    const d_id = itemIdToDelete;
    if(d_id !=null){
      const fetchData = async () => {
        try { 
          const response = await api.get(`depatment.php?d_id=${d_id}`);  
          console.log(response.data); 
          // window.location.reload();
        } catch (error) {  
          console.error("Error fetching data:", error); 
        }  
      };  
      fetchData();
    }
    setItemIdToDelete(null);
    setShowDeleteConfirmation(false);
  };


  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="mb-5">
            <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
              <h1 className="text-white">Departments List</h1>
            </Col>

            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
              <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                <FormGroup className="mb-0 ml-6">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search Department"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
              <Button className="ml-4" onClick={toggleAddDepartmentModal}>Add </Button>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr style={{ textAlign: "center" }}>
                      <th
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                        scope="col"
                      >
                        S.No
                      </th>
                      <th
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                        scope="col"
                      >
                        Department Name
                      </th>
                      <th
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                        scope="col"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {filteredData.map((item, index) => (
                      <tr key={index}>
                        <th scope="row">{++index}</th>
                        <td className="d-flex "style={{alignItems:'center',justifyContent:'center'}}> <i className="fas fa-arrow-up text-success mr-3" style={{visibility:'hidden'}} />
                        
                          {/* <a
                              className="avatar rounded-circle  m-2"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            > */}
                          <img
                          className="avatar rounded-circle  m-2"
                            alt="..."
                            src={require(`../../../images/${item.dept_logo}`)}
                          />
                          {/* </a> */}
                         <h4> {item.dept_name}</h4>
                          </td>
                        <td>
                          <i
                            className="fa fa-trash"
                            style={{
                              fontSize: "23px",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteIconClick(item.d_id)}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={showDeleteConfirmation}
        toggle={() => setShowDeleteConfirmation(false)}
      >
        <ModalHeader>Confirm Deletion</ModalHeader>
        <ModalBody>Are you sure you want to delete this department ?</ModalBody>
        <div className="modal-footer">
          <Button color="danger" onClick={handleDeleteConfirmation}>
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            No
          </Button>
        </div>
      </Modal>

      <Modal isOpen={showAddDepartmentModal} toggle={toggleAddDepartmentModal}>
        <ModalHeader toggle={toggleAddDepartmentModal}><h2 style={{fontWeight:'bolder', textTransform:'uppercase'}}>New Department</h2></ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit} enctype="multipart/form-data" >
            <Row>
              <Col md={12}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    Department Name *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-name"
                    type="text"
                    name="dept_name"
                    pattern="^[a-zA-Z ]+$"
                    title="Enter letters only"
                    placeholder="Enter Department Name" 
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </Col>
              
              <Col md={12}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-file">
                    Upload Department Logo *
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                    id="input-file" accept="image/*"
                    name="dept_logo" 
                    onChange={handleFileChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "center" }}>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Department3;
