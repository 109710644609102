import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, Container, Row, Col, Progress, CardImg, Button, UncontrolledTooltip } from 'reactstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import the default calendar styles
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import the styles
import {api} from '../../../api';
import Cookies from 'js-cookie';

const Dashboard2 = () => {
    const [date, setDate] = useState(new Date());
    const [selectedDateLabel, setSelectedDateLabel] = useState('');
    const [isBirthday, setIsBirthday] = useState(false);
    const [timeOfDay, setTimeOfDay] = useState('');

    // Function to format the selected date label
    const getFormattedDateLabel = (date) => {
        // Customize the date label format as needed
        return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
    };
    useEffect(() => {
        // Update the selected date label when the date changes
        setSelectedDateLabel(getFormattedDateLabel(date));
    }, [date]);

    const checkBirthday = (date) => {
        // Check if the selected date matches a predefined birthday (e.g., January 1)
        return date.getMonth() === 0 && date.getDate() === 1;
    };

    const getTimeOfDay = (date) => {
        const hour = date.getHours();

        if (hour >= 0 && hour < 12) {
            return 'Morning🌞';
        } else if (hour >= 12 && hour < 18) {
            return 'Afternoon⛅'; // Corrected to 'Afternoon'
        } else {
            return 'Night🌙';
        }
    };

    const emp_id = Cookies.get('token');


    useEffect(() => {
        const label = getFormattedDateLabel(date);
        setSelectedDateLabel(label);

        // Check if it's the user's birthday
        setIsBirthday(checkBirthday(date));

        // Determine the time of day
        setTimeOfDay(getTimeOfDay(date));
    }, [date]);

    const [progressPercentage, setProgressPercentage] = useState(75);

    const [pcount,setPcount] = useState();
    const [loading, setLoading] = useState(true);
    const [countper, setcountper] = useState({
        pending : '',
        inprogress:'',
        completed:''
    });

    // const [projects,setproject] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await api.get(`enggtaskcount.php?emp_id=${emp_id}`);
            setPcount(response.data);
            console.log(response.data);
            const completedcount = Math.floor((response.data[0].completed_tasks / response.data[0].total_tasks)*100);
            const pendingcount = Math.floor((response.data[0].todo_tasks / response.data[0].total_tasks)*100);
            const inprogresscount = Math.floor((response.data[0].in_progress_tasks / response.data[0].total_tasks)*100);
            console.log(completedcount + "" + pendingcount + "" + inprogresscount);
            setcountper({
                pending : pendingcount,
                inprogress: inprogresscount,
                completed: completedcount
            })
            setLoading(false);
          } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
          }
        };
        fetchData();
      }, []);
      console.log(countper)

      const [projects, setproject] = useState([]);
        useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`viewProjectDetails.php?emp_id=${emp_id}`);
                setproject(response.data);  
            console.log(response.data);
            } catch (error) {
            console.error("Error fetching data:", error);
            }
        };
        fetchData();
        }, []);
        console.log(projects)



        const [employees, setEmployees] = useState([]);
        const fetchEmployeeName = async () => {
            try {
                const response = await api.get("employeeCalendar.php");
                setEmployees(response.data);
    
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        useEffect(() => {
            fetchEmployeeName();
        },[])
    
    
        useEffect(() => {
            const label = getFormattedDateLabel(date);
            setTimeOfDay(getTimeOfDay(date));
        }, [date, employees]);
    
    
        const isEmployeeBirthday = (date, employee) => {
            const employeeDOB = new Date(employee.dob);
            return (
                date.getDate() === employeeDOB.getDate() &&
                date.getMonth() === employeeDOB.getMonth()
            );
        };
    
        const isEmployeeWorkAnniversary = (date, employee) => {
            const anniversaryDate = new Date(employee.doj);
            return (
                date.getDate() === anniversaryDate.getDate() &&
                 date.getMonth() === anniversaryDate.getMonth()
            );     
        };
    

    return (
        <>

            <div className="header bg-gradient-info py-7 py-lg-8">
                <Container fluid>
                <Row>
                        {/* ... Your existing code ... */}

                         {/* Completed Tasks Card */}
                         <Col lg="6" xl="3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">Completed Task</CardTitle>


                                        </div>
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #0e4cfd, #6a8eff)' }}>
                                                <i className="fas fa-thumbs-up  fa-solid fa-check fa-bounce" />
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{pcount?.[0]?.completed_tasks || 'N/A'}</span>

                                    <div className="progress-wrapper p-0">
                                        <div className="progress-info mt-2">
                                            <span>Progress</span>
                                            <div className="progress-percentage">
                                                <span>{countper.completed}%</span>
                                            </div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${countper.completed}%` , backgroundColor:'#2c60fe'}} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                   
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        {/* Total Projects Card */}
                        <Col lg="6" xl="3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">Total Task</CardTitle>
                                        </div>
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #759bff, #843cf6)' }}>
                                                <i className="fas fa-chart-bar fa-beat-fade"></i>
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{pcount?.[0]?.total_tasks || 'N/A'}</span>

                                    <div className="progress-wrapper p-0" style={{visibility : 'hidden'}}>
                                        <div className="progress-info mt-2">
                                            <span>Progress</span>
                                            <div className="progress-percentage">
                                                <span>100%</span>
                                            </div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated"  role="progressbar" style={{ width: '100%' }} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="6" xl="3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">To-do task</CardTitle>


                                        </div>
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #fc5286, #fbaaa2)' }}>
                                                <i className="fas fa-pencil fa-flip" style={{ '--fa-animation-duration': '2s;', '--fa-fade-opacity': '0.6;' }}></i>
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{pcount?.[0]?.todo_tasks || 'N/A'}</span>

                                    <div className="progress-wrapper p-0">
                                        <div className="progress-info mt-2">
                                            <span>Progress</span>
                                            <div className="progress-percentage">
                                               <span>{countper.pending}%</span>
                                            </div>
                                        </div>
                                       <div className="progress">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${countper.pending}%` , backgroundColor:'#FC6B8E'}} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                   
                                        </div>
                                    </div>


                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="6" xl="3">
                            <Card className="card-stats mb-4 mb-xl-0"style={{borderRadius:'30px'}}>
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div>
                                            <CardTitle className="h4 mb-0">Inprogress Task</CardTitle>


                                        </div>
                                        <div style={{ height: '30px', width: '30px' }}>
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #ffc480, #ff763b)' }}>
                                                <i className="fa-solid fa-sync fa-spin" />
                                            </div>

                                        </div>

                                    </div> <span className="h2 font-weight-bold  ml-3">{pcount?.[0]?.in_progress_tasks || 'N/A'}</span>

                                    <div className="progress-wrapper p-0">
                                        <div className="progress-info mt-2">
                                            <span>Progress</span>
                                            <div className="progress-percentage">
                                                <span>{countper.inprogress}%</span>
                                            </div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${countper.inprogress}%` , backgroundColor:'#FF9153'}} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                   
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                       

                    </Row>




                    <Row className="mt-5">

                    <Col lg="7"> {/* Adjust the column size to your preference */}
                            {/* Your existing cards */}
                            <Card className="card-stats mb-4 mb-xl-0 m-0" style={{borderRadius: '20px'}}>
                                <CardBody>
                                    <Row>
                                        <Col className="d-flex align-items-start text-center">
                                            <div className='h1'> Overview</div>
                                        </Col>
                                        <img
                                                src={require('../../project-management/assets/img/brand/WebDesignLayout-ezgif.com-crop (1).gif')}
                                                style={{ height: '100px', width: '200px', transform: 'scaleX(-1)' }}
                                                // transform: 'scaleX(-1)', 
                                                alt="Reversed Image"
                                            />
                                    </Row>
                                </CardBody>

                            </Card>
                            {Array.isArray(projects) && projects.length > 0 ? (
                                projects.map(project => (
                                    <Card key={project.projectId} className="card-stats mb-4 mb-xl-0 p-2 mt-3" style={{borderRadius: '50px'}}>
                                        <CardBody>
                                            <Row>
                                                {/* Circular Progress Bar on the Left */}
                                                <Col xs="3" className="text-center">
                                                    <div style={{ width: '80px', height: '80px' }}>
                                                        <CircularProgressbar
                                                            value={project.progress}
                                                            text={`${project.progress}%`}
                                                            styles={buildStyles({
                                                                textColor: 'blue',
                                                                pathColor: '#007bff',
                                                                trailColor: '#eee',
                                                            })}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col xs="6" className="text-center">
                                                    <h3>{project.projectName}</h3>
                                                    <p>About: {project.description}</p>
                                                </Col>

                                                <Col xs="3" className="d-flex justify-content-end">
                                                    <div className="avatar-group d-flex">
                                                        {project.profile.images.map((image, index) => (
                                                            <div className="avatar-group " style={{height:'40px', width:'25px'}}  key={index}>
                                                                <a
                                                                    className="avatar avatar-sm "
                                                                    href="#pablo"
                                                                    id={`tooltip-${project.projectId}-${index}`} // Use project.projectId and index for unique IDs
                                                                    onClick={(e) => e.preventDefault()}
                                                                >
                                                                    <img
                                                                        alt={`Image ${index}`}
                                                                        className="rounded-circle"
                                                                        style={{height:'35px', width:'35px'}}
                                                                        src={require(`./../../images/${image}`)}
                                                                    />
                                                                </a>
                                                                <UncontrolledTooltip delay={0} target={`tooltip-${project.projectId}-${index}`}>
                                                                    {project.profile.full_names[index]}
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                ))
                            ) : (
                                <div className='mt-4' style={{textAlign: 'center'}}>No projects available</div>
                            )}

                        </Col>
                        <Col lg="5">
                            <Card className="card-stats mb-4 mb-xl-0">
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle tag="h3" className="text-uppercase text-muted mb-0">
                                                Calendar
                                            </CardTitle>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col className="col-auto mt-3 mr-3">
                                            <Calendar
                                                
                                                onChange={setDate}
                                                value={date}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <p>Selected Date: {selectedDateLabel}</p>
                                            {isBirthday && (
                                                <div>
                                                    <Row>
                                                        <Col className="col-auto">
                                                            {/* Add the image on one side */}
                                                            <img
                                                                src="path/to/birthday_image.jpg"
                                                                alt="Birthday"
                                                                style={{
                                                                    width: '100px', // Set the width of the image
                                                                    height: '100px', // Set the height of the image
                                                                    borderRadius: '50%', // Make it a circular image
                                                                }}
                                                            />
                                                        </Col>

                                                    </Row>
                                                </div>
                                            )}
                                            {timeOfDay && <p className="text-center p-3" style={{fontWeight:'bold', color:'#000',backgroundColor:"#dff6f9",
                                            borderRadius: "0.375rem",
                                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",}}>Good {timeOfDay}</p>}
                                        </Col>
                                    </Row>

                                    {/* Birthday and Anniversary */}

                                    {employees.some((employee) => isEmployeeBirthday(date, employee) || isEmployeeWorkAnniversary(date, employee)) && (
                                        <>
                                            {employees.map((employee, index) => {
                                                if (isEmployeeBirthday(date, employee) && isEmployeeWorkAnniversary(date, employee)) {
                                                    return (
                                                        <Row key={index} style={{ border: '2px solid #ddd', borderRadius: '10px', padding: '20px', backgroundColor: '#fff', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', marginBottom: '10px' }}>
                                                            <Col xs={3} className='text-center'>
                                                                <img
                                                                    src={require(`./../../images/${employee.profile_image}`)}
                                                                    alt="Birthday & Work Anniversary"
                                                                    style={{ width: '80px', height: '80px', borderRadius: '50%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <p style={{ fontSize: 'bold', color: '#333', marginBottom: '10px', textAlign: 'center' }}>🎉Happy Birthday & Work Anniversary!🎉</p>
                                                                <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px', color: '#666', marginBottom: '5px' }}>{employee.emp_name}</p>
                                                                <p style={{ marginTop: '10px', fontSize: '16px', color: '#555', textAlign: 'center' }}>Double celebration! Wishing you a day filled with joy, laughter, and all the things you love.</p>
                                                            </Col>
                                                        </Row>
                                                    );
                                                } else if (isEmployeeBirthday(date, employee)) {
                                                    return (
                                                        <Row key={index} style={{ border: '2px solid #ddd', borderRadius: '10px', padding: '20px', backgroundColor: '#fff', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', marginBottom: '10px' }}>
                                                            <Col xs={3} className='text-center'>
                                                                <img
                                                                    src={require(`./../../images/${employee.profile_image}`)}
                                                                    alt="Birthday"
                                                                    style={{ width: '80px', height: '80px', borderRadius: '50%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <p style={{ fontSize: 'bold', color: '#333', marginBottom: '10px', textAlign: 'center' }}>🎉Happy Birthday!🎉</p>
                                                                <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px', color: '#666', marginBottom: '5px' }}>{employee.emp_name}</p>
                                                                <p style={{ marginTop: '10px', fontSize: '16px', color: '#555', textAlign: 'center' }}>Wishing you a day filled with joy, laughter, and all the things you love!</p>
                                                            </Col>
                                                        </Row>
                                                    );
                                                } else if (isEmployeeWorkAnniversary(date, employee)) {
                                                    return (
                                                        <Row key={index} style={{ border: '2px solid #ddd', borderRadius: '10px', padding: '20px', backgroundColor: '#fff', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', marginBottom: '10px' }}>
                                                            <Col xs={3} className='text-center'>
                                                                <img
                                                                    src={require(`./../../images/${employee.profile_image}`)}
                                                                    alt="Work Anniversary"
                                                                    style={{ width: '80px', height: '80px', borderRadius: '50%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <p style={{ fontSize: 'bold', color: '#333', marginBottom: '10px', textAlign: 'center' }}>🎉Congratulations on your work anniversary!🎉</p>
                                                                <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px', color: '#666', marginBottom: '5px' }}>{employee.emp_name}</p>
                                                                <p style={{ marginTop: '10px', fontSize: '16px', color: '#555', textAlign: 'center' }}>It's been a pleasure having you on the team. Your hard work and dedication are appreciated.</p>
                                                            </Col>
                                                        </Row>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </>
                                    )}




                                    {/* ... Your existing code ... */}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    
                                    



                </Container>
            </div>
        </>
    );
};


export default Dashboard2;
