




import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Col,
  Badge,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Dropdown,  CardTitle,
} from "reactstrap";import * as XLSX from 'xlsx';
import { api } from "../../../../api";
import Cookies from "js-cookie";



const Material5 = () => {
  const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [entriesToShow, setEntriesToShow] = useState(10); // State variable for selected number of entries
  const [dataToShow, setDataToShow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState('');

  

  // Sample data
  const allData = [
    { shopName: "/argon/", materialName: "Bricks", quality: "High", prices: 20000, dateTime: "01/03/2024" },
    { shopName: "/argon/", materialName: "Bricks", quality: "High", prices: 20000, dateTime: "01/03/2024" },
    { shopName: "/argon/", materialName: "Bricks", quality: "High", prices: 20000, dateTime: "01/03/2024" },
    { shopName: "/argon/", materialName: "Bricks", quality: "High", prices: 20000, dateTime: "01/03/2024" },
    { shopName: "/argon/", materialName: "Bricks", quality: "High", prices: 20000, dateTime: "01/03/2024" },
    { shopName: "/argon/", materialName: "Bricks", quality: "High", prices: 20000, dateTime: "01/03/2024" },






  ];

  useEffect(() => {
    // Update data to show based on the selected count
    setDataToShow(allData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
  }, [entriesToShow, currentPage, allData]);

  const toggleProjectDropdown = () => {
    setProjectDropdownOpen(!projectDropdownOpen);
  };

  const handleEntriesChange = (value) => {
    setEntriesToShow(Number(value));
  };

  const handleDropdownToggle = (index) => {
    setDataToShow((prevData) => {
      const newData = [...prevData];
      newData[index].dropdownOpen = !newData[index].dropdownOpen;
      return newData;
    });
  };

  const prePage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < Math.ceil(allData.length / entriesToShow)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changeCPage = (n) => {
    setCurrentPage(n);
  };
  

const handleDownload = () => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const fileName = 'material_list';

  // Specify the indexes of the records you want to export
  const indexesToExport = [1, 5];
  
  // Filtered data to export based on the specified indexes
  const filteredData = indexesToExport.map(index => materialdetails[index]);

  const ws = XLSX.utils.json_to_sheet(filteredData, { header: ['MaterialName', 'Quality', 'Prices', 'DateTime'] });
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

  // Apply bold and background color to headers
  const headerCellStyle = { font: { bold: true }, fill: { bgColor: { indexed: 64 } } };
  Object.keys(ws).filter(key => key.startsWith('A1')).forEach(key => ws[key].s = headerCellStyle);

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const excelData = new Blob([excelBuffer], { type: fileType });
  const excelUrl = URL.createObjectURL(excelData);
  const link = document.createElement('a');
  link.href = excelUrl;
  link.download = fileName + fileExtension;
  document.body.appendChild(link);
  link.click();
  setTimeout(function () {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(excelUrl);
  }, 100);
};

  

  const [dropdownOpen, setDropdownOpen] = useState(false);


  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const client_id = Cookies.get("token");
  const [project, setproject] = useState([]);
  const [materialdetails, setmaterialdetails] = useState();

  useEffect(() => {
    const fetchData = async () => {
        try {
          const response2 = await api.get(`clientproject.php?client_project_id=${client_id}`);
          setproject(response2.data);
          const response = await api.get(`matListBasedOnProject.php?client_id=${client_id}`);
          setmaterialdetails(response.data);
          console.log(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    fetchData();
}, []);


const handleProjectSelection = async (p_id) => {
  try {
    if(p_id == "all"){
      const response = await api.get(`matListBasedOnProject.php?client_id=${client_id}&client_p_id=${p_id}`);
      setmaterialdetails(response.data);
      console.log(response);
    }else{
      const response = await api.get(`matListBasedOnProject.php?p_id=${p_id}`);
      setmaterialdetails(response.data);
      console.log(response);
    }    
  
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
  
  
  
  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="">
          <Col lg="12" className="mb-3 mt-5">
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="mb-4 justify-content-end " >
                <DropdownToggle caret>
                  All Projects
                </DropdownToggle>
                <DropdownMenu left>
                  {project && project.length > 0 ? (
                        <>
                            <DropdownItem onClick={() => handleProjectSelection("all")}>
                                All Projects
                            </DropdownItem>
                            {project.map(project => (
                                <DropdownItem key={project.p_id} onClick={() => handleProjectSelection(project.p_id)}>
                                    {project.p_name}
                                </DropdownItem>
                            ))}
                        </>
                    ) : (
                        <DropdownItem>
                            No Projects
                        </DropdownItem>
                    )}
                </DropdownMenu>
              </Dropdown>
              </Col>
            <Col className="mb-5 mb-xl-0" xl="12" style={{ marginTop: "-20px" }}>
              <Card className="shadow">
                <CardBody >
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Material List</h3>
                      </div>
                      <div className="col text-right">
                      <Dropdown isOpen={projectDropdownOpen} toggle={toggleProjectDropdown}>
                          <DropdownToggle caret>
                            Show Entries: {entriesToShow}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => handleEntriesChange(5)}>5</DropdownItem>
                            <DropdownItem onClick={() => handleEntriesChange(10)}>10</DropdownItem>
                            <DropdownItem onClick={() => handleEntriesChange(50)}>50</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      <button className="btn btn-success mr-2" onClick={handleDownload}>Download</button>
                       
                      </div>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Sl.NO</th>
                        <th scope="col">Material Name</th>
                        <th scope="col">Quality</th>
                        <th scope="col">Prices</th>
                        <th scope="col">Date/time</th>
                      </tr>
                    </thead>
                    <tbody>
                    {materialdetails && materialdetails.length > 0 ? (
                        materialdetails.map((item, index) => (
                          <tr key={index}>
                            <td>{++index}</td>
                            <td>{item.mat_name}</td>
                            <td>{item.mat_quantity}</td>
                            <td>{item.mat_money}</td>
                            <td>{item.Date_Time}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">No material available</td>
                        </tr>
                      )}

                    </tbody>
                  </Table>
                  <nav className='mt-3'>
                    <ul className='pagination justify-content-center '>
                      <li className='page-item '>
                        <a href='#' className='page-link text-primary' onClick={prePage}>Prev</a>
                      </li>
                      {[currentPage - 1, currentPage, currentPage + 1].map((pageNumber) => {
                        if (pageNumber > 0 && pageNumber <= Math.ceil(allData.length / entriesToShow)) { // Check if pageNumber is within valid range
                          return (
                            <li key={pageNumber} className='page-item'>
                              <a href='#' className={`page-link text-white ${pageNumber === currentPage ? 'active' : ''}`} style={{ backgroundColor: '#5E72E4' }} onClick={() => changeCPage(pageNumber)}>{pageNumber}</a>
                            </li>
                          );
                        }
                        return null; // If pageNumber is out of range, return null
                      })}
                      <li className='page-item'>
                        <a href='#' className='page-link text-primary' onClick={nextPage}>Next</a>
                      </li>
                    </ul>
                  </nav>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>




    </>
  );
};

export default Material5;


