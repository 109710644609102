import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
// import Image1 from '../../../images/Delete.png';
// import Image2 from '../../../images/65e957098749a_usa4.jpg';
// import Image3 from '../../../images/65eed413ceac2_burger1.jpg';
import { api } from '../../../../api';

const Corousel6 = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await api.get('wasteitemimagesVP.php');
        if (response.data && response.data.waste_images) {
          // Assuming images are stored in the images folder of your project
          const imageUrls = response.data.waste_images.map(
            (image) => require(`../../../images/${image}`)
          );
          setImages([...images, ...imageUrls]);
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    fetchImages();
  }, []);

  console.log('patg', images);

  return (
    <div>
      <Carousel data-bs-theme="dark">
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={image}
              alt={`Slide ${index + 1}`}
              style={{ height: '444px', width: '400px', borderRadius: '7px' }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Corousel6;