import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { api } from "../api";
import { useNavigate } from "react-router-dom";
import Logo from "../../src/dashboard/images/AECP Logo 1 png.png";
import "./employeeForm.css";
import Back from "../dashboard/images/sidebar1.jpeg";

const EmployeeForm = () => {
  const navigate = useNavigate();
  const [emp, setemp] = useState({
    emp_name: "",
    email: "",
    phoneno: "",
    dob: "",
    doj: "",
    role: "",
    salary: "",
    address: "",
    files: "",
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setemp((prevState) => ({
      ...prevState,
      files: file,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("emp_name", emp.emp_name);
    formDataToSend.append("email", emp.email);
    formDataToSend.append("phoneno", emp.phoneno);
    formDataToSend.append("dob", emp.dob);
    formDataToSend.append("doj", emp.doj);
    formDataToSend.append("role", emp.role);
    formDataToSend.append("salary", emp.salary);
    formDataToSend.append("address", emp.address);
    formDataToSend.append("files", emp.files);

    try {
      const response = await api.post("addEmployee.php", formDataToSend);
      console.log("Data sent successfully:", response.data);
      navigate(`/welcome-message/${emp.emp_name}`);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const [role,setRole] = useState();

  const fetchRole = async () => {
    const response = await api.get(`addRole.php`);
    setRole(response.data);
  }

  console.log('role',role);

  useEffect(()=>{
    fetchRole();
  },[]);

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${Back})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        width: "100%",
        top: 0,
        left: 0,
      }}
    >
      <Row className="w-75 p-4 justify-content-center align-items-center">
        <Col
          xs={12}
          md={12}
          lg={12}
          className="mx-auto text-center p-4"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #dee2e6",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="mb-4">
            <img
              src={Logo}
              alt="AECP Logo"
              className="logo-img"
              style={{ height: "100px", width: "auto" }}
            />
          </div>
          <h2 className="mb-4">Employee Registration Form</h2>
          <Form onSubmit={handleFormSubmit}>
            <Row form>
              <Col md={4} className="text-left">
                <FormGroup>
                  <Label className="form-control-label">Full Name</Label>
                  <Input
                    className="form-control-alternative shadow-hover shadow-focus"
                    id="input-name"
                    type="text"
                    name="emp_name"
                    onChange={(e) =>
                      setemp({ ...emp, emp_name: e.target.value })
                    }
                    placeholder="Enter Employee Name"
                    pattern="^[a-zA-Z ]+$"
                    title="Enter letters only"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={4} className="text-left">
                <FormGroup>
                  <Label className="form-control-label">Contact Number</Label>
                  <Input
                    className="form-control-alternative shadow-hover shadow-focus"
                    id="input-number"
                    type="text"
                    name="phoneno"
                    pattern="^\d{10}$"
                    title="Enter 10 digits only"
                    placeholder="Enter Contact Number"
                    required
                    onChange={(e) =>
                      setemp({ ...emp, phoneno: e.target.value })
                    }
                  ></Input>
                </FormGroup>
              </Col>
              <Col md={4} className="text-left">
                <FormGroup>
                  <Label className="form-control-label">Email</Label>
                  <Input
                    className="form-control-alternative shadow-hover shadow-focus"
                    id="input-mail"
                    type="email"
                    name="email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                    title="Enter valid email address"
                    onChange={(e) => setemp({ ...emp, email: e.target.value })}
                    placeholder="Enter Email Address"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={4} className="text-left">
                <FormGroup>
                  <Label className="form-control-label">Date Of Birth</Label>
                  <Input
                    className="form-control-alternative shadow-hover shadow-focus"
                    id="input-birthday"
                    type="date"
                    name="dob"
                    onChange={(e) => setemp({ ...emp, dob: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={4} className="text-left">
                <FormGroup>
                  <Label className="form-control-label">Date Of Joining</Label>
                  <Input
                    className="form-control-alternative shadow-hover shadow-focus"
                    id="input-joining"
                    type="date"
                    name="doj"
                    onChange={(e) => setemp({ ...emp, doj: e.target.value })}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={4} className="text-left">
                <FormGroup>
                  <Label className="form-control-label">Role</Label>
                  <Input
                    className="form-control-alternative shadow-hover shadow-focus"
                    id="input-manager"
                    type="select"
                    name="role"
                    onChange={(e) => setemp({ ...emp, role: e.target.value })}
                  >
                    <option value="">Select Role</option>
                    {role &&
                      Array.isArray(role) &&
                      role.map((option) => (
                        <option key={option.role_name} value={option.role_name}>
                            {option.role_name}
                        </option>
                      ))

                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} className="text-left">
                <FormGroup>
                  <Label className="form-control-label">Address</Label>
                  <Input
                    className="form-control-alternative shadow-hover shadow-focus"
                    id="input-location"
                    type="text"
                    name="address"
                    onChange={(e) =>
                      setemp({ ...emp, address: e.target.value })
                    }
                    placeholder="Location"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={4} className="text-left">
                <FormGroup>
                  <Label className="form-control-label">Salary</Label>
                  <Input
                    className="form-control-alternative shadow-hover shadow-focus"
                    id="input-salary"
                    type="text"
                    name="salary"
                    title="Enter numbers only"
                    onChange={(e) => setemp({ ...emp, salary: e.target.value })}
                    placeholder="Enter Salary"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={4} className="text-left">
                <FormGroup>
                  <Label className="form-control-label">Upload Photo</Label>
                  <Input
                    type="file"
                    className="form-control shadow-hover shadow-focus"
                    id="input-file"
                    accept="image/*"
                    name="files"
                    onChange={handleFileChange}
                    style={{
                      border: "1px solid #ced4da",
                      borderRadius: "4px",
                      padding: "5px",
                    }}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit" className="mt-3">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EmployeeForm;
