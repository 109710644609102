import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, Table, Container, Row, Col, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Badge, Label } from "reactstrap";
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {api} from '../../../../api';
import Cookies from 'js-cookie';

const Requestresource3 = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [entriesToShow, setEntriesToShow] = useState(10);
    
    const [material, setMaterial] = useState([]);
    const [dropdownStates, setDropdownStates] = useState([]);
    
    const filtered = Array.isArray(material) ? 
    material.filter(item =>
        (item.description || item.role).toLowerCase().includes(searchQuery.toLowerCase())
    ) : 
    [];


    const emp_id = Cookies.get('token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response2 = await api.get(`requestresourse.php?hr='hr'`);
                setMaterial(response2.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // const [selectedproject, setselectedproject] = useState();

    const handleProjectSelection = async (p_id, p_name) => {

        setselectedproject(p_name);

        try {
            const response = await api.get(`requestresourse.php?pid=${p_id}`);
            console.log('API Response:', response.data);
            setMaterial(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleDropdownToggle = (index) => {
        setDropdownStates(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const statusOptions = [
        { value: "Approved", label: "Accept" },
        { value: "Rejected", label: "Reject" }
    ];

    const [modalEmployee, setModalEmployee] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedproject, setselectedproject] = useState();
    const [modal, setModal] = useState(false); // State to control modal visibility

    const toggleModalView = () => {
        setModalOpen(!modalOpen);
    };

    const toggleModal = () => {
        setModal(!modal);
    };

    const [report, setreport] = useState({
        hr_id : emp_id,
        r_id : '',
        status : '',
        report : ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setreport((prevState) => ({
        ...prevState,
        [name]: value,
        }));

        console.log(report)
    };

    const handleItemClick = (id) => {
        // setSelectedId(id);
        setreport((prevState) => ({
          ...prevState,
          r_id : id,
        }));
        toggleModal();
      };

    const handleStatusAction = async (id, status) => {
        // e.preventDefault();
        try {
            const response = await api.post('resourceAction.php', report);
            console.log(response.data);
        } catch (error) {
            console.error('Error updating status:', error);
        }
        // window.location.reload();
    };

   

    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Container fluid>
                    <Row className=''>
                   
                        
                        <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
                            <h1>Resource Details</h1>
                        </Col>
                        <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
                            <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                                <FormGroup className="mb-0 ml-6">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Search Materials"
                                            type="text"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <marquee behavior="scroll" direction="right"  style={{height:'58px'}}>
          {/* <img src={require("../../assets/img/brand//240_F_364017781_DR0bENAGus0GPESus7E2w4JaT7wzIZ1M-removebg-preview.png")} style={{ height: '100px' ,marginbottom:'-17px' }} alt="Delivery Truck" /> */}
          <img src={require("../../../project-management/assets/img/brand/delivery-truck-1--unscreen.gif")} style={{ height: '80px' ,marginbottom:'-17px' }} alt="Delivery Truck" />
          Resource Delivery
            </marquee>
                    <Row className="">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Resource List</h3>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">SL.No</th>
                                            <th scope="col">Resource Type</th>
                                            <th scope="col">Quality</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {filtered && filtered.length > 0 ? (
                                    filtered.map((material, index) => (
                                        <tr key={index}>
                                            <td>{++index}</td>
                                        <td>{material.role}</td>
                                        <td>{material.quantity}</td>
                                        <td>{material.description.replace(/<\/?[^>]+(>|$)/g, '')}</td>
                                        <td>
                                        {
                                            material.hr_status === "pending" ? (
                                                <Badge style={{ backgroundColor: 'yellow', color: 'black' }}>{material.hr_status}</Badge>
                                            ) : material.hr_status === "Rejected" ? (
                                                <Badge style={{ backgroundColor: 'red', color: 'black' }}>{material.hr_status}</Badge>
                                            ) : (
                                                <Badge style={{ backgroundColor: 'green', color: 'white' }}>{material.hr_status}</Badge>
                                            )
                                        }


                                        </td>
                                        <td>
                                            <button className='btn btn-success' onClick={() => { setModalEmployee(material); toggleModalView(); }}><i className="fa-solid fa-eye" style={{fontSize:'22px'}} ></i></button>
                                            <button className='btn btn-success' onClick={() => handleItemClick(material.r_id) }>Set Action</button>
                                        </td>
                                        </tr>
                                    ))
                                    ) : (
                                    <tr>
                                        <td colSpan="5" style={{ textAlign: 'center' }}>No details available, Project not yet started</td>
                                    </tr>
                                    )}

                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}><h2 style={{fontWeight:'bolder'}}>Status Action</h2></ModalHeader>
                <ModalBody>
                    {/* Your form elements go here */}
                    <Form onSubmit={handleStatusAction} >
                        <FormGroup>
                            <Label for="exampleSelect">Select</Label>
                            <Input
                                className="form-control-alternative"
                                id="input-status"
                                type="select"
                                name="status"
                                onChange={handleInputChange}
                            >
                                <option value="">Select Status</option>
                                {statusOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleText">Description</Label>
                            <Input type="textarea" name="report" id="exampleText" onChange={handleInputChange} />
                        </FormGroup>
                        <Row>
                            <Col lg='12' style={{ textAlign: 'center' }}>
                                <Button color="primary" type="submit">Submit</Button>
                            </Col>
                            </Row>
                        {/* Add more form fields as needed */}
                    </Form>
                </ModalBody>
            </Modal>

            {modalEmployee && modalEmployee && (
              <Modal isOpen={modalOpen} toggle={toggleModalView}>
                <ModalHeader toggle={toggleModalView}>Resource View</ModalHeader>
                <ModalBody >
                <div className="row">
                                    <div className="col-md-12">
                                        <div className=''>
                                            <label className='border p-2 rounded font-weight-bold' style={{width:'155px'}}>Material Type</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.role}</label>
                                        </div>
                                        <div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>Quantity</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.quantity}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>Description</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.description.replace(/<\/?[^>]+(>|$)/g, '')}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>General Manager</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.status}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>General Action</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.gm_report}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>HR</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.hr_status}</label>
                                        </div>
                                    </div>
                            </div>
                </ModalBody>
              </Modal>
            )}
        </>
    );
};
export default Requestresource3;
