import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, Table, Container, Row, Col, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Badge, Label } from "reactstrap";
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { api } from '../../../../api';
import Cookies from 'js-cookie';

const Requestproject = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [entriesToShow, setEntriesToShow] = useState(5);

    const [filteredData, setFilteredData] = useState([]);
    const [resourceList, setResourceList] = useState([]);
    const [dynamo, setDynamo] = useState([]);
    const [change, setChange] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);




    const [modal, setModal] = useState(false); // State to control modal visibility
    const toggleModal = () => setModal(prevState => !prevState);
    useEffect(() => {
        // Replace this with your actual data fetching logic
        const originalData = [
            { Materials: "bricks" },
        ];

        const filtered = originalData.filter(item =>
            item.Materials.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchQuery]);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleProjectDropdown = () => {
        setProjectDropdownOpen(!projectDropdownOpen);
    };

    // const toggleModal = () => {
    //     setModal(!modal);
    // };




    const emp_id = Cookies.get('token');

    const [project, setproject] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`viewProjectDetails.php?p_m_id=${emp_id}`);
                setproject(response.data);
                console.log(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const [projectmanager, setprojectmanager] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.post(`employeesView.php`);
                setprojectmanager(response.data);
                console.log(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);



    const [clientForm, setClientForm] = useState({
        status: '',
        text: '',
        manager: '',

        description: '',
    });



    const statusOptions = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        // Add more options as needed
    ];



    const managerOptions = [
        { value: 'electrician', label: 'electrician ' },
        { value: 'designer', label: 'designer' },
        // Add more options as needed
    ];


    const [allresourse, setallresourse] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`requestresourse.php?s_m_id=${emp_id}`);
                setFilteredData(response.data);
                setDynamo(response.data);
                setResourceList(response.data);
                setChange(response.data);
                console.log(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();

        console.log(allresourse)
    }, []);

    console.log('test1', filteredData);






    const [resourse, setresourse] = useState({
        emp_id: emp_id,
        p_id: '',
        role: '',
        quantity: '',
        description: '',
        files: null,
        g_m_id: ''
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setresourse((prevClientForm) => ({
            ...prevClientForm,
            [name]: value,
        }));

        console.log(resourse)
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setresourse((prevClientForm) => ({
            ...prevClientForm,
            files: file,
        }));

        console.log(resourse)
    };


    const handleProjectSelection = async (p_id) => {
        try {
            const response = await api.get(`requestresourse.php?pid=${p_id}&emp_id=${emp_id}`);
            setFilteredData(response.data);
            setChange(response.data);
            setCurrentPage(1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('emp_id', resourse.emp_id);
        formDataToSend.append('p_id', resourse.p_id);
        formDataToSend.append('role', resourse.role);
        formDataToSend.append('quantity', resourse.quantity);
        formDataToSend.append('description', resourse.description);
        formDataToSend.append('files', resourse.files);
        formDataToSend.append('g_m_id', resourse.g_m_id);

        try {
            const response = await api.post("requestresourse.php", formDataToSend);
            console.log("Data sent successfully:", response.data);
            // window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteProjectName, setDeleteProjectName] = useState('');

    const handleDeleteConfirmation = (e) => {
        // console.log(deleteProjectName);

        // e.preventDefault();
        const fetchData = async () => {
            try {
                const response = await api.get(`requestresourse.php?del_id=${deleteProjectName}`);
                console.log(response.data);
                window.location.reload();
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
        setShowDeleteConfirmation(false);
    };

    //Pagination code Part 2 goes here

    const [projectDropdownOpen1, setProjectDropdownOpen1] = useState(false);
    // const [entriesToShow,setEntriesToShow] = useState(5);
    // const [currentPage,setCurrentPage] = useState(1);
    const totalPages = Math.ceil(dynamo.length / entriesToShow);

    const toggleProjectDropdown1 = () => {
        setProjectDropdownOpen1(!projectDropdownOpen1);
    };

    const handleEntriesChange = (value) => {
        setEntriesToShow(value === 'All' ? resourceList.length : Number(value));
    };

    const prePage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const getSerialNumber = (index) => {
        return (currentPage - 1) * entriesToShow + index + 1;
    };

    useEffect(() => {
        if (Array.isArray(change)) {
            setFilteredData(change.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
        } else {
            setFilteredData([]); // Handle case where change is not an array
        }
    }, [entriesToShow, currentPage, change]);
    
    useEffect(() => {
        if (Array.isArray(change)) {
            if (searchQuery.trim() === "") {
                setFilteredData(change.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
            } else {
                const filteredDataDetails = change.filter(item =>
                    item.p_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item.role.toLowerCase().includes(searchQuery.toLowerCase())
                );
    
                setFilteredData(filteredDataDetails.slice(0, entriesToShow));
            }
        } else {
            setFilteredData([]); // Handle case where change is not an array
        }
    }, [searchQuery, entriesToShow, currentPage, change]);
    





    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Container fluid>
                    <Row className='mb-5'>
                        <Col>
                            <Dropdown isOpen={projectDropdownOpen} toggle={toggleProjectDropdown}>
                                <DropdownToggle caret>
                                    Select Project
                                </DropdownToggle>
                                <DropdownMenu left>
                                    {Array.isArray(project) && project.length > 0 ? (
                                        project.map(project => (
                                            <DropdownItem key={project.p_id} onClick={() => handleProjectSelection(project.p_id)}>
                                                {project.p_name}
                                            </DropdownItem>
                                        ))
                                    ) : (
                                        <p>No Project Yet?</p>
                                    )}

                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
                            <h1>Request Resource</h1>
                        </Col>

                        <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
                            <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                                <FormGroup className="mb-0 ml-2">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search" />
                                            </InputGroupText>
                                        </InputGroupAddon >
                                        <Input
                                            placeholder="Search Resource"
                                            type="text"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}

                                        />

                                    </InputGroup>

                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Resource List</h3>
                                        </div>
                                        <div className="d-flex justify-content-end align-items-center">
                                            <div className="mr-3">
                                                <Dropdown isOpen={projectDropdownOpen1} toggle={toggleProjectDropdown1}>
                                                    <DropdownToggle caret>
                                                        Show Entries: {entriesToShow === dynamo.length ? "All" : entriesToShow}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => handleEntriesChange(5)}>5</DropdownItem>
                                                        <DropdownItem onClick={() => handleEntriesChange(10)}>10</DropdownItem>
                                                        <DropdownItem onClick={() => handleEntriesChange('All')}>All</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>

                                            <Button color="primary" onClick={toggleModal}>Request</Button>
                                        </div>

                                        <div>
                                            <Modal isOpen={modal} toggle={toggleModal}>
                                                <ModalHeader toggle={toggleModal}>Request</ModalHeader>
                                                <Form onSubmit={handleFormSubmit} enctype="multipart/form-data">
                                                    <Row className='px-3'>

                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-status">Project Name</label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-status"
                                                                    type="select"
                                                                    name="p_id"
                                                                    onChange={handleInputChange}
                                                                >
                                                                    <option value="">Select Project Name</option>
                                                                    {project && project.length > 0 ? (
                                                                        project.map(option => (
                                                                            <option key={option.p_id} value={option.p_id}>{option.p_name}</option>
                                                                        ))
                                                                    ) : (
                                                                        <option value="">No projects available</option>
                                                                    )}

                                                                </Input>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-status">General Manager</label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-status"
                                                                    type="select"
                                                                    name="g_m_id"
                                                                    onChange={handleInputChange}
                                                                >
                                                                    <option value="">Select General Manager</option>
                                                                    {Array.isArray(projectmanager) && projectmanager.length > 0 ? (
                                                                        projectmanager.map(option => (
                                                                            <option key={option.emp_id} value={option.emp_id}>{option.emp_name + "[" + option.role + "]"}</option>
                                                                        ))
                                                                    ) : (
                                                                        <option value="">No General managers available</option>
                                                                    )}


                                                                </Input>
                                                            </FormGroup>
                                                        </Col>


                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-manager">Role</label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-manager"
                                                                    type="text"
                                                                    name="role"
                                                                    onChange={handleInputChange}
                                                                    placeholder='Painter,Electrician etc...'
                                                                >

                                                                </Input>
                                                            </FormGroup>
                                                        </Col>


                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="number">Quantity</label>
                                                                <Input className="form-control-alternative" onChange={handleInputChange} type="text" name="quantity" id="yourNumberField" placeholder="Enter a number" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-description">Remark</label>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setresourse(prevState => ({
                                                                            ...prevState,
                                                                            description: data
                                                                        }));
                                                                        console.log(resourse)
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-file">Upload File</label>
                                                                <Input
                                                                    type="file"
                                                                    className="form-control-file"
                                                                    id="input-file"
                                                                    name="files"
                                                                    onChange={handleFileChange}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg='12' className='text-center mb-2'>
                                                            <Button color="primary" type="submit">Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Modal>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">SL.No</th>
                                            <th scope="col">Project Name</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Current Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData && Array.isArray(filteredData) && filteredData.length > 0 ? (
                                            filteredData.map((item, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{getSerialNumber(index)}</th>
                                                    <td>{item.p_name}</td>
                                                    <td>{item.role}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.status}</td>
                                                    <td>
                                                        <button onClick={() => { setDeleteProjectName(item.r_id); setShowDeleteConfirmation(true); }} className='btn btn-deeppink'>
                                                            <i className="fa-solid fa-trash text-danger"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            filteredData && Array.isArray(filteredData) ? (
                                                <tr>
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>No details available, Project not yet started</td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>No resource requested on this project</td>
                                                </tr>
                                            )
                                        )}

                                    </tbody>
                                </Table>
                                <nav className='mt-3'>
                                    <ul className='pagination justify-content-center'>
                                        <li className='page-item'>
                                            <a
                                                href='#'
                                                className='page-link text-primary'
                                                onClick={prePage}
                                                disabled={currentPage === 1}
                                            >
                                                Prev
                                            </a>
                                        </li>
                                        <li className='page-item'>
                                            <a
                                                href='#'
                                                className='page-link text-primary'
                                                onClick={nextPage}
                                                disabled={currentPage === totalPages}
                                            >
                                                Next
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={showDeleteConfirmation} toggle={() => setShowDeleteConfirmation(false)}>
                <ModalHeader>Confirm Deletion</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete?
                </ModalBody>
                <div className="modal-footer">
                    <Button color="danger" onClick={handleDeleteConfirmation}>Yes</Button>
                    <Button color="secondary" onClick={() => setShowDeleteConfirmation(false)}>No</Button>
                </div>
            </Modal>

        </>
    );
};

export default Requestproject;
