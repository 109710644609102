import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import logo from '../../../images/A'
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";
import { api } from "../../../../api";
import { loadImage } from "Utility/imageIssue";
import { showSuccessToast,showDeleteToast,showErrorToast } from "toastUtils";

const Employees3 = () => {
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [clientForm, setClientForm] = useState({
    name: "",
    status: "",
    Starting: "",
    Ending: "",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteProjectName, setDeleteProjectName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDepartmentOptions, setSelectedDepartmentOptions] = useState(
    []
  );

  const filtered = Array.isArray(filteredEmployees)
    ? filteredEmployees.filter(
      (item) =>
        item &&
        item.emp_name &&
        item.emp_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : [];

  const [selectedMemberOptions, setSelectedMemberOptions] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const toggleModal = () => setModalOpen((prevState) => !prevState);
  const toggleNewEmployee = () =>
    setShowAddClientModal((prevState) => !prevState);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");

  const toggleAddProjectModal = () =>
    setShowAddProjectModal(!showAddProjectModal);

  const handleProjectChange = (e) => {
    setSelectedProject(e.target.value);
  };




  const handleDeleteConfirmation = (confirmed, employeeId) => {
    if (confirmed) {
      console.log(`Deleting employee with ID: ${employeeId}`);
      const fetchData = async () => {
        try {
          const response = await api.get(
            `employeesView.php?emp_id=${employeeId}`
          );
          console.log(response.data);
          window.location.reload();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
    // Close the delete confirmation modal
    toggleDeleteConfirmationModal(employeeId);
  };

  const handleAddProject = () => {
    // Handle adding project logic here
    console.log("Selected Project:", selectedProject);
    // You can perform further actions here, like submitting the form or calling an API
    // Reset selected project and close modal
    setSelectedProject("");
    toggleAddProjectModal();
  };

  const handleDepartmentSelectChange = (selectedOptions) => {
    setSelectedDepartmentOptions(selectedOptions);
    setClientForm((prevState) => ({
      ...prevState,
      department: selectedOptions.map((option) => option.value),
    }));
  };

  const handleMemberSelectChange = (selectedOptions) => {
    setSelectedMemberOptions(selectedOptions);
    setClientForm((prevState) => ({
      ...prevState,
      members: selectedOptions.map((option) => option.value),
    }));
  };

  const handleManagerSelectChange = (selectedOption) => {
    setSelectedManager(selectedOption);
  };

  const handleLocationSelectChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
  };

  const toggleViewMoreModal = (employee) => {
    setSelectedEmployee(employee);
  };

  const renderViewMoreModal = () => {
    if (!selectedEmployee) return null;

    return (
      <Modal isOpen={true} toggle={() => toggleViewMoreModal(null)}>
        <ModalHeader toggle={() => toggleViewMoreModal(null)}>
          <h2>Employee Name Details</h2>
        </ModalHeader>
        <ModalBody
          style={{
            justifyContent: "center",
            textAlign: "center",
            color: "black",
            fontWeight: "bold",
          }}
        >
          <img
            alt="Employee_Image"
            className="rounded-circle"
            src={
              selectedEmployee && selectedEmployee.profile_image
                ? require(`../../../images/${selectedEmployee.profile_image}`)
                : "default_image_path"
            }
            style={{ height: 100, marginBottom: 10 }}
          />
          <p>
            <span style={{ fontWeight: "bolder" }}>Name :</span>{" "}
            {selectedEmployee.emp_name}
          </p>
          <p>
            <span style={{ fontWeight: "bolder" }}>Contact Number :</span>
            {selectedEmployee.phoneno}
          </p>
          <p>
            <span style={{ fontWeight: "bolder" }}>Email Id :</span>
            {selectedEmployee.email}
          </p>
          <p>
            <span style={{ fontWeight: "bolder" }}>Date Of Birth :</span>
            {selectedEmployee.dob}
          </p>
          <p>
            <span style={{ fontWeight: "bolder" }}>Role :</span>{" "}
            {selectedEmployee.role}
          </p>
          <p>
            <span style={{ fontWeight: "bolder" }}>Date Of Joinig :</span>
            {selectedEmployee.doj}
          </p>
          <p>
            <span style={{ fontWeight: "bolder" }}>Salary :</span>{" "}
            {selectedEmployee.salary}
          </p>
          <p>
            <span style={{ fontWeight: "bolder" }}>Address :</span>{" "}
            {selectedEmployee.address}
          </p>
          {/* Add other employee details as needed */}
        </ModalBody>
      </Modal>
    );
  };

  const employees = [
    {
      id: 1,
      name: "Mine",
      age: 2022,
      location: "Developers",
      position: "Mine Description",
      image: require("../../assets/img/theme/team-4-800x800.jpg"),
      dropdownOpen: false,
    },
    {
      id: 2,
      name: "AecEarth",
      age: 2021,
      location: "Career",
      position: "AecEarth Description",
      image: require("../../assets/img/theme/team-4-800x800.jpg"),
      dropdownOpen: false,
    },
    {
      id: 3,
      name: "Swifter",
      age: 2000,
      location: "Sales",
      position: "Swifter Description",
      image: require("../../assets/img/theme/team-4-800x800.jpg"),
      dropdownOpen: false,
    },
    {
      id: 4,
      name: "BIM",
      age: 2023,
      location: "Construction",
      position: "BIM Description",
      image: require("../../assets/img/theme/team-4-800x800.jpg"),
      dropdownOpen: false,
    },
  ];

  // Define status options
  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "ongoing", label: "Ongoing" },
    { value: "completed", label: "Completed" },
  ];
  const managerOptions = [
    { value: "jay", label: "jay" },
    { value: "anu", label: "anu" },
  ];
  const siteOptions = [
    { value: "harish", label: "harish" },
    { value: "jyo", label: "jyo" },
  ];

  const [empdetails, setempdetails] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`employeesView.php`);
      setFilteredEmployees(response.data);

      setLoading(false);
      // console.log(empdetails)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [emp, setemp] = useState();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setemp((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    console.log(emp);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setemp((prevState) => ({
      ...prevState,
      files: file,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("emp_name", emp.emp_name);
    formDataToSend.append("email", emp.email);
    formDataToSend.append("phoneno", emp.phoneno);
    formDataToSend.append("dob", emp.dob);
    formDataToSend.append("doj", emp.doj);
    formDataToSend.append("role", emp.role);
    formDataToSend.append("salary", emp.salary);
    formDataToSend.append("department", emp.department);
    formDataToSend.append("address", emp.address);
    formDataToSend.append("files", emp.files);

    try {
      const response = await api.post("addEmployee.php", formDataToSend);
      console.log("Data sent successfully:", response.data);
      // fetchData();
      // window.location.reload();
      setShowAddClientModal(false);
      showSuccessToast('Employee Added Successfully')
    } catch (error) {
      console.error("Error submitting data:", error);
      showErrorToast('Error in Adding Employee')
    }
  };

  const toggleDropdown = (id) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleDropdownItemClick = (action, employeeId, employeeName) => {
    if (action === "Delete") {
      // Open delete confirmation modal
      toggleDeleteConfirmationModal(employeeId);
    } else {
      console.log(`Clicked ${action}`);
    }
  };

  const [deleteConfirmationEmployeeId, setDeleteConfirmationEmployeeId] =
    useState(null);

  const toggleDeleteConfirmationModal = (employeeId) => {
    setDeleteConfirmationEmployeeId(
      employeeId === deleteConfirmationEmployeeId ? null : employeeId
    );
  };

  const [role,setrole] = useState();
  const [department,setdepartment] = useState();
  const fetchRoleData = async () => {
    try {
        const response = await api.get("addRole.php");
        setrole(response.data);
        const response2 = await api.get("department.php");
        setdepartment(response2.data);

    } catch (error) {
        console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchRoleData();
  }, []);



  
  const toggleNewEmail = () =>setShowEmailClientModal((prevState) => !prevState);

  const [showAddHobbyModal, setShowAddHobbyModal] = useState(false);
  const [hobbyInput, setHobbyInput] = useState("");
  const [addedHobbies, setAddedHobbies] = useState({
    emails: ["shashibhavanck1431@gmail.com"]  // to hold the list of emails
  });

  const toggleAddHobbyModal = () => {
    setShowAddHobbyModal(!showAddHobbyModal);
  };

  const handleHobbyInputChange = (e) => {
    setHobbyInput(e.target.value);
  };

  const handleAddHobby = () => {
    
    if (isValidEmail(hobbyInput)) {
      if (!addedHobbies.emails.some(email => email.toLowerCase() === hobbyInput.toLowerCase())) {
        setAddedHobbies(prevState => ({
          emails: [...prevState.emails, hobbyInput]
        }));
        console.log(addedHobbies);
      } else {
        alert("This email is already added.");
      }
      setHobbyInput("");
    } else {
      alert("Please enter a valid email address.");
    }
    
  };
  
  
  const [showEmailClientModal, setShowEmailClientModal] = useState(false);
  const handleCancel = () => {
    setHobbyInput(""); // Clear the input
    toggleAddHobbyModal(); // Close the modal
  };

  const isValidEmail = (email) => {
    // Regular expression to validate email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };



  const handleRemoveHobby = (indexToRemove) => {
    setAddedHobbies((prevHobbies) => ({
      emails: prevHobbies.emails.filter((_, index) => index !== indexToRemove)
    }));
  };


  // const EmailSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     let response = await api.post("materialMail.php", addedHobbies, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     });
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  
const EmailSubmit=(e)=>{
  // e.preventDefault();
  api.post(`materialMail.php`,addedHobbies, {headers: {
            'Content-Type': 'application/json'
          }}).then((response)=>{
            console.log(response.data);
          }).catch((error)=>{
            console.log(error);
          })
}


  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="mb-5">
            <Col className="order-xl-2 mb-5 mb-xl-0 text-left">
              <h1 className="text-white">Employees List</h1>
            </Col>
            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex">
              <Form className="navbar-search navbar-search-dark form-inline  d-none d-md-flex ml-lg-auto">
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search Employee"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
              <Button onClick={toggleNewEmployee}>Add Employee</Button>
              <Button   onClick={toggleNewEmail}>Invite</Button>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            {filtered &&
              filtered.length > 0 &&
              filtered.map((employee) => (
                <Modal
                  key={employee.emp_id}
                  isOpen={deleteConfirmationEmployeeId === employee.emp_id}
                  toggle={() => toggleDeleteConfirmationModal(employee.emp_id)}
                >
                  <ModalHeader
                    toggle={() =>
                      toggleDeleteConfirmationModal(employee.emp_id)
                    }
                  >
                    Confirm Delete
                  </ModalHeader>
                  <ModalBody>
                    Are you sure you want to delete {employee.emp_name}?
                    <div className="mt-3 text-right">
                      <form>
                        <Button
                          color="danger"
                          onClick={() =>
                            handleDeleteConfirmation(true, employee.emp_id)
                          }
                        >{`Yes, delete ${employee.emp_name}`}</Button>{" "}
                      </form>
                      <Button
                        color="secondary"
                        onClick={() =>
                          handleDeleteConfirmation(false, employee.emp_id)
                        }
                      >
                        No
                      </Button>
                    </div>
                  </ModalBody>
                </Modal>
              ))}

            {filtered && filtered.length > 0 ? (
              filtered.map((employee) => (
                <Col md="3" key={employee.id}>
                  <Card className="card-profile shadow mb-5">
                    <Row className="justify-content-center">
                      <Col className="order-lg-2" lg="3">
                        <div className="card-profile-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={employee && employee?.profile_image
                                ? loadImage(employee.profile_image)
                                : loadImage('AECP Logo 1 png.png')
                              }
                              style={{ height: 100, width:100 }}
                            />
                          </a>
                        </div>
                        <Dropdown
                          isOpen={dropdownOpen[employee.emp_id]}
                          toggle={() => toggleDropdown(employee.emp_id)}
                          style={{ top: "5px", left: "110px" }}
                        >
                          <DropdownToggle
                            tag="span"
                            data-toggle="dropdown"
                            aria-expanded={dropdownOpen}
                            caret
                            style={{
                              cursor: "pointer",
                              justifyContent: "center",
                            }}
                          >
                            <i className="fas fa-ellipsis-v"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <Link
                              to={`/hr-admin/hr-admin/hr-management/editproject3/${employee.emp_id}`}
                              style={{ color: "Black" }}
                            >
                              <DropdownItem
                                onClick={() => console.log("Edit clicked")}
                              >
                                Edit
                              </DropdownItem>
                            </Link>
                            <DropdownItem
                              onClick={() =>
                                handleDropdownItemClick(
                                  "Delete",
                                  employee.emp_id,
                                  employee.emp_name
                                )
                              }
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <CardBody className="pt-0 pt-md-5 mt-5">
                      <div className="text-center">
                        <h3>{employee.emp_name}</h3>
                        <div className="h3 font-weight-600">
                          <span
                            className="p-2 badge badge-secondary"
                            style={{ backgroundColor: "#000", color: "#fff" }}
                          >
                            {employee.role}
                          </span>
                        </div>
                        <div className="h5 mt-4">{employee.phoneno}</div>
                        <hr className="my-4" />
                        <Button
                          color="primary"
                          href="#pablo"
                          onClick={() => toggleViewMoreModal(employee)}
                        >
                          View More
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  No details available, Project not yet started
                </td>
              </tr>
            )}
          </Row>
        </Container>
      </div>
      <Modal isOpen={showAddClientModal} toggle={toggleNewEmployee}>
        <ModalHeader toggle={toggleNewEmployee}>
          <h2 style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
            New Employee
          </h2>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    Employee Name *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-name"
                    type="text"
                    name="emp_name"
                    onChange={handleInputChange}
                    placeholder="Enter Employee Name"
                    pattern="^[a-zA-Z ]+$"
                    title="Enter letters only"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-number">
                    Contact Number *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-number"
                    type="text"
                    name="phoneno"
                    pattern="^\d{10}$"
                    title="Enter 10 digits only"
                    placeholder="Enter Contact Number"
                    required
                    onChange={handleInputChange}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Email Id *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-mail"
                    type="email"
                    name="email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                    title="Enter valid email address"
                    onChange={handleInputChange}
                    placeholder="Enter Email Address"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-birthday"
                  >
                    Date of Birth *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-birthday"
                    type="date"
                    name="dob"
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-manager">
                    Select Role *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-manager"
                    type="select"
                    name="role"
                    onChange={handleInputChange}
                  >
                    <option value="">Select Role</option>
                    {role &&
                      Array.isArray(role) &&
                      role.map((option) => (
                        <option key={option.role_name} value={option.role_name}>
                          {option.role_name}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-manager">
                    Select Department *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-manager"
                    type="select"
                    name="department"
                    onChange={handleInputChange}
                  >
                    <option value="">Select Role</option>
                    {department &&
                      Array.isArray(department) &&
                      department.map((option) => (
                        <option key={option.d_id} value={option.d_id}>
                          {option.dept_name}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-joining">
                    Date of Joining *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-joining"
                    type="date"
                    name="doj"
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-salary">
                    Salary *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-salary"
                    type="text"
                    name="salary"
                    // pattern="^\d"
                    title="Enter numbers only"
                    onChange={handleInputChange}
                    placeholder="Enter Salary"
                    required
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-file">
                    Upload Image *
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                    id="input-file"
                    accept="image/*"
                    name="files"
                    onChange={handleFileChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-location"
                  >
                    Address *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-location"
                    type="text"
                    name="address"
                    onChange={handleInputChange}
                    placeholder="Location"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "center" }}>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>




      <Modal isOpen={showEmailClientModal} toggle={toggleNewEmail}>
        <ModalHeader toggle={toggleNewEmail}>
          <h2 style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
            Email
          </h2>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={EmailSubmit}>
            <FormGroup>
              <Input
                type="email"
                id="hobbyInput"
                placeholder="Enter email"
                value={hobbyInput}
                onChange={handleHobbyInputChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent form submission
                    handleAddHobby(); // Call function to add email
                  }
                }}
              />

            </FormGroup>
            <div id="addedHobbies" className="d-flex flex-wrap">
              {/* Display added hobbies */}
              {addedHobbies.emails.map((hobby, index) => (
                <div key={index} className="mr-2 mb-2 d-flex align-items-center">
                  {/* <span className="badge badge-primary mr-2">{hobby}<button */}
                  <span className=" mr-2 p-2"style={{borderRadius:'20px',border:'2px',backgroundColor:'#e5e9fb',color:'#2644e9'}}>{hobby}<button

                    type=""
                    className="btn btn-sm "
                    onClick={() => handleRemoveHobby(index)}
                  >
                    <i className="fas fa-times"></i>
                  </button></span>
                </div>
              ))}
            </div>

            <Button color="primary" type="submit">
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>


      {renderViewMoreModal()}
    </>
  );
};

export default Employees3;
