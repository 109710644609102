import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2'; // Import Bar instead of Pie
import { api } from '../../../api'; // Import api module correctly

const CircleChart = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    api.get('countstockitemscategorywiseSTM.php')
      .then(response => {
        const result = response.data;
        if (result.status === 'success') {
          const categoryData = result.category_counts.map(item => ({
            label: item.item_category,
            value: parseInt(item.item_count)
          }));

          setData({
            labels: categoryData.map(item => item.label),
            datasets: [{
              label: 'Item Counts', // Label for the dataset
              data: categoryData.map(item => item.value),
              backgroundColor: ['#1d6f1d', '#B90000', '#FFAA1D', '#0C4CA3', '#00A8E8', '#FFD300', '#FF6F61', '#008744', '#6A0DAD', '#FF69B4', '#FCB900'], // You can add more colors if needed
            }],
          });
        } else {
          console.error('Error fetching data:', result.message);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div>
      {data ? (
        <Bar
          data={data}
          options={{
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  min: 0, // Set a minimum value for the y-axis scale
                }
              }]
            }
          }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default CircleChart;



