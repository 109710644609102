import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Badge,
} from "reactstrap";
import { api } from "../../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const Viewtask2 = () => {
  const { id } = useParams();

  const [taskStatus, setTaskStatus] = useState({
    t_id: id,
    status: "",
  });

  const [report, setreport] = useState({
    t_id: id,
    report_issue: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setreport((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(report);
  };

  const sumbitreport = async () => {
    try {
      const response = await api.post("report_issue.php", report);
      console.log("Data sent successfully:", response.data);
      if (response.data == "Report issue updated successfully") {
        alert("Report updated, Now updated status");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const [viewtask, setviewtask] = useState();
  const [loading, setLoading] = useState(true);
  const [animating, setAnimating] = useState(false);
  const [profileImages, setProfileImages] = useState([]);
  const toggleDropdown2 = () => setDropdownOpen2((prevState) => !prevState);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  console.log(profileImages);

  const [uploadedImages, setUploadedImages] = useState([]);
  const renderUploadedFiles = () => {
    return profileImages.map((image, index) => (
      <div key={index}>
        <p>
          Uploaded Image {index + 1}: {image.name}
        </p>
      </div>
    ));
  };

  const [color, setcolor] = useState("#F7D800");

  useEffect(() => {
    if (viewtask && viewtask.length > 0) {
      const taskStatus = viewtask[0].status;
      switch (taskStatus) {
        case "to-do":
          setcolor("red");
          break;
        case "In-Progress":
          setcolor("#F7D800");
          break;
        case "completed":
          setcolor("#008000");
          break;
        default:
          setcolor("#F7D800");
          break;
      }
    }
  }, [viewtask]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`viewTask.php?t_id=${id}`);
        setviewtask(response.data);
        // setProfileImages(response.data[0].output_images);
        console.log(response.data);
        setTaskStatus((prevState) => ({
          ...prevState,
          status: response.data.status,
        }));
        console.log(viewtask);
        console.log("shahsi");
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  if (loading || viewtask === null) {
    return <div>Loading...</div>; // Display loading indicator while data is being fetched
  }

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const fileList = Object.values(files);
    setProfileImages((prevImages) => [...prevImages, ...fileList]);
    console.log(fileList);
  };

  const imagesString = viewtask[0]?.output_images;
  const imagesArray = imagesString ? imagesString.split(",") : [];

  const next = () => {
    if (animating || imagesArray.length === 0) return;
    const nextIndex =
      activeIndex === imagesArray.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating || imagesArray.length === 0) return;
    const nextIndex =
      activeIndex === 0 ? imagesArray.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating || imagesArray.length === 0) return;
    setActiveIndex(newIndex);
  };

  const slides = imagesArray
    .map((image, index) => {
      // Check if the image exists
      try {
        require(`../../../images/${image}`);
      } catch (error) {
        // Handle the case where the image doesn't exist
        console.error(`Image ${image} not found`);
        return null; // Return null to indicate that the slide should be skipped
      }

      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={index}
        >
          <div
            className="profile-image-container"
            style={{
              width: "376px",
              height: "256px",
              border: "2px solid black",
              borderRadius: "9%",
              overflow: "hidden",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
              margin: "0 auto",
            }}
          >
            <img
              id=""
              alt={`Uploaded Image ${index + 1 + image}`}
              src={require(`../../../images/${image}`)}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
          <CarouselCaption captionText="" captionHeader="" />
        </CarouselItem>
      );
    })
    .filter((slide) => slide !== null); // Filter out null values

  // Now, use the slides array as needed

  const sumbitImages = async (e) => {
    try {
      if (profileImages.length > 0) {
        const formData = new FormData();
        profileImages.forEach((image, index) => {
          formData.append(`image[${index}]`, image);
        });
        formData.append("t_id", id);

        const response = await api.post(`multileimageoutput.php`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          console.log("Images uploaded successfully");
          console.log(response.data);
          window.location.reload();
        } else {
          console.log("Failed to upload images. Status:", response.status);
        }
      } else {
        console.log("No images to upload");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const statusAction = async (status) => {
    const data = {
      t_id: id,
      status: status,
    };

    try {
      const response = await api.post("viewTask.php", data);
      console.log("Data sent successfully:", response.data);
      // You may want to reload the page here or handle the response in some other way
      if (response.data.message === "Please upload output image") {
        alert("Please upload output image");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Col className="order-xl-2 mb-5 mb-xl-0 text-center  mb-5">
          <h1>View Task Details</h1>
        </Col>
        <Link to="/site-admin/site-management/task2">
          {" "}
          <Button className="ml-5">Back</Button>
        </Link>
        <Container
          className="py-4 d-flex "
          style={{ justifyContent: "center" }}
        >
          <div
            className="p-4 rounded w-100"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid black",
            }}
          >
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <div className="">
                    <label
                      className="form-control-label"
                      htmlFor="input-status"
                    >
                      Project Status
                    </label>
                  </div>
                  <Badge
                    style={{
                      backgroundColor: color,
                      color: "black",
                      height: "35px",
                      width: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {viewtask[0].status}
                  </Badge>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-status"
                    >
                      Task Status
                    </label>
                    <Dropdown
                      className="mb-2"
                      isOpen={dropdownOpen2}
                      toggle={toggleDropdown2}
                    >
                      <DropdownToggle caret>Task Status</DropdownToggle>
                      <DropdownMenu>
                        {viewtask[0].status === "to-do" && (
                          <DropdownItem
                            onClick={() => statusAction("In-Progress")}
                          >
                            In-Progress
                          </DropdownItem>
                        )}

                        {viewtask[0].status !== "completed" &&
                        viewtask[0].status !== "to-do" ? (
                          viewtask[0].overdue_status !== "overdue" ||
                          viewtask[0].report_issue !== "" ? (
                            <DropdownItem
                              onClick={() => statusAction("completed")}
                            >
                              Complete
                            </DropdownItem>
                          ) : (
                            <DropdownItem>Please Report</DropdownItem>
                          )
                        ) : (
                          viewtask[0].overdue_status === "overdue" &&
                          viewtask[0].report_issue !== "" &&
                          viewtask[0].status !== "completed" &&
                          viewtask[0].status !== "to-do" && (
                            <DropdownItem>Please Report</DropdownItem>
                          )
                        )}

                        {viewtask[0].status === "completed" && (
                          <DropdownItem
                            onClick={() => statusAction("In-Progress")}
                          >
                            In-Progress
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <Row className="justify-content-center align-items-center mb-4">
                <Col lg="12" className="text-center mb-3">
                  <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                  >
                    <CarouselIndicators
                      items={imagesArray}
                      activeIndex={activeIndex}
                      onClickHandler={goToIndex}
                    />

                    {slides}

                    <CarouselControl
                      direction="prev"
                      directionText="Previous"
                      onClickHandler={previous}
                    />
                    <CarouselControl
                      direction="next"
                      directionText="Next"
                      onClickHandler={next}
                    />
                  </Carousel>
                </Col>
                <Col lg="12" className="text-center">
                  {/* {renderedImages} */}
                  <div className="form-group">
                    <label htmlFor="input-upload-files">Uploaded Files:</label>
                    {renderUploadedFiles()}
                  </div>
                  <Button
                    color="info"
                    onClick={() =>
                      document.getElementById("input-profile-image").click()
                    }
                  >
                    <FontAwesomeIcon icon={faPencilAlt} /> Upload Your work
                  </Button>
                  <Button color="success" onClick={() => sumbitImages()}>
                    submit
                  </Button>
                  <FormGroup style={{ display: "none" }}>
                    <Input
                      type="file"
                      id="input-profile-image"
                      accept="image/*"
                      onChange={handleFileUpload}
                      multiple // Allow multiple file selection
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <label
                      className="border p-2 rounded font-weight-bold"
                      style={{ width: "155px" }}
                    >
                      Project Name
                    </label>
                    <label className=" p-2 rounded font-weight-bold m-1 ">
                      :
                    </label>
                    <label className="border p-2 rounded ">
                      {viewtask[0].p_name}
                    </label>
                  </div>
                  <div>
                    <label
                      className="border p-2 rounded font-weight-bold "
                      style={{ width: "155px" }}
                    >
                      Task Name
                    </label>
                    <label className=" p-2 rounded font-weight-bold m-1 ">
                      :
                    </label>
                    <label className="border p-2 rounded ">
                      {viewtask[0].taskname}
                    </label>
                  </div>
                  <div>
                    <label
                      className="border p-2 rounded font-weight-bold "
                      style={{ width: "155px" }}
                    >
                      Assigned To
                    </label>
                    <label className=" p-2 rounded font-weight-bold m-1 ">
                      :
                    </label>
                    <label className="border p-2 rounded ">
                      {viewtask[0].emp_name}
                    </label>
                  </div>
                  <div>
                    <label
                      className="border p-2 rounded font-weight-bold "
                      style={{ width: "155px" }}
                    >
                      Assigned By
                    </label>
                    <label className=" p-2 rounded font-weight-bold m-1 ">
                      :
                    </label>
                    <label className="border p-2 rounded ">
                      {viewtask[0].assignee_name}
                    </label>
                  </div>
                  <div>
                    <label
                      className="border p-2 rounded font-weight-bold "
                      style={{ width: "155px" }}
                    >
                      Start Date
                    </label>
                    <label className=" p-2 rounded font-weight-bold m-1 ">
                      :
                    </label>
                    <label className="border p-2 rounded ">
                      {viewtask[0].start_date}
                    </label>
                  </div>
                  <div>
                    <label
                      className="border p-2 rounded font-weight-bold "
                      style={{ width: "155px" }}
                    >
                      Ending Date
                    </label>
                    <label className=" p-2 rounded font-weight-bold m-1 ">
                      :
                    </label>
                    <label className="border p-2 rounded ">
                      {viewtask[0].end_date}
                    </label>
                  </div>
                  <div>
                    <label
                      className="border p-2 rounded font-weight-bold "
                      style={{ width: "155px" }}
                    >
                      Description
                    </label>
                    <label className=" p-2 rounded font-weight-bold m-1 ">
                      :
                    </label>
                    <label className="border p-2 rounded ">
                      {viewtask[0].t_description.replace(/<\/?[^>]+(>|$)/g, "")}
                    </label>
                  </div>
                </div>
              </div>

              {viewtask[0] &&
                viewtask[0].overdue_status === "overdue" &&
                viewtask[0].report_issue === "" &&
                viewtask[0].status !== "completed" &&
                viewtask[0].status !== "to-do" && (
                  <>
                    <h3>Report</h3>
                    <textarea
                      className="form-control"
                      // onChange={handleTextChange}
                      rows={5} // Optional: number of visible text lines
                      cols={30} // Optional: number of visible text columns
                      placeholder="Enter your issue..."
                      name="report_issue"
                      onChange={handleInputChange}
                      required
                    />
                    <Button
                      className="mt-2 "
                      color="danger"
                      onClick={() => sumbitreport()}
                    >
                      Report
                    </Button>
                  </>
                )}
            </Form>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Viewtask2;
