import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col,
    Progress,
    CardImg,
    Button,
    UncontrolledTooltip,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Table, Badge, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, CardHeader, Label,
} from "reactstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";




import { api } from '../../../../api';

import * as XLSX from 'xlsx';
import { useNavigate } from "react-router-dom/dist";

const Wasteage4 = () => {

    const [totalEmployees, setTotalEmployees] = useState(0);
    const [onTime, setOnTime] = useState(0);
    const [late, setLate] = useState(0);
    const [leaves, setLeaves] = useState(0);

    const navigate = useNavigate();

    const [ViewProjectName, setViewProjectName] = useState('');
    const [showViewConfirmation, setShowViewConfirmation] = useState(false);

    const [deleteProjectName, setDeleteProjectName] = useState('');

    const allData = [
        { slNo: 1, wastedItems: 10, replacedItems: 20, costSpent: 3000, wasteIncome: 1500, },
        { slNo: 2, wastedItems: 8, replacedItems: 25, costSpent: 3500, wasteIncome: 1800, },
        { slNo: 3, wastedItems: 15, replacedItems: 18, costSpent: 2800, wasteIncome: 1300, },
        { slNo: 4, wastedItems: 12, replacedItems: 22, costSpent: 3200, wasteIncome: 1600, },
        { slNo: 5, wastedItems: 9, replacedItems: 21, costSpent: 3100, wasteIncome: 1400, },
        { slNo: 6, wastedItems: 11, replacedItems: 23, costSpent: 3300, wasteIncome: 1700, },
        { slNo: 7, wastedItems: 14, replacedItems: 19, costSpent: 2900, wasteIncome: 1200, },
        { slNo: 8, wastedItems: 13, replacedItems: 24, costSpent: 3400, wasteIncome: 1500, },
        { slNo: 9, wastedItems: 16, replacedItems: 17, costSpent: 2700, wasteIncome: 1100, },
        { slNo: 10, wastedItems: 18, replacedItems: 16, costSpent: 2600, wasteIncome: 1000, },
        { slNo: 11, wastedItems: 20, replacedItems: 15, costSpent: 2500, wasteIncome: 900, },
        { slNo: 12, wastedItems: 19, replacedItems: 14, costSpent: 2400, wasteIncome: 800, },
        { slNo: 13, wastedItems: 17, replacedItems: 13, costSpent: 2300, wasteIncome: 700, },
        { slNo: 14, wastedItems: 16, replacedItems: 12, costSpent: 2200, wasteIncome: 600, },
        { slNo: 15, wastedItems: 14, replacedItems: 11, costSpent: 2100, wasteIncome: 500, },
        { slNo: 16, wastedItems: 12, replacedItems: 10, costSpent: 2000, wasteIncome: 400, },
        { slNo: 17, wastedItems: 10, replacedItems: 9, costSpent: 1900, wasteIncome: 300, },
        { slNo: 18, wastedItems: 8, replacedItems: 8, costSpent: 1800, wasteIncome: 200, },
        { slNo: 19, wastedItems: 6, replacedItems: 7, costSpent: 1700, wasteIncome: 100, },
        { slNo: 20, wastedItems: 4, replacedItems: 6, costSpent: 1600, wasteIncome: 0, }
    ];

    const [replacedata, setreplacedata] = useState();
    const [wastecount, setwastecount] = useState();
    const [wastedata, setwastedata] = useState();


    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
            const response = await api.get(`wasteitemaddSTM.php?repalce='replace'`);
            setreplacedata(response.data);
            const response2 = await api.post(`wasteCount.php`);
            setwastecount(response2.data);
            const response3 = await api.get(`wasteitemaddSTM.php?waste='waste'`);
            setwastedata(response3.data);
            setLoading(false);
            console.log(wastecount.data.replacement.count)
          } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []); 




    const [showRoleModal, setShowRoleModal] = useState(false);

    // const toggleRole = () => {
    //     setShowRoleModal(!showRoleModal);
    // };

    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);



    // Add searchQuery as dependency


    // const [role, setrole] = useState();

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setrole((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //     }));

    //     console.log(role)
    // };

    const [type, settype] = useState();

    

    const [sendWastedata , setsendWastedata] = useState({
        'waste_type' : type,
        'itemwaste' : '',
        'wasteimage': ''
    });

    useEffect(() => {
        setsendWastedata({
            ...sendWastedata,
            'waste_type': type
        });
    }, [type]);


    const handleInputChange1 = (e) => {
        const { name, value } = e.target;
        setsendWastedata((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        console.log(sendWastedata);
    };

    const handleFileChange1 = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        setsendWastedata((prevState) => ({
          ...prevState,
          [name]: file,
        }));
        console.log(sendWastedata);
      };

    const [sendReplacedata, setsendReplacedata] = useState(
        {
            'waste_type' : type,
            'replaceItemName' : '',
            'replaceImage' : null,
            'costSpent' : '',
            'revenue' : '',
            'newreplaceImage' : null
        }
    );

    useEffect(() => {
        setsendReplacedata({
            ...sendReplacedata,
            'waste_type': type
        });
    }, [type]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setsendReplacedata((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        console.log(sendReplacedata)
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        setsendReplacedata((prevState) => ({
          ...prevState,
          [name]: file,
        }));
      };




    const handleFormSubmit = async (e) => {
        // e.preventDefault();

        let data = null;
        if(type == "waste"){
            const formdatawaste = new FormData();
            formdatawaste.append("waste_type",sendWastedata.waste_type);
            formdatawaste.append("itemwaste",sendWastedata.itemwaste);
            formdatawaste.append("wasteimage",sendWastedata.wasteimage);

            data = formdatawaste;
        }else{
            const formdatareplace = new FormData();
            formdatareplace.append("waste_type",sendReplacedata.waste_type);
            formdatareplace.append("replaceItemName",sendReplacedata.replaceItemName);
            formdatareplace.append("replaceImage",sendReplacedata.replaceImage);
            formdatareplace.append("costSpent",sendReplacedata.costSpent);
            formdatareplace.append("newreplaceImage",sendReplacedata.newreplaceImage);
            formdatareplace.append("revenue",sendReplacedata.revenue);
            data = formdatareplace;
        }

        try {
            const response = await api.post("wasteitemaddSTM.php", data);
            console.log("Data sent successfully:", response.data);
            window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };





    const toggleDropdown = (index) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }

    // Pgination code from here
    const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
    const [entriesToShow, setEntriesToShow] = useState(5); // State variable for selected number of entries
    const [dataToShow, setDataToShow] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showWasteFields, setShowWasteFields] = useState(false);
    const [showReplaceFields, setShowReplaceFields] = useState(false);
    const [View1ProjectName, setView1ProjectName] = useState('');



    useEffect(() => {

        setDataToShow(allData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
    }, [entriesToShow, currentPage, allData.length]);



    const toggleProjectDropdown = () => {
        setProjectDropdownOpen(!projectDropdownOpen);
    };

    const handleEntriesChange = (value) => {
        setEntriesToShow(Number(value));
    };

    const handleDropdownToggle = (index) => {
        setDataToShow((prevData) => {
            const newData = [...prevData];
            newData[index].dropdownOpen = !newData[index].dropdownOpen;
            return newData;
        });
    };

    const prePage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < Math.ceil(allData.length / entriesToShow)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const changeCPage = (n) => {
        setCurrentPage(n);
    };

    const handleDownload = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = 'replace_item_list';
    
        // Convert dataToShow to the format needed for XLSX.utils.json_to_sheet
        const formattedData = replacedata.map((item, index) => ({
            'Sl.No': ++index,
            'Replaced Items': item.replace_item_name,
            'Cost Spent': item.cost,
            'Waste Income': item.revenue_generated,
            'Image Link': item.replace_item_image // Assuming 'replace_item_image' contains the image URL
        }));
    
        // Create a worksheet
        const ws = XLSX.utils.json_to_sheet(formattedData, {
            header: ['Sl.No', 'Replaced Items', 'Cost Spent', 'Waste Income', 'Image Link'],
        });
    
        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Waste List');
    
        // Apply bold and background color to headers
        const headerCellStyle = { font: { bold: true }, fill: { bgColor: { indexed: 64 } } };
        Object.keys(ws).filter((key) => key.startsWith('A1')).forEach((key) => {
            ws[key].s = headerCellStyle;
        });
    
        // Convert the workbook to a binary Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
        // Create a Blob from the Excel buffer
        const excelData = new Blob([excelBuffer], { type: fileType });
    
        // Create a URL for the Blob
        const excelUrl = URL.createObjectURL(excelData);
    
        // Create a link and trigger the download
        const link = document.createElement('a');
        link.href = excelUrl;
        link.download = fileName + fileExtension;
        document.body.appendChild(link);
        link.click();
    
        // Cleanup
        setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(excelUrl);
        }, 100);
    };
    
    

    const getSerialNumber = (index) => {
        return (currentPage - 1) * entriesToShow + index + 1;
    };




    const toggleRole = () => {
        setShowRoleModal(!showRoleModal);
        setShowWasteFields(false); // Close waste fields when modal toggles
        setShowReplaceFields(false); // Close replace fields when modal toggles
    };


    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const handleDeleteClick = (itemId) => {
        setSelectedItemId(itemId);
        setShowDeleteConfirmation(true);
    };

    const handleDeleteConfirmation = () => {
        const data = {
            id : deleteProjectName,
            waste_type : 'replace'
        }
        const fetchData = async () => {
            try { 
              const response = await api.post(`wasteitemdeleteSTM.php`, data);  
              console.log(response.data); 
              window.location.reload();
            } catch (error) {  
              console.error("Error fetching data:", error); 
            }  
          };  
          fetchData();
        setShowDeleteConfirmation(false);
    };


    const [projectDropdownOpen1, setProjectDropdownOpen1] = useState(false);


    const toggleProjectDropdown1 = () => {
         setProjectDropdownOpen1(!projectDropdownOpen1);
     };
     
     
     const handleEntriesChange1 = (value) => {
         setEntriesToShow1(Number(value));
     };

     const [entriesToShow1, setEntriesToShow1] = useState(10); // State variable for selected number of entries
     const [dataToShow1, setDataToShow1] = useState([]);
     const [currentPage1, setCurrentPage1] = useState(1);
     const [showView1Confirmation, setShowView1Confirmation] = useState(false);
     const [delete1ProjectName, setDelete1ProjectName] = useState('');
     const [showDelete1Confirmation, setShowDelete1Confirmation] = useState(false);
     
     
       const allData1 = [
         { hello: "1", world: "Image1",  },
         { hello: "2", world: "Image2",  },
         { hello: "3", world: "Image3",  },
         { hello: "4", world: "Image4",  },
         { hello: "5", world: "Image5",  },
         { hello: "6", world: "Image6",  },
     ]
     
     
     
     const handleView1Confirmation = () => {
         console.log("View");
         setShowView1Confirmation(false);
     };
     
     const [wastedelete, setwastedelete] = useState();
      const handleDelete1Confirmation = () => {
         const data = {
            id : wastedelete,
            waste_type : 'waste'
        }
        const fetchData = async () => {
            try { 
              const response = await api.post(`wasteitemdeleteSTM.php`, data);  
              console.log(response.data); 
              window.location.reload();
            } catch (error) {  
              console.error("Error fetching data:", error); 
            }  
          };  
          fetchData();
         setShowDelete1Confirmation(false);
     };
     
     
      const prePage1 = () => {
         if (currentPage1 > 1) {
             setCurrentPage1(currentPage1 - 1);
         }
     };
     
      const nextPage1 = () => {
         if (currentPage1 < Math.ceil(allData1.length / entriesToShow1)) {
             setCurrentPage1(currentPage1 + 1);
         }
     };
     
     
     const changeCPage1 = (n) => {
         setCurrentPage1(n);
     };

     



    return (
        <div className="header bg-gradient-info py-7 py-lg-8">
            <Container fluid>

                <Row className="d-flex justify-content-center align-items-center">

                    <Col lg="6" xl="3" className="mb-3">
                        <Card className="card-stats mb-4 mb-xl-0" style={{ borderRadius: '30px' }}>
                            <CardBody>
                                <div className="d-flex justify-content-between ">
                                    <div>
                                        <CardTitle className="h4 mb-0">Total Wastes</CardTitle>
                                    </div>
                                    <div style={{ height: '30px', width: '30px' }}>
                                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #759bff, #843cf6)' }}>
                                            <i className="fa-solid fa-person-digging fa-beat-fade"></i>
                                        </div>

                                    </div>

                                </div> <span className="h2 font-weight-bold  ml-3">{wastecount && wastecount?.data.waste.count}</span>


                            </CardBody>
                        </Card>
                    </Col>

                    {/* Completed Projects Card */}
                    <Col lg="6" xl="3" className="mb-3">
                        <Card className="card-stats mb-4 mb-xl-0" style={{ borderRadius: '30px' }}>
                            <CardBody>
                                <div className="d-flex justify-content-between ">
                                    <div>
                                        <CardTitle className="h4 mb-0">Replaced Items</CardTitle>


                                    </div>
                                    <div style={{ height: '30px', width: '30px' }}>
                                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #fc5286, #fbaaa2)' }}>

                                            <i className="fa-solid fa-calendar-days fa-spin" style={{ '--fa-animation-duration': '2s;', '--fa-fade-opacity': '0.6;' }}></i>
                                        </div>

                                    </div>

                                </div> <span className="h2 font-weight-bold  ml-3">{wastecount && wastecount?.data.replacement.count}</span>




                            </CardBody>
                        </Card>
                    </Col>



                    {/* Inprogress Projects Card */}
                    <Col lg="6" xl="3" className="mb-3">
                        <Card className="card-stats mb-4 mb-xl-0" style={{ borderRadius: '30px' }}>
                            <CardBody>
                                <div className="d-flex justify-content-between ">
                                    <div>
                                        <CardTitle className="h4 mb-0">Revenue Genarated</CardTitle>


                                    </div>
                                    <div style={{ height: '30px', width: '30px' }}>
                                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #ffc480, #ff763b)' }}>
                                            <i className="fa-solid fa-square-check fa-bounce" />
                                        </div>

                                    </div>

                                </div> <span className="h2 font-weight-bold  ml-3">{wastecount && wastecount?.data.replacement.amount}</span>


                            </CardBody>
                        </Card>
                    </Col>








                </Row>






                <div className="mt-5">

                    <Row className="mb-5">


                        <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
                            <Form className="navbar-search navbar-search-dark form-inline ml-5 d-md-flex ml-lg-auto ">
                                <FormGroup className="mb-0 ">

                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Search Stock"
                                            type="text"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}


                                        />
                                    </InputGroup>

                                </FormGroup>
                            </Form>
                            <Button className="ml-4" onClick={toggleRole}>
                                Add New Waste
                            </Button>
                        </Col>
                    </Row>




                    <Modal isOpen={showRoleModal} toggle={toggleRole}>
                        <ModalHeader toggle={toggleRole}>
                            <h2 style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
                                New Waste
                            </h2>
                        </ModalHeader>
                        <ModalBody>
                            <Form onSubmit={handleFormSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup check>
                                            <Input
                                                type="checkbox"
                                                id="wasteCheckbox"
                                                checked={showWasteFields}
                                                onChange={() => {
                                                    settype('waste');
                                                    setShowWasteFields(true);
                                                    setShowReplaceFields(false); 
                                                }}
                                            />
                                            <Label check for="wasteCheckbox">
                                                Waste
                                            </Label>
                                        </FormGroup>
                                        {showWasteFields && (
                                            <>
                                                <FormGroup>
                                                    <Label
                                                        className="form-control-label"
                                                        htmlFor="input-waste-item-name"
                                                    >
                                                        Waste Item Name
                                                    </Label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-waste-item-name"
                                                        type="text"
                                                        name="itemwaste"
                                                        onChange={handleInputChange1}
                                                        placeholder="Enter Waste Item Name"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label
                                                        className="form-control-label"
                                                        htmlFor="input-waste-image"
                                                    >
                                                        Upload Waste Image
                                                    </Label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-waste-image"
                                                        type="file"
                                                        name="wasteimage"
                                                        onChange={handleFileChange1}
                                                    />
                                                </FormGroup>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup check>
                                            <Input
                                                type="checkbox"
                                                id="replaceCheckbox"
                                                checked={showReplaceFields}
                                                onChange={() => {
                                                    setShowWasteFields(false);
                                                    setShowReplaceFields(true);
                                                    settype('replace');
                                                }}

                                            />
                                            <Label check for="replaceCheckbox">
                                                Replace
                                            </Label>
                                        </FormGroup>
                                        {showReplaceFields && (
                                            <>
                                                <FormGroup>
                                                    <Label
                                                        className="form-control-label"
                                                        htmlFor="input-replace-item-name"
                                                    >
                                                        Replace Item Name
                                                    </Label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-replace-item-name"
                                                        type="text"
                                                        name="replaceItemName"
                                                        onChange={handleInputChange}
                                                        placeholder="Enter Name of the item to be replaced"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label
                                                        className="form-control-label"
                                                        htmlFor="input-cost-spent"
                                                    >
                                                        Enter Cost Spent
                                                    </Label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-cost-spent"
                                                        type="number"
                                                        name="costSpent"
                                                        onChange={handleInputChange}
                                                        placeholder="Enter the Cost"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label
                                                        className="form-control-label"
                                                        htmlFor="input-replace-image"
                                                    >
                                                        Upload Replaced Item Image
                                                    </Label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-replace-image"
                                                        type="file"
                                                        name="replaceImage"
                                                        onChange={handleFileChange}
                                                    />
                                                </FormGroup>
                                                <hr />

                                                <FormGroup>
                                                    <Label
                                                        className="form-control-label"
                                                        htmlFor="input-cost-spent"
                                                    >
                                                        Enter the Revenue Generated from Waste
                                                    </Label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-cost-spent"
                                                        type="number"
                                                        name="revenue"
                                                        onChange={handleInputChange}
                                                        placeholder="Enter the Cost"
                                                    />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label
                                                        className="form-control-label"
                                                        htmlFor="input-replace-image"
                                                    >
                                                        Upload The Image Of New Item
                                                    </Label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-replace-image"
                                                        type="file"
                                                        name="newreplaceImage"
                                                        onChange={handleFileChange}
                                                    />
                                                </FormGroup>


                                            </>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12" style={{ textAlign: "center" }}>
                                        <Button color="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>



                </div>
                <React.Fragment>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardBody>

                                    <CardHeader className="border-0">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <h3 className="mb-0">Maintainance List</h3>
                                            </div>
                                            <div className="col text-right">
                                                <Dropdown isOpen={projectDropdownOpen} toggle={toggleProjectDropdown}>
                                                    <DropdownToggle caret>
                                                        Show Entries: {entriesToShow === allData.length ? "All" : entriesToShow}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => handleEntriesChange(5)}>5</DropdownItem>
                                                        <DropdownItem onClick={() => handleEntriesChange(10)}>10</DropdownItem>
                                                        <DropdownItem onClick={() => handleEntriesChange(allData.length)}>All</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                                <button className="btn btn-success mr-2" onClick={handleDownload}>Download</button>

                                            </div>
                                        </Row>
                                    </CardHeader>

                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr style={{ textAlign: "center" }}>

                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Sl.No </th>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Replaced Items</th>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Cost Spent</th>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Waste Income </th>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> View </th>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Delete </th>


                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "center" }}>
                                            {replacedata && replacedata.map((item, index) => (
                                                <tr key={index}>
                                                    <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="row">{++index}</th>
                                                    <td>{item.replace_item_name}</td>
                                                    <td>{item.cost}</td>
                                                    <td>{item.revenue_generated}</td>
                                                    <td onClick={() => { setViewProjectName(item); setShowViewConfirmation(true) }}>
                                                        <i className="fas fa-eye text-primary"></i>
                                                    </td>
                                                    <td onClick={() => { setDeleteProjectName(item.replace_id); setShowDeleteConfirmation(true) }}>

                                                        <i className="fas fa-trash text-danger"></i>

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                    <nav className='mt-3'>
                                        <ul className='pagination justify-content-center '>
                                            <li className='page-item '>
                                                <a href='#' className='page-link text-primary' onClick={prePage}>Prev</a>
                                            </li>
                                            {[currentPage - 1, currentPage, currentPage + 1].map((pageNumber) => {
                                                if (pageNumber > 0 && pageNumber <= Math.ceil(allData.length / entriesToShow)) {
                                                    return (
                                                        <li key={pageNumber} className='page-item'>
                                                            <a href='#' className={`page-link text-white ${pageNumber === currentPage ? 'active' : ''}`} style={{ backgroundColor: '#5E72E4' }} onClick={() => changeCPage(pageNumber)}>{pageNumber}</a>
                                                        </li>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <li className='page-item'>
                                                <a href='#' className='page-link text-primary' onClick={nextPage}>Next</a>
                                            </li>
                                        </ul>
                                    </nav>

                                </CardBody>
                            </Card>
                        </Col>


                    </Row>
                </React.Fragment>

                <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12" style={{ marginTop: "-20px" }}>
                            <Card className="shadow">
                                <CardBody >
                                    <CardHeader className="border-0">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <h3 className="mb-0">Waste Details</h3>
                                            </div>
                                            <div className="col text-right">
                                                <Dropdown isOpen={projectDropdownOpen1} toggle={toggleProjectDropdown1}>
                                                    <DropdownToggle caret>
                                                        Show Entries: {entriesToShow}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => handleEntriesChange1(5)}>5</DropdownItem>
                                                        <DropdownItem onClick={() => handleEntriesChange1(10)}>10</DropdownItem>
                                                        <DropdownItem onClick={() => handleEntriesChange1(50)}>50</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>

                                            </div>

                                        </Row>
                                    </CardHeader>
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col">SL NO</th>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col">Waste Items</th>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col">View</th>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col">Detele</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {wastedata && wastedata.map((item, index) => (
                                                <tr key={index}>
                                                    <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="row">{++index}</th>
                                                    <td>{item.item_name}</td>

                                                    <td onClick={() => { setView1ProjectName(item); setShowView1Confirmation(true) }}>
                                                        <i className="fas fa-eye text-primary"></i>
                                                    </td>
                                                    <td onClick={() => { setwastedelete(item.waste_id); setShowDelete1Confirmation(true) }}>

                                                        <i className="fas fa-trash text-danger"></i>

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <nav className='mt-3'>
                                        <ul className='pagination justify-content-center '>
                                            <li className='page-item '>
                                                <a href='#' className='page-link text-primary' onClick={prePage1}>Prev</a>
                                            </li>
                                            {[currentPage - 1, currentPage, currentPage + 1].map((pageNumber) => {
                                                if (pageNumber > 0 && pageNumber <= Math.ceil(allData1.length / entriesToShow)) { // Check if pageNumber is within valid range
                                                    return (
                                                        <li key={pageNumber} className='page-item'>
                                                            <a href='#' className={`page-link text-white ${pageNumber === currentPage ? 'active' : ''}`} style={{ backgroundColor: '#5E72E4' }} onClick={() => changeCPage1(pageNumber)}>{pageNumber}</a>
                                                        </li>
                                                    );
                                                }
                                                return null; // If pageNumber is out of range, return null
                                            })}
                                            <li className='page-item'>
                                                <a href='#' className='page-link text-primary' onClick={nextPage1}>Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
            </Container>


            {/* Add New Maintainance and its modal */}



            {/* Maintainance Record Table */}

            <Modal isOpen={showDeleteConfirmation} toggle={() => setShowDeleteConfirmation(false)}>
                            <ModalHeader>Confirm Deletion</ModalHeader>
                            <ModalBody>
                                Are you sure you hi to delete this "{deleteProjectName}"?
                            </ModalBody>
                            <div className="modal-footer">
                                <Button color="danger" onClick={handleDeleteConfirmation}>Yes</Button>
                                <Button color="secondary" onClick={() => setShowDeleteConfirmation(false)}>No</Button>
                            </div>
            </Modal>
            <Modal isOpen={showViewConfirmation} toggle={() => setShowViewConfirmation(false)}>
                        <ModalHeader toggle={() => setShowViewConfirmation(false)} className='pb-0 mt-1'><hi>View Replacement Details</hi></ModalHeader>
                            <ModalBody>
                                {/* Are you sure you want to View this "{ViewProjectName}"? */}
                                <div className="row ">
                                    
                                  
                                       
                                        <div className="col-md-12">
                                        <div className="form-group">
                                            <h2>Item Name : {ViewProjectName.replace_item_name}</h2>
                                        </div>
                                        <div className="form-group">
                                            <h2>COST SPENT : {ViewProjectName.cost}</h2>
                                        </div>
                                        <div className="form-group">
                                            <h2>WASTE INCOME: {ViewProjectName.revenue_generated}</h2>
                                        </div>
                                        
                                    
                                    </div>
                                    <div className="col-md-6">
                                            <div className="form-group">
                                                    <h2>Old Image : </h2>
                                                    <div style={{borderRadius:'20px', border:'1px solid black', width:'210px' ,height:'102px'}}>
                                                   
                                            {ViewProjectName.replace_item_image && (
                                                <img style={{width:'208px' ,height:'100px',borderRadius:'20px' }}
                                                alt="Reversed Image" src={require(`../../../images/${ViewProjectName.replace_item_image}`)}  />
                                            )}

                                                    </div>
                                                   
                                            </div>
                                            </div>
                                    <div className="col-lg-6">
                                            <div className="form-group">
                                                <h2>New Image : </h2>
                                                <div style={{borderRadius:'20px', border:'1px solid black', width:'210px' ,height:'102px'}}>
                                                
                                            {ViewProjectName.new_item_image && (
                                                <img style={{width:'208px' ,height:'100px',borderRadius:'20px' }}
                                                alt="Reversed Image" src={require(`../../../images/${ViewProjectName.new_item_image}`)}  />
                                            )}
                                            </div>
                                            </div>
                                    </div>
                                </div>
                            </ModalBody>
                       
            </Modal>


            <Modal isOpen={showView1Confirmation} toggle={() => setShowView1Confirmation(false)}>
                            <ModalHeader toggle={() => setShowView1Confirmation(false)} className='pb-0 mt-1'><h2>VIEW WASTE ITEM</h2></ModalHeader>
                            <ModalBody>
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <h3>Item Name : {View1ProjectName.item_name}</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div>
                                                <h3>Image : </h3>
                                                 {View1ProjectName.image && (
                                                <img style={{width:'417px' ,height:'198px',borderRadius:'20px',borderRadius: '20px', border: '1px solid black' }}
                                                alt="Reversed Image" src={require(`../../../images/${View1ProjectName.image}`)}  />
                                            )}
                                            </div>
                                        </div>
                                </div>
                                </div>
                            </ModalBody>
            </Modal>

            <Modal isOpen={showDelete1Confirmation} toggle={() => setShowDelete1Confirmation(false)}>
                            <ModalHeader>Confirm Deletion</ModalHeader>
                            <ModalBody>
                                Are you sure you to delete this "{wastedelete}"?
                            </ModalBody>
                            <div className="modal-footer">
                                <Button color="danger" onClick={handleDelete1Confirmation}>Yes</Button>
                                <Button color="secondary" onClick={() => setShowDelete1Confirmation(false)}>No</Button>
                            </div>
            </Modal>


        </div>
    );
};

export default Wasteage4;
