import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    Badge,
    Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption

} from "reactstrap";
// core components
import Header from "dashboard/project-management/components/Headers/Header";
import Select from 'react-select';
import {api} from '../../../../api';



   



const Viewproject = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [showAddClientModal, setShowAddClientModal] = useState(false); // New state for Add Project modal
    const [clientForm, setClientForm] = useState({});
    
    const [selectedSiteManagers, setSelectedSiteManagers] = useState([

        { value: 'harish', label: 'harish' },
        { value: 'jyo', label: 'jyo' }
    ]);
    const [image, setImage] = useState(null);
    const [profileImage, setProfileImage] = useState(null);


 

    const toggleModal = () => setModalOpen(prevState => !prevState);
    const toggleAddClientModal = () => setShowAddClientModal(prevState => !prevState); // Function to toggle Add Project modal

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setClientForm(prevState => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log("Form submitted:", clientForm);
        // Reset form fields after submission (if needed)
        setClientForm({
            name: '',
            status: '',
            Timeline: '',
            Budget: '',
            Experiences: '',
            Reviews: '',
            Contact: '',
            Payment: '',

        });
        // Close the modal after submission (if needed)
        setShowAddClientModal(false);
    };

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [selectedManager, setSelectedManager] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');



    // Sample employee data (replace with your actual data)
    const employees = [
        { id: 1, name: 'Mine', age: 2022, location: 'Developers', position: 'Mine Description', image: require("../../assets/img/theme/team-4-800x800.jpg"), dropdownOpen: false },
        { id: 2, name: 'AecEarth', age: 2021, location: 'Career', position: 'AecEarth Description', image: require("../../assets/img/theme/team-4-800x800.jpg"), dropdownOpen: false },
        { id: 3, name: 'Swifter', age: 2000, location: 'Sales', position: 'Swifter Description', image: require("../../assets/img/theme/team-4-800x800.jpg"), dropdownOpen: false },
        { id: 4, name: 'BIM', age: 2023, location: 'Construction', position: 'BIM Description', image: require("../../assets/img/theme/team-4-800x800.jpg"), dropdownOpen: false },
        // Add more employees as needed
    ];

    // Function to toggle dropdown for a specific employee
    const toggleDropdown = (employeeId) => {
        setFilteredEmployees(prevEmployees => prevEmployees.map(employee => {
            if (employee.id === employeeId) {
                return { ...employee, dropdownOpen: !employee.dropdownOpen };
            }
            return employee;
        }));
    };

    // Function to filter employees based on search query
    const filterEmployees = () => {
        if (!searchQuery) {
            setFilteredEmployees(employees);
        } else {
            const filtered = employees.filter(employee =>
                employee.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredEmployees(filtered);
        }
    };



    // Update filtered employees when searchQuery changes
    useEffect(() => {
        filterEmployees();
    }, [searchQuery]);


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setClientForm(prevState => ({
            ...prevState,
            file: file
        }));
    };

    const handleManagerSelectChange = (selectedOption) => {
        setSelectedManager(selectedOption);
    };

    const handleLocationSelectChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
    };

    const handleSiteManagerSelectChange = (selectedOptions) => {
        setSelectedSiteManagers(selectedOptions);
        setClientForm(prevState => ({
            ...prevState,
            siteManagers: selectedOptions.map(option => option.value)
        }));
    };
    const siteOptions = [
        { value: 'harish', label: 'harish' },
        { value: 'jyo', label: 'jyo' },
        { value: 'rifwhuiw', label: 'harisrkbfkrwefh' },
        { value: 'jyrefkerwo', label: 'jrebfkjrwyo' },
    ];



    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [profileImages, setProfileImages] = useState([]);
    

    const handleFileUpload = (event) => {
        const files = event.target.files;
        const uploadedImagesArray = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onload = function (e) {
                const path = URL.createObjectURL(file);

                uploadedImagesArray.push({ name: file.name, data: path });
                if (uploadedImagesArray.length === files.length) {
                    setProfileImages(uploadedImagesArray);
                }
            };
            console.log(uploadedImagesArray);
            reader.readAsDataURL(file);
        }
    };
    
    


    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === profileImages.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? profileImages.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = profileImages.map((image, index) => (
        <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={index}
        >
            <div className="profile-image-container" style={{
                width: '376px',
                height: '256px',
                border: '2px solid black',
                borderRadius: '9%',
                overflow: 'hidden',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                margin: '0 auto',
            }}>
                <img
                    src={image.data}
                    alt={`Uploaded Image ${index + 1}`}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                />
            </div>
            <CarouselCaption captionText={image.name} captionHeader="" />
        </CarouselItem>
    ));
    
    const renderUploadedFiles = () => {
        return uploadedImages.map((file, index) => (
            <div key={index}>
                <p>File {index + 1}: {file.name}</p>
            </div>
        ));
    };
    const [uploadedImages, setUploadedImages] = useState([]);


    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const toggleDropdown2 = () => setDropdownOpen2(prevState => !prevState);
    
    const {id} = useParams();

    const [loading, setLoading] = useState(true);
    const [projectData, setprojectData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`viewProjectByID.php?p_id=${id}`);
                setprojectData(response.data);
                console.log(projectData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);


    const sumbitprojectstatus = async(status)=>{
        const data = {
            p_id : id,
            status : status
        }

        console.log(data)
        try {
            const response = await api.get("viewProjectByID.php", data);
            console.log("Data sent successfully:", response.data);
            // window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
        }

        // console.log(id + "hello" + data)
    }
    



    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Col className="order-xl-2 mb-5 mb-xl-0 text-center  mb-5">
                    <h1>View Project Details</h1>
                </Col>
                <Link to="/project-admin/project-management/project"> <Button className='ml-5'>Back</Button></Link>
                <Container className="py-4 ">
                    <div className="d-flex justify-content-center">
                        <div className="p-4 rounded w-50" style={{ backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid black', }}>
                        {projectData && projectData.length > 0 && (
                            <>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="">
                                            <label className="form-control-label" htmlFor="input-status">Project Status</label>
                                        </div>
                                        <Badge style={{ backgroundColor: '#F7D800', color: 'black',height:'35px',width:'150px',display:'flex' ,justifyContent:'center',alignItems:'center',fontWeight:'bold'}} >
                                                {projectData[0].p_status}
                                            </Badge>
                                    </div>
                                    
                                    <div className="col-md-6">
                                    <div className="form-group">
                                            <label className="form-control-label" htmlFor="input-status">Project Status</label>
                                            <Dropdown className="mb-2" isOpen={dropdownOpen2} toggle={toggleDropdown2}>
                                                <DropdownToggle caret>
                                                    Select Status
                                                </DropdownToggle>
                                                <DropdownMenu >
                                                    <DropdownItem  onClick={() => sumbitprojectstatus("In-Progress")}>In-Progress</DropdownItem>
                                                    {/* Add more DropdownItems as needed */}
                                                </DropdownMenu>
                                            </Dropdown>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="input-project-name">Project Name:</label>
                                            <p> {projectData[0].p_name}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="input-project-name">Start Date:</label>
                                            <p>{projectData[0].start_date}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="input-project-name">End Date:</label>
                                            <p>{projectData[0].end_date}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="input-project-name">Project Description:</label>
                                            <p>{projectData[0].p_description.replace(/<\/?[^>]+(>|$)/g, '')}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="input-project-name">BOQ File:</label>
                                            <h5><a href={`http://localhost/AECP/src/dashboard/images/${projectData[0].BOQ}`} download>{projectData[0].BOQ}</a></h5>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="input-project-name">BOM File:</label>
                                            <h5><a href={`http://localhost/AECP/src/dashboard/images/${projectData[0].BOM}`} download>{projectData[0].BOM}</a></h5>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="input-project-name">Files:</label>
                                            {projectData[0].files.split(',').map((file, index) => (
                                                <h5 key={index}>File {++index} : <a href={`http://localhost/AECP/src/dashboard/images/${file}`} download>{file}</a></h5>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        </div>
                    </div>
                </Container>

            </div>


        </>
    );
};
export default Viewproject;
