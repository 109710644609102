import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import {
//   FaPhone,
//   FaVideo,
//   FaEllipsisH,
//   FaPaperclip,
//   FaPaperPlane,
//   FaEllipsisV, // Add the vertical ellipsis icon
// } from 'react-icons/fa';
// import { FaFile } from 'react-icons/fa';

const Hub = ({ goBack }) => {
    const [message, setMessage] = useState('');
    const [file, setFile] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const chatContainerRef = useRef(null);

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleSendClick = () => {
        sendMessage();
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    const sendMessage = () => {
        if (message.trim() !== '' || file) {
            const newMessage = {
                text: message,
                file: file,
                sender: 'user',
                timestamp: new Date().toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                }),
            };
            setChatHistory([newMessage, ...chatHistory]);
            setMessage('');
            setFile(null);
        }
    };

    useEffect(() => {
        // Scroll to the bottom of the chat container when a new message is added
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop =
                chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    const getRandomProfilePic = () => {
        const picNumber = Math.floor(Math.random() * 5) + 1;
        return `https://example.com/profile${picNumber}.jpg`;
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleForwardClick = (content) => {
        // You can implement your forward functionality here
        console.log('Forwarding:', content);
    };

    const styles = {
        // ... other styles ...

        topRightIconContainer: {
            marginLeft: 'auto', // This will push the element to the right
            marginTop: '4px',
            // Adjust the top margin as needed
        },
    };



    return (
        <div className=" header bg-gradient-info py-7 py-lg-8">
            <Container fluid>
                <div className="maincontainer">
                    <div className="container-fluid h-100">
                        <div
                            style={{
                                fontFamily: 'Arial, sans-serif',
                                height: '100vh',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '10px',
                                    backgroundColor: '#f0f0f0',
                                    borderBottom: '1px solid #ccc',
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ cursor: 'pointer', marginRight: '10px' }}>
                                    <Link to="/project-admin/project-management/community">  <i className="fa-solid fa-right-from-bracket" style={{ transform: 'rotate(180deg)', }}></i></Link>
                                     </div>
                                    <img
                                        // src={getRandomProfilePic()}
                                        src={require('../../assets//img/theme/team-2-800x800.jpg')}
                                        alt="Profile"
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            marginRight: '10px',
                                        }}
                                    />
                                    <div>
                                        <div style={{ fontWeight: 'bold' }}>Jyosh</div>
                                        <div>
                                        <i class="fa-solid fa-lightbulb text-success m-1"></i>                                          
                                        Online
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    {/* <i class="fa-solid fa-mobile-screen"></i>
                                    <i class="fa-solid fa-camera"></i>
                                    <i class="fa-solid fa-ellipsis-vertical"></i> */}
                                </div>
                            </div>

                            <div
                                ref={chatContainerRef}
                                style={{
                                    flex: 1,
                                    overflowY: 'auto',
                                    padding: '10px',
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >


                                {chatHistory.map((msg, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            padding: '8px',
                                            margin: '8px 0',
                                            borderRadius: '5px',
                                            maxWidth: '70%',
                                            alignSelf: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                                            backgroundColor: msg.sender === 'user' ? '#DCF8C6' : '#EFEFEF',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: '12px',
                                                    color: '#777',
                                                    marginBottom: '4px',
                                                    alignSelf: 'flex-start',
                                                }}
                                            >
                                                {msg.sender === 'user' ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span>You</span>
                                                        {msg.file && (
                                                            <div style={styles.topRightIconContainer}>
                                                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                                                    <DropdownToggle tag="span" onClick={(e) => e.preventDefault()}>
                                                                        {/* <FaEllipsisV style={{ cursor: 'pointer' }} /> */}
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem onClick={() => handleForwardClick(msg)}>
                                                                            Forward
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </div>

                                                        )}
                                                    </div>
                                                ) : (
                                                    'Jyoshna'
                                                )}
                                            </div>
                                            {msg.text && (
                                                <div style={{ marginBottom: '4px' }}>
                                                    {msg.text}
                                                </div>
                                            )}
                                            {msg.file && !msg.file.type.startsWith('image/') && (
                                                <div>
                                                    {/* <FaFile style={{ marginRight: '4px' }} /> */}
                                                    <a
                                                        href={URL.createObjectURL(msg.file)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {msg.file.name}
                                                    </a>
                                                </div>
                                            )}

                                            {msg.file && msg.file.type.startsWith('image/') && (
                                                <div>
                                                    <img
                                                        src={URL.createObjectURL(msg.file)}
                                                        alt={msg.file.name}
                                                        style={{ maxWidth: '100%', maxHeight: '150px', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            window.open(URL.createObjectURL(msg.file));
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: '12px',
                                                color: '#777',
                                                marginTop: '4px',
                                                textAlign: 'end',
                                            }}
                                        >
                                            {msg.timestamp}
                                        </div>
                                    </div>
                                ))}

                            </div>

                            <div
                                style={{
                                    borderTop: '1px solid #ccc',
                                    padding: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: 'sticky',
                                    bottom: '0',
                                    backgroundColor: '#fff',
                                    zIndex: '1',
                                }}
                            >
                                <div style={{ marginRight: '10px', cursor: 'pointer' }}>
                                    <label htmlFor="fileInput">
                                        <span data-tooltip="Add Document">
                                            <i class="fa-solid fa-paperclip"></i>
                                        </span>
                                    </label>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <input
                                        type="text"
                                        placeholder="Type your message..."
                                        className="form-control-label"
                                        style={{
                                            flex: 1,
                                            padding: '8px',
                                            marginRight: '10px',
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            fontSize: '14px', // Add font size
                                            color: '#333', // Add text color
                                            /* Add more styles as needed */
                                        }}
                                        value={message}
                                        onChange={handleInputChange}
                                    />
                                    

                                    {file && (
                                        <div style={{ marginRight: '10px', fontSize: '12px', color: '#777' }}>
                                            {file.name}
                                        </div>
                                    )}
                                </div>

                                <button
                                    style={{
                                        backgroundColor: '#4CAF50',
                                        color: '#fff',
                                        padding: '8px 16px',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleSendClick}
                                >
                                    <i class="fa-solid fa-paper-plane"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Hub;
