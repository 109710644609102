import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col,
    Progress,
    CardImg,
    Button,
    UncontrolledTooltip,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Table, Badge, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, CardHeader, Label, ModalFooter
} from "reactstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import SampleInvoice4 from "./SampleInvoice4";
import { saveAs } from 'file-saver';
import { render } from '@react-pdf/renderer';
import { FaTrash } from "react-icons/fa";



import { api } from '../../../../api';

import * as XLSX from 'xlsx';
import { useNavigate } from "react-router-dom/dist";

const Invoice4 = () => {

    const [totalEmployees, setTotalEmployees] = useState(0);
    const [onTime, setOnTime] = useState(0);
    const [late, setLate] = useState(0);
    const [leaves, setLeaves] = useState(0);

    const [getInvoice, setGetInvoice] = useState([])
    console.log('get', getInvoice);
    const [filteredData, setFilteredData] = useState([])
    const [originalData, setOriginalData] = useState([]);

    console.log('og', originalData);

    const navigate = useNavigate();


    const [showRoleModal, setShowRoleModal] = useState(false);

    // const toggleRole = () => {
    //     setShowRoleModal(!showRoleModal);
    // };

    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);



    // Add searchQuery as dependency


    const [role, setrole] = useState();




    const handleStatusEdit = async (id, status) => {
        const data = {
            id: id,
            status: status
        }
        try {
            const response = await api.post('', data);
            console.log(response.data);
        } catch (error) {
            console.error('Error updating status:', error);
        }
        navigate('/store-admin/store-management/Editwaste4')
    };

    const toggleDropdown = (index) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }

    // Pgination code from here
    const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
    const [entriesToShow, setEntriesToShow] = useState(5); // State variable for selected number of entries
    const [dataToShow, setDataToShow] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showWasteFields, setShowWasteFields] = useState(false);
    const [showReplaceFields, setShowReplaceFields] = useState(false);

    const totalPages = Math.ceil(filteredData.length / entriesToShow);

    useEffect(() => {
        if (Array.isArray(filteredData)) {
            setDataToShow(filteredData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
        }
    }, [entriesToShow, currentPage, filteredData]);



    const toggleProjectDropdown = () => {
        setProjectDropdownOpen(!projectDropdownOpen);
    };

    const handleEntriesChange = (value) => {
        setEntriesToShow(value === 'All' ? filteredData.length : Number(value));
        setCurrentPage(1);
    };

    const handleDropdownToggle = (index) => {
        setGetInvoice((prevData) => {
            const newData = [...prevData];
            newData[index].dropdownOpen = !newData[index].dropdownOpen;
            return newData;
        });
    };

    const prePage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const changeCPage = (n) => {
        setCurrentPage(n);
    };


    const handleDownload = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = 'invoice_list';

        const formattedData = dataToShow.map((item) => ({
            'Sl.No': item.invoice_id,
            'Invoice Number': item.invoice_number,
            'Sent To': item.client_name,
            'Sent On': item.created_at,
        }));

        const ws = XLSX.utils.json_to_sheet(formattedData, {
            header: ['Sl.No', 'Invoice Number', 'Sent To', 'Sent On'],
        });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Invoice List');

        // Apply bold and background color to headers
        const headerCellStyle = { font: { bold: true }, fill: { bgColor: { indexed: 64 } } };
        Object.keys(ws).filter((key) => key.startsWith('A1')).forEach((key) => {
            ws[key].s = headerCellStyle;
        });

        // Convert the workbook to a binary Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Create a Blob from the Excel buffer
        const excelData = new Blob([excelBuffer], { type: fileType });

        // Create a URL for the Blob
        const excelUrl = URL.createObjectURL(excelData);

        // Create a link and trigger the download
        const link = document.createElement('a');
        link.href = excelUrl;
        link.download = fileName + fileExtension;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(excelUrl);
        }, 100);
    };

    const getSerialNumber = (index) => {
        return (currentPage - 1) * entriesToShow + index + 1;
    };




    const toggleRole = () => {
        setShowRoleModal(!showRoleModal);
        setShowWasteFields(false); // Close waste fields when modal toggles
        setShowReplaceFields(false); // Close replace fields when modal toggles
    };


    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const handleDeleteClick = (itemId) => {
        setSelectedItemId(itemId);
        setShowDeleteConfirmation(true);
    };

    const handleDeleteConfirmation = () => {
        // Here you would perform the deletion of the selected item
        // For now, let's just log the item ID and close the modal
        console.log("Deleting item with ID:", selectedItemId);
        setShowDeleteConfirmation(false);
    };



    const handleDownloadPDF = async () => {
        try {
            const pdfBlob = await render(<SampleInvoice4 />).toBlob();
            saveAs(pdfBlob, 'invoice.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    // Invoice MOdal
    const [showModal, setShowModal] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);

    const handlePreviewClick = (invoice) => {
        setSelectedInvoice(invoice);
        setShowModal(true);
    };

    console.log('selected', selectedInvoice);

    // add service button and integration 

    const [serviceDetails, setServiceDetails] = useState([
        {
            id: 1, // Adding ID to keep track of each row
            service_name: '',
            unit_cost: '',
            quantity: '',
        },
    ]);

    const [addInvoice, setAddInvoice] = useState({
        project_name: '',
        client_name: '',
        client_address: '',
        client_email: '',
        client_phone: '',
        invoice_date: '',
        invoice_due_date: '',
        invoice_type: '',
        tax: '',
        services: [{}]
    })





    const addNewRow = () => {
        setServiceDetails([
            ...serviceDetails,
            {
                service_name: '',
                unit_cost: '',
                quantity: '',
            },
        ]);
    };

    const handleServiceChange = (e, index) => {
        const { name, value } = e.target;
        const updatedServiceDetails = [...serviceDetails];
        updatedServiceDetails[index] = {
            ...updatedServiceDetails[index],
            [name]: value,
        };
        setServiceDetails(updatedServiceDetails);
    };



    const deleteRow = (index) => {
        const updatedServiceDetails = [...serviceDetails];
        updatedServiceDetails.splice(index, 1);
        setServiceDetails(updatedServiceDetails);
    };





    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            project_name: addInvoice.project_name,
            client_name: addInvoice.client_name,
            client_address: addInvoice.client_address,
            client_email: addInvoice.client_email,
            client_phone: addInvoice.client_phone,
            invoice_date: addInvoice.invoice_date,
            invoice_due_date: addInvoice.invoice_due_date,
            invoice_type: addInvoice.invoice_type,
            tax: addInvoice.tax,
            services: serviceDetails.map(({ id, ...rest }) => rest),
        };

        try {
            const response = await api.post(`invoice.php`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log("Data sent successfully:", response.data);

            // Reset the form fields after successful submission
            setAddInvoice({
                project_name: "",
                client_name: "",
                client_address: "",
                client_email: "",
                client_phone: "",
                invoice_date: "",
                invoice_due_date: "",
                invoice_type: "",
                tax: "",
                services: [],
            });
            setServiceDetails([
                {
                    service_name: '',
                    unit_cost: '',
                    quantity: '',
                },
            ]);

            // Reload the page
            window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };




    const [project, setproject] = useState([]);
    const fetchProjectData = async () => {
        try {
            const response = await api.get(`viewProjectDetails.php`);
            setproject(response.data);
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    console.log(addInvoice);
    useEffect(() => {
        fetchProjectData();
    }, [])


    //Table integration goes here


    const fetchInvoice = async () => {
        const response = await api.get(`invoiceview.php`)

        setGetInvoice(response.data)
        setFilteredData(response.data)
        setOriginalData(response.data);
        console.log('getInvoice', getInvoice);
    }

    useEffect(() => {
        fetchInvoice()
    }, []);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredData(originalData)
        } else {
            const finalData = originalData.filter(item =>
                item.client_name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredData(finalData)
        }
    }, [searchQuery, originalData])





    return (
        <div className="header bg-gradient-info py-7 py-lg-8">
            <Container fluid>








                <div className="mt-5">

                    <Row className="mb-5 align-items-center">
                        <Col xs="12" md="auto" className="mb-3 mb-md-0">
                            <Form className="navbar-search navbar-search-dark form-inline d-flex ">
                                <InputGroup className="input-group-alternative w-100">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-search" />
                                            <Input
                                                placeholder="Search Invoice"
                                                type="text"
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </InputGroupText>
                                    </InputGroupAddon>

                                </InputGroup>
                            </Form>
                        </Col>
                        <Col xs="12" md="auto">
                            <Button onClick={toggleRole}>Add Invoice</Button>
                        </Col>
                    </Row>


                    <Modal isOpen={showRoleModal} toggle={toggleRole} size="xl">
                        <ModalHeader toggle={toggleRole}>
                            <h2 style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
                                Add Invoice
                            </h2>
                        </ModalHeader>
                        <ModalBody>
                            <Form onSubmit={handleFormSubmit}>
                                {/* Project Info Section */}
                                <h4 className="mb-3">Project Information</h4>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="input-project-name">Project Name</Label>
                                            <Input
                                                type="select"
                                                name="project_name"
                                                id="input-project-name"
                                                placeholder="Enter Project Name"
                                                required
                                                onChange={(e) => setAddInvoice({ ...addInvoice, project_name: e.target.value })}                                            >
                                                <option value="">Select Project</option>
                                                {project && Array.isArray(project) && project.map(option => (
                                                    <option key={option.p_id} value={option.p_name}>{option.p_name}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="input-client-name">Client Name</Label>
                                            <Input
                                                type="text"
                                                name="client_name"
                                                id="input-client-name"
                                                placeholder="Enter Client Name"
                                                required
                                                onChange={(e) => setAddInvoice({ ...addInvoice, client_name: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="input-client-address">Client Address</Label>
                                            <Input
                                                type="text"
                                                name="client_addresss"
                                                id="input-client-address"
                                                placeholder="Enter Client Address"
                                                required
                                                onChange={(e) => setAddInvoice({ ...addInvoice, client_address: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="input-client-email">Client Email</Label>
                                            <Input
                                                type="email"
                                                name="client_email"
                                                id="input-client-email"
                                                placeholder="Enter Client's Email Address"
                                                required
                                                onChange={(e) => setAddInvoice({ ...addInvoice, client_email: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="input-client-address">Client Phone Number</Label>
                                            <Input
                                                type="tel"
                                                name="client_phone"
                                                id="input-client-address"
                                                placeholder="Enter Client Address"
                                                required
                                                onChange={(e) => setAddInvoice({ ...addInvoice, client_phone: e.target.value })} maxLength={10}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <hr />

                                {/* Invoice Details Section */}
                                <h4 className="mb-3">Invoice Details</h4>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="input-invoice-date">Invoice Date</Label>
                                            <Input
                                                type="date"
                                                name="invoice_date"
                                                id="input-invoice-date"
                                                placeholder="Enter the invoice generation date"
                                                required
                                                onChange={(e) => setAddInvoice({ ...addInvoice, invoice_date: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="input-invoice-date">Invoice Due Date</Label>
                                            <Input
                                                type="date"
                                                name="invoice_due_date"
                                                id="input-invoice-date"
                                                placeholder="Enter the invoice generation date"
                                                required
                                                onChange={(e) => setAddInvoice({ ...addInvoice, invoice_due_date: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="input-invoice-for">Invoice For</Label>
                                            <Input
                                                type="select"
                                                name="invoice_type"
                                                id="input-invoice-for"
                                                onChange={(e) => setAddInvoice({ ...addInvoice, invoice_type: e.target.value })}
                                                required                                      >
                                                <option value="">Select Invoice for</option>
                                                <option value="materials">Materials</option>
                                                <option value="client">Client</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="input-tax-rate">Tax Rate (%)</Label>
                                            <Input
                                                type="number"
                                                name="tax"
                                                id="input-tax-rate"
                                                placeholder="Enter Tax Rate"
                                                required
                                                onChange={(e) => setAddInvoice({ ...addInvoice, tax: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <hr />

                                {/* Service Details Section */}
                                <Row form>
                                    <Col md={12}>
                                        <h4 className="mb-3">
                                            Service Details
                                            <Button color="primary" onClick={addNewRow} className="float-right">
                                                Add
                                            </Button>
                                        </h4>
                                    </Col>
                                    {serviceDetails.map((row, index) => (
                                        <Col md={12} key={index}>
                                            <Row form>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for={`input-services-${row.id}`}>Services</Label>
                                                        <Input
                                                            type="text"
                                                            name="service_name" // Corrected name attribute
                                                            id={`input-services-${row.id}`}
                                                            placeholder="Enter the service name"
                                                            value={row.service_name}
                                                            onChange={(e) => handleServiceChange(e, index)}
                                                            required
                                                        />

                                                    </FormGroup>
                                                </Col>

                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for={`input-price-${row.id}`}>Price</Label>
                                                        <Input
                                                            type="number"
                                                            name="unit_cost" // Corrected name attribute
                                                            id={`input-price-${row.id}`}
                                                            placeholder="Enter Price of Service"
                                                            value={row.unit_cost}
                                                            onChange={(e) => handleServiceChange(e, index)}
                                                            required
                                                        />

                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for={`input-quantity-${row.id}`}>Quantity</Label>
                                                        <Input
                                                            type="number"
                                                            name="quantity" // Corrected name attribute
                                                            id={`input-quantity-${row.id}`}
                                                            placeholder="Enter Quantity"
                                                            value={row.quantity}
                                                            onChange={(e) => handleServiceChange(e, index)}
                                                            required
                                                        />

                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="text-right">
                                                    {index !== 0 && ( // Conditionally rendering delete button for all rows except the first one
                                                        <Button color="danger" onClick={() => deleteRow(row.id)}>
                                                            <FaTrash /> Delete
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    ))}
                                </Row>




                                <hr />

                                <Row>
                                    <Col lg="12" className="text-center">
                                        <Button color="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>

                </div>
                <React.Fragment>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardBody>

                                    <CardHeader className="border-0">
                                        <Row className="align-items-center">
                                            <div className="col">
                                                <h3 className="mb-0">Invoice List</h3>
                                            </div>
                                            <div className="col text-right">
                                                <Dropdown isOpen={projectDropdownOpen} toggle={toggleProjectDropdown}>
                                                    <DropdownToggle caret>
                                                        Show Entries: {entriesToShow === filteredData.length ? "All" : entriesToShow}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => handleEntriesChange(5)}>5</DropdownItem>
                                                        <DropdownItem onClick={() => handleEntriesChange(10)}>10</DropdownItem>
                                                        <DropdownItem onClick={() => handleEntriesChange('All')}>All</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                                <button className="btn btn-success mr-2" onClick={handleDownload}>Download</button>

                                            </div>
                                        </Row>
                                    </CardHeader>

                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr style={{ textAlign: "center" }}>

                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Sl.No </th>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Invoice Number </th>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Sent To</th>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Sent On</th>
                                                <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Invoice Preview </th>

                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "center" }}>
                                            {dataToShow.length > 0 ? (
                                                dataToShow.map((item, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">{getSerialNumber(index)}</th>
                                                        <td>{item.invoice_number}</td>
                                                        <td>{item.client_name}</td>
                                                        <td>{item.created_at}</td>
                                                        <td>
                                                            <a href="#" onClick={() => handlePreviewClick(item)}>
                                                                Preview Invoice
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="5">No data available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    <nav className='mt-3'>
                                        <ul className='pagination justify-content-center'>
                                            <li className='page-item'>
                                                <a
                                                    href='#'
                                                    className='page-link text-primary'
                                                    onClick={prePage}
                                                    disabled={currentPage === 1}
                                                >
                                                    Prev
                                                </a>
                                            </li>
                                            <li className='page-item'>
                                                <a
                                                    href='#'
                                                    className='page-link text-primary'
                                                    onClick={nextPage}
                                                    disabled={currentPage === totalPages}
                                                >
                                                    Next
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>

                                </CardBody>
                            </Card>
                        </Col>

                        <Modal
                            isOpen={showDeleteConfirmation}
                            toggle={() => setShowDeleteConfirmation(false)}
                        >
                            <ModalHeader>Confirm Deletion</ModalHeader>
                            <ModalBody>Are you sure you want to delete this record?</ModalBody>
                            <div className="modal-footer">
                                <Button color="danger" onClick={handleDeleteConfirmation}>
                                    Yes
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => setShowDeleteConfirmation(false)}
                                >
                                    No
                                </Button>
                            </div>
                        </Modal>


                        {/* Invoice Preview */}
                        <Modal isOpen={showModal} toggle={() => setShowModal(false)} size="lg">
                            <ModalHeader toggle={() => setShowModal(false)}>Invoice Preview</ModalHeader>
                            <ModalBody>
                                {selectedInvoice && <SampleInvoice4 invoice={selectedInvoice} />}
                                {/* console.log('invoice',invoice); */}
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                    Close
                                </button>
                            </ModalFooter>
                        </Modal>
                    </Row>

                </React.Fragment>
            </Container>




        </div>
    );
};

export default Invoice4;



