import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import {api} from '../../../../api'

const Profile3 = () => {
  
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    // Initialize your form data fields here
    emp_id : 2,
    emp_name: '',
    email: '',
    phoneno: '',
    dob : '',
    role: '',
    salary: '',
    address: '',
    image : ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("PMprofileView.php?id=12");
        setTasks(response.data);
        console.log(tasks)
        setFormData({
          emp_id : 2,
          emp_name: response.data.emp_name,
          email: response.data.email,
          phoneno: response.data.phoneno,
          dob: response.data.dob,
          role: response.data.role,
          salary: response.data.salary,
          address: response.data.address,
          image : response.data.profile_image
        })
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };
    fetchData();
  }, []);
  
  
  


  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formDataToSend = new FormData();
    formDataToSend.append('username', formData.emp_name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('id', formData.emp_id);
    formDataToSend.append('phoneno', formData.phoneno);
    formDataToSend.append('dob', formData.dob);
    formDataToSend.append('salary', formData.salary);
    formDataToSend.append('role', formData.role);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('image', formData.image);

    try {
      const response = await api.post("pmProfileUpdate.php",formDataToSend);
      console.log("Data sent successfully:", response.data);
      // window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  

   // Function to handle form input changes
   const handleInputChange = (event) => {
    const {name, value}  = event.target
    // console.log(event.target);
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
    console.log(formData);
  };
 


  const [inputValue, setInputValue] = useState("");

 
  const [profileImage, setProfileImage] = useState(null);

 

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // console.log(file);
      setProfileImage(URL.createObjectURL(file));
      // You can also send the file to the server here if needed
      setFormData({ ...formData, image: event.target.files[0] });
    }
    

  };

  

  const handleCancelUpload = () => {
    // Reset the profile image state when the user cancels the upload
    setProfileImage(null);
    // Clear the file input value to allow selecting the same file again
    document.getElementById('input-profile-image').value = '';
  };


  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "400px",
          backgroundImage:
            "url(" + require("../../assets/img/theme/profile-cover.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center " fluid>
          <Row>
            <Col lg="10" md="10" >
              <h1 className="display-2 text-white ">Hello {tasks.emp_name}</h1>
              <p className="text-white mt-10 mb-5">
                This is your profile page. Where you can see your Profile Details
                and let us know your changes
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7 mb--5" fluid>
        <Row>
          <Col className="order-xl-2 mb-10 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {tasks.profile_image &&
                      <img
                        style={{
                          width: '150px',
                          height: '150px',
                          border: '2px solid black',
                          borderRadius: '50%',
                          overflow: 'hidden',
                          boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                          margin: '0 auto',
                        }}
                        alt="..."
                        className="rounded-circle"
                        src={require(`../../../images/${tasks.profile_image}`)}
                      />
                    }
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
              <CardBody className="pt-0 pt-md-5 mt-5">
                <div className="text-center">
                  <h3>
                     {tasks.emp_name}
                    <span className="font-weight-light"></span>
                  </h3>
                  <div className="h5 font-weight-300">
                    {/* <i className="ni location_pin mr-2" /> */}
                    {tasks.email}
                  </div>
                  <div className="h5 font-weight-300">
                    {/* <i className="ni location_pin mr-2" /> */}
                    {tasks.dob}
                  </div>
                  <div className="h5 font-weight-300">
                    {/* <i className="ni location_pin mr-2" /> */}
                    {tasks.phoneno}
                  </div>
                  <div className="h5 font-weight-300">
                    {/* <i className="ni location_pin mr-2" /> */}
                    {tasks.doj}
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    {tasks.role}
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    {tasks.salary}
                  </div>
                  <hr className="my-4" />
                  <p>{tasks.address}</p>
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    Show more
                  </a> */}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className=" border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Edit Profile</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ backgroundColor: "white" }}>
                <Form onSubmit={handleSubmit} enctype="multipart/form-data">
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>

                  <Row className="justify-content-center align-items-center">
                      <Col lg="12" className="text-center mb-3">
                        {/* Displaying a placeholder image if profileImage is null */}
                        {!profileImage && (
                          <div
                            className="profile-image-container"
                            style={{
                              width: '120px',
                              height: '120px',
                              border: '2px solid black',
                              borderRadius: '50%',
                              overflow: 'hidden',
                              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                              margin: '0 auto',
                            }}
                          >
                            {tasks.profile_image &&
                            <img
                                src={require(`../../../images/${tasks.profile_image}`)}
                                alt="Placeholder"
                                name="image"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            }
                          </div>
                        )}
                        {/* Displaying the uploaded image */}
                        {profileImage && (
                          <div
                            className="profile-image-container"
                            style={{
                              width: '120px',
                              height: '120px',
                              border: '2px solid black',
                              borderRadius: '50%',
                              overflow: 'hidden',
                              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                              margin: '0 auto',
                            }}
                          >
                            <img
                              src={profileImage}
                              alt="Profile"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                        )}
                      </Col>

                      <Col lg="12" className="text-center">
                        <Button
                          color="info"
                          onClick={() => document.getElementById('input-profile-image').click()}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </Button>

                        <FormGroup style={{ display: 'none' }}>
                          <Input
                            type="file"
                            id="input-profile-image"
                            accept="image/*"
                            onChange={handleFileUpload}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username
                          </label>
                          
                          <Input
                            className="form-control-alternative"
                            // defaultValue="lucky.jesse"
                            id="input-username"
                            placeholder="Username"
                            type="text"
                            name="emp_name"
                            defaultValue={tasks.emp_name}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="Email@example.com"
                            type="email"
                            name="email"
                            defaultValue={tasks.email}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Mobile number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Mobile Number"
                            type="tel"
                            name="phoneno"
                            defaultValue={tasks.phoneno}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Date Of Birth
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder="Date Of Birth"
                            type="Date"
                            name="dob"
                            defaultValue={tasks.dob}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Salary
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Salary"
                            type="tel"
                            name="salary"
                            defaultValue={tasks.salary}
                            onChange={handleInputChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Role
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder="Role"
                            type="text"
                            name="role"
                            defaultValue={tasks.role}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label>Address</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Let us know your Location"
                            rows="4"
                            type="textarea"
                            name="address"
                            defaultValue={tasks.address}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg="12" style={{ textAlign: "center" }}>
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                      
                    </Col>
                  </Row>
                  <hr className="my-4" /> 
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile3;
