import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// core components
import Header from "dashboard/project-management/components/Headers/Header";
import Select from "react-select";
import { api } from "../../../../api";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Cookies from 'js-cookie';

const Editproject1 = () => {
  const { id } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [showAddClientModal, setShowAddClientModal] = useState(false); // New state for Add Project modal
  const [clientForm, setClientForm] = useState({});

  const toggleModal = () => setModalOpen((prevState) => !prevState);
  const toggleAddClientModal = () =>
    setShowAddClientModal((prevState) => !prevState); // Function to toggle Add Project modal

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleManagerSelectChange = (selectedOption) => {
    setSelectedManager(selectedOption);
  };

  const handleLocationSelectChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
  };

  const emp_id = Cookies.get('token');

  // const [members, setmembers] = useState();
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await api.get("empGMdetails.php");

  //       // Extract only two variables and format them
  //       const formattedData = response.data.map((item) => ({
  //         value: item.emp_id, // Assuming employee id is stored in 'id'
  //         label: item.emp_name, // Assuming employee name is stored in 'name'
  //       })); // Take only the first two records

  //       setmembers(formattedData);
  //       console.log(response.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const [updateValue, setupdateValues] = useState({
    p_id: id,
    p_name: "",
    p_status: "",
    p_budget: "",
    site_manager: '',
    start_date: "",
    end_date: "",
    p_location: "",
    p_description: "",
    departments : '',
    BOQ : null,
    BOM : null,
    files: '',
    project_manager_id : '',
    gm_id : emp_id,
    client_id : ''
  });

  const [allfiles, setallfiles] = useState({
    BOQ : '',
    BOM : '',
    files: '',
  });
  
  const [selecteddepartment, setselecteddepartment] = useState([]);

  useEffect(() => {
    api
      .get("viewProjectByID.php?p_id=" + id)
      .then((res) => {
        setupdateValues({
          ...updateValue,
          p_name: res.data[0].p_name,
          p_status: res.data[0].p_status,
          p_budget: res.data[0].p_budget,
          site_manager: res.data[0].site_manager,
          start_date: res.data[0].start_date,
          end_date: res.data[0].end_date,
          p_location: res.data[0].p_location,
          p_description: res.data[0].p_description,
          departments: res.data[0].departments,
          project_manager_id : res.data[0].project_manager_id,
          gm_id : res.data[0].gm_id,
          client_id : res.data[0].client_id,
        });
        setallfiles({
          ...allfiles , 
          BOQ : res.data[0].BOQ,
          BOM : res.data[0].BOM,
          files: res.data[0].files,
        })
        console.log(res.data);
        console.log(updateValue)
      })
      .catch((err) => console.log(err));
  }, [id],[updateValue.p_status, selecteddepartment]);

  const [project, setProject] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`viewProjectDetails.php?p_m_id=${emp_id}`);
                setProject(response.data);
                console.log(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);


  const formDataToSend = new FormData();
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    
        formDataToSend.append('p_id', updateValue.p_id);
        formDataToSend.append('p_name', updateValue.p_name);
        formDataToSend.append('p_status', updateValue.p_status);
        formDataToSend.append('p_budget', updateValue.p_budget);
        formDataToSend.append('p_site_manager', [updateValue.site_manager]);
        formDataToSend.append('start_date', updateValue.start_date);
        formDataToSend.append('end_date', updateValue.end_date);
        formDataToSend.append('p_location', updateValue.p_location);
        formDataToSend.append('p_description', updateValue.p_description);
        formDataToSend.append('departments[]', updateValue.departments);
        formDataToSend.append('BOQ', updateValue.BOQ);
        formDataToSend.append('BOM', updateValue.BOM); 
        if (updateValue.files && updateValue.files.length > 0) {
          for (let i = 0; i < updateValue.files.length; i++) {
            formDataToSend.append("image[]", updateValue.files[i]);
          }
        }else{
          formDataToSend.append("image[]", null);
        }
        formDataToSend.append('project_manager_id', updateValue.project_manager_id);
        formDataToSend.append('gm_id', updateValue.gm_id);
        formDataToSend.append('client_id', updateValue.client_id);
        formDataToSend.append('old_boq', allfiles.BOQ);
        formDataToSend.append('old_bom', allfiles.BOM);
        formDataToSend.append('old_image', allfiles.files);

        console.log(updateValue);

        try {
          const response = await api.post("editProject.php",formDataToSend);
          console.log("Data sent successfully:", response.data);
          // window.history.back();
          window.history.back();
          } catch (error) {
          console.error("Error submitting data:", error);
          }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setupdateValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(updateValue);
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   formDataToSend.append('files', file);
  // };

  const [selectedSiteManagers, setSelectedSiteManagers] = useState();

  const [selectedprojectmanager, setselectedprojectmanager] = useState({
    value : "",
    label : ""
  });


  const fetchDepartData = async () => {
    try{
      if (updateValue.departments != null) {
        console.log(`Fetching departments with IDs: ${updateValue.departments}`);
          const response = await api.get(`empSiteManDetails.php?departments=${updateValue.departments}`);

          if (Array.isArray(response.data) && response.data.length > 0) {
              const formattedData = response.data.map((item) => ({
                  value: item.d_id, // Assuming department id is stored in 'd_id'
                  label: item.dept_name, // Assuming department name is stored in 'dept_name'
              }));
              setselecteddepartment(formattedData);   
          } else {
              console.log("No data received or empty array");
          }
      }
    }catch (error) {
        console.error("Error fetching data:", error);
    }
  }

  const runFetchDepartDataTwice = async () => {
    // await fetchDepartData();
    setTimeout(async () => {
        await fetchDepartData();
    }, 2);
  };

  useEffect(() => {
    const fetchData = async () => {
        try {
            if (updateValue.site_manager != null) {
                const response = await api.get(`empSiteManDetails.php?site_manager=${updateValue.site_manager}`);

                if (Array.isArray(response.data) && response.data.length > 0) {
                    const formattedData = response.data.map((item) => ({
                        value: item.emp_id, // Assuming employee id is stored in 'emp_id'
                        label: item.emp_name, // Assuming employee name is stored in 'emp_name'
                    }));
                    setSelectedSiteManagers(formattedData);
                } else {
                    console.log("No data received or empty array");
                }
            }
            if (updateValue.project_manager_id != null) {
                const response = await api.get(`empSiteManDetails.php?project_manager_id=${updateValue.project_manager_id}`);

                if (Array.isArray(response.data) && response.data.length > 0) {
                    const formattedData = response.data.map((item) => ({
                        value: item.emp_id, // Assuming employee id is stored in 'emp_id'
                        label: item.emp_name, // Assuming employee name is stored in 'emp_name'
                    }));
                    setselectedprojectmanager(formattedData);
                    console.log(selectedprojectmanager[0].label);
                } else {
                    console.log("No data received or empty array");
                }
            }
            if (updateValue.departments != null) {
              console.log(`Fetching departments with IDs: ${updateValue.departments}`);
                const response = await api.get(`empSiteManDetails.php?departments=${updateValue.departments}`);

                if (Array.isArray(response.data) && response.data.length > 0) {
                    const formattedData = response.data.map((item) => ({
                        value: item.d_id, // Assuming department id is stored in 'd_id'
                        label: item.dept_name, // Assuming department name is stored in 'dept_name'
                    }));
                    setselecteddepartment(formattedData);   
                } else {
                    console.log("No data received or empty array");
                }
            }
            console.log(selecteddepartment);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    fetchData();
    runFetchDepartDataTwice();
}, [updateValue.site_manager, updateValue.project_manager_id, updateValue.departments]); // Correctly listing all dependencies in a single array

   
  const [members, setmembers] = useState();
  const [projectmanager , setprojectmanager] = useState();
  const [department , setdepartment] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("empGMdetails.php");
        const response2 = await api.get('empGMdetails.php?p_m="p_m"');
        const response3 = await api.get('department.php');
        
        // Extract only two variables and format them
        const formattedData = response.data.map(item => ({
          value: item.emp_id, // Assuming employee id is stored in 'id'
          label: item.emp_name + " (" + item.role + ")" // Assuming employee name is stored in 'name'
        })); // Take only the first two records

        // Extract only two variables and format them
        const formattedData2 = response2.data.map(item => ({
          value: item.emp_id, // Assuming employee id is stored in 'id'
          label: item.emp_name  // Assuming employee name is stored in 'name'
        })); // Take only the first two records

         // Extract only two variables and format them
         const formattedData3 = response3.data.map(item => ({
          value: item.d_id, // Assuming employee id is stored in 'id'
          label: item.dept_name // Assuming employee name is stored in 'name'
        })); // Take only the first two records

        setmembers(formattedData); 
        setprojectmanager(formattedData2);
        setdepartment(formattedData3);
        console.log(response.data);  
        console.log(response3.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);


  const handleSiteManagerSelectChange = (selectedOptions) => {
    setSelectedSiteManagers(selectedOptions);
    setupdateValues((prevState) => ({
      ...prevState,
      site_manager: selectedOptions.map((option) => option.value),
    }));
    console.log(updateValue);
  };

  const handledepartmentSelectChange = (selectedOptions) => {
    setselecteddepartment(selectedOptions);
    setupdateValues(prevState => ({
      ...prevState,
      departments : selectedOptions.map(option => option.value)
    }));
    // console.log(addproject);
  };

  const [clientName, setclientName] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("clientGM.php");
        setclientName(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);


  const [BOQ, setBOQ] = useState('No File Selected');
  const [BOM, setBOM] = useState('No File Selected');

  const handleBOQFileChange = (e) => {
    const file = e.target.files[0];
    setBOQ(file.name);
    setupdateValues((prevState) => ({
      ...prevState,
      BOQ: file,
    }));
  };

  const handleBOMFileChange = (e) => {
    const file = e.target.files[0];
    setBOM(file.name);
    setupdateValues((prevState) => ({
      ...prevState,
      BOM: file,
    }));
  };

  const fileInputRef = useRef(null);

  const handleAddFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    setupdateValues((prevState) => ({
      ...prevState,
      files: [...prevState.files, ...files],
    }));
  };

  const handleCancelFile = (index) => {
    if (typeof allfiles.files === 'string') {
      const fileArray = allfiles.files.split(',');
      const updateFile = fileArray.filter((_, i) => i !== index);
      const updatedFileString = updateFile.join(',');
      setallfiles((prevState) => ({
        ...prevState,
        files: updatedFileString,
      }));
    } else {
      console.error('allfiles.files is not a string');
    }
  };
  
  

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Col className="order-xl-2 mb-5 mb-xl-0 text-center  mb-5">
          <h1>Edit Project Details</h1>
        </Col>
        <Link to="/general-admin/general-management/project1">
          {" "}
          <Button className="ml-5">Back</Button>
        </Link>
        <Container className="py-4 ">
          <div
            className="p-4 rounded"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid black",
            }}
          >
            <Form onSubmit={handleFormSubmit}>
              <div className="row">
              <Col md={6}>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-status">Client Name</label>
            <Input
              className="form-control-alternative"
              id="input-status"
              type="select"
              name="client_id"
              onChange={handleInputChange}
              required
            >
              <option value="">Select Client Name</option>
              {clientName && clientName.map(option => (
                  <option key={option.client_id} value={option.client_id}>{option.client_name}</option>
              ))}
              {!clientName || clientName.length === 0 && (
                  <option value="">No clients available</option>
              )}

            </Input>
          </FormGroup>
        </Col>
                <Col md={6}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-name">
                      Project Name
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-name"
                      type="text"
                      name="p_name"
                      value={updateValue.p_name}
                      onChange={handleInputChange}
                      placeholder="Project Name"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-status"
                    >
                      Project Status
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-status"
                      type="select"
                      name="p_status"
                      onChange={handleInputChange}
                      value={updateValue.p_status}
                    >
                      <option value="">Select Status</option>
                      <option  value="pending"  >Pending</option>
                      <option  value="inprogress" >In-progress</option>
                      <option value="completed" >Completed</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-name">
                      Project Budget
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-budget"
                      type="number"
                      name="p_budget" 
                      defaultValue={updateValue.p_budget}
                      onChange={handleInputChange}
                      placeholder="Project Budget"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-status">
                    Select Project Manager
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-status"
                    type="select"
                    name="project_manager_id"
                    onChange={handleInputChange}
                    // required
                  >
                    <option value="">Select </option>
                    {projectmanager ? (
                      projectmanager.map((option) => (
                        <option key={option.value} value={option.value} >
                          {option.label}
                        </option>
                      ))
                    ) : (
                      <option value="">No Project Manager's</option>
                    )}


                  </Input>
                </FormGroup>
              </Col>
                {/* <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-site-manager"
                    >
                      Select Site Manager
                    </label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isMulti
                      defaultValue={selectedSiteManagers}
                      onChange={handleSiteManagerSelectChange}
                      options={members}
                      value={selectedSiteManagers}
                    />
                  </FormGroup>
                </Col> */}
                
                
                <div className="col-md-6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-age">
                      Project Starting Date
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-age"
                      type="date"
                      name="start_date"
                      defaultValue={updateValue.start_date}
                      onChange={handleInputChange}
                      placeholder="Starting Date"
                    />
                  </FormGroup>
                </div>
                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-ending"
                    >
                      Project Ending Date
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-ending"
                      type="date"
                      name="end_date"
                      defaultValue={updateValue.end_date}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-location"
                    >
                      Location
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-location"
                      type="location"
                      name="p_location"
                      defaultValue={updateValue.p_location}
                      onChange={handleInputChange}
                      placeholder="Location"
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-site-manager">Select Department's</label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isMulti
                    value={selecteddepartment}
                    onChange={handledepartmentSelectChange}
                    options={department}
                  />
                </FormGroup>
              </Col>
                <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-file">
                    Upload BOQ File
                  </label>
                  <div className="custom-file-input-wrapper">
                    <label className="btn btn-success p-1 custom-file-input-label" htmlFor="input-BOQ-file">
                      Choose File
                    </label>
                    <Input
                      type="file"
                      id="input-BOQ-file"
                      name="BOQ"
                      style={{ display: 'none' }}
                      onChange={handleBOQFileChange}
                    />
                      <h5>Old file : {allfiles.BOQ}</h5>
                      <h5>Selected file : {BOQ}</h5>
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-file">
                    Upload BOM File
                  </label>
                  <div className="custom-file-input-wrapper">
                    <label className="btn btn-success p-1 custom-file-input-label" htmlFor="input-BOM-file">
                      Choose File
                    </label>
                    <Input
                      type="file"
                      id="input-BOM-file"
                      name="BOM"
                      style={{ display: 'none' }}
                      onChange={handleBOMFileChange}
                    />
                      <h5>Old file : {allfiles.BOM}</h5>
                      <h5>Selected file : {BOM}</h5>
                  </div>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label>Files:</label>
                  <div>
                    <Button
                      type="button"
                      color="success"
                      style={{ padding: "3px" }}
                      onClick={handleAddFileClick}
                      className="p-1"
                    >
                      Upload Documents
                    </Button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      multiple
                    />
                    <div className="d-flex row mt-1">
                      <div className="col">Old Files : 
                        {allfiles.files.split(',').map((file, index) => (
                          <h5 key={index}>File {++index} : <a href={`http://localhost/AECP/src/dashboard/images/${file}`} download>{file}</a><i onClick={()=> handleCancelFile(index)} className="fa fa-times-circle ml-1"></i></h5>
                        ))}
                      </div>
                      <div className="col">Selected Files : 
                        {updateValue.files.length > 0 ? (
                          updateValue.files.map((file, index) => (
                            <h5 className="mt-1 mt-0" key={index}>File {index + 1} : {file.name}</h5>
                          ))
                        ) : (
                          <h5>No Documents Selected</h5>
                        )}
                      </div>
                    </div>

                  </div>
                </FormGroup>
              </Col>
                <Col md={12}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-description"
                    >
                      Project Description
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={updateValue.p_description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setupdateValues((prevState) => ({
                          ...prevState,
                          p_description: data,
                        }));
                      }}
                    />
                  </FormGroup>
                </Col>
              </div>
              {/* <Link to="/admin/project"> */}
                <div className="row">
                  <div className="col-md-12 text-center">
                    {" "}
                    {/* Centered button */}
                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </div>
                </div>
              {/* </Link> */}
            </Form>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Editproject1;
