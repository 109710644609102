import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { api } from "../../../../api";
import { capitalWords } from "Utility/capitalWords";

const Role3 = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [roledata, setroledata] = useState([]);

  useEffect(() => {
    // Fetch data and set departdata
    const fetchData = async () => {
      try {
        const response = await api.get(`addRole.php`);
        setroledata(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const originalData = roledata || [];

    if (searchQuery === "") {
      setFilteredData(originalData);
    } else {
      const filtered = originalData.filter((item) =>
        item.role_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchQuery, roledata]);

  const [itemIdToDelete, setItemIdToDelete] = useState(null);

  const handleDeleteIconClick = (role_id) => {
    setItemIdToDelete(role_id);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirmation = (e) => {
    // e.preventDefault();
    // Implement delete logic here
    const roleid = itemIdToDelete;
    if (roleid != null) {
      const fetchData = async () => {
        try {
          const response = await api.get(`addRole.php?roleid=${roleid}`);
          console.log(response.data);
          window.location.reload();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
    setItemIdToDelete(null);
    setShowDeleteConfirmation(false);
  };

  const [showRoleModal, setShowRoleModal] = useState(false);

  const toggleRole = () => {
    setShowRoleModal(!showRoleModal);
  };

  const [role, setrole] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setrole((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    console.log(role);
  };

  const handleFormSubmit = async (e) => {
    // e.preventDefault();

    try {
      const response = await api.post("addRole.php", role);
      console.log("Data sent successfully:", response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const [viewrole, setviewrole] = useState();
  const [loading, setLoading] = useState(true);
  const capitalizeWords = (str) => {
    console.log('str',str);
    return str.replace(/\b\w/g, char => char.toUpperCase());
  }

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="mb-5">
            <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
              <h1 className="text-white">Roles List</h1>
            </Col>

            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
              <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                <FormGroup className="mb-0 ml-6">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search Role"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
              <Button className="ml-4" onClick={toggleRole}>
                Add Role
              </Button>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr style={{ textAlign: "center" }}>
                      <th
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                        scope="col"
                      >
                        S.No
                      </th>
                      <th
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                        scope="col"
                      >
                        Role
                      </th>
                      <th
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                        scope="col"
                      >
                        Capacity
                      </th>
                      <th
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                        scope="col"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {filteredData.length > 0 ? (
                      filteredData.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{ capitalWords(item.role_name)}</td>
                          <td>{item.capacity}</td>
                          <td>
                            <i
                              className="fa fa-trash"
                              style={{
                                fontSize: "23px",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDeleteIconClick(item.id)}
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={showDeleteConfirmation}
        toggle={() => setShowDeleteConfirmation(false)}
      >
        <ModalHeader>Confirm Deletion</ModalHeader>
        <ModalBody>Are you sure you want to delete this role ?</ModalBody>
        <div className="modal-footer">
          <Button color="danger" onClick={handleDeleteConfirmation}>
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            No
          </Button>
        </div>
      </Modal>

      <Modal isOpen={showRoleModal} toggle={toggleRole}>
        <ModalHeader toggle={toggleRole}>
          <h2 style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
            New Role
          </h2>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-role">
                    Enter Role *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-role"
                    type="select"
                    name="role"
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="hr">HR</option>
                    <option value="vice president">Vice President</option>
                    <option value="general manager">General Manager</option>
                    <option value="project manager">Project Manager</option>
                    <option value="site manager">Site Manager</option>
                    <option value="store manager">Store Manager</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-role">
                    Enter Capacity
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-role"
                    type="number"
                    name="capacity"
                    pattern="^[a-zA-Z ]+$"
                    onChange={handleInputChange}
                    placeholder="Enter number of capacity"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "center" }}>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Role3;
