import defaultImage from "../dashboard/images/AECP Logo 1 png.png";

export const loadImage = (imageName) => {
  try {
    return require(`../dashboard/images/${imageName}`);
 
  } catch (error) {
    console.warn(`Image not found: ${imageName}`);
    return defaultImage; // Provide a default image
  }
};
