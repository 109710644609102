import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Progress,
  CardImg,
  Button,
  UncontrolledTooltip,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Table, Badge, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, CardHeader, ModalFooter,
} from "reactstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { CgSelect } from "react-icons/cg";


import { api } from '../../../../api';

import * as XLSX from 'xlsx';
import { Link } from "react-router-dom";

const Equipment4 = () => {

  const [totalEmployees, setTotalEmployees] = useState(0);
  const [onTime, setOnTime] = useState(0);
  const [late, setLate] = useState(0);
  const [leaves, setLeaves] = useState(0);


  const [allData,setAllData]=useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]);


  

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await api.get(`equipmaintanenceviewSTM.php`);
        setOriginalData(response.data.equipment_list);
        setFilteredData(response.data.equipment_list);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); 

  console.log('allData',allData);






  const [showRoleModal, setShowRoleModal] = useState(false);

  const toggleRole = () => {
    setShowRoleModal(!showRoleModal);
  };

  // const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);



  // Add searchQuery as dependency

  const [addMaintainance, setAddMaintainance] = useState({
    equipment_name: "",
    equipment_type: "",
    maintenance_type: "",
    maintenance_date: "",
    maintenance_schedule: ""
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddMaintainance(prevState => ({
      ...prevState,
      [name]: value
    }));
    console.log(addMaintainance);
  };


  // Function to handle form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = {
      equipment_name: addMaintainance.equipment_name,
      equipment_type: addMaintainance.equipment_type,
      maintenance_type: addMaintainance.maintenance_type,
      maintenance_date: addMaintainance.maintenance_date,
      maintenance_schedule: addMaintainance.maintenance_schedule
    };

    try {
      const response = await api.post("equipmaintanenceaddSTM.php", formDataToSend);
      console.log("Data sent successfully:", response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };


  const handleStatusAction = async (id, status) => {
    const data = {
      id: id,
      status: status
    }
    try {
      const response = await api.post('', data);
      console.log(response.data);
    } catch (error) {
      console.error('Error updating status:', error);
    }
    window.location.reload();
  };

  const toggleDropdown = (index) => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  }

  // Pgination code from here
  const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
  const [entriesToShow, setEntriesToShow] = useState(5); // State variable for selected number of entries
  const [dataToShow, setDataToShow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filteredData.length / entriesToShow);


  // useEffect(() => {

  //   setDataToShow(allData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
  // }, [entriesToShow, currentPage, allData]);


  useEffect(() => {
    setDataToShow(filteredData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
  }, [entriesToShow, currentPage, filteredData]);




  const toggleProjectDropdown = () => {
    setProjectDropdownOpen(!projectDropdownOpen);
  };

  const handleEntriesChange = (value) => {
    setEntriesToShow(value === 'All' ? filteredData.length : Number(value));
    setCurrentPage(1);
  };

  const handleDropdownToggle = (index) => {
    setDataToShow((prevData) => {
      const newData = [...prevData];
      newData[index].dropdownOpen = !newData[index].dropdownOpen;
      return newData;
    });
  };

  const prePage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const changeCPage = (n) => {
    setCurrentPage(n);
  };



  const handleDownload = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'maintenance_list';

    // Convert allData to the format needed for XLSX.utils.json_to_sheet
    const formattedData = allData.map((item) => ({
      'Sl No': item.slNo,
      'Equipment Name': item.equipmentName,
      'Equipment Type': item.equipmentType,
      'Type of Maintenance': item.typeOfMaintenance,
      'Maintenance Schedule': item.maintenanceSchedule,
      'Maintenance Date': item.maintenanceDate,
      'Issue Addressed': item.issueAddressed,
      'Downtime Duration': item.downtimeDuration,
      'Cost to Solve Issue': item.costToSolveIssue,
    }));

    // Create a worksheet
    const ws = XLSX.utils.json_to_sheet(formattedData);

    // Apply bold and background color to headers
    const headerCellStyle = { font: { bold: true }, fill: { bgColor: { indexed: 64 } } };
    Object.keys(ws).filter((key) => key.startsWith('A1')).forEach((key) => {
      ws[key].s = headerCellStyle;
    });

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Maintenance List');

    // Convert the workbook to a binary Excel file
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the Excel buffer
    const excelData = new Blob([excelBuffer], { type: fileType });

    // Create a URL for the Blob
    const excelUrl = URL.createObjectURL(excelData);

    // Create a link and trigger the download
    const link = document.createElement('a');
    link.href = excelUrl;
    link.download = fileName + fileExtension;
    document.body.appendChild(link);
    link.click();

    // Cleanup
    setTimeout(function () {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(excelUrl);
    }, 100);
  };


   // State to store the ID of the item to delete
  const [modalOpen, setModalOpen] = useState(false); // State to control the modal


  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };


  const [deleteItemId, setDeleteItemId] = useState(null);
  // Function to handle delete confirmation
  const handleDeleteConfirmation = async () =>{
    try{
      const response = await api.post(`equipmaintanencedelSTM.php`,{'equipment_id':deleteItemId})
      window.location.reload();
      
    } catch(error){
      console.log('Error Deleting Data:',error);
    }
    setDeleteItemId(null);
    setModalOpen(!modalOpen);
    
  };

  const [upCards, setUpCards] = useState([]);
  const handleEquipmentCards = async () => {
    try {
      const response = await api.get(`countequipmentmaintenceSTM.php`);
      if (response.data.status === "success") {
        setUpCards(response.data.data);
      } else {
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      console.error("Error Fetching Data", error);
    }
  };

  useEffect(() => {
    handleEquipmentCards();
  }, []);

  console.log('upcards', upCards);



  useEffect(() => {
    if (searchQuery === '') {
      setFilteredData(originalData);
    } else {
      const filteredResults = originalData.filter(item =>
        item.equipment_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filteredResults);
    }
  }, [searchQuery, originalData]);




  return (
    <div className="header bg-gradient-info py-7 py-lg-8">
      <Container fluid>



        <Row className="d-flex justify-content-center align-items-center">
          <Col lg="6" xl="3" className="mb-3">
            <Card className="card-stats mb-4 mb-xl-0" style={{ borderRadius: '30px' }}>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div>
                    <CardTitle className="h4 mb-0">Today's Maintainance</CardTitle>
                  </div>
                  <div style={{ height: '30px', width: '30px' }}>
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #759bff, #843cf6)' }}>
                      <i className="fa-solid fa-person-digging fa-fade"></i>
                    </div>
                  </div>
                </div>
                {upCards.map((card, index) => (
                  <span key={index} className="h2 font-weight-bold">{card.todays_maintenance}</span>
                ))}
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" xl="3" className="mb-3">
            <Card className="card-stats mb-4 mb-xl-0" style={{ borderRadius: '30px' }}>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div>
                    <CardTitle className="h4 mb-0">Upcoming Maintainance</CardTitle>
                  </div>
                  <div style={{ height: '30px', width: '30px' }}>
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #569715, #569715)' }}>
                      <i className="fa-solid fa-wallet fa-spin" style={{ '--fa-animation-duration': '2s;', '--fa-fade-opacity': '0.6' }}></i>
                    </div>
                  </div>
                </div>
                {upCards.map((card, index) => (
                  <span key={index} className="h2 font-weight-bold "  >{card.upcoming_maintenance}</span>
                ))}
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" xl="3" className="mb-3">
            <Card className="card-stats mb-4 mb-xl-0" style={{ borderRadius: '30px' }}>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div>
                    <CardTitle className="h4 mb-0">Completed Maintainance</CardTitle>
                  </div>
                  <div style={{ height: '30px', width: '30px' }}>
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #ff7979, #ff6d6d)' }}>
                      <i className="fa-solid fa-square-check fa-bounce" />
                    </div>
                  </div>
                </div>
                {upCards.map((card, index) => (
                  <span key={index} className="h2 font-weight-bold ">{card.completed_maintenance}</span>
                ))}
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" xl="3" className="mb-3">
            <Card className="card-stats mb-4 mb-xl-0" style={{ borderRadius: '30px' }}>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div>
                    <CardTitle className="h4 mb-0">Materials Under Downtime</CardTitle>
                  </div>
                  <div style={{ height: '30px', width: '30px' }}>
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ background: 'linear-gradient(230deg, #7AA2E3, #5F90D8)' }}>
                      <i className="fa-solid fa-clock-rotate-left fa-flip" />
                    </div>
                  </div>
                </div>
                {upCards.map((card, index) => (
                  <span key={index} className="h2 font-weight-bold ">{card.materials_under_downtime}</span>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>


        {/* Maintainance Record Header */}

        <Card className="card-stats mb-4 mb-xl-0 m-0 mt-6">
          <CardBody className="d-flex justify-content-center align-items-center" >
            <Row>
              <h1 className="px-2" style={{ fontWeight: 'bolder' }}>Maintainance Record</h1>
            </Row>
          </CardBody>
        </Card>


        {/* Add New Maintainance and its modal */}

        <div className="mt-5">

          <Row className="mb-5">


            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
              <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                <FormGroup className="mb-0 ml-6">

                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search Stock"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}


                    />
                  </InputGroup>

                </FormGroup>
              </Form>
              <Button className="ml-4" onClick={toggleRole}>
                Add New Maintainance
              </Button>
            </Col>
          </Row>

          <Modal isOpen={showRoleModal} toggle={toggleRole}>
            <ModalHeader toggle={toggleRole}>
              <h2 style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
                New Maintainance
              </h2>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={handleFormSubmit}>
                <Row>
                  <Col md={12}>
                    <FormGroup>

                      <label className="form-control-label" htmlFor="input-role">
                        Enter Equipment Name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-role"
                        type="text"
                        name="equipment_name"
                        pattern="^[a-zA-Z ]+$"
                        onChange={handleInputChange}

                      />

                      <label className="form-control-label" htmlFor="input-role">
                        Enter Equipment Type
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-role"
                        type="text"
                        name="equipment_type"
                        pattern="^[a-zA-Z ]+$"
                        onChange={handleInputChange}
                        placeholder="Enter Equipment Type"
                      />

                      <label className="form-control-label" htmlFor="input-role">
                        Type Of Maintainance
                      </label>
                      <div className="input-group">
                        <select
                          className="form-control"
                          id="input-role"
                          name="maintenance_type"
                          onChange={handleInputChange}
                        >
                          <option value="">Select Type Of Maintainance</option>
                          <option value="Preventive Maintainance">Preventive Maintainance</option>
                          <option value="Emergency Maintainance">Emergency Maintainance</option>
                          <option value="Parts Replacement">Parts Replacement</option>
                          <option value="Predictive Maintainance">Predictive Maintainance</option>
                        </select>
                      </div>


                      <label className="form-control-label" htmlFor="input-role">
                        Enter Maintenance Date
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-role"
                        type="date"
                        name="maintainance_date"
                        value={addMaintainance && addMaintainance.maintenance_date} // Assuming role is the state variable holding the form data
                        onChange={(e) => handleInputChange({
                          target: {
                            name: "maintenance_date",
                            value: e.target.value,
                          }
                        })}
                        pattern="\d{4}-\d{2}-\d{2}" // Add pattern to enforce the format
                      />


                      <label className="form-control-label" htmlFor="input-role">
                        Maintainance Schedule
                      </label>
                      <div className="input-group">
                        <select
                          className="form-control"
                          id="input-role"
                          name="maintenance_schedule"
                          onChange={handleInputChange}
                        >
                          <option value="">Select Maintainance Schedule</option>
                          <option value="Day">Day</option>
                          <option value="Month">Month</option>
                          <option value="Year">Year</option>
                        </select>
                      </div>

                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "center" }}>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

        </div>

        {/* Maintainance Record Table */}

        <React.Fragment>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardBody>

                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Maintainance List</h3>
                      </div>
                      <div className="col text-right">
                        <Dropdown isOpen={projectDropdownOpen} toggle={toggleProjectDropdown}>
                          <DropdownToggle caret>
                            Show Entries: {entriesToShow}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => handleEntriesChange(5)}>5</DropdownItem>
                            <DropdownItem onClick={() => handleEntriesChange(10)}>10</DropdownItem>
                            <DropdownItem onClick={() => handleEntriesChange(50)}>50</DropdownItem>
                            <DropdownItem onClick={() => handleEntriesChange('All')}>All</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                        <button className="btn btn-success mr-2" onClick={handleDownload}>Download</button>

                      </div>
                    </Row>
                  </CardHeader>

                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr style={{ textAlign: "center" }}>

                        <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Sl.No </th>
                        <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Equipment Name </th>
                        <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Equipment Type </th>
                        <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Type of Maintenance </th>
                        <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Maintenance Schedule </th>
                        <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Maintenance Date </th>
                        <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Issue Addressed </th>
                        <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Downtime Duration </th>
                        <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Cost to Solve Issue </th>
                        <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Action </th>


                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>
                      {dataToShow.map((item, index) => (
                        <tr key={index}>
                          <th >{item.equipment_id}</th>
                          <td>{item.equipment_name}</td>
                          <td>{item.equipment_type}</td>
                          <td>{item.maintenance_type}</td>
                          <td>{item.maintenance_schedule}</td>
                          <td>{item.maintenance_date}</td>
                          <td>{item.issues_addressed}</td>
                          <td>{item.downtime_duration}</td>

                          <td>{item.cost}</td>



                          <td>
                            <Dropdown isOpen={dropdownOpen[index]} toggle={() => toggleDropdown(index)}>
                              <DropdownToggle caret>
                                <i className="fas fa-ellipsis-v"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                              <Link to={`/store-admin/store-management/ViewMaintainance4/${item.equipment_id}`}>


                                  <DropdownItem>
                                    {/* Use Link instead of onClick */}
                                    <i className="fas fa-eye mr-2"></i> View
                                  </DropdownItem>                                  </Link>

                                <Link to={`/store-admin/store-management/EditMaintainance4/${item.equipment_id}`}>

                                  <DropdownItem >

                                    <i className="fas fa-edit mr-2"></i> Edit



                                  </DropdownItem>  </Link>

                                <DropdownItem onClick={() => { toggleModal(); setDeleteItemId(item.equipment_id); }}>
                                  <i className="fas fa-trash-alt mr-2"></i> Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </td>




                        </tr>
                      ))}
                    </tbody>



                  </Table>

                  <nav className='mt-3'>
                    <ul className='pagination justify-content-center'>
                      <li className='page-item'>
                        <a
                          href='#'
                          className='page-link text-primary'
                          onClick={prePage}
                          disabled={currentPage === 1}
                        >
                          Prev
                        </a>
                      </li>
                      <li className='page-item'>
                        <a
                          href='#'
                          className='page-link text-primary'
                          onClick={nextPage}
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </React.Fragment>


        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
          <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Confirm Delete</ModalHeader>
          <ModalBody>
            Are you sure you want to delete this item?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleDeleteConfirmation}>Delete</Button>{' '}
            <Button color="secondary" onClick={() => setModalOpen(!modalOpen)}>Cancel</Button>
          </ModalFooter>
        </Modal>

      </Container>




    </div>
  );
};

export default Equipment4;
