import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
// core components
import Header from "dashboard/general-management/components/Headers/Header.js";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import {api} from '../../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const Editclient1 = (props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [showAddClientModal, setShowAddClientModal] = useState(false); // New state for Add Project modal
    const [clientForm, setClientForm] = useState({});

    const toggleModal = () => setModalOpen(prevState => !prevState);
    const toggleAddClientModal = () => setShowAddClientModal(prevState => !prevState); // Function to toggle Add Project modal

    

    // getting id for client to edit
    const {id} = useParams();

    const [updateValue, setupdateValues] = useState({
        id : id,
        client_name: '',
        client_email: '',
        client_number: '',
        company_name: '',
        address : '',
        profile_image : ''
    })

    
    

    useEffect(() =>{
        api.get('clientGM.php?id='+id)
        .then(res =>{
            setupdateValues({...updateValue, 
                client_name: res.data[0].client_name,
                client_email: res.data[0].client_email,
                client_number: res.data[0].client_number,
                company_name: res.data[0].company_name,
                address: res.data[0].address,
                profile_image: res.data[0].profile_image,


            })
            console.log(res.data)
        } )
        .catch(err => console.log(err))
    }, [id]);
    // console.log(updateValue)



    const handleFormSubmit = async(e) => {
        e.preventDefault();

        const formdata = new FormData();
        formdata.append("client_id",updateValue.id);
        formdata.append("client_name",updateValue.client_name);
        formdata.append("client_email",updateValue.client_email);
        formdata.append("client_number",updateValue.client_number);
        formdata.append("company_name",updateValue.company_name);
        formdata.append("profile_image",updateValue.profile_image);
        formdata.append("address",updateValue.address);
        // formdata.append("old_image",updateValue.profile_image);
        try {
            const response = await api.post("clientGM.php",formdata); 
            console.log("Data sent successfully:", response.data); 
            // window.location.reload();
      
          } catch (error) {
            console.error("Error submitting data:", error);
          }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setupdateValues(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(updateValue)
    };
    const [profileImage, setProfileImage] = useState(null);

  
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
  
      // Check if a file is selected
      if (file) {
        console.log(file);
        setProfileImage(URL.createObjectURL(file));
        // You can also send the file to the server here if needed
        setupdateValues({ ...updateValue, profile_image: event.target.files[0] });
      }
    };

    

    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
            <Col className="order-xl-2 mb-5 mb-xl-0 text-center  mb-5">
                        <h1>Edit Client Details {id}</h1>
                    </Col>
                    <Link to="/general-admin/general-management/client1"> <Button className='ml-5'>Back</Button></Link>

                <Container className="py-4">
                    
                <div className="p-4 rounded" style={{ backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid black' }}>
                        <Form onSubmit={handleFormSubmit}>
                            <Row className="justify-content-center align-items-center">
                        <Col lg="12" className="text-center mb-3">
                            {!profileImage && (
                            <div
                                className="profile-image-container"
                                style={{
                                width: '120px',
                                height: '120px',
                                border: '2px solid black',
                                borderRadius: '50%',
                                overflow: 'hidden',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                                margin: '0 auto',
                                }}
                            >
                                {updateValue.profile_image &&
                                <img
                                    src={require(`../../../images/${updateValue.profile_image}`)}
                                    alt="Placeholder"
                                    name="image"
                                    style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    }}
                                    
                                />
                                }
                            </div>
                            )}
                            {profileImage && (
                            <div
                                className="profile-image-container"
                                style={{
                                width: '120px',
                                height: '120px',
                                border: '2px solid black',
                                borderRadius: '50%',
                                overflow: 'hidden',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                                margin: '0 auto',
                                }}
                            >
                                <img
                                src={profileImage}
                                alt="Profile"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                />
                            </div>
                            )}
                        </Col>

                        <Col lg="12" className="text-center">
                            <Button
                            color="info"
                            onClick={() => document.getElementById('input-profile-image').click()}
                            >
                            <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>

                            <FormGroup style={{ display: 'none' }}>
                            <Input
                                type="file"
                                id="input-profile-image"
                                accept="image/*"
                                onChange={handleFileUpload}
                                
                            />
                            </FormGroup>
                        </Col>
                            </Row>
                            <div className="row">
                            <Col md={6}>
                                <FormGroup>
                                <label className="form-control-label" htmlFor="input-name">Client Name</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-name"
                                    type="text"
                                    name="client_name"
                                    defaultValue={updateValue.client_name}
                                    onChange={handleInputChange}
                                    placeholder='Client Name'
                                />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                <label className="form-control-label" htmlFor="input-name">Client Email</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-name"
                                    type="email"
                                    name="client_email"
                                    defaultValue={updateValue.client_email}
                                    onChange={handleInputChange}
                                    placeholder='Client Email'
                                />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                <label className="form-control-label" htmlFor="input-name">Client Number</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-name"
                                    type="tel"
                                    name="client_number"
                                    defaultValue={updateValue.client_number}
                                    onChange={handleInputChange}
                                    placeholder='Client Number'
                                />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                <label className="form-control-label" htmlFor="input-name">Company Name</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-name"
                                    type="text"
                                    name="company_name"
                                    defaultValue={updateValue.company_name}
                                    onChange={handleInputChange}
                                    placeholder='Company Name'
                                />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                <label className="form-control-label" htmlFor="input-location">Location</label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-name"
                                    type="location"
                                    name="address"
                                    defaultValue={updateValue.address}
                                    onChange={handleInputChange}
                                    placeholder='Location'
                                />
                                </FormGroup>
                            </Col>
                            </div>
                            {/* <Link to="/admin/client"> */}
                            
                            <div className="row">
                                <div className="col-md-12 text-center"> {/* Centered button */}
                               
                                    <Button type="submit" color="primary">Submit</Button>
                                    
                                </div>
                            </div>
                        </Form>
                    </div>
                </Container>

            </div>


        </>
    );
};

export default Editclient1;
