import React, { useState, useEffect } from "react";
import {
  Card,
  Badge,
  Button,
  Table,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Label,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
  CardHeader,
} from "reactstrap";

import { api } from "../../../../api";
import Cookies from "js-cookie";
import * as XLSX from "xlsx";

const Materials1 = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [entriesToShow, setEntriesToShow] = useState(5);
  const [modal, setModal] = useState(false); // State to control modal visibility

  const [material, setMaterial] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [dynamo, setDynamo] = useState([]);
  const [change, setChange] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [dropdownStates, setDropdownStates] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const statusOptions = [
    { value: "Approved", label: "Accept" },
    { value: "Rejected", label: "Reject" },
  ];

  const emp_id = Cookies.get("token");
  // let emp_id = 1;

  const [project, setProject] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `viewProjectDetails.php?g_m_id=${emp_id}`
        );
        setProject(response.data);
        console.log(response.data);
        const response2 = await api.get(
          `matListBasedOnProject.php?g_m_id=${emp_id}`
        );
        setMaterial(response2.data);
        setDynamo(response2.data);
        setMaterialList(response2.data);
        setChange(response2.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleModalView = () => {
    setModalOpen(!modalOpen);
  };

  const [selectedproject, setselectedproject] = useState();
  const [modalEmployee, setModalEmployee] = useState(null);

  const handleProjectSelection = async (p_id, p_name) => {
    setselectedproject(p_name);

    try {
      if (p_id == "all") {
        const response = await api.get(
          `matListBasedOnProject.php?g_m_id=${emp_id}`
        );
        console.log("API Response:", response.data);
        setMaterial(response.data);
        setChange(response.data);
        setCurrentPage(1);
        console.log(response);
      } else {
        const response = await api.get(
          `matListBasedOnProject.php?p_id=${p_id}`
        );
        console.log("API Response:", response.data);
        setMaterial(response.data);
        setChange(response.data);
        setCurrentPage(1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDropdownToggle = (index) => {
    setDropdownStates((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleItemClick = (id) => {
    // setSelectedId(id);
    setreport((prevState) => ({
      ...prevState,
      gm_id: id,
    }));
    toggleModal();
  };

  const handleStatusAction = async (e) => {
    // e.preventDefault();
    try {
      const response = await api.post("materialreport.php", report);
      console.log("Data sent successfully:", response.data);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case "pending":
        return <Badge color="warning">{status}</Badge>;
      case "Approved":
        return <Badge color="success">{status}</Badge>;
      case "Rejected":
        return <Badge color="danger">{status}</Badge>;
      default:
        return <Badge color="secondary">{status}</Badge>;
    }
  };

  const [report, setreport] = useState();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setreport((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    console.log(report);
  };

  //Pagination code part 2  goes here

  const [projectDropdownOpen1, setProjectDropdownOpen1] = useState(false);
  const totalPages = Math.ceil(dynamo.length / entriesToShow);

  const toggleProjectDropdown1 = () => {
    setProjectDropdownOpen1(!projectDropdownOpen1);
  };

  const handleEntriesChange = (value) => {
    setEntriesToShow(value === "All" ? materialList.length : Number(value));
  };

  const prePage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const getSerialNumber = (index) => {
    return (currentPage - 1) * entriesToShow + index + 1;
  };

  useEffect(() => {
    if (Array.isArray(change)) {
      setMaterial(
        change.slice(
          (currentPage - 1) * entriesToShow,
          currentPage * entriesToShow
        )
      );
    } else {
      setMaterial([]); // Handle case where change is not an array
    }
  }, [entriesToShow, currentPage, change]);

  useEffect(() => {
    if (Array.isArray(change)) {
      if (searchQuery.trim() === "") {
        setMaterial(
          change.slice(
            (currentPage - 1) * entriesToShow,
            currentPage * entriesToShow
          )
        );
      } else {
        const filteredDataDetails = change.filter(
          (item) =>
            item.mat_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.mat_status.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setMaterial(filteredDataDetails.slice(0, entriesToShow));
      }
    } else {
      setMaterial([]); // Handle case where change is not an array
    }
  }, [searchQuery, entriesToShow, currentPage, change]);

  const handleDownload = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "material_list";

    // Define the columns you want to keep and their new names
    const columnMapping = {
      mat_name: "Material Name",
      mat_money: "Budget",
      mat_quantity: "Quantity",
      Priority: "Priority",
      Date_Time: "Date",
    };

    // Filter the material array to only include the desired columns and map to new column names
    const filteredMaterial = material.map((item) => {
      let filteredItem = {};
      Object.keys(columnMapping).forEach((originalColumn) => {
        if (item[originalColumn] !== undefined) {
          filteredItem[columnMapping[originalColumn]] = item[originalColumn];
        }
      });
      return filteredItem;
    });

    const ws = XLSX.utils.json_to_sheet(filteredMaterial);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    // Apply bold and background color to headers
    const headerCellStyle = {
      font: { bold: true },
      fill: { bgColor: { indexed: 64 } },
    };
    Object.keys(ws)
      .filter((key) => key.startsWith("A1"))
      .forEach((key) => (ws[key].s = headerCellStyle));

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    const excelUrl = URL.createObjectURL(excelData);
    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = fileName + fileExtension;
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(excelUrl);
    }, 100);
  };

  const [activeIndex1, setActiveIndex1] = useState(0);
  const [animating, setAnimating] = useState(false);
  const imagesString = modalEmployee?.files;
  const imagesArray = imagesString ? imagesString.split(",") : [];

  const next1 = () => {
    if (animating || imagesArray.length === 0) return;
    const nextIndex =
      activeIndex1 === imagesArray.length - 1 ? 0 : activeIndex1 + 1;
    setActiveIndex1(nextIndex);
  };

  const previous1 = () => {
    if (animating || imagesArray.length === 0) return;
    const nextIndex =
      activeIndex1 === 0 ? imagesArray.length - 1 : activeIndex1 - 1;
    setActiveIndex1(nextIndex);
  };

  const goToIndex1 = (newIndex) => {
    if (animating || imagesArray.length === 0) return;
    setActiveIndex1(newIndex);
  };

  const slides1 = imagesArray
    .map((image, index) => {
      // Check if the image exists
      try {
        require(`../../../images/${image}`);
      } catch (error) {
        // Handle the case where the image doesn't exist
        console.error(`Image ${image} not found`);
        return null; // Return null to indicate that the slide should be skipped
      }

      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={index}
        >
          <div
            className="profile-image-container"
            style={{
              width: "376px",
              height: "256px",
              border: "2px solid black",
              borderRadius: "9%",
              overflow: "hidden",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
              margin: "0 auto",
            }}
          >
            <img
              id=""
              alt={`Uploaded Image ${index + 1 + image}`}
              src={require(`../../../images/${image}`)}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
          <CarouselCaption captionText="" captionHeader="" />
        </CarouselItem>
      );
    })
    .filter((slide) => slide !== null); // Filter out null values

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="">
            <Col>
              <Dropdown
                isOpen={projectDropdownOpen}
                toggle={() => setProjectDropdownOpen(!projectDropdownOpen)}
              >
                <DropdownToggle caret>
                  {selectedproject ? selectedproject : "All Projects"}
                </DropdownToggle>
                <DropdownMenu left>
                  <DropdownItem
                    onClick={() =>
                      handleProjectSelection("all", "All Projects")
                    }
                  >
                    All Projects
                  </DropdownItem>
                  {Array.isArray(project) ? (
                    project.map((project) => (
                      <DropdownItem
                        key={project.p_name}
                        onClick={() =>
                          handleProjectSelection(project.p_id, project.p_name)
                        }
                      >
                        {project.p_name}
                      </DropdownItem>
                    ))
                  ) : (
                    <DropdownItem>No Projects Found</DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </Col>

            <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
              <h1>Material Details</h1>
            </Col>
            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
              <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                <FormGroup className="mb-0 ml-6">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search Materials"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <marquee
            behavior="scroll"
            direction="right"
            style={{ height: "58px" }}
          >
            {/* <img src={require("../../assets/img/brand//240_F_364017781_DR0bENAGus0GPESus7E2w4JaT7wzIZ1M-removebg-preview.png")} style={{ height: '100px' ,marginbottom:'-17px' }} alt="Delivery Truck" /> */}
            <img
              src={require("../../assets/img/brand/delivery-truck-1--unscreen.gif")}
              style={{ height: "80px", marginbottom: "-17px" }}
              alt="Delivery Truck"
            />
            Material Delivery
          </marquee>
          <Row className="">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Material List</h3>
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="mr-3">
                        <Dropdown
                          isOpen={projectDropdownOpen1}
                          toggle={toggleProjectDropdown1}
                        >
                          <DropdownToggle caret>
                            Show Entries:{" "}
                            {entriesToShow === dynamo.length
                              ? "All"
                              : entriesToShow}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => handleEntriesChange(5)}
                            >
                              5
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleEntriesChange(10)}
                            >
                              10
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleEntriesChange("All")}
                            >
                              All
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <button
                        className="btn btn-success mr-2"
                        onClick={handleDownload}
                      >
                        Download
                      </button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Material Name</th>
                      <th scope="col">Quality</th>
                      <th scope="col">Prices</th>
                      <th scope="col">Date</th>
                      <th scope="col">Priority</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {material && material.length > 0 ? (
                      material.map((material, index) => (
                        <tr key={index}>
                          <td>{material.mat_name}</td>
                          <td>{material.mat_quantity}</td>
                          <td>{material.mat_money}</td>
                          <td>{material.Date_Time}</td>
                          <td>
                            {material.Priority === "Low" ? (
                              <Badge color="success">{material.Priority}</Badge>
                            ) : (
                              <Badge color="warning">{material.Priority}</Badge>
                            )}
                          </td>
                          <td>{getStatusBadge(material.mat_status)}</td>
                          <td>
                            <button
                              className="btn btn-success"
                              onClick={() => {
                                setModalEmployee(material);
                                toggleModalView();
                              }}
                            >
                              <i
                                className="fa-solid fa-eye"
                                style={{ fontSize: "22px" }}
                              ></i>
                            </button>
                            <button
                              className="btn btn-success"
                              onClick={() => handleItemClick(material.m_id)}
                            >
                              Set Action
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" style={{ textAlign: "center" }}>
                          No details available, Project not yet started
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <nav className="mt-3">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a
                        href="#"
                        className="page-link text-primary"
                        onClick={prePage}
                        disabled={currentPage === 1}
                      >
                        Prev
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        href="#"
                        className="page-link text-primary"
                        onClick={nextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          <h2 style={{ fontWeight: "bolder" }}>Status Action</h2>
        </ModalHeader>
        <ModalBody>
          {/* Your form elements go here */}
          <Form onSubmit={handleStatusAction}>
            <FormGroup>
              <Label for="exampleSelect">Select</Label>
              <Input
                className="form-control-alternative"
                id="input-status"
                type="select"
                name="status"
                onChange={handleInputChange}
              >
                <option value="">Select Status</option>
                {statusOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">Description</Label>
              <Input
                type="textarea"
                name="description"
                id="exampleText"
                onChange={handleInputChange}
              />
            </FormGroup>
            <Row>
              <Col lg="12" style={{ textAlign: "center" }}>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
            {/* Add more form fields as needed */}
          </Form>
        </ModalBody>
      </Modal>

      {modalEmployee && modalEmployee && (
        <Modal isOpen={modalOpen} toggle={toggleModalView}>
          <ModalHeader toggle={toggleModalView}>Material View</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <label
                    className="border p-2 rounded font-weight-bold"
                    style={{ width: "155px" }}
                  >
                    Material Name
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.mat_name}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Budget
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.mat_money}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Quantity
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.mat_quantity}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Priority
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.Priority}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Date
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.Date_Time}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    General Manager
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.mat_status}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Store Manager
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.store_status}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Action
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <label className="border p-2 rounded ">
                    {modalEmployee.store_report}
                  </label>
                </div>
                <div>
                  <label
                    className="border p-2 rounded font-weight-bold "
                    style={{ width: "155px" }}
                  >
                    Uploaded Images
                  </label>
                  <label className=" p-2 rounded font-weight-bold m-1 ">
                    :
                  </label>
                  <Carousel
                    activeIndex={activeIndex1}
                    next={next1}
                    previous={previous1}
                  >
                    <CarouselIndicators
                      items={imagesArray}
                      activeIndex={activeIndex1}
                      onClickHandler={goToIndex1}
                    />

                    {slides1}

                    <CarouselControl
                      direction="prev"
                      directionText="Previous"
                      onClickHandler={previous1}
                    />
                    <CarouselControl
                      direction="next"
                      directionText="Next"
                      onClickHandler={next1}
                    />
                  </Carousel>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default Materials1;
