/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import {api} from '../../../../api'
import Cookies from 'js-cookie';

const AdminNavbar = (props) => {
  const [tasks, setTasks] = useState({
    username : '',
    profile_image: ''
  });

  console.log("Tasks",tasks)

  useEffect(() => {
    const cookieId = Cookies.get('token');
    const fetchData = async () => {
      try {
        const response = await api.get(`PMprofileView.php?id=${cookieId}`);
        setTasks({
          username: response.data.emp_name,
          profile_image : response.data.profile_image
        })
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);


  // console.log(tasks);


  const handleLogout = (token) => {
    Cookies.remove(token);
    // window.location.storeef = '/login';
  };
  
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    {tasks.profile_image && // Render img tag only if profile_image is not null or empty
                      <img
                      style={{
                        width: '50px',
                        height: '40px',
                      }}
                        alt="..."
                        src={require(`../../../images/${tasks.profile_image}`)}
                      />
                    }
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                     
                      {tasks.username}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/president-admin/president-management/user-profile6" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem to="/login" tag={Link}  onClick={() => handleLogout('token')}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};


export default AdminNavbar;
