import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, Table, Container, Row, Col, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Badge, Label } from "reactstrap";
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {api} from '../../../../api';
import Cookies from 'js-cookie';
import * as XLSX from 'xlsx';

const Resource1 = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    //Pagination code part 1 goes here...
    const [entriesToShow, setEntriesToShow] = useState(5);
    const [material, setMaterial] = useState([]);
    const [resourceList, setResourceList] = useState([]);
    const [dynamo, setDynamo] = useState([]);
    const [change, setChange] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);


    const [dropdownStates, setDropdownStates] = useState([]);

    const [modalEmployee, setModalEmployee] = useState(null);
    
    const filtered = Array.isArray(material) ? 
    material.filter(item =>
        (item.description || item.role).toLowerCase().includes(searchQuery.toLowerCase())
    ) : 
    [];


    const emp_id = Cookies.get('token');
    // let emp_id = 1;
    
    const [project, setProject] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`viewProjectDetails.php?g_m_id=${emp_id}`);
                setProject(response.data);
                console.log(response.data);
                const response2 = await api.get(`requestresourse.php?g_m_id=${emp_id}`);
                setMaterial(response2.data);
                setDynamo(response2.data);
                setResourceList(response2.data);
                setChange(response2.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const statusOptions = [
        { value: "Approved", label: "Accept" },
        { value: "Rejected", label: "Reject" }
      ];


    const [modalOpen, setModalOpen] = useState(false);
    const [selectedproject, setselectedproject] = useState();
    const [modal, setModal] = useState(false); // State to control modal visibility

    const toggleModalView = () => {
        setModalOpen(!modalOpen);
    };

    const toggleModal = () => {
        setModal(!modal);
    };

    const [report, setreport] = useState({
        gm_id : emp_id,
        r_id : '',
        status : '',
        report : ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setreport((prevState) => ({
        ...prevState,
        [name]: value,
        }));

        console.log(report)
    };

    const handleItemClick = (id) => {
        // setSelectedId(id);
        setreport((prevState) => ({
          ...prevState,
          r_id : id,
        }));
        toggleModal();
      };

    const handleProjectSelection = async (p_id, p_name) => {

        setselectedproject(p_name);

        try {
            if(p_id == "all"){
                const response = await api.get(`requestresourse.php?g_m_id=${emp_id}`);
                console.log('API Response:', response.data);
                setMaterial(response.data);
                setChange(response.data);
                setCurrentPage(1);
              }else{
                const response = await api.get(`requestresourse.php?p_id=${p_id}`);
                console.log('API Response:', response.data);
                setMaterial(response.data);
                setChange(response.data);
                setCurrentPage(1);
              }  
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleDropdownToggle = (index) => {
        setDropdownStates(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const handleStatusAction = async (id, status) => {
        // e.preventDefault();
        try {
            const response = await api.post('resourceAction.php', report);
            console.log(response.data);
        } catch (error) {
            console.error('Error updating status:', error);
        }
        // window.location.reload();
    };

    //Pagination code part2 goes here
    const [projectDropdownOpen1, setProjectDropdownOpen1] = useState(false);
    const totalPages = Math.ceil(dynamo.length / entriesToShow);

    const toggleProjectDropdown1 = () => {
        setProjectDropdownOpen1(!projectDropdownOpen1);
    };

    const handleEntriesChange = (value) => {
        setEntriesToShow(value === 'All' ? resourceList.length : Number(value));
    };

    const prePage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const getSerialNumber = (index) => {
        return (currentPage - 1) * entriesToShow + index + 1;
    };

    useEffect(() => {
        if (Array.isArray(change)) {
            setMaterial(change.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
        } else {
            setMaterial([]); // Handle case where change is not an array
        }
    }, [entriesToShow, currentPage, change]);
    
    useEffect(() => {
        if (Array.isArray(change)) {
            if (searchQuery.trim() === "") {
                setMaterial(change.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
            } else {
                const filteredDataDetails = change.filter(item =>
                    item.role.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item.hr_status.toLowerCase().includes(searchQuery.toLowerCase())
                );
                setMaterial(filteredDataDetails.slice(0, entriesToShow));
            }
        } else {
            setMaterial([]); // Handle case where change is not an array
        }
    }, [searchQuery, entriesToShow, currentPage, change]);
    

   
    function removePTags(description) {
        return description.replace(/<\/?p>/g, '');
    }


    const handleDownload = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = 'Resourse_list';
    
        // Define the columns you want to keep and their new names
        const columnMapping = {
            'role': 'Resource Type',
            'quantity': 'Quantity',
            'status' : 'Status'
        };
    
        // Filter the material array to only include the desired columns and map to new column names
        const filteredMaterial = material.map(item => {
            let filteredItem = {};
            Object.keys(columnMapping).forEach(originalColumn => {
                if (item[originalColumn] !== undefined) {
                    filteredItem[columnMapping[originalColumn]] = item[originalColumn];
                }
            });
            return filteredItem;
        });
    
        const ws = XLSX.utils.json_to_sheet(filteredMaterial);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    
        // Apply bold and background color to headers
        const headerCellStyle = { font: { bold: true }, fill: { bgColor: { indexed: 64 } } };
        Object.keys(ws).filter(key => key.startsWith('A1')).forEach(key => ws[key].s = headerCellStyle);
    
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const excelData = new Blob([excelBuffer], { type: fileType });
        const excelUrl = URL.createObjectURL(excelData);
        const link = document.createElement('a');
        link.href = excelUrl;
        link.download = fileName + fileExtension;
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(excelUrl);
        }, 100);
    };
    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Container fluid>
                    <Row className=''>
                   
                        <Col>
                        
                            <Dropdown isOpen={projectDropdownOpen} toggle={() => setProjectDropdownOpen(!projectDropdownOpen)}>
                                <DropdownToggle caret>
                                {selectedproject ? selectedproject : "All Projects"}
                                </DropdownToggle>
                                <DropdownMenu left>
                                <DropdownItem onClick={() => handleProjectSelection("all", "All Projects")}>
                                    All Projects
                                </DropdownItem>
                                {Array.isArray(project) ? (
                                    project.map(project => (
                                        <DropdownItem key={project.p_name} onClick={() => handleProjectSelection(project.p_id, project.p_name)}>
                                            {project.p_name}
                                        </DropdownItem>
                                    ))
                                ) : (
                                    <DropdownItem >
                                            No Projects Found
                                    </DropdownItem>
                                )}

                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        
                        <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
                            <h1>Resource Details</h1>
                        </Col>
                        <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
                            <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                                <FormGroup className="mb-0 ml-6">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Search Materials"
                                            type="text"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <marquee behavior="scroll" direction="right"  style={{height:'68px'}}>
                    {/* <img src={require("../../assets/img/brand//240_F_364017781_DR0bENAGus0GPESus7E2w4JaT7wzIZ1M-removebg-preview.png")} style={{ height: '100px' ,marginbottom:'-17px' }} alt="Delivery Truck" /> */}
                    <img src={require("../../../images/cab (2).gif")} style={{ height: '80px' ,marginbottom:'-17px' }} alt="Delivery Truck" />
                    Resource Delivery
                        </marquee>
                    <Row className="">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Resource List</h3>
                                        </div>
                                        <div className="d-flex justify-content-end align-items-center">
                                            <div className="mr-3">
                                                <Dropdown isOpen={projectDropdownOpen1} toggle={toggleProjectDropdown1}>
                                                    <DropdownToggle caret>
                                                        Show Entries: {entriesToShow === dynamo.length ? "All" : entriesToShow}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => handleEntriesChange(5)}>5</DropdownItem>
                                                        <DropdownItem onClick={() => handleEntriesChange(10)}>10</DropdownItem>
                                                        <DropdownItem onClick={() => handleEntriesChange('All')}>All</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                            <button className="btn btn-success mr-2" onClick={handleDownload}>Download</button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">SL.No</th>
                                            <th scope="col">Resource Type</th>
                                            <th scope="col">Quality</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {material && material.length > 0 ? (
                                    material.map((material, index) => (
                                        <tr key={index}>
                                            <th scope="row">{getSerialNumber(index)}</th>
                                        <td>{material.role}</td>
                                        <td>{material.quantity}</td>
                                        <td dangerouslySetInnerHTML={{ __html: removePTags(material.description) }}></td>
                                        <td>
                                        {
                                            material.status === "pending" ? (
                                                <Badge color='warning' >{material.status}</Badge>
                                            ) : material.status === "Rejected" ? (
                                                <Badge color='danger' >{material.status}</Badge>
                                            ) : (
                                                <Badge color='success'>{material.status}</Badge>
                                            )
                                        }
                                        </td>
                                        <td>
                                            <button className='btn btn-success' onClick={() => { setModalEmployee(material); toggleModalView(); }}><i className="fa-solid fa-eye" style={{fontSize:'22px'}} ></i></button>
                                            <button className='btn btn-success' onClick={() => handleItemClick(material.r_id) }>Set Action</button>
                                        </td>
                                        </tr>
                                    ))
                                    ) : (
                                    <tr>
                                        <td colSpan="5" style={{ textAlign: 'center' }}>No details available, Project not yet started</td>
                                    </tr>
                                    )}

                                    </tbody>
                                </Table>
                                <nav className='mt-3'>
                                    <ul className='pagination justify-content-center'>
                                        <li className='page-item'>
                                            <a
                                                href='#'
                                                className='page-link text-primary'
                                                onClick={prePage}
                                                disabled={currentPage === 1}
                                            >
                                                Prev
                                            </a>
                                        </li>
                                        <li className='page-item'>
                                            <a
                                                href='#'
                                                className='page-link text-primary'
                                                onClick={nextPage}
                                                disabled={currentPage === totalPages}
                                            >
                                                Next
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}><h2 style={{fontWeight:'bolder'}}>Status Action</h2></ModalHeader>
                <ModalBody>
                    {/* Your form elements go here */}
                    <Form onSubmit={handleStatusAction} >
                        <FormGroup>
                            <Label for="exampleSelect">Select</Label>
                            <Input
                                className="form-control-alternative"
                                id="input-status"
                                type="select"
                                name="status"
                                onChange={handleInputChange}
                            >
                                <option value="">Select Status</option>
                                {statusOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleText">Description</Label>
                            <Input type="textarea" name="report" id="exampleText" onChange={handleInputChange} />
                        </FormGroup>
                        <Row>
                            <Col lg='12' style={{ textAlign: 'center' }}>
                                <Button color="primary" type="submit">Submit</Button>
                            </Col>
                            </Row>
                        {/* Add more form fields as needed */}
                    </Form>
                </ModalBody>
            </Modal>

            {modalEmployee && modalEmployee && (
              <Modal isOpen={modalOpen} toggle={toggleModalView}>
                <ModalHeader toggle={toggleModalView}>Resource View</ModalHeader>
                <ModalBody >
                <div className="row">
                                    <div className="col-md-12">
                                        <div className=''>
                                            <label className='border p-2 rounded font-weight-bold' style={{width:'155px'}}>Material Type</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.role}</label>
                                        </div>
                                        <div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>Quantity</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.quantity}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>Description</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.description.replace(/<\/?[^>]+(>|$)/g, '')}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>General Manager</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.status}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>HR</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.hr_status}</label>
                                        </div><div>
                                            <label className='border p-2 rounded font-weight-bold 'style={{width:'155px'}}>HR Action</label>
                                            <label className=' p-2 rounded font-weight-bold m-1 '>:</label>
                                            <label className='border p-2 rounded '>{modalEmployee.hr_report}</label>
                                        </div>
                                    </div>
                </div>
                </ModalBody>
              </Modal>
            )}
        </>
    );
};
export default Resource1;
