import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import Welcome from '../dashboard/images/Welcome.jpeg';

const WelcomeMessage = () => {
  const { name } = useParams();
  
  return (
    <Container style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Row>
        <Col>
          <h2 className='p-5 text-center'>Thank You {name} for Registering <br /> with AECP!</h2>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-center mb-3'>
          <img 
            src={Welcome} 
            alt='welcome-image' 
            style={{
              maxHeight: '70vh',
              maxWidth: '100%',
              objectFit: 'contain'
            }} 
            className='welcome-img' 
          />
        </Col>
      </Row>
    </Container>
  );
}

export default WelcomeMessage;
