
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { api } from '../../../../api';
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,

} from "reactstrap";
import Cookies from 'js-cookie';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { showSuccessToast, showErrorToast } from '../../../../toastUtils';


const Edittask = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [showAddClientModal, setShowAddClientModal] = useState(false); // New state for Add Project modal
    const [clientForm, setClientForm] = useState({});
    const [selectedSiteManagers, setSelectedSiteManagers] = useState([]);


    const toggleModal = () => setModalOpen(prevState => !prevState);
    const toggleAddClientModal = () => setShowAddClientModal(prevState => !prevState); // Function to toggle Add Project modal

    const emp_id = Cookies.get('token');

    const { id } = useParams();
    const [updateValue, setupdateValues] = useState({
        status: '',
        id: id,
        p_id: '',
        emp_id: '',
        taskname: '',
        assign_to: '',
        start_date: '',
        end_date: '',
        description: '',
        image: null
    })

    useEffect(() => {
        api
            .get("viewTask.php?t_id=" + id)
            .then((res) => {
                setupdateValues({
                    ...updateValue,
                    status: res.data[0].status,
                    emp_id: res.data[0].emp_id,
                    p_id: res.data[0].p_id,
                    taskname: res.data[0].taskname,
                    assign_to: res.data[0].assign_to,
                    start_date: res.data[0].start_date,
                    end_date: res.data[0].end_date,
                    description: res.data[0].t_description,
                    image: res.data[0].image,
                });
                // console.log(res.data);
                console.log(updateValue)
            })
            .catch((err) => console.log(err));
    }, [id]);

    const formData = new FormData();
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            formData.append('t_id', updateValue.id);
            formData.append('p_id', updateValue.p_id);
            formData.append('taskname', updateValue.taskname);
            formData.append('assign_to', updateValue.assign_to);
            formData.append('start_date', updateValue.start_date);
            formData.append('end_date', updateValue.end_date);
            formData.append('t_description', updateValue.description);
            formData.append('status', updateValue.status);
            formData.append('image', updateValue.image);

            console.log(formData)



            const response = await api.post(`edittask.php`, formData);

            console.log("Data sent successfully:", response.data);
            showSuccessToast("Data Updated Successfully")
            window.history.back();



            // window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
            showErrorToast("Error Submitting Data");
        }

    };



    const [searchQuery, setSearchQuery] = useState('');
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [selectedManager, setSelectedManager] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');




    const toggleDropdown = (employeeId) => {
        setFilteredEmployees(prevEmployees => prevEmployees.map(employee => {
            if (employee.id === employeeId) {
                return { ...employee, dropdownOpen: !employee.dropdownOpen };
            }
            return employee;
        }));
    };

    // Function to filter employees based on search query
    const filterEmployees = () => {
        if (!searchQuery) {
            setFilteredEmployees(employees);
        } else {
            const filtered = employees.filter(employee =>
                employee.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredEmployees(filtered);
        }
    };







    const handleManagerSelectChange = (selectedOption) => {
        setSelectedManager(selectedOption);
    };

    const handleLocationSelectChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
    };

    const handleSiteManagerSelectChange = (selectedOptions) => {
        setSelectedSiteManagers(selectedOptions);
        setClientForm(prevState => ({
            ...prevState,
            siteManagers: selectedOptions.map(option => option.value)
        }));
    };

    const statusOptions = [
        { value: 'to-do', label: 'to-do' },
        { value: 'in-progress', label: 'in-progress' },
        { value: 'completed', label: 'completed' }
    ];


    const [employees, setemployees] = useState();
    const fetchEmployeeData = async () => {
        try {
            const response = await api.get("employeesView.php");
            setemployees(response.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchEmployeeData();
    }, []);


    const handleStatusChange = (event) => {
        setupdateValues({ ...updateValue, status: event.target.value });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setupdateValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        console.log(updateValue);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        formData.append('files', file);
    };

    const [project, setproject] = useState([]);
    const fetchProjectData = async () => {
        try {
            const response = await api.get(`viewProjectDetails.php?p_m_id=${emp_id}`);
            setproject(response.data);
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Update filtered employees when searchQuery changes
    useEffect(() => {
        filterEmployees();
        fetchProjectData();
    }, [searchQuery]);


    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Col className="order-xl-2 mb-5 mb-xl-0 text-center  mb-5">
                    <h1>Edit Task Details</h1>
                </Col>
                <Button className='ml-5' onClick={() => window.history.back()}>Back</Button>
                <Container className="py-4 ">
                    <div className="p-4 rounded" style={{ backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid black' }}>

                        <Row className='px-3'>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Project Name</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-status"
                                        type="select"
                                        name="p_id"
                                        value={updateValue.p_id}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Project</option>
                                        {project && Array.isArray(project) && project.map(option => (
                                            <option key={option.p_id} value={option.p_id}>{option.p_name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Task Status</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-status"
                                        type="select"
                                        name="status"
                                        onChange={handleStatusChange}
                                        value={updateValue.status}
                                    >
                                        {statusOptions.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-name">Task Name</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-name"
                                        type="text"
                                        name="taskname"
                                        defaultValue={updateValue.taskname}
                                        placeholder='Project Name'
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-manager">Select Assign To</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-manager"
                                        type="select"
                                        value={updateValue.assign_to} // Ensure you're using the correct state value
                                        onChange={(e) => setupdateValues({ ...updateValue, assign_to: e.target.value })} // Update status, not assign_to
                                    >
                                        {employees && employees.map(option => (
                                            <option key={option.emp_id} value={option.emp_id}>{option.emp_name}</option>
                                        ))}
                                    </Input>


                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-starting">Task Starting Date</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-starting"
                                        type="datetime-local"
                                        name="start_date"
                                        defaultValue={updateValue.start_date}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-ending">Task Ending Date</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-ending"
                                        type="datetime-local"
                                        name="end_date"
                                        defaultValue={updateValue.end_date}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-description">Project Description</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={updateValue.description}
                                        onChange={(event, editor) => {
                                            const data = editor.getData(); // Corrected: Use editor.getData() instead of event.data
                                            setupdateValues(prevState => ({
                                                ...prevState,
                                                description: data // Corrected: Use t_description to update the description field
                                            }));
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-file">Upload File</label>
                                    <Input
                                        type="file"
                                        className="form-control-file"
                                        id="input-file"
                                        name="file"

                                        onChange={handleFileChange}
                                    />
                                    {updateValue.image && (
                                        <h4>Old file: {updateValue.image}</h4>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='12' className='text-center mb-2'>
                                <Button color="primary" type="submit" onClick={handleSubmit} >Submit</Button>
                            </Col>
                        </Row>
                    </div>
                </Container>


            </div>


        </>
    );
};

export default Edittask;