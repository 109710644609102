import React, { useState, useEffect } from 'react';
import backgroundImage from './dashboard/project-management/assets/img/brand/gif6.gif';
import { Button } from 'reactstrap';
import { api } from './api';

const Login = () => {
    const [showLogin, setShowLogin] = useState(false); // State variable to control login div visibility
    const [loginData, setLoginData] = useState({ email: '', password: '' });

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLogin(true);
        }, 45);

        // Check if token exists on component mount
        const cookieValue = getCookie('token');
        const cookieValue1 = getCookie('path');
        if (cookieValue && cookieValue1 ) {
           
            window.location.href = cookieValue1;
        }

        return () => clearTimeout(timeoutId);
    }, []);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };



    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post("Login.php", loginData);
            const status = response.data.message;
            console.log(status);
            if(status != "Failed"){
                const token = response.data.token;
                const path = response.data.path; 
                const role = response.data.role;  
                
                if(role!=undefined || role!=null){
                    document.cookie = `role=${role}; path=/`;  
                }
                
                document.cookie = `token=${token}; path=/`;
                document.cookie = `path=${path}; path=/`; 
                window.location.href =path ;
            }else{
                alert("Invalid username or password");
                // window.location.reload();
            }
            
        } catch (error) {
            console.error("Error submitting data:", error);
            
        }
    };
    

    const getCookie = (name) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return '';
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {showLogin && (
                <div className="Container" style={styles.overlay}>
                    <div style={styles.loginCard}>
                        <h1>Login</h1>
                        <form onSubmit={handleSubmit}>
                            <label>
                                <div style={{ position: 'relative' }}>
                                    <i className="fas fa-user" style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}></i>
                                    <input
                                        type="email"
                                        name='email'
                                        required
                                        placeholder='Email'
                                        value={loginData.email}
                                        onChange={handleInputChange}
                                        style={styles.input}
                                    />
                                </div>
                            </label>
                            <br />
                            <label>
                            <div style={{ position: 'relative' }}>
                                <i className="fas fa-lock" style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}></i>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    name='password'
                                    placeholder='Password'
                                    required
                                    value={loginData.password}
                                    onChange={handleInputChange}
                                    style={styles.input}
                                />
                                <i
                                    className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                    onClick={togglePasswordVisibility}
                                    style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                ></i>
                            </div>
                            </label>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <Button type="submit" color="primary" style={styles.btn}>Submit</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 1,
        opacity: 1,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        animation: 'fadeInFromRight 2s forwards', // Animation to fade in from right
    },
    loginCard: {
        position: 'relative',
        maxWidth: '400px', // Limit maximum width for larger screens
        backgroundColor: 'rgba(20, 205, 239, 0.4)', // Transparent background
        borderRadius: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        zIndex: 2,
    },
    input: {
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: '3px',
        padding: '8px 30px 8px 30px',
        margin: '5px',
    },
    btn: {
        display: 'block',
        width: '50%',
        margin: 'auto',
        padding: '10px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    },
    // Animation keyframes for fading in from right
    '@keyframes fadeInFromRight': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
};

export default Login;
