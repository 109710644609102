import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";


import "dashboard/project-management/assets/plugins/nucleo/css/nucleo.css"
import "dashboard/general-management/assets/plugins/nucleo/css/nucleo.css"
import "dashboard/site-management/assets/plugins/nucleo/css/nucleo.css"


import "dashboard/store-management/assets/plugins/nucleo/css/nucleo.css"



import "@fortawesome/fontawesome-free/css/all.min.css";
import "dashboard/general-management/assets/scss/argon-dashboard-react.scss"
import "dashboard/project-management/assets/scss/argon-dashboard-react.scss"
import "dashboard/site-management/assets/scss/argon-dashboard-react.scss"

import "dashboard/store-management/assets/scss/argon-dashboard-react.scss"

import AdminLayout from "dashboard/project-management/layouts/Admin";
import AuthLayout from "dashboard/project-management/layouts/Auth";
import AdminLayoutGeneral from "dashboard/general-management/layouts/Admin";
import AuthLayoutGeneral from "dashboard/general-management/layouts/Auth";
import AdminLayoutsite from "dashboard/site-management/layouts/Admin";
import AuthLayoutsite from "dashboard/site-management/layouts/Auth";
import AdminLayouthr from "dashboard/hr-management/layouts/Admin";
import AuthLayouthr from "dashboard/hr-management/layouts/Auth";

import AdminLayoutstore from "dashboard/store-management/layouts/Admin";
import AuthLayoutstore from "dashboard/store-management/layouts/Auth";
import Login from './Login'


import ProtectedRoute from "ProtectedRoute";
import AdminLayoutclient from "dashboard/client-management/layouts/Admin";
import AuthLayoutclient from "dashboard/client-management/layouts/Auth";
import EmployeeForm from "Registration-Form/employeeForm";
import WelcomeMessage from "Registration-Form/welcomeMessage";
import { ToastContainer } from 'react-toastify'; // Import the ToastContainer
import 'react-toastify/dist/ReactToastify.css';

//President
import AdminLayoutpresident from "dashboard/president-management/layouts/Admin";
import AuthLayoutpresident from "dashboard/president-management/layouts/Auth";

const root = ReactDOM.createRoot(document.getElementById("root"));



root.render(
  <BrowserRouter>
    <Routes>
    <Route path="/project-admin/*" element={
      <ProtectedRoute>
        <AdminLayout />
      </ProtectedRoute>
    } />

    
      <Route path="/project-auth/*" element={
      <ProtectedRoute>
      <AuthLayout />
      </ProtectedRoute>
      } />


      <Route path="/general-admin/*" element={
        <ProtectedRoute>
          <AdminLayoutGeneral />
        </ProtectedRoute>
      } />
      <Route path="/general-auth/*" element={<AuthLayoutGeneral />} />



      <Route path="/site-admin/*" element={
      <ProtectedRoute>
        <AdminLayoutsite />
      </ProtectedRoute>
      } />
      <Route path="/site-auth/*" element={<AuthLayoutsite />} />


      <Route path="/hr-admin/*" element={
      <ProtectedRoute>
       <AdminLayouthr />
      </ProtectedRoute>
      } />
      <Route path="/hr-auth/*" element={<AuthLayouthr />} />


      <Route path="/store-admin/*" element={
      <ProtectedRoute>
       <AdminLayoutstore />
      </ProtectedRoute>
      } />
      <Route path="/store-auth/*" element={<AuthLayoutstore />} />      <Route path="/" element={<Navigate to={'/login'} replace/>} />

      <Route path="/client-admin/*" element={
      <ProtectedRoute>
      <AdminLayoutclient />
     </ProtectedRoute>} 
     />
      <Route path="/client-auth/*" element={<AuthLayoutclient />} />

      <Route path='/president-admin/*' element={
        <ProtectedRoute>
          <AdminLayoutpresident />
        </ProtectedRoute>
      } />
      <Route path='/president-auth/*' element={<AuthLayoutpresident/>} />


      <Route path="/login" element={<Login />} />

      <Route path="/employee-form" element={<EmployeeForm/>}/>

      <Route path="/welcome-message/:name" element={<WelcomeMessage/>}/>


      
    </Routes>
    <ToastContainer />
  </BrowserRouter>
);
