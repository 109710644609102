import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { api } from "../../../../api";
import Cookies from "js-cookie";

const Clientproject1 = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [clientForm, setClientForm] = useState({
    name: "",
    status: "",
    Starting: "",
    Ending: "",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDepartmentOptions, setSelectedDepartmentOptions] = useState(
    []
  );
  const [selectedMemberOptions, setSelectedMemberOptions] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const toggleModal = () => setModalOpen((prevState) => !prevState);
  const toggleAddClientModal = () =>
    setShowAddClientModal((prevState) => !prevState);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");

  const toggleAddProjectModal = () =>
    setShowAddProjectModal(!showAddProjectModal);

  const handleProjectChange = (e) => {
    setSelectedProject(e.target.value);
  };

  const [clientproject, setclientproject] = useState();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setclientproject((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    console.log(clientproject);
  };

  const handleFormSubmit = async (e) => {
    // e.preventDefault();
    try {
      const response = await api.post("clientproject.php", clientproject);
      console.log("Data sent successfully:", response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    setShowAddClientModal(false);
  };

  const toggleDropdown = (employeeId) => {
    setFilteredEmployees((prevEmployees) =>
      prevEmployees.map((employee) => {
        if (employee.id === employeeId) {
          return { ...employee, dropdownOpen: !employee.dropdownOpen };
        }
        return employee;
      })
    );
  };

  const filterEmployees = () => {
    if (!searchQuery) {
      setclientprojects(searchData);
    } else {
      const filtered = searchData.filter((employee) =>
        employee.c_p_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setclientprojects(filtered);
    }
  };

  useEffect(() => {
    filterEmployees();
  }, [searchQuery]);

  const handleDeleteConfirmation = (confirmed, p_c_id) => {
    if (confirmed) {
      console.log(`Deleting employee with ID: ${p_c_id}`);
      const fetchData = async () => {
        try {
          const response = await api.get(`deleteProject.php?id=${p_c_id}`);
          console.log(response.data);
          window.location.reload();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
    toggleDeleteConfirmationModal(p_c_id);
  };

  const handleAddProject = () => {
    // Handle adding project logic here
    console.log("Selected Project:", selectedProject);
    // You can perform further actions here, like submitting the form or calling an API
    // Reset selected project and close modal
    setSelectedProject("");
    toggleAddProjectModal();
  };

  const handleDepartmentSelectChange = (selectedOptions) => {
    setSelectedDepartmentOptions(selectedOptions);
    setClientForm((prevState) => ({
      ...prevState,
      department: selectedOptions.map((option) => option.value), // Assuming you have a 'department' field in clientForm
    }));
  };

  const handleMemberSelectChange = (selectedOptions) => {
    setSelectedMemberOptions(selectedOptions);
    setClientForm((prevState) => ({
      ...prevState,
      members: selectedOptions.map((option) => option.value), // Assuming you have a 'members' field in clientForm
    }));
  };

  const handleManagerSelectChange = (selectedOption) => {
    setSelectedManager(selectedOption);
  };

  const handleLocationSelectChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
  };

  const toggleViewMoreModal = (employee) => {
    setSelectedEmployee(employee);
  };

  const renderViewMoreModal = () => {
    if (!selectedEmployee) return null;

    return (
      <Modal isOpen={true} toggle={() => toggleViewMoreModal(null)}>
        <ModalHeader toggle={() => toggleViewMoreModal(null)}>
          Employee View
        </ModalHeader>
        <ModalBody style={{ justifyContent: "center", textAlign: "center" }}>
          <img
            alt="..."
            className="rounded-circle"
            src={selectedEmployee.image}
            style={{ height: 100, marginBottom: 10 }}
          />
          <p>Name: {selectedEmployee.name}</p>
          <p>Date Of Birth: {selectedEmployee.age}</p>
          {/* Add other employee details as needed */}
        </ModalBody>
      </Modal>
    );
  };



  const [clientprojects, setclientprojects] = useState();
  const [searchData,setSearchData] = useState();
  const [clientName, setclientName] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response2 = await api.get("clientproject.php");
        setclientprojects(response2.data);
        setSearchData(response2.data);
        const response = await api.get("clientGM.php");
        setclientName(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [deleteConfirmationEmployeeId, setDeleteConfirmationEmployeeId] =
    useState(null);

  const toggleDeleteConfirmationModal = (employeeId) => {
    setDeleteConfirmationEmployeeId(
      employeeId === deleteConfirmationEmployeeId ? null : employeeId
    );
  };

  const handleDropdownItemClick = (action, employeeId, employeeName) => {
    if (action === "Delete") {
      // Open delete confirmation modal
      toggleDeleteConfirmationModal(employeeId);
    } else {
      console.log(`Clicked ${action}`);
    }
  };

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="mb-5">
            <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
              <h1>Client Project Details</h1>
            </Col>
            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex">
              <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search Project Name"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
              <Button onClick={toggleAddClientModal}>Add Project</Button>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            {clientprojects && clientprojects.length > 0 ? (
              clientprojects.map((employee) => (
                <Col md="3" key={employee.c_p_id}>
                  <Card
                    className="card-profile shadow mb-5 "
                    style={{ borderRadius: "50px" }}
                  >
                    <CardBody className="pt-0 pt-md-5 mt-0">
                      <div className="text-center">
                        <h3>Project Name : {employee.c_p_name}</h3>
                        <h3>Project Budget : {employee.c_p_budget}</h3>
                        <h3>Project Squarefeet : {employee.c_p_sqrfeet}</h3>
                        <h3>Location : {employee.c_p_location}</h3>
                        <h3>End-Date : {employee.c_p_timeline}</h3>
                        <h3>
                          Contract Details :{" "}
                          {employee.contract_details.replace(
                            /<\/?[^>]+(>|$)/g,
                            ""
                          )}
                        </h3>
                        <hr className="my-4" />
                        <i
                          className="fa fa-trash"
                          onClick={() =>
                            handleDropdownItemClick(
                              "Delete",
                              employee.p_c_id,
                              employee.p_c_name
                            )
                          }
                        ></i>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <div>No projects available</div>
            )}
          </Row>
        </Container>
      </div>

      {clientprojects &&
        Array.isArray(clientprojects) &&
        clientprojects.length > 0 &&
        clientprojects.map((employee) => (
          <Modal
            key={employee.p_c_id}
            isOpen={deleteConfirmationEmployeeId === employee.p_c_id}
            toggle={() => toggleDeleteConfirmationModal(employee.p_id)}
          >
            <ModalHeader
              toggle={() => toggleDeleteConfirmationModal(employee.p_c_id)}
            >
              Confirm Delete
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete {employee.client_name}?
              <div className="mt-3 text-right">
                <form>
                  <Button
                    color="danger"
                    onClick={() =>
                      handleDeleteConfirmation(true, employee.p_c_id)
                    }
                  >{`Yes, delete ${employee.c_p_name}`}</Button>{" "}
                </form>
                <Button
                  color="secondary"
                  onClick={() =>
                    handleDeleteConfirmation(false, employee.p_c_id)
                  }
                >
                  No
                </Button>
              </div>
            </ModalBody>
          </Modal>
        ))}

      <Modal isOpen={showAddClientModal} toggle={toggleAddClientModal}>
        <ModalHeader toggle={toggleAddClientModal}>
          Add Client Project
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-status">
                    Client Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-status"
                    type="select"
                    name="client_id"
                    onChange={handleInputChange}
                  >
                    <option value="">Select Client Name</option>
                    {clientName &&
                      clientName.map((option) => (
                        <option key={option.client_id} value={option.client_id}>
                          {option.client_name}
                        </option>
                      ))}
                    {!clientName ||
                      (clientName.length === 0 && (
                        <option value="">No clients available</option>
                      ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    Project Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-name"
                    type="text"
                    name="pname"
                    onChange={handleInputChange}
                    placeholder="Project Name"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-budget">
                    Project Budget
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-budget"
                    type="number"
                    name="pbudget"
                    onChange={handleInputChange}
                    placeholder="Project Budget"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-starting"
                  >
                    Project Timeline{" "}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-starting"
                    type="date"
                    name="ptimeline"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-expenses"
                  >
                    Project Sqrfeet
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-expenses"
                    type="number"
                    name="psqrfeet"
                    onChange={handleInputChange}
                    placeholder="Project Expenses"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-location"
                  >
                    Location
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-location"
                    type="text"
                    name="plocation"
                    onChange={handleInputChange}
                    placeholder="Location"
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-revenue">
                    Contract Details
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setclientproject((prevState) => ({
                        ...prevState,
                        contract_details: data,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "center" }}>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      {renderViewMoreModal()}
    </>
  );
};

export default Clientproject1;
