import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { api } from "../../../../api";

const Leave3 = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchData,setSearchData] = useState([]);
  const [modal, setModal] = useState(false); 
  
  //Pagination code part 1 goes here 
  const [entriesToShow, setEntriesToShow] = useState(5);
  const [filteredList, setFilteredList] = useState([]);
  const [dynamo, setDynamo] = useState([]);
  const [change, setChange] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleProjectDropdown = () => {
    setProjectDropdownOpen(!projectDropdownOpen);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const [leave, setleave] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`hrleave.php`);
        setFilteredData(response.data);
        setSearchData(response.data);
        setFilteredList(response.data);
        setDynamo(response.data);
        setChange(response.data);
        setLoading(false);
        console.log(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(()=>{
    if(searchQuery === "" ) {
      setFilteredData(searchData);
    }else {
      const filtered = filteredData.filter((item) =>  
        item.emp_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.role.toLowerCase().includes(searchQuery.toLowerCase())

      );
      setFilteredData(filtered)
    }
  },[searchQuery])

  //Pagination code part 2 goes here

  const [projectDropdownOpen1,setProjectDropdownOpen1] = useState(false);
  const totalPages = Math.ceil(dynamo.length / entriesToShow);

  const toggleProjectDropdown1 = () => {
    setProjectDropdownOpen1(!projectDropdownOpen1);
  }

  const handleEntriesChange = (value) => {
    setEntriesToShow(value === "All"? filteredList.length : Number(value));
    setCurrentPage(1);
  }

  const prePage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1 );
    }
  }

  const nextPage = () => {
    if(currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }

  const getSerialNumber = (index) => {
    return (currentPage - 1) * entriesToShow + index + 1;
  }

  useEffect(() => {
    const start = (currentPage - 1) * entriesToShow;
    const end = start + entriesToShow;
    setFilteredData(dynamo.slice(start, end));
  }, [currentPage, entriesToShow, dynamo]);
  

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="mb-5">
            <Col className="order-xl-2 mb-5 mb-xl-0">
              <h1 className="text-white">Leave Management</h1>
            </Col>

            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex ">
              <Form className="navbar-search navbar-search-dark form-inline ml-5 d-none d-md-flex ml-lg-auto ">
                <FormGroup className="mb-0 ml-6">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search Employee"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                    <Row className="align-items-center" >
                      <div className="col">
                        <h3 className="mb-0">Leave List</h3>
                      </div>

                      <div className="d-flex justify-content-end align-items-center">
                      <div className="mr-3">
                        <Dropdown
                          isOpen={projectDropdownOpen1}
                          toggle={toggleProjectDropdown1}
                        >
                          <DropdownToggle caret>
                            Show Entries:{" "}
                            {entriesToShow === dynamo.length
                              ? "All"
                              : entriesToShow}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => handleEntriesChange(5)}
                            >
                              5
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleEntriesChange(10)}
                            >
                              10
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleEntriesChange("All")}
                            >
                              All
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                     
                    </div>

                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr style={{ textAlign: "center" }}>
                      <th
                        scope="col"
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        SL.No
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        Employee Name
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        Role
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        Applied On
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        Current Status
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        Update
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {filteredData.length > 0 ? (
                      filteredData.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">{getSerialNumber(index)}</th>
                          <td>{item.emp_name}</td>
                          <td>{item.role}</td>
                          <td>{item.content_des}</td>
                          <td>{item.applied_date}</td>         
                          <td>
                            <Badge
                              style={{
                                backgroundColor: "#F7D800",
                                color: "black",
                              }}
                              className="p-2"
                            >
                              {item.status}
                            </Badge>
                          </td>
                          <td>
                            <Link
                              to={`/hr-admin/hr-admin/hr-management/leavetable3/${item.id}`}
                              // to={`hr-admin/hr-admin/hr-management/leavetable3/${item.id}`}
                              style={{ color: "Black" }}
                            >
                              <i
                                className="fa-solid fa-eye"
                                style={{ fontSize: "22px" }}
                              ></i>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <nav className="mt-3">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a
                        href="#"
                        className="page-link text-primary"
                        onClick={prePage}
                        disabled={currentPage === 1}
                      >
                        Prev
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        href="#"
                        className="page-link text-primary"
                        onClick={nextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Leave3;
