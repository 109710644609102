import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,

} from "reactstrap";
// core components
import Header from "dashboard/project-management/components/Headers/Header";
import Select from 'react-select';


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { api } from '../../../../api'


const EditMaintainance4 = () => {

    const { id } = useParams();

    const [modalOpen, setModalOpen] = useState(false);
    const [showAddClientModal, setShowAddClientModal] = useState(false); // New state for Add Project modal
    const [clientForm, setClientForm] = useState({});
    const [selectedSiteManagers, setSelectedSiteManagers] = useState([]);


    const toggleModal = () => setModalOpen(prevState => !prevState);
    const toggleAddClientModal = () => setShowAddClientModal(prevState => !prevState); // Function to toggle Add Project modal



    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log("Form submitted:", clientForm);
        // Reset form fields after submission (if needed)
        setClientForm({
            name: '',
            status: '',
            Timeline: '',
            Budget: '',
            Experiences: '',
            Reviews: '',
            Contact: '',
            Payment: '',

        });
        // Close the modal after submission (if needed)
        setShowAddClientModal(false);
    };

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [selectedManager, setSelectedManager] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');



    // Sample employee data (replace with your actual data)
    const employees = [
        { id: 1, name: 'Mine', age: 2022, location: 'Developers', position: 'Mine Description', image: require("../../assets/img/theme/team-4-800x800.jpg"), dropdownOpen: false },
        { id: 2, name: 'AecEarth', age: 2021, location: 'Career', position: 'AecEarth Description', image: require("../../assets/img/theme/team-4-800x800.jpg"), dropdownOpen: false },
        { id: 3, name: 'Swifter', age: 2000, location: 'Sales', position: 'Swifter Description', image: require("../../assets/img/theme/team-4-800x800.jpg"), dropdownOpen: false },
        { id: 4, name: 'BIM', age: 2023, location: 'Construction', position: 'BIM Description', image: require("../../assets/img/theme/team-4-800x800.jpg"), dropdownOpen: false },
        // Add more employees as needed
    ];

    // Function to toggle dropdown for a specific employee
    const toggleDropdown = (employeeId) => {
        setFilteredEmployees(prevEmployees => prevEmployees.map(employee => {
            if (employee.id === employeeId) {
                return { ...employee, dropdownOpen: !employee.dropdownOpen };
            }
            return employee;
        }));
    };

    // Function to filter employees based on search query
    const filterEmployees = () => {
        if (!searchQuery) {
            setFilteredEmployees(employees);
        } else {
            const filtered = employees.filter(employee =>
                employee.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredEmployees(filtered);
        }
    };



    // Update filtered employees when searchQuery changes
    useEffect(() => {
        filterEmployees();
    }, [searchQuery]);


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setClientForm(prevState => ({
            ...prevState,
            file: file
        }));
    };

    const handleManagerSelectChange = (selectedOption) => {
        setSelectedManager(selectedOption);
    };

    const handleLocationSelectChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
    };

    const handleSiteManagerSelectChange = (selectedOptions) => {
        setSelectedSiteManagers(selectedOptions);
        setClientForm(prevState => ({
            ...prevState,
            siteManagers: selectedOptions.map(option => option.value)
        }));
    };

    const statusOptions = [
        { value: 'pending', label: 'Pending' },
        { value: 'ongoing', label: 'Ongoing' },
        { value: 'completed', label: 'Completed' }
    ];

    //Edit code here
    const [value, setValue] = useState({
        equipment_id: id,
        equipment_name: '',
        equipment_type: '',
        maintenance_type: '',
        maintenance_date: '',
        maintenance_schedule: '',
        issues_addressed: '',
        downtime_duration: '',
        cost: ''
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    

    useEffect(() => {
        api.get(`equipmaintanenceeditSTM.php?equipment_id=${id}`)
            .then((res) => {
                console.log('API Response:', res.data);
                const { maintenance_details } = res.data;
                if (maintenance_details) {
                    const updatedValue = {
                        equipment_id: id,
                        equipment_name: maintenance_details.equipment_name,
                        equipment_type: maintenance_details.equipment_type,
                        maintenance_type: maintenance_details.maintenance_type,
                        maintenance_date: maintenance_details.maintenance_date,
                        maintenance_schedule: maintenance_details.maintenance_schedule,
                        issues_addressed: maintenance_details.issues_addressed,
                        downtime_duration: maintenance_details.downtime_duration,
                        cost: maintenance_details.cost,
                    };
                    setValue(updatedValue);
                    console.log('Updated Value state:', updatedValue);
                }
            })
            .catch((err) => console.log(err));
    }, [id]);

    console.log("Value state:", value);



    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await api.put(`equipmaintanenceeditSTM.php`, value, {

                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log("API Data:", response.data);

            console.log("Data sent successfully:", response.data);

            if (response.data.status === 'success') {
                window.history.back();
            } else {
                console.log("Update was not successful:", response.data.message);
            }

        } catch (error) {
            console.error("Error submitting data:", error);
        }
    }


    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setValue((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //     }));
    // };

    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Col className="order-xl-2 mb-5 mb-xl-0 text-center  mb-5">
                    <h1>Edit Maintainance Details</h1>
                </Col>
                <Button onClick={() => window.history.back()} className='ml-5'>Back</Button>
                <Container className="py-4 ">
                    <div className="p-4 rounded" style={{ backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid black' }}>

                        <Form >
                        <Row className='px-3'>

                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Equipment Name</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-status"
                                        type="text"
                                        name="equipment_name"
                                        defaultValue={value.equipment_name}
                                        onChange={handleInputChange}
                                    >

                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-name">Equipment Type</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-name"
                                        type="text"
                                        name="equipment_type"
                                        defaultValue={value.equipment_type}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-manager">Change Type Of Maintenance</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-manager"
                                        type="select"
                                        name="maintenance_type"
                                        value={value.maintenance_type} // Set the value here
                                        onChange={handleInputChange}
                                    >
                                        <option value="">{value.maintenance_type} </option>
                                        <option value="Preventive Maintenance">Preventive Maintenance</option>
                                        <option value="Emergency Maintenance">Emergency Maintenance</option>
                                        <option value="Parts Replacement">Parts Replacement</option>
                                        <option value="Predictive Maintenance">Predictive Maintenance</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-site-manager">Change Maintainance Schedule</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-site-manager"
                                        type="select"
                                        name="maintenance_schedule"
                                        value={value.maintenance_schedule}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">{value.maintenance_schedule}</option>
                                        <option value="day">Day</option>
                                        <option value="week">Week</option>
                                        <option value="month">Month</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            {/* <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-starting">Change Maintainance Date</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-starting"
                                        type="date"
                                        name="Starting"
                                        value={clientForm.Starting}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col> */}

                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-name">Change Addressed Issue</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-name"
                                        type="text"
                                        name="issues_addressed"
                                        defaultValue={value.issues_addressed}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-name">Change Downtime Duration</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-name"
                                        type="text"
                                        name="downtime_duration"
                                        defaultValue={value.downtime_duration}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-name">Cost</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-name"
                                        type="text"
                                        name="cost"
                                        defaultValue={value.cost}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>




                        </Row>
                        <Row>
                            <Col lg='12' className='text-center mb-2'>
                                <Button color="primary" type="submit" onClick={handleSubmit}>Submit</Button>
                            </Col>
                        </Row>

                        </Form>

                    </div>
                </Container>


            </div>


        </>
    );
};

export default EditMaintainance4;
