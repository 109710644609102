import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({ children }) {
    // Retrieve token from cookies
    const token = getCookie("token");
    const role = getCookie("role");
    // Check if token exists
    if (!token) {
      return <Navigate to="/login" />;
    }

    
  
    // Render children if token exists
    return children;
  }
  
  // Function to get cookie by name
  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie contains the name we're looking for
      if (cookie.startsWith(`${name}=`)) {
        // If found, return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if cookie not found
    return null;
  }
  