
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { api } from '../../../../api';
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody, Label,
} from "reactstrap";


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



const Viewwaste4 = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [showAddClientModal, setShowAddClientModal] = useState(false); // New state for Add Project modal
    const [clientForm, setClientForm] = useState({});
    const [selectedSiteManagers, setSelectedSiteManagers] = useState([]);


    const toggleModal = () => setModalOpen(prevState => !prevState);
    const toggleAddClientModal = () => setShowAddClientModal(prevState => !prevState); // Function to toggle Add Project modal

    const emp_id = 1;

    const { id } = useParams();

    const [updateValue, setUpdateValue] = useState('');

    const handleStatusChange = (e) => {
        setUpdateValue({ ...updateValue, status: e.target.value });
    };

    const statusOptions = [
        { value: 'preventive', label: 'Preventive Maintenance' },
        { value: 'emergency', label: 'Emergency Maintenance' },
        { value: 'parts-replacement', label: 'Parts Replacement' },
        { value: 'predictive', label: 'Predictive Maintenance' }
    ];

    const [hasIssue, setHasIssue] = useState(false);


    const [statusValue, setStatusValue] = useState('');

    const statusValues = [
        { value: 'in-progress', label: 'In-Progress' },
        { value: 'completed', label: 'Completed' },
        
    ];





    const formData = new FormData();
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            formData.append('t_id', updateValue.id);
            formData.append('p_id', updateValue.p_id);
            formData.append('taskname', updateValue.taskname);
            formData.append('assign_to', updateValue.assign_to);
            formData.append('start_date', updateValue.start_date);
            formData.append('end_date', updateValue.end_date);
            formData.append('t_description', updateValue.description);
            formData.append('status', updateValue.status);
            formData.append('image', updateValue.image);

            console.log(formData)



            const response = await api.post(`edittask.php`, formData);

            console.log("Data sent successfully:", response.data);

            // window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
        }

    };



    const [searchQuery, setSearchQuery] = useState('');
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [selectedManager, setSelectedManager] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');




    const toggleDropdown = (employeeId) => {
        setFilteredEmployees(prevEmployees => prevEmployees.map(employee => {
            if (employee.id === employeeId) {
                return { ...employee, dropdownOpen: !employee.dropdownOpen };
            }
            return employee;
        }));
    };

    // Function to filter employees based on search query
    const filterEmployees = () => {
        if (!searchQuery) {
            setFilteredEmployees(employees);
        } else {
            const filtered = employees.filter(employee =>
                employee.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredEmployees(filtered);
        }
    };







    const handleManagerSelectChange = (selectedOption) => {
        setSelectedManager(selectedOption);
    };

    const handleLocationSelectChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
    };

    const handleSiteManagerSelectChange = (selectedOptions) => {
        setSelectedSiteManagers(selectedOptions);
        setClientForm(prevState => ({
            ...prevState,
            siteManagers: selectedOptions.map(option => option.value)
        }));
    };

    // const statusOptions = [
    //     { value: 'to-do', label: 'to-do' },
    //     { value: 'in-progress', label: 'in-progress' },
    //     { value: 'completed', label: 'completed' }
    // ];


    const [employees, setemployees] = useState();
    const fetchEmployeeData = async () => {
        try {
            const response = await api.get("employeesView.php");
            setemployees(response.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchEmployeeData();
    }, []);


    // const handleStatusChange = (event) => {
    //     setupdateValues({ ...updateValue, status: event.target.value });
    // };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdateValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        console.log(updateValue);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        formData.append('files', file);
    };

    const [project, setproject] = useState([]);
    const fetchProjectData = async () => {
        try {
            const response = await api.get(`viewProjectDetails.php?p_m_id=${emp_id}`);
            setproject(response.data);
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Update filtered employees when searchQuery changes
    useEffect(() => {
        filterEmployees();
        fetchProjectData();
    }, [searchQuery]);




    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Col className="order-xl-2 mb-5 mb-xl-0 text-center  mb-5">
                    <h1>Edit Task Details</h1>
                </Col>
                <Link to="/store-admin/store-management/employees4"> <Button className='ml-5'>Back</Button></Link>
                <Container className="py-4 ">
                    <div className="p-4 rounded" style={{ backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid black' }}>

                        <Row className='px-3'>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Equipment Name</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-status"
                                        type="text"
                                        name="p_id"
                                        value={updateValue.p_id}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Project</option>
                                        {project && Array.isArray(project) && project.map(option => (
                                            <option key={option.p_id} value={option.p_id}>{option.p_name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Equipment Type</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-status"
                                        type="text"
                                        name="status"
                                        onChange={handleStatusChange}
                                        value={updateValue.status}
                                    >
                                        {statusOptions.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Maintenance Type</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-status"
                                        type="select"
                                        name="status"
                                        onChange={handleStatusChange}
                                        value={updateValue.status}
                                    >
                                        <option value="">Select Maintenance Type</option>
                                        {statusOptions.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-name">Maintainance Date</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-name"
                                        type="date"
                                        name="taskname"
                                        defaultValue={updateValue.taskname}
                                        placeholder='Category Name'
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-name">Next Maintainance Date</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-name"
                                        type="date"
                                        name="taskname"
                                        defaultValue={updateValue.taskname}
                                        placeholder='Category Name'
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>

                            {/* Radio Button Group */}
                            <Col md={12}>
                                <FormGroup>
                                    <legend className="form-control-label">Do you have an issue?</legend>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="hasIssue" onChange={() => setHasIssue(true)} checked={hasIssue} />
                                            Yes
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="hasIssue" onChange={() => setHasIssue(false)} checked={!hasIssue} />
                                            No
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                            </Col>

                            {/* Additional Fields */}
                            {hasIssue && (
                                <>
                                    <Col md={12}>
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="option1">Address Issue</label>
                                            <Input
                                                className="form-control-alternative"
                                                id="option1"
                                                type="text"
                                                name="option1"
                                                onChange={handleInputChange}
                                                placeholder='Enter Your Issue'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="option2">Downtime Duration</label>
                                            <Input
                                                className="form-control-alternative"
                                                id="option2"
                                                type="text"
                                                name="option2"
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="option3">Cost to resolve issue</label>
                                            <Input
                                                className="form-control-alternative"
                                                id="option3"
                                                type="number"
                                                name="option3"
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </>
                            )}



                            <Col md={12}>
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Select Status</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-status"
                                        type="select"
                                        name="status"
                                        onChange={handleStatusChange}
                                        value={updateValue.status}
                                    >
                                        <option value="">Select Maintenance Type</option>
                                        {statusValues.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>


                            <Col md={12}>
                            <FormGroup>
                                <Label className="form-control-label">Upload File</Label>
                                <Input
                                    type="file"
                                    accept=".pdf,.doc,.docx,.xls,.xlsx"
                                    onChange={handleFileChange}
                                />
                            </FormGroup>
                        </Col>









                        </Row>
                        <Row>
                            <Col lg='12' className='text-center mb-2'>
                                <Button color="primary" type="submit" onClick={handleSubmit} >Submit</Button>
                            </Col>
                        </Row>
                    </div>
                </Container>


            </div>


        </>
    );
};

export default Viewwaste4;