import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Badge,
    Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption
} from "reactstrap";
import { api } from '../../../../api';

const Viewproject6 = () => {
    const [loading, setLoading] = useState(true);
    const [projectData, setProjectData] = useState();
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`viewProjectByID.php?p_id=${id}`);
                setProjectData(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    const sumbitProjectStatus = async (status) => {
        const data = {
            p_id: id,
            status: status
        };

        try {
            const response = await api.get(`viewProjectByID.php?p_id=${id}&status=${status}`);
            console.log("Data sent successfully:", response.data);
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Col className="order-xl-2 mb-5 mb-xl-0 text-center mb-5">
                    <h1>View Project Details</h1>
                </Col>
                <Button className='ml-5' onClick={() => window.history.back()}>Back</Button>
                <Container className="py-4">
                    <div className="d-flex justify-content-center">
                        <div className="p-4 rounded w-50" style={{ backgroundColor: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid black' }}>
                            {projectData && projectData.length > 0 && (
                                <>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="">
                                                <label className="form-control-label" htmlFor="input-status">Project Status</label>
                                            </div>
                                            <Badge style={{ backgroundColor: '#F7D800', color: 'black', height: '35px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
                                                {projectData[0].p_status}
                                            </Badge>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="input-project-name">Project Name:</label>
                                                <p> {projectData[0].p_name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="input-project-name">Project Budget:</label>
                                                <p>{projectData[0].p_budget}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="input-project-name">Employees:</label>
                                                <p>{projectData[0].p_budget}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="input-project-name">Start Date:</label>
                                                <p>{projectData[0].start_date}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="input-project-name">End Date:</label>
                                                <p>{projectData[0].end_date}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="input-project-name">Project Description:</label>
                                                <p>{projectData[0].p_description}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="input-project-name">Files:</label>
                                                <a href={`../../../images/${projectData[0].files}`} download>{projectData[0].files}</a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Viewproject6;
