import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api } from '../../../api';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const LineChartComponent = ({selectedYear}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.get(`itemscountandcostVP.php?year=${selectedYear}`);

      // Create an object to store month-wise data
      const monthData = {};
      result.data.forEach(item => {
        monthData[item.month] = item;
      });

      // Create the formattedData array with all 12 months
      const formattedData = months.map((month, index) => {
        const itemData = monthData[index + 1] || { item_count: '0', total_cost: '0' };
        return {
          month,
          itemCount: parseFloat(itemData.item_count),
          totalCost: parseFloat(itemData.total_cost),
        };
      });

      setData(formattedData);
    };

    fetchData();
  }, [selectedYear]);

  console.log('data', data);

  const renderTooltip = ({ payload, label }) => {
    if (payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className='text-dark'>
          <p>{`Month: ${label}`}</p>
          <p>{`Item Count: ${data.itemCount}`}</p>
          <p>{`Total Cost: Rs.${data.totalCost.toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">

    <LineChart  data={data}>
      <XAxis dataKey="month" stroke="#000" />
      <YAxis stroke="#000" />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip content={renderTooltip} />
      <Legend />
      <Line type="monotone" dataKey="itemCount" stroke="#36a2eb" strokeWidth={3} />
    </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
