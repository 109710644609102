/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect, useRef } from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Col,
  Badge,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Dropdown,
  CardTitle,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "dashboard/project-management/variables/charts.js";
import {api} from '../../../../api'
import Cookies from 'js-cookie';

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [selectedOption, setSelectedOption] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);


  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);



  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  const handleOptionSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const emp_id = Cookies.get('token');
  // let emp_id = 1;


  const [project, setproject] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`viewProjectDetails.php?p_m_id=${emp_id}`);
        setproject(response.data);
        console.log(response.data);
        const response2 = await api.get(`materailsCountdeatils.php?p_id=all&&p_m_id=${emp_id}`);
        console.log('API Response:', response2.data);
        setbudget(response2.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  console.log(project)


  const [display, setdisplay] = useState("display");
  const [display2, setdisplay2] = useState("none");
  const [budget, setbudget] = useState({ details: [] });
  const handleProjectSelection = async (p_id) => {
    setdisplay2("display")
    try {
      if(p_id == "all"){
        setdisplay("display");
        const response = await api.get(`materailsCountdeatils.php?p_id=${p_id}&&p_m_id=${emp_id}`);
        console.log('API Response:', response.data);
        setbudget(response.data);
      }else{
        setdisplay("none");
        const response = await api.get(`materailsCountdeatils.php?p_id=${p_id}`);
        console.log('API Response:', response.data);
        setbudget(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  console.log(budget);


  return (
    <>

      <div className="header bg-gradient-info py-7 py-lg-5 ">
      <div className="bg-gradient-info pb-8 pt-2 pt-md-8">
        <Container fluid>
          <div className="header-body">
          <div className="justify-content-end ">
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="mb-4 justify-content-end " >
            <DropdownToggle caret>
              Select Project
            </DropdownToggle>
            <DropdownMenu left>
            {display2 != "none" && (
                <DropdownItem onClick={() => handleProjectSelection("all")}>
                    All Projects
                </DropdownItem>
            )}
            {Array.isArray(project) ? (
                project.map(project => (
                    <DropdownItem key={project.p_id} onClick={() => handleProjectSelection(project.p_id)}>
                        {project.p_name}
                    </DropdownItem>
                ))
            ) : (
                // Render fallback UI or handle the error in any other appropriate way
                <p className="text-center">No projects found</p>
            )}
            

                  
            </DropdownMenu>
          </Dropdown>
          <span>Select project to view budget details</span>
          </div>
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-3">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Budget
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {budget.p_budget}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fa-solid fa-dollar-sign" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 100%
                      </span>{" "}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Drained Amount
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{budget.spent}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-hand-holding-dollar" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> {budget.speper} %
                      </span>{" "}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Remaning Amount
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{budget.remaining}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-file-invoice-dollar" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                      <i className="fa fa-arrow-up" /> {budget.remper} %
                      </span>{" "}
                    </p>
                  </CardBody>
                </Card>
              </Col>
             
            </Row>
          </div>
        </Container>
      </div> 
        {/* Page content */}
        <Container className="mt--7 " fluid>
        <Row className="mt-2">
            <Col className="mb-5 mb-5" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Material Details</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Material Name</th>
                    <th scope="col">Amount Spent</th>
                    <th scope="col">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                {budget.details ? (
                    budget.details.map((material, index) => (
                        <tr key={index}>
                            <td>{material.mat_name}</td>
                            <td>{material.mat_money}</td>
                            <td>{material.mat_quantity}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td center colSpan="3">No details available, Project not yet started</td>
                    </tr>
                )}


                </tbody>
              </Table>
            </Card>
          </Col>
          </Row>

          

          
        </Container>
      </div>
    </>
  );
};

export default Index;
