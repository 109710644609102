import React, { useState, useEffect } from "react";
import {
  Card,
  Badge,
  Button,
  Table,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
  CardHeader,
} from "reactstrap";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CircleChart from "dashboard/hr-management/variables/circlebar";
import { api } from "../../../../api";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { BiSolidTrafficCone } from "react-icons/bi";
import { GiNuclearWaste } from "react-icons/gi";
import CircleChart6 from "../../variables/CircleChart6";
import Images from "./Corousel6";

const Materials6 = () => {
  const [countDetails, setCountDetails] = useState(null);
  const [displayCards, setDisplayCards] = useState([]);

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const response = await api.get(`materialscountVP.php`);
        setDisplayCards(response.data);
        console.log("mat", displayCards);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchCardData();
  }, []);

  const totalItems = displayCards?.queries?.total_items;
  const totalCost = displayCards?.queries?.total_cost;
  const todayMaintainance = displayCards?.queries?.maintenance_today;
  const wasteItems = displayCards?.queries?.waste_items;
  console.log("hi", totalItems);

  const [data, setData] = useState(null);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [dropdownToggleText, setDropdownToggleText] = useState('Select Year');
  
  const [dropdownMonthOpen, setDropdownMonthOpen] = useState(false);
  const [dropdownYearOpen, setDropdownYearOpen] = useState(false);
  const years = [];

  const currentYear = new Date().getFullYear();

  for (let i = currentYear - 4; i <= currentYear; i++) {
    years.push(i);
  }


  const toggleYearDropdown = () =>
    setDropdownYearOpen((prevState) => !prevState);

 

  const handleYearChange = (event) => {
    const selectedYear = parseInt(event.target.value);
    setSelectedYear(selectedYear);
    setDropdownToggleText(`${selectedYear}`);
  };

  // useEffect(() => {
  //   // Fetch data based on selected month and year
  //   const fetchData = async () => {
  //     try {
  //       const response = await api.get(
  //         `itemscountandcostVP.php?year=${selectedYear}`
  //       );
  //       const result = response.data;
  //       console.log(result);
  //       if (result.status === "success") {
  //         const categoryData = result.category_counts.map((item) => ({
  //           label: item.item_category,
  //           value: parseInt(item.item_count),
  //         }));

  //         setData({
  //           labels: categoryData.map((item) => item.label),
  //           datasets: [
  //             {
  //               label: "Item Counts",
  //               data: categoryData.map((item) => item.value),
  //               backgroundColor: [
  //                 "#1d6f1d",
  //                 "#B90000",
  //                 "#FFAA1D",
  //                 "#0C4CA3",
  //                 "#00A8E8",
  //                 "#FFD300",
  //                 "#FF6F61",
  //                 "#008744",
  //                 "#6A0DAD",
  //                 "#FF69B4",
  //                 "#FCB900",
  //               ],
  //             },
  //           ],
  //         });
  //       } else {
  //         console.error("Error fetching data:", result.message);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [selectedYear]);

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="d-flex justify-content-center align-items-center">
            <Col lg="6" xl="3" className="mb-3">
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{ borderRadius: "30px" }}
              >
                <CardBody>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CardTitle className="h4 mb-0">Total Items</CardTitle>
                    </div>
                    <div style={{ height: "30px", width: "30px" }}>
                      <div
                        className="icon icon-shape bg-danger text-white rounded-circle shadow"
                        style={{
                          background:
                            "linear-gradient(230deg, #759bff, #843cf6)",
                        }}
                      >
                        <i className="fa-regular fa-comment-dots fa-fade"></i>
                      </div>
                    </div>
                  </div>
                  <span className="h2 font-weight-bold  ml-3">
                    {totalItems}
                  </span>
                </CardBody>
              </Card>
            </Col>

            {/* Completed Projects Card */}
            <Col lg="6" xl="3" className="mb-3">
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{ borderRadius: "30px" }}
              >
                <CardBody>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CardTitle className="h4 mb-0">Items Value</CardTitle>
                    </div>
                    <div style={{ height: "30px", width: "30px" }}>
                      <div
                        className="icon icon-shape bg-danger text-white rounded-circle shadow"
                        style={{
                          background:
                            "linear-gradient(230deg, #569715, #569715)",
                        }}
                      >
                        <i
                          className="fa-solid fa-wallet fa-spin"
                          style={{
                            "--fa-animation-duration": "2s;",
                            "--fa-fade-opacity": "0.6;",
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <span className="h2 font-weight-bold  ml-3">{totalCost}</span>
                </CardBody>
              </Card>
            </Col>

            {/* Inprogress Projects Card */}
            <Col lg="6" xl="3" className="mb-3">
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{ borderRadius: "30px" }}
              >
                <CardBody>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CardTitle className="h4 mb-0">
                        Under Maintainance
                      </CardTitle>
                    </div>
                    <div style={{ height: "30px", width: "30px" }}>
                      <div
                        className="icon icon-shape bg-danger text-white rounded-circle shadow"
                        style={{
                          background:
                            "linear-gradient(230deg, #C50C36, #C50C36)",
                        }}
                      >
                        <BiSolidTrafficCone className="fa-flip" />
                      </div>
                    </div>
                  </div>
                  <span className="h2 font-weight-bold  ml-3">
                    {todayMaintainance}
                  </span>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" xl="3" className="mb-3">
              <Card
                className="card-stats mb-4 mb-xl-0"
                style={{ borderRadius: "30px" }}
              >
                <CardBody>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CardTitle className="h4 mb-0">Wastage Items</CardTitle>
                    </div>
                    <div style={{ height: "30px", width: "30px" }}>
                      <div
                        className="icon icon-shape bg-danger text-white rounded-circle shadow"
                        style={{
                          background:
                            "linear-gradient(230deg, #7AA2E3, #7AA2E3)",
                        }}
                      >
                        <GiNuclearWaste className="fa-beat" />
                      </div>
                    </div>
                  </div>
                  <span className="h2 font-weight-bold  ml-3">
                    {wasteItems}
                  </span>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Container className="mt-3">
            <Row className="gx-5" >
              <Col md={6}>
                <div
                  className="d-flex justify-content-center"
                  style={{ backgroundColor: "white", borderRadius: "0px" }}
                >


                  <Dropdown
                    isOpen={dropdownYearOpen}
                    toggle={toggleYearDropdown}
                  >
                    <DropdownToggle caret>{dropdownToggleText}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>Select Year</DropdownItem>
                      {years.map((year) => (
                        <DropdownItem onClick={handleYearChange} value={year}>
                          {year}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{
                    height: "400px",
                    width: "100%",
                    position: "relative",
                    borderRadius: "0px",

                    // overflow:'hidden',
                  }}
                >
                  <CircleChart6 selectedYear = {selectedYear} />
                </Card>
              </Col>
              <Col md={6}>
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{
                    height: "444px",
                    width: "100%",
                  }}
                >
                  <Images />
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
};

export default Materials6;
