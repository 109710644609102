import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { api } from "../../../../api";
import Cookies from "js-cookie";

const Employee = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const toggleModal = () => setModalOpen((prevState) => !prevState);

  const emp_id = Cookies.get("token");

  const [project, setProject] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [modalEmployee, setModalEmployee] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`viewProjectDetails.php?p_m_id=${emp_id}`);
        setProject(response.data);
        const response2 = await api.get(`employeesView.php?project_id=${emp_id}`);
        setSelectedEmployees(response2.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [emp_id]);

  const handleProjectSelection = async (p_id, p_name) => {
    setSelectedProject(p_name);
    try {
      const response = await api.get(`sitemanagerview.php?p_id=${p_id}`);
      setSelectedEmployees(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const filteredEmployees = selectedEmployees.filter(employee =>
    employee.emp_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="mb-5">
            <Col className="order-xl-2 mb-5 mb-xl-0 text-left ">
              <h1>Employee Details</h1>
            </Col>
            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex">
              <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Search Employee Role"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret>Select Project</DropdownToggle>
                <DropdownMenu>
                  {Array.isArray(project) ? (
                    project.map((project) => (
                      <DropdownItem
                        key={project.p_id}
                        onClick={() => handleProjectSelection(project.p_id, project.p_name)}
                      >
                        {project.p_name}
                      </DropdownItem>
                    ))
                  ) : (
                    <DropdownItem>No Projects Found</DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            {filteredEmployees && filteredEmployees.length > 0 ? (
              filteredEmployees.map((employee) => (
                <Col md="3" key={employee.sm_id}>
                  <Card className="card-profile shadow mb-5">
                    <Row className="justify-content-center">
                      <Col className="order-lg-2" lg="3">
                        <div className="card-profile-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={employee.profile_image ? require(`../../../images/${employee.profile_image}`) : ""}
                              style={{ height: '100px', width: '100px' }}
                            />
                          </a>
                        </div>
                      </Col>
                    </Row>
                    <CardBody className="pt-0 pt-md-5 mt-5">
                      <div className="text-center">
                        <h3>{employee.emp_name}</h3>
                        <div className="h5 font-weight-300">
                          <i className="ni location_pin mr-2" />
                          {employee.email}
                        </div>
                        <div className="h5 font-weight-300">
                          <i className="ni location_pin mr-2" />
                          {employee.phoneno}
                        </div>
                        <div className="h5 mt-4">
                          <i className="ni business_briefcase-24 mr-2" />
                          {employee.dob}
                        </div>
                        <hr className="my-4" />
                        <a href="#pablo" onClick={() => { setModalEmployee(employee); toggleModal(); }}>
                          View more
                        </a>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <h4>Select project to view employee details (OR) No employees found..</h4>
            )}

            {modalEmployee && modalEmployee && (
              <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Employee View</ModalHeader>
                <ModalBody style={{ justifyContent: "center", textAlign: "center" }}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="rounded-circle"
                      src={modalEmployee.profile_image ? require(`../../../images/${modalEmployee.profile_image}`) : ""}
                      style={{ height: 100, marginBottom: 10 }}
                    />
                  </a>
                  <p>Name: {modalEmployee.emp_name}</p>
                  <p>Date Of Birth: {modalEmployee.dob}</p>
                  <p>Mobile Number: {modalEmployee.phoneno}</p>
                  <p>Email: {modalEmployee.email}</p>
                  <p>Role: {modalEmployee.role}</p>
                  <p>Address: {modalEmployee.address}</p>
                </ModalBody>
              </Modal>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Employee;
