import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Progress,
  CardImg,
  Button,
  Label,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  UncontrolledDropdown,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-calendar/dist/Calendar.css"; // Import the default calendar styles
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Import the styles
import { api } from "../../../../api";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";

const Task2 = () => {
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [modal, setModal] = useState(false);
  const [modal1, setEditModal] = useState(false);
  const [deleteProjectName, setDeleteProjectName] = useState("");
  const [showTodoDeleteConfirmation, setShowTodoDeleteConfirmation] =
    useState(false);
  const [showProgressDeleteConfirmation, setShowProgressDeleteConfirmation] =
    useState(false);
  const [showCompletedDeleteConfirmation, setShowCompletedDeleteConfirmation] =
    useState(false);

  const toggleDropdown1 = () => setDropdownOpen1((prevState) => !prevState);
  const toggleDropdown2 = () => setDropdownOpen2((prevState) => !prevState);

  const toggleDropdown3 = () => setDropdownOpen3((prevState) => !prevState);
  const toggleModal = () => setModal((prevState) => !prevState);
  const toggleEditModal = () => setEditModal((prevState) => !prevState);

  const [clientForm, setClientForm] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [viewProgressModal, setViewProgressModal] = useState(false);
  const [viewCompletedModal, setViewCompletedModal] = useState(false);

  // const toggleViewModal = () => setViewModal(prevState => !prevState);

  const [selectedTodoForView, setSelectedTodoForView] = useState(null);
  const [selectedProgressView, setSelectedProgressView] = useState(null);
  const [selectedCompletedView, setSelectedCompletedView] = useState(null);

  const toggleViewModal = (item) => {
    setSelectedTodoForView(item);
    setViewModal((prevState) => !prevState);
  };

  const toggleProgressModal = (prog) => {
    setSelectedProgressView(prog);
    setViewProgressModal((prevState) => !prevState);
  };

  const toggleCompletedModal = (comp) => {
    setSelectedCompletedView(comp);
    setViewCompletedModal((prevState) => !prevState);
  };

  // Function to format the selected date label

  const [progressTodoPercentage, setProgressTodoPercentage] = useState(1);
  const [progressInComPercentage, setProgressInComPercentage] = useState(50);
  const [progressComPercentage, setProgressComPercentage] = useState(100);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const Employee = ["Jyosh", "Shashi", "Divya", "Harish"];
  const Projects = ["Mine", "AecEarth", "Bim", "Swifter"];
  const Period = ["Week", "Month", "Year"];

  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "ongoing", label: "Ongoing" },
    { value: "completed", label: "Completed" },
  ];

  const emp_id = Cookies.get("token");

  // const emp_id = 1;

  const [project, setproject] = useState([]);
  const fetchProjectData = async () => {
    try {
      const response = await api.get(`viewProjectDetails.php?s_m_id=${emp_id}`);
      setproject(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [task, settask] = useState([]);

  const fetchProjectDataTask = async () => {
    try {
      const response = await api.get(`viewTask.php?id=${emp_id}`);
      settask(response.data);
      console.log(response.data);
      console.log(task);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const todoTasks = Array.isArray(task)
    ? task.filter((task) => task.status.trim() === "to-do")
    : [];
  //   console.log(task);
  // console.log(todoTasks);
  const completedTasks = Array.isArray(task)
    ? task.filter((task) => task.status.trim() === "completed")
    : [];
  const progressTasks = Array.isArray(task)
    ? task.filter((task) => task.status.trim() === "In-Progress")
    : [];


  const [employees, setemployees] = useState();
  const fetchEmployeeData = async () => {
    try {
      const response = await api.get("employeesView.php");
      setemployees(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchProjectData();
    fetchEmployeeData();
    fetchProjectDataTask();
  }, []);

  const [filterChoose, setfilterChoose] = useState({
    emp_id: emp_id,
    assign_to: "",
    p_id: "",
    period: "",
  });

 

  const handleEmployeeSelect = async (employee) => {
    setSelectedEmployee(employee.emp_name);
    const updatedFilterChoose = {
      ...filterChoose,
      assign_to: employee.emp_id,
    };
    setfilterChoose(updatedFilterChoose);
    await fetchDataWithFilterChoose(updatedFilterChoose);
  };
  
  const handleProjectSelect = async (project) => {
    setSelectedProject(project.p_name);
    const updatedFilterChoose = {
      ...filterChoose,
      p_id: project.p_id,
    };
    setfilterChoose(updatedFilterChoose);
    await fetchDataWithFilterChoose(updatedFilterChoose);
  };
  
  const handlePeriodSelect = async (period) => {
    setSelectedPeriod(period);
    const updatedFilterChoose = {
      ...filterChoose,
      period: period,
    };
    setfilterChoose(updatedFilterChoose);
    await fetchDataWithFilterChoose(updatedFilterChoose);
  };
  
  const fetchDataWithFilterChoose = async (filterChooseParam) => {
    try {
      const response = await api.post(`filterchoose.php`, filterChooseParam);
      settask(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [todoIdForDelete, setTodoIdForDelete] = useState(null);
  const [selectedItemIdForDelete, setSelectedItemIdForDelete] = useState(null);
  const [completedIdForDelete, setCompletedIdForDelete] = useState(null);

  const handleDeleteConfirmation = async (t_id) => {
    const fetchData = async () => {
      try {
        const response = await api.delete(`deleteTask.php?id=${t_id}`);
        console.log(response.data);
        window.location.reload();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  };

  const handleProgressDeleteConfirmation = async (t_id) => {
    const fetchData = async () => {
      try {
        const response = await api.delete(`deleteTask.php?id=${t_id}`);
        console.log(response.data);
        window.location.reload();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  };

  const handleCompletedDeleteConfirmation = async (t_id) => {
    const fetchData = async () => {
      try {
        const response = await api.delete(`deleteTask.php?id=${t_id}`);
        console.log(response.data);
        window.location.reload();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  };

  const [addtask, setaddtask] = useState({
    projectid: "",
    taskname: "",
    assignto: "",
    startdate: "",
    enddate: "",
    description: "",
    image: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setaddtask((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(addtask);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setaddtask((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    // event.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("emp_id", emp_id);
    formDataToSend.append("projectid", addtask.projectid);
    formDataToSend.append("taskname", addtask.taskname);
    formDataToSend.append("assignto", addtask.assignto);
    formDataToSend.append("startdate", addtask.startdate);
    formDataToSend.append("enddate", addtask.enddate);
    formDataToSend.append("description", addtask.description);
    formDataToSend.append("uploadimage", addtask.image);

    try {
      const response = await api.post("addTask.php", formDataToSend);
      console.log("Data sent successfully:", response.data);
      window.location.reload();
      toggleModal(true);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row>
            {/* ... Your existing code ... */}

            {/* Total Projects Card */}
            <Col lg="12" xl="12">
              <Card className="card-start ">
                <CardBody>
                  <div className=" mb-3 ">
                    <h2 className="mr-3">Your Task </h2>
                    <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between">
                      <div className="text-lg-end">
                        <Dropdown
                          className="mb-2"
                          isOpen={dropdownOpen1}
                          toggle={toggleDropdown1}
                        >
                          <DropdownToggle caret>
                            {selectedEmployee
                              ? selectedEmployee
                              : "Select Employee"}
                          </DropdownToggle>
                          <DropdownMenu left>
                            {Array.isArray(employees) ? (
                              employees.map((employeeItem, index) => (
                                <DropdownItem
                                  key={index}
                                  onClick={() =>
                                    handleEmployeeSelect(employeeItem)
                                  }
                                >
                                  {employeeItem.emp_name}{" "}
                                  {/* Render specific property of employee object */}
                                </DropdownItem>
                              ))
                            ) : (
                              <p>No employees available</p>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                        <Dropdown
                          className="mb-2"
                          isOpen={dropdownOpen2}
                          toggle={toggleDropdown2}
                        >
                          <DropdownToggle caret>
                            {selectedProject
                              ? selectedProject
                              : "Select Project"}
                          </DropdownToggle>
                          <DropdownMenu left>
                            {Array.isArray(project) ? (
                              project.map((projectItem, index) => (
                                <DropdownItem
                                  key={index}
                                  onClick={() =>
                                    handleProjectSelect(projectItem)
                                  }
                                >
                                  {projectItem.p_name}{" "}
                                  {/* Render specific property of project object */}
                                </DropdownItem>
                              ))
                            ) : (
                              <p>No projects available</p>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                        <Dropdown
                          className="mb-2"
                          isOpen={dropdownOpen3}
                          toggle={toggleDropdown3}
                        >
                          <DropdownToggle caret>
                            {selectedPeriod ? selectedPeriod : "Select Period"}
                          </DropdownToggle>
                          <DropdownMenu left>
                            {Period.map((period, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() => handlePeriodSelect(period)}
                              >
                                {period}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                        <Button color="primary" onClick={toggleModal}>
                          Add Task
                        </Button>
                      </div>
                      <div className="">
                        <img
                          src={require("../../assets/img/brand/image-processing20200321-20407-unscreen-ezgif.com-crop.gif")}
                          style={{
                            width: "300px",
                            height: "96px",
                            transform: "scaleX(-1)",
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      <Modal isOpen={modal} toggle={toggleModal}>
                        <Form
                          onSubmit={handleSubmit}
                          enctype="multipart/form-data"
                        >
                          <ModalHeader toggle={toggleModal}>
                            Add Task
                          </ModalHeader>
                          <Row className="px-3">
                            <Col md={12}>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-status"
                                >
                                  Project Name
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-status"
                                  type="select"
                                  name="projectid"
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select Project</option>
                                  {project &&
                                    Array.isArray(project) &&
                                    project.map((option) => (
                                      <option
                                        key={option.p_id}
                                        value={option.p_id}
                                      >
                                        {option.p_name}
                                      </option>
                                    ))}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-name"
                                >
                                  Task Name
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-name"
                                  type="text"
                                  name="taskname"
                                  onChange={handleInputChange}
                                  placeholder="Task Name"
                                />
                              </FormGroup>
                            </Col>

                            <Col md={12}>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-manager"
                                >
                                  {" "}
                                  Assign To
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-manager"
                                  type="select"
                                  name="assignto"
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select Assign To </option>
                                  {employees &&
                                    Array.isArray(employees) &&
                                    employees.map((option) => (
                                      <option
                                        key={option.emp_id}
                                        value={option.emp_id}
                                      >
                                        {option.emp_name}
                                      </option>
                                    ))}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-starting"
                                >
                                  Task Starting Date
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-starting"
                                  type="datetime-local"
                                  name="startdate"
                                  onChange={handleInputChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-ending"
                                >
                                  Task Ending Date
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-ending"
                                  type="datetime-local"
                                  name="enddate"
                                  onChange={handleInputChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-description"
                                >
                                  Project Description
                                </label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={addtask.description}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setaddtask((prevState) => ({
                                      ...prevState,
                                      description: data,
                                    }));
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-file"
                                >
                                  Upload File
                                </label>
                                <Input
                                  type="file"
                                  className="form-control-file"
                                  id="input-file"
                                  name="image"
                                  onChange={handleFileChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12" className="text-center mb-2 ">
                              <Button
                                color="primary"
                                type="submit"
                                className="bg-slate-500 text-white"
                              >
                                Submit
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Modal>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg="4">
              <Card className="card-stats mb-4 mb-xl-0 m-0 ">
                <CardBody className="text-center">
                  <Row>
                    <img
                      src={require("../../assets/img/brand/output-onlinegiftools (1).gif")}
                      style={{
                        "--fa-beat-scale": 1.5,
                        color: "red",
                        fontSize: "20px",
                        height: "40px",
                        width: "40px",
                      }}
                    />
                    {/* <i class="fa-solid fa-clock-rotate-left mr-2 mt-1" style={{ color: '#A52B2A', fontSize: '20px' }}></i> */}
                    <h2 className="ml-2">To Do</h2>
                    <i
                      class="fa-solid fa-circle-right ml-2 mr-2 mt-1"
                      style={{ color: "#ED6F07", fontSize: "20px" }}
                    ></i>
                    <h2> ({todoTasks.length})</h2>
                  </Row>
                </CardBody>
              </Card>
              {todoTasks.map((item) => {
                return (
                  <>
                    <Card className="card-stats mb-4 mb-xl-0  mt-3 gap-">
                      <CardBody>
                        <Row>
                          <Col xs="3" className="text-center mr-3">
                            <div style={{ width: "60px", height: "60px" }}>
                              <CircularProgressbar
                                value={progressTodoPercentage}
                                text={`${progressTodoPercentage}%`}
                                styles={buildStyles({
                                  textColor: "#ED6F07",

                                  pathColor: "orange",
                                  trailColor: "#eee",
                                })}
                              />
                            </div>
                            <div></div>
                          </Col>

                          <Col className="text-left ml-3">
                            <h3>{item.taskname} </h3>
                            <img
                              src={require("../../assets/img/brand/upcoming (1).gif")}
                              style={{
                                "--fa-beat-scale": 1.5,
                                color: "red",
                                fontSize: "20px",
                                height: "40px",
                                width: "40px",
                              }}
                            />

                            {item.end_date}
                            <Col className="d-flex justify-content-left ">
                              <div className="avatar-group style={{height:'40px', width:'25px'}}">
                                <a
                                  className="avatar avatar-sm"
                                  href="#pablo"
                                  id={"tooltip_" + item.t_id}
                                  onClick={(e) => e.preventDefault()}
                                >
                                  {item.emp_profile_image && 
                                  <img
                                    alt="..."
                                    className="rounded-circle"
                                    style={{height:'35px', width:'35px'}}
                                    src={require(`../../../images/${item.emp_profile_image}`)}
                                  />
                                  }
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={"tooltip_" + item.t_id}
                                >
                                  {item.emp_name}
                                </UncontrolledTooltip>
                                <a
                                  className="avatar avatar-sm"
                                  href="#pablo"
                                  id={"tooltip2" + item.t_id}
                                  onClick={(e) => e.preventDefault()}
                                >
                                  {item.assign_to_profile_image && 
                                  <img
                                    alt="..."
                                    className="rounded-circle"
                                    style={{height:'35px', width:'35px'}}
                                    src={require(`../../../images/${item.assign_to_profile_image}`)}
                                  />
                                  }
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={"tooltip2" + item.t_id}
                                >
                                  {item.assign_to_name}
                                </UncontrolledTooltip>
                              </div>
                            </Col>
                          </Col>
                          <span>
                            <UncontrolledDropdown className="">
                              <DropdownToggle
                                caret
                                color="link"
                                onClick={toggleDropdown}
                                className="p-0"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </DropdownToggle>
                              <DropdownMenu right>
                                {item &&
                                  (item.assign_to === item.emp_id ||
                                  item.emp_id === emp_id ? ( // Original condition
                                    <>
                                      <Link
                                        to={`/site-admin/site-management/Viewtask2/${item.t_id}`}
                                        style={{ color: "black" }}
                                      >
                                        <DropdownItem onClick={toggleEditModal}>
                                          View
                                        </DropdownItem>
                                      </Link>
                                      <Link
                                        to={`/site-admin/site-management/edittask2/${item.t_id}`}
                                        style={{ color: "black" }}
                                      >
                                        <DropdownItem onClick={toggleEditModal}>
                                          Edit
                                        </DropdownItem>
                                      </Link>
                                      <DropdownItem
                                        onClick={() => {
                                          setShowTodoDeleteConfirmation(true);
                                          setTodoIdForDelete(item.t_id);
                                        }}
                                      >
                                        Delete
                                      </DropdownItem>
                                    </>
                                  ) : (
                                    // Else condition (current user assigned to the task)
                                    <>
                                      <Link
                                        to={`/site-admin/site-management/Viewtask2/${item.t_id}`}
                                        style={{ color: "black" }}
                                      >
                                        <DropdownItem onClick={toggleEditModal}>
                                          View
                                        </DropdownItem>
                                      </Link>
                                    </>
                                  ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </span>

                          <Modal isOpen={viewModal} toggle={toggleViewModal}>
                            <ModalHeader toggle={toggleViewModal}>
                              View Task
                            </ModalHeader>
                            <ModalBody>
                              {selectedTodoForView && (
                                <>
                                  <h3 for="exampleField">Project Name:</h3>
                                  <h3>
                                    Task Name: {selectedTodoForView.taskname}
                                  </h3>
                                  <h3>
                                    Assign To: {selectedTodoForView.assign_to}
                                  </h3>
                                  <h3>
                                    Start Date: {selectedTodoForView.start_date}
                                  </h3>
                                  <h3>
                                    End Date: {selectedTodoForView.end_date}
                                  </h3>
                                  <h3>
                                    Description:{" "}
                                    {selectedTodoForView.t_description}
                                  </h3>
                                  {/* Add other task details */}
                                </>
                              )}
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                onClick={() => toggleViewModal(null)}
                                className="btn btn-primary"
                              >
                                Close
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </Row>
                      </CardBody>
                    </Card>
                    <Modal
                      isOpen={showTodoDeleteConfirmation}
                      toggle={() => setShowTodoDeleteConfirmation(false)}
                    >
                      <ModalHeader>Confirm Deletion</ModalHeader>
                      <ModalBody>
                        <p>
                          Are you sure you want to delete this {todoIdForDelete}{" "}
                          ?
                        </p>
                      </ModalBody>
                      <div className="modal-footer">
                        <Button
                          color="danger"
                          onClick={() => {
                            setShowTodoDeleteConfirmation(false);
                            handleDeleteConfirmation(todoIdForDelete);
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => setShowTodoDeleteConfirmation(false)}
                        >
                          No
                        </Button>
                      </div>
                    </Modal>
                  </>
                );
              })}
            </Col>
            <Col lg="4">
              <Card className="card-stats mb-4 mb-xl-0 m-0">
                <CardBody>
                  <Row>
                    <img
                      src={require("../../assets/img/brand/busy (1).gif")}
                      style={{
                        "--fa-beat-scale": 1.5,
                        color: "red",
                        fontSize: "20px",
                        height: "40px",
                        width: "40px",
                      }}
                    />
                    {/* <i class="fa-solid fa-person-running mr-2 mt-1" style={{ color: 'blue', fontSize: '20px' }}></i> */}
                    <h2 className="ml-2">In Progress</h2>
                    <i
                      class="fa-solid fa-circle-right ml-2 mr-2 mt-1"
                      style={{ color: "blue", fontSize: "20px" }}
                    ></i>

                    <h2> ({progressTasks.length})</h2>
                  </Row>
                </CardBody>
              </Card>

              {progressTasks.map((prog) => {
                return (
                  <>
                    <Card className="card-stats mb-4 mb-xl-0  mt-3">
                      <CardBody>
                        <Row>
                          <Col xs="3" className="text-center mr-3">
                            <div style={{ width: "60px", height: "60px" }}>
                              <CircularProgressbar
                                value={progressInComPercentage}
                                text={`${progressInComPercentage}%`}
                                styles={buildStyles({
                                  textColor: "blue",

                                  pathColor: "#007bff",
                                  trailColor: "#eee",
                                })}
                              />
                            </div>
                            <div></div>
                          </Col>

                          <Col className="text-left ml-3">
                            <h3>{prog.taskname} </h3>
                            {/* <i class="fa-regular fa-calendar-check m-1"></i> */}
                            <img
                              src={require("../../assets/img/brand/upcoming (1).gif")}
                              style={{
                                "--fa-beat-scale": 1.5,
                                color: "red",
                                fontSize: "20px",
                                height: "40px",
                                width: "40px",
                              }}
                            />
                            {prog.end_date}
                            <Col className="d-flex justify-content-left ">
                              <div className="avatar-group style={{height:'40px', width:'25px'}}">
                                <a
                                  className="avatar avatar-sm"
                                  href="#pablo"
                                  id={"tooltip1" + prog.t_id}
                                  onClick={(e) => e.preventDefault()}
                                >
                                  {prog.emp_profile_image && 
                                  <img
                                    alt="..."
                                    className="rounded-circle"
                                    style={{height:'35px', width:'35px'}}
                                    src={require(`../../../images/${prog.emp_profile_image}`)}
                                  />
                                  }
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={"tooltip1" + prog.t_id}
                                >
                                  {prog.emp_name}
                                </UncontrolledTooltip>
                                <a
                                  className="avatar avatar-sm"
                                  href="#pablo"
                                  id={"tooltip2" + prog.t_id}
                                  onClick={(e) => e.preventDefault()}
                                >
                                  {prog.assign_to_profile_image && 
                                  <img
                                    alt="..."
                                    className="rounded-circle"
                                    style={{height:'35px', width:'35px'}}
                                    src={require(`../../../images/${prog.assign_to_profile_image}`)}
                                  />
                                  }
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={"tooltip2" + prog.t_id}
                                >
                                  {prog.assign_to_name}
                                </UncontrolledTooltip>
                              </div>
                            </Col>
                          </Col>

                          <span>
                            <UncontrolledDropdown className="">
                              <DropdownToggle
                                caret
                                color="link"
                                onClick={toggleDropdown}
                                className="p-0"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </DropdownToggle>
                              <DropdownMenu right>
                                {prog &&
                                  (prog.assign_to === prog.emp_id ||
                                  prog.emp_id === emp_id ? ( // Original condition
                                    <>
                                      {/* <DropdownItem onClick={() => toggleProgressModal(prog)}>View</DropdownItem> */}
                                      <Link
                                        to={`/site-admin/site-management/Viewtask2/${prog.t_id}`}
                                        style={{ color: "black" }}
                                      >
                                        <DropdownItem onClick={toggleEditModal}>
                                          View
                                        </DropdownItem>
                                      </Link>
                                      <Link
                                        to={`/site-admin/site-management/edittask2/${prog.t_id}`}
                                        style={{ color: "black" }}
                                      >
                                        <DropdownItem onClick={toggleEditModal}>
                                          Edit
                                        </DropdownItem>
                                      </Link>
                                      <DropdownItem
                                        onClick={() => {
                                          setShowProgressDeleteConfirmation(
                                            true
                                          );
                                          setSelectedItemIdForDelete(prog.t_id);
                                        }}
                                      >
                                        Delete
                                      </DropdownItem>
                                    </>
                                  ) : (
                                    // Else condition (current user assigned to the task)
                                    <>
                                      {/* <DropdownItem onClick={() => toggleViewModal(prog)}>View</DropdownItem> */}
                                      <Link
                                        to={`/site-admin/site-management/Viewtask2/${prog.t_id}`}
                                        style={{ color: "black" }}
                                      >
                                        <DropdownItem onClick={toggleEditModal}>
                                          View
                                        </DropdownItem>
                                      </Link>
                                    </>
                                  ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </span>

                          <Modal
                            isOpen={viewProgressModal}
                            toggle={toggleProgressModal}
                          >
                            <ModalHeader toggle={toggleProgressModal}>
                              View Task
                            </ModalHeader>
                            <ModalBody>
                              {selectedProgressView && (
                                <>
                                  <h3 for="exampleField">Project Name:</h3>
                                  <h3>
                                    Task Name: {selectedProgressView.taskname}
                                  </h3>
                                  <h3>
                                    Assign To: {selectedProgressView.assign_to}
                                  </h3>
                                  <h3>
                                    Start Date:{" "}
                                    {selectedProgressView.start_date}
                                  </h3>
                                  <h3>
                                    End Date: {selectedProgressView.end_date}
                                  </h3>
                                  <h3>
                                    Description:{" "}
                                    {selectedProgressView.t_description}
                                  </h3>
                                  {/* Add other task details */}
                                </>
                              )}
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                onClick={() => toggleProgressModal(null)}
                                className="btn btn-primary"
                              >
                                Close
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </Row>
                      </CardBody>
                    </Card>

                    <Modal
                      isOpen={showProgressDeleteConfirmation}
                      toggle={() => setShowProgressDeleteConfirmation(false)}
                    >
                      <ModalHeader>Confirm Deletion</ModalHeader>
                      <ModalBody>
                        Are you sure you want to delete this "{}"?
                      </ModalBody>
                      <div className="modal-footer">
                        {/* <Button color="danger" onClick={() => { setShowProgressDeleteConfirmation(false); handleProgressDeleteConfirmation(prog.t_id) }}>Yes</Button> */}
                        <Button
                          color="danger"
                          onClick={() => {
                            setShowProgressDeleteConfirmation(false);
                            handleProgressDeleteConfirmation(
                              selectedItemIdForDelete
                            );
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() =>
                            setShowProgressDeleteConfirmation(false)
                          }
                        >
                          No
                        </Button>
                      </div>
                    </Modal>
                  </>
                );
              })}
            </Col>
            <Col lg="4">
              <Card className="card-stats mb-4 mb-xl-0 m-0">
                <CardBody>
                  <Row>
                    <img
                      src={require("../../assets/img/brand/heart (1).gif")}
                      style={{
                        "--fa-beat-scale": 1.5,
                        color: "red",
                        fontSize: "20px",
                        height: "40px",
                        width: "40px",
                      }}
                    />
                    {/* <i class="fa-solid fa-heart-circle-check mr-2 mt-1 " style={{ color: 'red', fontSize: '20px' }}></i> */}
                    <h2 className="ml-2">Completed</h2>
                    <i
                      class="fa-solid fa-circle-right ml-2 mr-2 mt-1"
                      style={{ color: "#68D241", fontSize: "20px" }}
                    ></i>
                    <h2> ({completedTasks.length})</h2>
                  </Row>
                </CardBody>
              </Card>

              {completedTasks.map((comp) => {
                return (
                  <>
                    <div>
                      <Card className="card-stats mb-4 mb-xl-0  mt-3">
                        <CardBody>
                          <Row>
                            <Col xs="3" className="text-center mr-3">
                              <div style={{ width: "60px", height: "60px" }}>
                                <CircularProgressbar
                                  value={progressComPercentage}
                                  text={`${progressComPercentage}%`}
                                  styles={buildStyles({
                                    textColor: "green",

                                    pathColor: "#68D241",
                                    trailColor: "#eee",
                                  })}
                                />
                              </div>
                              <div></div>
                            </Col>

                            <Col className="text-left ml-3">
                              <h3>{comp.taskname}</h3>
                              <img
                                src={require("../../assets/img/brand/upcoming (1).gif")}
                                style={{
                                  "--fa-beat-scale": 1.5,
                                  color: "red",
                                  fontSize: "20px",
                                  height: "40px",
                                  width: "40px",
                                }}
                              />

                              {comp.end_date}
                              <Col className="d-flex justify-content-left ">
                                <div className="avatar-group style={{height:'40px', width:'25px'}}">
                                  <a
                                    className="avatar avatar-sm"
                                    href="#pablo"
                                    id={"tooltip1" + comp.t_id}
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    {comp.emp_profile_image && 
                                    <img
                                      alt="..."
                                      className="rounded-circle"
                                      style={{height:'35px', width:'35px'}}
                                      src={require(`../../../images/${comp.emp_profile_image}`)}
                                    />
                                    }
                                  </a>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip1" + comp.t_id}
                                  >
                                    {comp.emp_name}
                                  </UncontrolledTooltip>
                                  <a
                                    className="avatar avatar-sm"
                                    href="#pablo"
                                    id={"tooltip2" + comp.t_id}
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    {comp.assign_to_profile_image && 
                                    <img
                                      alt="..."
                                      className="rounded-circle"
                                      style={{height:'35px', width:'35px'}}
                                      src={require(`../../../images/${comp.assign_to_profile_image}`)}
                                    />
                                    }
                                  </a>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip2" + comp.t_id}
                                  >
                                    {comp.assign_to_name}
                                  </UncontrolledTooltip>
                                </div>
                              </Col>
                            </Col>
                            <span>
                              <UncontrolledDropdown className="">
                                <DropdownToggle
                                  caret
                                  color="link"
                                  onClick={toggleDropdown}
                                  className="p-0"
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  {comp &&
                                    (comp.assign_to === comp.emp_id ? ( // Original condition
                                      <>
                                        <Link
                                          to={`/site-admin/site-management/Viewtask2/${comp.t_id}`}
                                          style={{ color: "black" }}
                                        >
                                          <DropdownItem
                                            onClick={toggleEditModal}
                                          >
                                            View
                                          </DropdownItem>
                                        </Link>
                                        <Link
                                          to={`/site-admin/site-management/edittask2/${comp.t_id}`}
                                          style={{ color: "black" }}
                                        >
                                          <DropdownItem
                                            onClick={toggleEditModal}
                                          >
                                            Edit
                                          </DropdownItem>
                                        </Link>
                                        <DropdownItem
                                          onClick={() => {
                                            setShowProgressDeleteConfirmation(
                                              true
                                            );
                                            setSelectedItemIdForDelete(
                                              comp.t_id
                                            );
                                          }}
                                        >
                                          Delete
                                        </DropdownItem>
                                      </>
                                    ) : (
                                      // Else condition (current user assigned to the task)
                                      <>
                                        <Link
                                          to={`/site-admin/site-management/Viewtask2/${comp.t_id}`}
                                          style={{ color: "black" }}
                                        >
                                          <DropdownItem
                                            onClick={toggleEditModal}
                                          >
                                            View
                                          </DropdownItem>
                                        </Link>
                                      </>
                                    ))}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </span>

                            <Modal
                              isOpen={viewCompletedModal}
                              toggle={toggleCompletedModal}
                            >
                              <ModalHeader toggle={toggleCompletedModal}>
                                View Task
                              </ModalHeader>
                              <ModalBody>
                                {selectedCompletedView && (
                                  <>
                                    <h3 for="exampleField">Project Name:</h3>
                                    <h3>
                                      Task Name:{" "}
                                      {selectedCompletedView.taskname}
                                    </h3>
                                    <h3>
                                      Assign By:{" "}
                                      {selectedCompletedView.assign_to}
                                    </h3>
                                    <h3>
                                      Assign To:{" "}
                                      {selectedCompletedView.assign_to}
                                    </h3>
                                    <h3>
                                      Start Date:{" "}
                                      {selectedCompletedView.start_date}
                                    </h3>
                                    <h3>
                                      End Date: {selectedCompletedView.end_date}
                                    </h3>
                                    <h3>
                                      Description:{" "}
                                      {selectedCompletedView.t_description}
                                    </h3>
                                    {/* Add other task details */}
                                  </>
                                )}
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  onClick={() => toggleCompletedModal(null)}
                                  className="btn btn-primary"
                                >
                                  Close
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </Row>
                        </CardBody>
                      </Card>

                      <Modal
                        isOpen={showCompletedDeleteConfirmation}
                        toggle={() => setShowCompletedDeleteConfirmation(false)}
                      >
                        <ModalHeader>Confirm Deletion</ModalHeader>
                        <ModalBody>
                          Are you sure you want to delete this ""?
                        </ModalBody>
                        <div className="modal-footer">
                          <Button
                            color="danger"
                            onClick={() => {
                              setShowCompletedDeleteConfirmation(false);
                              handleCompletedDeleteConfirmation(
                                completedIdForDelete
                              );
                            }}
                          >
                            Yes
                          </Button>
                          <Button
                            color="secondary"
                            onClick={() =>
                              setShowCompletedDeleteConfirmation(false)
                            }
                          >
                            No
                          </Button>
                        </div>
                      </Modal>
                    </div>
                  </>
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Task2;
