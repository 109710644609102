import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { api } from "../../../api";

const LineChart = () => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const fetchChart = async () => {
      try {
        const response = await api.get(`financialgraphVP.php`);
        const data = response.data;
    
        const labels = data.map((item) =>
          new Date(item.year).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })
        );
        const profits = data.map((item) => item.profit);
        const profitPercentages = data.map((item) => item.profit_percentage);
        const totalBudgets = data.map((item) => item.total_budget);
        const totalMaterialCosts = data.map((item) => item.total_material_cost);
    
        setChartData({
          labels,
          datasets: [
            {
              label: "Profit",
              data: profits,
              fill: false,
              backgroundColor: "rgb(54, 162, 235)",
              borderColor: "rgb(54, 162, 235)",
              profitPercentages,
              totalBudgets,
              totalMaterialCosts,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching the chart data:", error);
      }
    };

    fetchChart();
  }, []);

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
          },
        },
      ],
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: (tooltipItem, data) => {
          const datasetIndex = tooltipItem.datasetIndex;
          const datasetLabel = data.datasets[datasetIndex].label;
          const value = tooltipItem.value;
          const profitPercentage = data.datasets[datasetIndex].profitPercentages[tooltipItem.index];
          const totalBudget = data.datasets[datasetIndex].totalBudgets[tooltipItem.index];
          const totalMaterialCost = data.datasets[datasetIndex].totalMaterialCosts[tooltipItem.index];
  
          return [
            `${datasetLabel}: ${value}`,
            `Profit Percentage: ${profitPercentage}`,
            `Total Budget: ${totalBudget}`,
            `Total Material Cost: ${totalMaterialCost}`,
          ];
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;
