/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import {api} from '../../../../api'
import Cookies from 'js-cookie';


const AdminNavbar = (props) => {
  // const [isAlertDropdownOpen, setIsAlertDropdownOpen] = useState(false);
  const [isMessageDropdownOpen, setIsMessageDropdownOpen] = useState(false);

  const navigate  = useNavigate();

  const toggleAlertDropdown = () => {
    setIsAlertDropdownOpen(!isAlertDropdownOpen);
  };

  const toggleMessageDropdown = () => {
    setIsMessageDropdownOpen(!isMessageDropdownOpen);
  };
  const [tasks, setTasks] = useState({
    pm_name : '',
    profile_image: ''
  });


  const cookieId = Cookies.get('token');
  const [logout, setlogout] = useState();


  useEffect(() => {
    const cookieId = Cookies.get('token');
    const fetchData = async () => {
      try {
        const response = await api.get(`PMprofileView.php?id=${cookieId}`);
        setTasks({
          pm_name: response.data.emp_name,
          profile_image : response.data.profile_image
        })

        const response2 = await api.post(`logout.php`);
        setlogout(response2.data.logout_time);
        console.log(logout);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);


  const [task,setTask] = useState([]);
  const [employees,setEmployees] = useState([]);
  const [projectNoti, setprojectNoti] = useState([]);

  const [isAlertDropdownOpen, setIsAlertDropdownOpen] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0); // Example count
  
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await api.get(`notificationTasks.php?id=${cookieId}`);
        setTask(response.data || []);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching tasks:', error);
        setTask([]);
      }
    };

    const fetchProjectNoti = async () => {
      try {
        const response2 = await api.get(`projectNotification.php?pm_id=${cookieId}`);
        setprojectNoti(response2.data || []);
        console.log(response2.data);
      } catch (error) {
        console.error('Error fetching project notifications:', error);
        setprojectNoti([]);
      }
    };

    fetchTasks();
    fetchProjectNoti();
  }, [cookieId]);

  console.log(projectNoti.length);

  useEffect(() => {
    console.log('Task:', task);
    console.log('Project Notifications:', projectNoti);
    const taskCount = Array.isArray(task) ? task.length : 0;
    const projectNotiCount = Array.isArray(projectNoti) ? projectNoti.length : 0;
    const totalNotiCount = taskCount + projectNotiCount;
    console.log('Notification Count:', totalNotiCount);
    setNotificationCount(totalNotiCount);
  }, [task, projectNoti]);

  const handleTaskClick = async (taskId) => {
    try {
      // Update notification status to "read"
      await api.post("updateNotificationStatus.php", { t_id: taskId });

      // Remove the task from tasks state
      setTask((prevTasks) => prevTasks.filter((task) => task.t_id !== taskId));

      navigate("/project-admin/project-management/task");
    } catch (error) {
      console.error("Error updating notification status:", error);
    }
  };

  const handleProjectStatusClick = async (noti_Id) => {
    try {
      // Update notification status to "read"
      await api.post("projectNotification.php", { pm_id : cookieId, n_id: noti_Id });

      // Remove the task from tasks state
      setprojectNoti((prevTasks) => prevTasks.filter((projectNoti) => projectNoti.n_id !== noti_Id));

      navigate("/project-admin/project-management/project");
    } catch (error) {
      console.error("Error updating notification status:", error);
    }
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await api.get('employeesView.php');
        setEmployees(response.data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, []);

  const handleLogout = async () => {
    try {
      const response = await api.get(`logout.php?emp_id=${cookieId}`);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    // Remove token cookie
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    // Remove path cookie
    document.cookie = 'path=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    // Remove role cookie
    document.cookie = 'role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    // Optionally, you can also clear any local storage or session storage
    localStorage.clear();
    sessionStorage.clear();

    // Redirect to login page
    window.location.href = '/login';
  };

  const employee = employees.find(emp => emp.emp_id === '1');

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
          <UncontrolledDropdown nav inNavbar onMouseEnter={toggleMessageDropdown} onMouseLeave={toggleMessageDropdown} isOpen={isMessageDropdownOpen}>
            <DropdownToggle nav >
              <i class="fa-solid fa-comment"></i>
            </DropdownToggle> 
            <DropdownMenu  aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
            <DropdownItem>Message Box</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Message 1</DropdownItem>
              <DropdownItem>Message 2</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>


          <UncontrolledDropdown nav inNavbar isOpen={isAlertDropdownOpen} toggle={toggleAlertDropdown} style={{zIndex: '10'}}>
              <DropdownToggle nav className="nav-link-icon fa-stack mb-2">
                <i className={isNotification ? "fa-solid fa-bell fa-bounce" : "fa-solid fa-bell"}style={{zIndex:'1' ,position:'relative'}} />
                {notificationCount >= 0 && (
                  <span className="badge badge-danger" style={{ position: 'absolute', right: '-4px', top: '1px', fontSize:'9px', borderRadius:'50%', zIndex:'0'}}>
                    {notificationCount}
                  </span>
                )}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>Notification</DropdownItem>
                <DropdownItem divider />
                {Array.isArray(task) && task.length > 0 && (
                  task.map(task => (
                    <DropdownItem key={task.t_id} onClick={() => handleTaskClick(task.t_id)} style={{ padding: '15px', borderBottom: '1px solid #f0f0f0' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          alt="Profile"
                          src={require(`../../../images/${task.profile_image}`)}
                          style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '20px' }}
                        />
                        <div style={{ flex: 1 }}>
                          <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Assigned By: {task.emp_name}</div>
                          <div style={{ marginBottom: '5px', color: '#333' }}>Task Name: {task.taskname}</div>
                          <div style={{ color: '#777' }}>End Date: {task.end_date}</div>
                        </div>
                      </div>
                    </DropdownItem>
                  ))
                )}

                {Array.isArray(projectNoti) && projectNoti.length > 0 && (
                  projectNoti.map(noti => (
                    <DropdownItem key={noti.n_id} onClick={() => handleProjectStatusClick(noti.n_id)} style={{ padding: '15px', borderBottom: '1px solid #f0f0f0' }}>
                      <div style={{ display: 'flex', alignItems: 'center', border: 'none' }}>
                        <img
                          alt="Profile"
                          src={require(`../../../images/${noti.profile_image}`)}
                          style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '20px' }}
                        />
                        <div style={{ flex: 1 }}>
                          <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Name : {noti.emp_name}</div>
                          <div style={{ marginBottom: '5px', color: '#333' }}>New project[{noti.message}] has been {noti.status}.</div>
                        </div>
                      </div>
                    </DropdownItem>
                  ))
                )}

                {(!Array.isArray(task) || task.length === 0) && 
                (!Array.isArray(projectNoti) || projectNoti.length === 0) && (
                  <DropdownItem>
                  No notifications
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>

          
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    {tasks.profile_image && // Render img tag only if profile_image is not null or empty
                      <img
                      style={{
                        width: '50px',
                        height: '40px',
                      }}
                        alt="..."
                        src={require(`../../../images/${tasks.profile_image}`)}
                      />
                    }
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                     
                      {tasks.pm_name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/project-admin/project-management/Profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleLogout()}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
