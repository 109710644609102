import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, Table, Container, Row, Col, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Badge, Label, CardBody } from "reactstrap";
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { api } from '../../../../api';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom/dist';

const Requestwaste4 = () => {
    const navigate = useNavigate();
    const allData = [
        { slNo: 1, requestName: "Repair Machine", deadline: "15-05-2024", requestFrom: "John Doe", requestTo: "Maintenance Department", requestedOn: "2024-04-01", description: "Machine in production line 1 needs repair due to motor failure. The malfunction is causing delays in the production process and needs immediate attention. Please assign a technician to troubleshoot and repair the machine as soon as possible. The faulty motor has been affecting the efficiency of the entire production line, resulting in decreased output and increased downtime. This issue is impacting our delivery schedules and customer satisfaction. We have already experienced significant losses due to the machine breakdown, and it's crucial to resolve it swiftly to avoid further financial losses. Additionally, prolonged downtime can also lead to employee frustration and demotivation. Hence, we urge the maintenance department to prioritize this repair request and allocate necessary resources to address the issue effectively.", status: "Pending" },
        { slNo: 2, requestName: "Purchase Office Supplies", deadline: "20-04-2024", requestFrom: "Alice Smith", requestTo: "Procurement Department", requestedOn: "2024-04-02", description: "Need to order pens, papers, and ink cartridges for the office. We are running low on essential office supplies and require replenishment to ensure smooth workflow. Please proceed with the purchase as per the budget allocation. It's important to maintain an adequate stock of office supplies to support day-to-day operations. Running out of supplies can disrupt work and cause unnecessary delays. By restocking the necessary items in advance, we can avoid such disruptions and maintain productivity levels. Additionally, having sufficient supplies on hand ensures that employees can focus on their tasks without worrying about resource shortages. Therefore, we request the procurement department to expedite the purchase process and ensure timely delivery of the required supplies.", status: "Approved" },
        { slNo: 3, requestName: "Employee Training", deadline: "10-05-2024", requestFrom: "Jane Johnson", requestTo: "Human Resources Department", requestedOn: "2024-04-03", description: "Requesting training session on new software for the team. Our department recently adopted a new software system, and it's crucial for all team members to undergo training to maximize its benefits. Please arrange a comprehensive training session with a qualified instructor. The new software is expected to streamline our processes and improve efficiency, but without proper training, we cannot fully utilize its features. Investing in training now will pay off in the long run by increasing employee proficiency and productivity. We also believe that trained employees are more engaged and satisfied with their work, leading to higher retention rates. Therefore, we urge the HR department to schedule the training session at the earliest convenience.", status: "Approved" },
        { slNo: 4, requestName: "Fix Office HVAC", deadline: "25-04-2024", requestFrom: "Chris Brown", requestTo: "Facilities Department", requestedOn: "2024-04-04", description: "Office air conditioning not working properly. The HVAC system in the office has been malfunctioning, resulting in uncomfortable working conditions for employees. We need a technician to diagnose and fix the issue promptly to restore a comfortable environment. The temperature fluctuations are affecting employee productivity and morale, and it's essential to address the problem as soon as possible. Moreover, prolonged exposure to uncomfortable temperatures can lead to health issues, impacting employee well-being and increasing absenteeism. Therefore, we request the facilities department to prioritize this repair and allocate resources accordingly. Additionally, we recommend regular maintenance checks to prevent similar issues in the future.", status: "Pending" },
        { slNo: 5, requestName: "New Employee Setup", deadline: "05-05-2024", requestFrom: "Mary Johnson", requestTo: "IT Department", requestedOn: "2024-04-05", description: "Setting up computer, email, and software access for new employee. A new employee is joining our team, and we need to set up their workstation with necessary computer equipment, email account, and access to essential software applications. Please ensure everything is ready before their start date. Proper onboarding is crucial for new employees to integrate smoothly into the team and start contributing effectively. By providing them with the necessary tools and resources from day one, we can accelerate their learning curve and help them become productive faster. Additionally, a smooth onboarding experience enhances employee satisfaction and engagement, which are key factors in retention. Therefore, we request the IT department to prioritize this setup and ensure a seamless onboarding process for the new employee.", status: "Approved" },
        { slNo: 6, requestName: "Meeting Room Booking", deadline: "12-05-2024", requestFrom: "David Lee", requestTo: "Facilities Department", requestedOn: "2024-04-06", description: "Need to book the conference room for a client meeting next week. We have an important client meeting scheduled for next week, and we need to reserve the conference room for the occasion. Please ensure that the room is available and equipped with necessary facilities such as projector and whiteboard. It's crucial to create a professional and conducive environment for the meeting to impress our clients and communicate effectively. A well-prepared meeting room reflects positively on our company and enhances our reputation. Therefore, we request the facilities department to confirm the room booking at the earliest convenience.", status: "Pending" },
        { slNo: 7, requestName: "Software Upgrade", deadline: "01-05-2024", requestFrom: "Emily White", requestTo: "IT Department", requestedOn: "2024-04-07", description: "Upgrade the accounting software to the latest version. The current version of the accounting software is outdated and lacks several important features and security updates. We need to upgrade to the latest version to ensure compliance with regulatory requirements and improve efficiency. Please schedule the upgrade process during off-peak hours to minimize disruption to daily operations. Additionally, we recommend thorough testing after the upgrade to ensure compatibility and functionality. Therefore, we request the IT department to plan and execute the software upgrade at the earliest convenience.", status: "Approved" },
        { slNo: 8, requestName: "Printer Repair", deadline: "28-04-2024", requestFrom: "Michael Brown", requestTo: "IT Department", requestedOn: "2024-04-08", description: "Printer in the marketing department is not printing properly. The printer in the marketing department has been experiencing print quality issues, including smudging and streaking. We need a technician to inspect and repair the printer to restore its functionality. The malfunctioning printer is causing delays in printing marketing materials and affecting departmental productivity. It's crucial to address the issue promptly to avoid further disruptions. Therefore, we request the IT department to assign a technician to diagnose and fix the printer issue as soon as possible.", status: "Pending" },
        { slNo: 9, requestName: "Security Access Request", deadline: "02-05-2024", requestFrom: "Emma Miller", requestTo: "Security Department", requestedOn: "2024-04-09", description: "Requesting access card for new employee. A new employee is joining our team, and we need to issue an access card to grant them entry to the office premises. Please generate the access card and ensure that it is activated before their start date. Access cards are essential for maintaining security and controlling access to restricted areas within the office. By providing the new employee with an access card, we can ensure that they have the necessary permissions to perform their job responsibilities. Therefore, we request the security department to process the access card request as soon as possible.", status: "Approved" },
        { slNo: 10, requestName: "Website Update", deadline: "06-05-2024", requestFrom: "Daniel Wilson", requestTo: "IT Department", requestedOn: "2024-04-10", description: "Update the company website with new product images. We have recently launched new products, and we need to update the website to showcase them. Please upload high-quality images of the new products to the website gallery. It's essential to keep the website content fresh and up-to-date to attract visitors and generate leads. By showcasing our latest products effectively, we can increase customer engagement and drive sales. Therefore, we request the IT department to update the website promptly.", status: "Pending" },
        { slNo: 11, requestName: "Office Furniture Purchase", deadline: "08-05-2024", requestFrom: "Olivia Taylor", requestTo: "Procurement Department", requestedOn: "2024-04-11", description: "Need to purchase new chairs and desks for the office. The current office furniture is old and worn out, causing discomfort to employees. We need to replace them with new ergonomic chairs and desks to improve comfort and productivity. Please procure high-quality furniture that meets ergonomic standards and budget constraints. Investing in comfortable office furniture is essential for employee well-being and retention. By providing a comfortable work environment, we can enhance employee satisfaction and performance. Therefore, we request the procurement department to initiate the furniture purchase process at the earliest.", status: "Approved" },
        { slNo: 12, requestName: "Network Maintenance", deadline: "03-05-2024", requestFrom: "Sophia Martinez", requestTo: "IT Department", requestedOn: "2024-04-12", description: "Perform routine maintenance on the company network. The company network requires regular maintenance to ensure optimal performance and security. We need to conduct routine checks and updates to identify and address any potential issues. Please schedule a maintenance window during off-peak hours to minimize disruption to daily operations. By maintaining the network proactively, we can prevent downtime and security breaches. Therefore, we request the IT department to perform network maintenance as soon as possible.", status: "Pending" },
        { slNo: 13, requestName: "Employee Benefits Update", deadline: "07-05-2024", requestFrom: "Ethan Anderson", requestTo: "Human Resources Department", requestedOn: "2024-04-13", description: "Update employee benefits policy. The current employee benefits policy needs to be updated to align with industry standards and company objectives. We need to review and revise the policy to ensure that it remains competitive and attractive to current and prospective employees. Please conduct a thorough analysis of market trends and employee feedback to inform the update process. By offering competitive benefits, we can attract and retain top talent in the industry. Therefore, we request the HR department to initiate the benefits update process at the earliest.", status: "Approved" },
        { slNo: 14, requestName: "Data Backup", deadline: "09-05-2024", requestFrom: "Isabella Thomas", requestTo: "IT Department", requestedOn: "2024-04-14", description: "Backup company data to secure server. It's crucial to regularly backup company data to prevent data loss due to hardware failures, cyber attacks, or human errors. We need to create backups of all critical data and store them securely on an off-site server. Please ensure that the backup process is automated and scheduled to run regularly. By maintaining up-to-date backups, we can recover data quickly in the event of a disaster and minimize downtime. Therefore, we request the IT department to implement a robust data backup solution as soon as possible.", status: "Pending" },
        { slNo: 15, requestName: "Meeting Catering", deadline: "04-05-2024", requestFrom: "William Garcia", requestTo: "Facilities Department", requestedOn: "2024-04-15", description: "Arrange catering for the monthly team meeting. We need to organize catering for the upcoming monthly team meeting. Please coordinate with the catering service provider to arrange a diverse menu that caters to dietary restrictions and preferences. Providing quality catering enhances the meeting experience and fosters team bonding. Therefore, we request the facilities department to handle the catering arrangements for the meeting.", status: "Approved" },
        { slNo: 16, requestName: "Server Upgrade", deadline: "11-05-2024", requestFrom: "Ava Hernandez", requestTo: "IT Department", requestedOn: "2024-04-16", description: "Upgrade company server for improved performance. The company server is running on outdated hardware and software, leading to performance issues and security vulnerabilities. We need to upgrade the server to the latest hardware and software to enhance performance and security. Please plan the upgrade process carefully to minimize downtime and data loss. By upgrading the server, we can ensure that it meets the growing demands of our business and remains secure against emerging threats. Therefore, we request the IT department to initiate the server upgrade process at the earliest convenience.", status: "Pending" },
        { slNo: 17, requestName: "Software License Renewal", deadline: "13-05-2024", requestFrom: "James Young", requestTo: "Procurement Department", requestedOn: "2024-04-17", description: "Renew licenses for accounting software. The licenses for our accounting software are due for renewal, and we need to ensure uninterrupted access to the software. Please verify the renewal dates and proceed with the license renewal process before expiration. Operating with expired licenses can result in compliance issues and business disruptions. By renewing the licenses on time, we can avoid penalties and maintain smooth operations. Therefore, we request the procurement department to handle the license renewal process promptly.", status: "Approved" },
        { slNo: 18, requestName: "Marketing Campaign Planning", deadline: "16-05-2024", requestFrom: "Grace Lewis", requestTo: "Marketing Department", requestedOn: "2024-04-18", description: "Plan marketing campaign for new product launch. We are planning to launch a new product, and we need to develop a comprehensive marketing campaign to promote it. Please coordinate with the marketing team to plan and execute the campaign effectively. A well-planned marketing campaign can create buzz around the new product and drive sales. Therefore, we request the marketing department to start the campaign planning process as soon as possible.", status: "Pending" },
        { slNo: 19, requestName: "Travel Arrangements", deadline: "14-05-2024", requestFrom: "Benjamin Allen", requestTo: "Travel Department", requestedOn: "2024-04-19", description: "Book flights and accommodation for upcoming business trip. I will be traveling for a business meeting next month and need assistance in booking flights and accommodation. Please arrange for convenient flight options and hotel reservations as per the company's travel policy. Booking in advance ensures availability and allows for better travel planning. Therefore, I request the travel department to handle the arrangements for my upcoming trip.", status: "Approved" },
        { slNo: 20, requestName: "Expense Report Approval", deadline: "18-05-2024", requestFrom: "Victoria Clark", requestTo: "Finance Department", requestedOn: "2024-04-20", description: "Review and approve employee expense reports. As part of the finance team, I need to review and approve employee expense reports for reimbursement. Please ensure that the reports are accurate and comply with company expense policies. Timely approval of expense reports is essential to reimburse employees promptly and maintain trust. Therefore, I request the finance department to expedite the approval process for the submitted expense reports.", status: "Pending" },
        { slNo: 21, requestName: "Equipment Purchase", deadline: "17-05-2024", requestFrom: "Luke Hill", requestTo: "Procurement Department", requestedOn: "2024-04-21", description: "Purchase new equipment for the production department. We need to invest in new equipment to upgrade our production capabilities and meet growing demand. Please procure high-quality machinery that meets industry standards and safety regulations. Investing in modern equipment improves efficiency and product quality, leading to increased customer satisfaction. Therefore, we request the procurement department to initiate the equipment purchase process at the earliest convenience.", status: "Approved" },
        { slNo: 22, requestName: "System Security Audit", deadline: "22-05-2024", requestFrom: "Chloe King", requestTo: "IT Department", requestedOn: "2024-04-22", description: "Conduct security audit on company systems. We need to assess the security posture of our company systems to identify vulnerabilities and weaknesses. Please conduct a comprehensive security audit and provide recommendations for improvement. Proactive security measures are essential to protect our data and prevent cyber attacks. Therefore, we request the IT department to perform a thorough security audit as soon as possible.", status: "Pending" },
        { slNo: 23, requestName: "Employee Performance Evaluation", deadline: "19-05-2024", requestFrom: "Jackie Scott", requestTo: "Human Resources Department", requestedOn: "2024-04-23", description: "Schedule performance evaluations for employees. It's time to conduct annual performance evaluations for all employees. Please coordinate with department heads to schedule the evaluations and provide them with evaluation forms and guidelines. Performance evaluations help identify strengths and areas for improvement, facilitate career development, and ensure alignment with organizational goals. Therefore, we request the HR department to initiate the performance evaluation process as soon as possible.", status: "Approved" },
        { slNo: 24, requestName: "Inventory Restocking", deadline: "20-05-2024", requestFrom: "Mia Wright", requestTo: "Procurement Department", requestedOn: "2024-04-24", description: "Restock inventory of office supplies and materials. We are running low on essential office supplies and materials, and we need to replenish our inventory. Please check stock levels and reorder items as necessary to maintain adequate supplies. Running out of supplies can disrupt work and cause delays, so it's crucial to keep the inventory well-stocked. Therefore, we request the procurement department to expedite the restocking process.", status: "Pending" },
        { slNo: 25, requestName: "Customer Support Training", deadline: "21-05-2024", requestFrom: "Lucas Hall", requestTo: "Training Department", requestedOn: "2024-04-25", description: "Arrange customer support training for the team. We need to enhance the customer service skills of our support team to ensure customer satisfaction. Please organize a training session covering topics such as effective communication, problem-solving, and product knowledge. Investing in customer support training improves customer retention and loyalty. Therefore, we request the training department to schedule the training session at the earliest convenience.", status: "Approved" }
    ];







    const [showRoleModal, setShowRoleModal] = useState(false);
    const [filteredData, setFilteredData] = useState([]);


    // const toggleRole = () => {
    //     setShowRoleModal(!showRoleModal);
    // };

    const [searchQuery, setSearchQuery] = useState("");

    const [role, setrole] = useState();

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };


    useEffect(() => {
        const filtered = allData.filter(item =>
            item.requestName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.requestFrom.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.requestTo.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchQuery, allData]);



    const handleFormSubmit = async (e) => {
        // e.preventDefault();

        try {
            const response = await api.post("addRole.php", role);
            console.log("Data sent successfully:", response.data);
            window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };


    const [description, setDescription] = useState('');


    // Pagination code starts here


    const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
    const [entriesToShow, setEntriesToShow] = useState(5); // State variable for selected number of entries
    const [dataToShow, setDataToShow] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showWasteFields, setShowWasteFields] = useState(false);
    const [showReplaceFields, setShowReplaceFields] = useState(false);


    useEffect(() => {

        setDataToShow(allData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow));
    }, [entriesToShow, currentPage, allData.length]);



    const toggleProjectDropdown = () => {
        setProjectDropdownOpen(!projectDropdownOpen);
    };

    const handleEntriesChange = (value) => {
        setEntriesToShow(Number(value));
    };

    const handleDropdownToggle = (index) => {
        setDataToShow((prevData) => {
            const newData = [...prevData];
            newData[index].dropdownOpen = !newData[index].dropdownOpen;
            return newData;
        });
    };

    const prePage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < Math.ceil(allData.length / entriesToShow)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const changeCPage = (n) => {
        setCurrentPage(n);
    };


    

    const getSerialNumber = (index) => {
        return (currentPage - 1) * entriesToShow + index + 1;
    };




    const toggleRole = () => {
        setShowRoleModal(!showRoleModal);
        setShowWasteFields(false); // Close waste fields when modal toggles
        setShowReplaceFields(false); // Close replace fields when modal toggles
    };




    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedDescription, setSelectedDescription] = useState('');


    const toggleDropdown = (index) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }

    const startIndex = (currentPage - 1) * entriesToShow;

    // Get the items to show on the current page
    const dataTo = allData.slice(startIndex, startIndex + entriesToShow);

    // Function to handle "View More" click
    const handleViewMore = (description) => {
        setSelectedDescription(description);
        setShowModal(true);
    };


    useEffect(() => {
        // Filter the data based on searchQuery
        const filtered = allData.filter(item =>
            item.requestName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.requestFrom.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.requestTo.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);

        // Reset currentPage to 1 when search query changes
        setCurrentPage(1);
    }, [searchQuery, allData]);

    useEffect(() => {
        // When filteredData changes, update data to show
        const startIndex = (currentPage - 1) * entriesToShow;
        const endIndex = startIndex + entriesToShow;
        setDataToShow(filteredData.slice(startIndex, endIndex));
    }, [currentPage, entriesToShow, filteredData]);


    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const handleDeleteClick = (itemId) => {
        setSelectedItemId(itemId);
        setShowDeleteConfirmation(true);
    };

    const handleDeleteConfirmation = () => {
        // Here you would perform the deletion of the selected item
        // For now, let's just log the item ID and close the modal
        console.log("Deleting item with ID:", selectedItemId);
        setShowDeleteConfirmation(false);
    };





    return (
        <>
            <div className="header bg-gradient-info py-7 py-lg-8">
                <Container fluid>

                    {/* Search and Add request & Modal */}
                    <Row className='justify-content-center mb-5'>
                        <Col xs={12} md={6} className="d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                            <Form className="navbar-search navbar-search-dark form-inline">
                                <FormGroup className="mb-0">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-search" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Search Requests"
                                            type="text"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-center justify-content-md-end">
                            <Button onClick={toggleRole}>
                                Add Requests
                            </Button>
                        </Col>
                    </Row>


                    <Modal isOpen={showRoleModal} toggle={toggleRole}>
                        <ModalHeader toggle={toggleRole}>
                            <h2 style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
                                New Request
                            </h2>
                        </ModalHeader>
                        <ModalBody>
                            <Form onSubmit={handleFormSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>

                                            <label className="form-control-label" htmlFor="input-role">
                                                Enter Request Name
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-role"
                                                type="text"
                                                name="role"
                                                pattern="^[a-zA-Z ]+$"
                                                onChange={handleInputChange}
                                                placeholder="Request Name"
                                            />

                                            <label className="form-control-label" htmlFor="input-role">
                                                Enter Request To
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-role"
                                                type="text"
                                                name="role"
                                                pattern="^[a-zA-Z ]+$"
                                                onChange={handleInputChange}
                                                placeholder="Request To"
                                            />

                                            <label className="form-control-label" htmlFor="input-role">
                                                Enter Deadline
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-role"
                                                type="date"
                                                name="role"
                                                pattern="^[a-zA-Z ]+$"
                                                onChange={handleInputChange}
                                            />

                                            <label htmlFor="description">Enter Description:</label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={description}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setDescription(data);
                                                }}
                                            />



                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12" style={{ textAlign: "center" }}>
                                        <Button color="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>



                    {/* ------------Add Request Modal ends Here------------ */}


                    {/* ----------------Table and Pagination starts from here-------------------- */}


                    <React.Fragment>
                        <Row className="mt-5">
                            <Col className="mb-5 mb-xl-0" xl="12">
                                <Card className="shadow">
                                    <CardBody>

                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <h3 className="mb-0">Request List</h3>
                                                </div>
                                                <div className="col text-right">
                                                    <Dropdown isOpen={projectDropdownOpen} toggle={toggleProjectDropdown}>
                                                        <DropdownToggle caret>
                                                            Show Entries: {entriesToShow === filteredData.length ? "All" : entriesToShow}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => handleEntriesChange(5)}>5</DropdownItem>
                                                            <DropdownItem onClick={() => handleEntriesChange(10)}>10</DropdownItem>
                                                            <DropdownItem onClick={() => handleEntriesChange(filteredData.length)}>All</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                   

                                                </div>
                                            </Row>
                                        </CardHeader>

                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr style={{ textAlign: "center" }}>

                                                    <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Sl.No </th>
                                                    <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Request Name </th>
                                                    <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Deadline </th>
                                                    <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Request To</th>
                                                    <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Description </th>
                                                    <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Status </th>

                                                    <th style={{ fontSize: "14px", color: "black", fontWeight: "700" }} scope="col"> Action </th>


                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "center" }}>
                                                {dataToShow.map((item, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">{getSerialNumber(index)}</th>
                                                        <td>{item.requestName}</td>
                                                        <td>{item.deadline}</td>
                                                        <td>{item.requestTo}</td>
                                                        <td>
                                                            {item.description.length > 50 ? (
                                                                <>
                                                                    {item.description.substring(0, 50)}
                                                                    <span
                                                                        className="text-primary cursor-pointer"
                                                                        onClick={() => handleViewMore(item.description)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        ... View More
                                                                    </span>
                                                                </>
                                                            ) : item.description}
                                                        </td>
                                                        <td>{item.status}</td>






                                                        <td>
                                                            <Dropdown isOpen={dropdownOpen[index]} toggle={() => toggleDropdown(index)}>
                                                                <DropdownToggle color="transparent" className="btn-icon-only" caret>
                                                                    <i className="fas fa-ellipsis-v" style={{ fontSize: "1.5rem", color: "#8898aa" }}></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu right>



                                                                    <DropdownItem onClick={() => navigate('/store-admin/store-management/EditRequest4')}>
                                                                        <i className="fas fa-edit mr-2"></i> Edit
                                                                    </DropdownItem>
                                                                    <DropdownItem divider />
                                                                    <DropdownItem onClick={() => handleDeleteClick(item.id)}>
                                                                        <i className="fas fa-trash-alt mr-2"></i> Delete
                                                                    </DropdownItem>

                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </td>




                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>

                                        <nav className='mt-3'>
                                            <ul className='pagination justify-content-center '>
                                                <li className='page-item '>
                                                    <a href='#' className='page-link text-primary' onClick={prePage}>Prev</a>
                                                </li>
                                                {[currentPage - 1, currentPage, currentPage + 1].map((pageNumber) => {
                                                    if (pageNumber > 0 && pageNumber <= Math.ceil(allData.length / entriesToShow)) {
                                                        return (
                                                            <li key={pageNumber} className='page-item'>
                                                                <a href='#' className={`page-link text-white ${pageNumber === currentPage ? 'active' : ''}`} style={{ backgroundColor: '#5E72E4' }} onClick={() => changeCPage(pageNumber)}>{pageNumber}</a>
                                                            </li>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <li className='page-item'>
                                                    <a href='#' className='page-link text-primary' onClick={nextPage}>Next</a>
                                                </li>
                                            </ul>
                                        </nav>


                                        {/* Delete Confirmation Modal */}

                                        <Modal
                                            isOpen={showDeleteConfirmation}
                                            toggle={() => setShowDeleteConfirmation(false)}
                                        >
                                            <ModalHeader>Confirm Deletion</ModalHeader>
                                            <ModalBody>Are you sure you want to delete this request?</ModalBody>
                                            <div className="modal-footer">
                                                <Button color="danger" onClick={handleDeleteConfirmation}>
                                                    Yes
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    onClick={() => setShowDeleteConfirmation(false)}
                                                >
                                                    No
                                                </Button>
                                            </div>
                                        </Modal>

                                        {/* View More Modal */}

                                        <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
                                            <ModalHeader toggle={() => setShowModal(false)}>Full Description</ModalHeader>
                                            <ModalBody>{selectedDescription}</ModalBody>
                                            <div className="modal-footer">
                                                <Button color="secondary" onClick={() => setShowModal(false)}>
                                                    Close
                                                </Button>
                                            </div>
                                        </Modal>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </React.Fragment>

                </Container>
            </div>
        </>
    );
};

export default Requestwaste4;
