/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "dashboard/general-management/variables/charts.js";

import Header from "dashboard/general-management/components/Headers/Header.js";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Progress,
  CardImg,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Import the default calendar styles
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Import the styles
import { api } from "../../../api";
import Cookies from "js-cookie";
import { loadImage } from "Utility/imageIssue";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };
  const [date, setDate] = useState(new Date());
  const [selectedDateLabel, setSelectedDateLabel] = useState("");
  const [isBirthday, setIsBirthday] = useState(false);
  const [timeOfDay, setTimeOfDay] = useState("");

  // Function to format the selected date label
  const getFormattedDateLabel = (date) => {
    // Customize the date label format as needed
    return `${date.toLocaleString("default", {
      month: "long",
    })} ${date.getDate()}, ${date.getFullYear()}`;
  };
  useEffect(() => {
    // Update the selected date label when the date changes
    setSelectedDateLabel(getFormattedDateLabel(date));
  }, [date]);

  const checkBirthday = (date) => {
    // Check if the selected date matches a predefined birthday (e.g., January 1)
    return date.getMonth() === 0 && date.getDate() === 1;
  };

  const getTimeOfDay = (date) => {
    const hour = date.getHours();

    if (hour >= 0 && hour < 12) {
      return "Morning🌞";
    } else if (hour >= 12 && hour < 18) {
      return "Afternoon⛅"; // Corrected to 'Afternoon'
    } else {
      return "Night🌙";
    }
  };

  const emp_id = Cookies.get("token");
  // const emp_id = Cookies.get('token');

  useEffect(() => {
    const label = getFormattedDateLabel(date);
    setSelectedDateLabel(label);

    // Check if it's the user's birthday
    setIsBirthday(checkBirthday(date));

    // Determine the time of day
    setTimeOfDay(getTimeOfDay(date));
  }, [date]);

  const [progressPercentage, setProgressPercentage] = useState(75);

  const [loading, setLoading] = useState(true);

  const [project, setproject] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`viewProjectDetails?gm_id=${emp_id}`);
        setproject(response.data);
      } catch (error) {
        console.log('Error fetching Data', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [emp_id]);



  console.log(project);

  const [employees, setEmployees] = useState([]);
  const fetchEmployeeName = async () => {
    try {
      const response = await api.get("employeeCalendar.php");
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchEmployeeName();
  }, []);

  useEffect(() => {
    const label = getFormattedDateLabel(date);
    setSelectedDateLabel(label);

    // Check if it's the user's birthday
    // const isAnyBirthday = employees.some(employee => isEmployeeBirthday(date, employee))
    // setIsBirthday(checkBirthday(date));

    // Determine the time of day
    setTimeOfDay(getTimeOfDay(date));
  }, [date, employees]);

  const isEmployeeBirthday = (date, employee) => {
    const employeeDOB = new Date(employee.dob);
    return (
      date.getDate() === employeeDOB.getDate() &&
      date.getMonth() === employeeDOB.getMonth()
    );
  };

  const isEmployeeWorkAnniversary = (date, employee) => {
    const anniversaryDate = new Date(employee.doj);
    return (
      date.getDate() === anniversaryDate.getDate() &&
      date.getMonth() === anniversaryDate.getMonth()
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <div className="header bg-gradient-info py-8 py-lg-6">
        <Container fluid>
          <Row className="mt-0">
            <Col lg="8">
              {" "}
              {/* Adjust the column size to your preference */}
              {/* Your existing cards */}
              <Card className="card-stats mb-4 mb-xl-0 m-0">
                <CardBody>
                  <Row>
                    <h1>Overview</h1>
                  </Row>
                </CardBody>
              </Card>
              {Array.isArray(project) && project.length > 0 ? (
                project.map((project) => (
                  <Card
                    key={project.projectId}
                    className="card-stats mb-4 mb-xl-0 p-2 mt-3"
                    style={{ borderRadius: "50px" }}
                  >
                    <CardBody>
                      <Row>
                        {/* Circular Progress Bar on the Left */}
                        <Col xs="3" className="text-center">
                          <div style={{ width: "80px", height: "80px" }}>
                            <CircularProgressbar
                              value={project.progress}
                              text={`${project.progress}%`}
                              styles={buildStyles({
                                textColor: "blue",
                                pathColor: "#007bff",
                                trailColor: "#eee",
                              })}
                            />
                          </div>
                        </Col>

                        <Col xs="6" className="text-center">
                          <h3>{project.projectName}</h3>
                          <p>About: {project.description}</p>
                        </Col>

                        <Col xs="3" className="d-flex justify-content-end">
                          <div className="avatar-group d-flex">
                            {project.profile.images.map((image, index) => (
                              <div
                                className="avatar-group "
                                style={{ height: "40px", width: "25px" }}
                                key={index}
                              >
                                <a
                                  className="avatar avatar-sm "
                                  href="#pablo"
                                  id={`tooltip-${project.projectId}-${index}`} // Use project.projectId and index for unique IDs
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <img
                                    alt={`Image ${index}`}
                                    className="rounded-circle"
                                    style={{ height: "35px", width: "35px" }}
                                    src={loadImage(image)}
                                  />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-${project.projectId}-${index}`}
                                >
                                  {project.profile.full_names[index]}
                                </UncontrolledTooltip>
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                ))
              ) : (
                <div className="mt-4" style={{ textAlign: "center" }}>
                  No projects available
                </div>
              )}
            </Col>
            <Col lg="4">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h3"
                        className="text-uppercase text-muted mb-0"
                      >
                        Calendar
                      </CardTitle>
                    </div>
                  </Row>
                  <Row>
                    <Col className="col-auto mt-3 mr-3">
                      {/* Use the Calendar component with inline CSS */}
                      <Calendar
                        style={{
                          width: "100%", // Set the width to 100% of the container
                          height: "300px", // Set a fixed height
                          backgroundColor: "#fff", // Set background color
                          borderRadius: "8px", // Add border-radius for rounded corners
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a subtle box shadow
                        }}
                        onChange={setDate}
                        value={date}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <p>Selected Date: {selectedDateLabel}</p>
                      {isBirthday && (
                        <div>
                          <Row>
                            <Col className="col-auto">
                              {/* Add the image on one side */}
                              <img
                                src="path/to/birthday_image.jpg"
                                alt="Birthday"
                                style={{
                                  width: "100px", // Set the width of the image
                                  height: "100px", // Set the height of the image
                                  borderRadius: "50%", // Make it a circular image
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                      {timeOfDay && (
                        <p
                          className="text-center p-3"
                          style={{
                            fontWeight: "bold",
                            color: "#000",
                            backgroundColor: "#dff6f9",
                            borderRadius: "0.375rem",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          Good {timeOfDay}
                        </p>
                      )}
                    </Col>
                  </Row>

                  {/* Birthday and Anniversary */}

                  {employees.some(
                    (employee) =>
                      isEmployeeBirthday(date, employee) ||
                      isEmployeeWorkAnniversary(date, employee)
                  ) && (
                    <>
                      {employees.map((employee, index) => {
                        if (
                          isEmployeeBirthday(date, employee) &&
                          isEmployeeWorkAnniversary(date, employee)
                        ) {
                          return (
                            <Row
                              key={index}
                              style={{
                                border: "2px solid #ddd",
                                borderRadius: "10px",
                                padding: "20px",
                                backgroundColor: "#fff",
                                boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                                marginBottom: "10px",
                              }}
                            >
                              <Col xs={3} className="text-center">
                                <img
                                  src={loadImage(employee.profile_image)}
                                  alt="Birthday & Work Anniversary"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50%",
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  }}
                                />
                              </Col>
                              <Col>
                                <p
                                  style={{
                                    fontSize: "bold",
                                    color: "#333",
                                    marginBottom: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  🎉Happy Birthday & Work Anniversary!🎉
                                </p>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    fontSize: "18px",
                                    color: "#666",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {employee.emp_name}
                                </p>
                                <p
                                  style={{
                                    marginTop: "10px",
                                    fontSize: "16px",
                                    color: "#555",
                                    textAlign: "center",
                                  }}
                                >
                                  Double celebration! Wishing you a day filled
                                  with joy, laughter, and all the things you
                                  love.
                                </p>
                              </Col>
                            </Row>
                          );
                        } else if (isEmployeeBirthday(date, employee)) {
                          return (
                            <Row
                              key={index}
                              style={{
                                border: "2px solid #ddd",
                                borderRadius: "10px",
                                padding: "20px",
                                backgroundColor: "#fff",
                                boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                                marginBottom: "10px",
                              }}
                            >
                              <Col xs={3} className="text-center">
                                <img
                                  src={loadImage(employee.profile_image)}
                                  alt="Birthday"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50%",
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  }}
                                />
                              </Col>
                              <Col>
                                <p
                                  style={{
                                    fontSize: "bold",
                                    color: "#333",
                                    marginBottom: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  🎉Happy Birthday!🎉
                                </p>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    fontSize: "18px",
                                    color: "#666",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {employee.emp_name}
                                </p>
                                <p
                                  style={{
                                    marginTop: "10px",
                                    fontSize: "16px",
                                    color: "#555",
                                    textAlign: "center",
                                  }}
                                >
                                  Wishing you a day filled with joy, laughter,
                                  and all the things you love!
                                </p>
                              </Col>
                            </Row>
                          );
                        } else if (isEmployeeWorkAnniversary(date, employee)) {
                          return (
                            <Row
                              key={index}
                              style={{
                                border: "2px solid #ddd",
                                borderRadius: "10px",
                                padding: "20px",
                                backgroundColor: "#fff",
                                boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                                marginBottom: "10px",
                              }}
                            >
                              <Col xs={3} className="text-center">
                                <img
                                  src={loadImage(employee.profile_image)}
                                  alt="Work Anniversary"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50%",
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  }}
                                />
                              </Col>
                              <Col>
                                <p
                                  style={{
                                    fontSize: "bold",
                                    color: "#333",
                                    marginBottom: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  🎉Congratulations on your work anniversary!🎉
                                </p>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    fontSize: "18px",
                                    color: "#666",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {employee.emp_name}
                                </p>
                                <p
                                  style={{
                                    marginTop: "10px",
                                    fontSize: "16px",
                                    color: "#555",
                                    textAlign: "center",
                                  }}
                                >
                                  It's been a pleasure having you on the team.
                                  Your hard work and dedication are appreciated.
                                </p>
                              </Col>
                            </Row>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </>
                  )}

                  {/* ... Your existing code ... */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
