import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, Table, Container, Row, Col } from 'reactstrap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { api } from '../../../../api';
import Cookies from 'js-cookie';


const Report2 = () => {
  const [reportData, setReportData] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [loading, setLoading] = useState(false);

  const emp_id = Cookies.get('token');

  const handleGenerateReport = async () => {
    let url = `pdfgenarate.php?engineer_task_id=${emp_id}`;

    if (selectedYear && selectedMonth) {
        url += `&year=${selectedYear}&month=${selectedMonth}`;
    } else if (selectedYear) {
        url += `&year=${selectedYear}`;
    } else if (selectedMonth) {
        url += `&month=${selectedMonth}`;
    }

    try {
        const response = await api.get(url);
        let path = response.data.path;
        let blob = 'http://localhost/AECP/src/dashboard/taskreport/' + path;
        const link = document.createElement("a");
        link.href = blob; 
        link.setAttribute("download", ""); // Force download by setting download attribute to an empty string
        link.style.display = "none"; 
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};





  const months = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
];

  const currentYear = new Date().getFullYear();
  const years = [];

  // Generate the current 10 years and the previous 10 years
  for (let i = currentYear - 5; i <= currentYear + 15; i++) {
    years.push(i);
  }




  const fetchData = () => {
    setLoading(true);
    let url = `viewtaskByID.php?id=${emp_id}&`; // Replace this with your backend URL
    if (selectedYear && selectedMonth) {
      url += `year=${selectedYear}&month=${selectedMonth}`;
    } else if (selectedYear) {
      url += `year=${selectedYear}`;
    } else if (selectedMonth) {
      url += `month=${selectedMonth}`;
    }

    api.get(url)
      .then(response => {
        setReportData(response.data);
        setLoading(false);
        console.log(reportData)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [selectedYear, selectedMonth]);

  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container fluid>
          <Row className="mb-5"></Row>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Task Details</h3>
                    </div>
                    <div  className='mr-3 mb-2 mb-sm-0 d-flex' >
                      <select className='form-control ' value={selectedYear} style={{  overflowY: 'auto',color:'black', border : '1px solid black' }} onChange={e => setSelectedYear(e.target.value)}>
                        <option value="">Year</option>
                        {years.map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                      </select>
                      <select className='form-control ml-2' value={selectedMonth} style={{ overflowY: 'auto',color:'black', border : '1px solid black' }} onChange={e => setSelectedMonth(e.target.value)}>
                        <option value="">Month</option>
                        {months.map(month => (
                          <option key={month.value} value={month.value}>
                              {month.label}
                          </option>
                      ))}
                      </select>
                    </div>
                    <div className="col text-right">
                      <Button color="primary" onClick={handleGenerateReport}>
                        Generate Report
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table id="report-table" className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                      <th scope="col">SL.No</th>
                      <th scope="col">Projects</th>
                      <th scope="col">Task</th>
                      <th scope="col">Due</th>
                      <th scope="col">StartTime</th>
                      <th scope="col">Description</th>
                      <th scope="col">Hours</th>
                    </tr>
                  </thead>
                  <tbody>

                    {reportData && reportData.length > 0 ? (
                      reportData.map((item, index) => (
                        <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.p_name}</td>
                        <td>{item.taskname}</td>
                        <td>{item.start_date.replace('T', '/')}</td>
                        <td>{item.end_date.replace('T', '/')}</td>
                        <td>{item.t_description.replace(/<[^>]*>?/gm, '')}</td>
                        <td>{item.time_taken_formatted}</td>
                      </tr>

                        ))
                                    ) : (
                                    <tr>
                                        <td colSpan="7" style={{ textAlign: 'center' }}>No Tasks available, for selected year and date.</td>
                                    </tr>
                                    )}
                   
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Report2;
